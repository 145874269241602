import {
  ELASTIC_DASHBOARDS_ACTION,
  HOME_ACTION,
  MINI_APPS_ACTION,
  PUSH_NOTIFICATIONS_ACTION,
  AUTOMATION_ACTION,
  ENTRY_STATUS_ACTION,
  AGGREGATION_ACTION,
} from './actionType';
import {
  captureError,
  captureInfo,
  firestore,
  ShowToast,
  versionCompare,
  getDeviceVersion,
  ENV,
  logAnalyticsEvent,
  database,
  getReduxState,
  ToastInstance,
} from '../imports';
import {
  omit,
  isFunction,
  isEmpty,
  forOwn,
  isEqual,
  sortBy,
  orderBy,
  isObject,
  flatMap,
  map,
  isNil,
  merge,
  pick,
  isInteger,
} from 'lodash';
import * as miniAppsActionHelper from './actionHelpers/miniAppsActionHelper';
import {
  getRowIdRowDataMapping,
  processRowsDataForMappedRowId,
  getPaginatedTableDataWrapper,
  makeRowObjFromRowDoc,
  getTableSortingOrder,
  GetIncrementalIndexes,
} from './actionHelpers/tableActionHelper';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {
  serializeError,
  JSONStringifier,
  handleCloudErrorMsgAndLogging,
  getLocalText,
  getUserCallingCode,
  getTimezone,
  // getNewRowPropertiesObject,
  getMiniAppsLogObject,
  getFrequency,
  getFiltersArrFromSelectedOptionsObj,
  getColumnFieldType,
  getPdfConfig,
  callCloudFunction,
  isKanbanConfigValid,
  getUpdateToastType,
} from '../utils/utils';
import {
  EXPLORE_LIO_MINI_APP_STEPS,
  EXPLORE_LIO_STEPS,
  FIELD_TYPE_ID,
  MINI_APPS,
  MESSAGE_AUTOMATION,
  FIELD_OBJ_MANIPULATION_PROPERTY,
  DASHBOARD_SUB_TYPE,
  DASHBOARD_TYPES_V3,
  TABLE_LIMITS,
  DATA_FILTERS,
  CLOUD_FUNCTION_PATHS,
  PDF_FIELD_EXPORTS,
  FIELD_OPERATION_TYPE,
  MINI_APPS_DATE_TIME_ENTRY,
} from '../utils/constant';
import {documentDataListenerWrapper} from './tableAction';
import {
  commonBodyParamsForElastic,
  mapFilterArrForStoringFirestore,
} from './actionHelpers/searchFilterActionHelper';
import MiniAppsAccessManager from '../utils/miniApps/MiniAppsAccessManager';
import {backOff} from 'exponential-backoff';
// import {fetchParentFileData} from './tableLinksActions';
import moment from 'moment';
import {updateGlobalLoader} from './homeAction';
import {checkUserPlanForAccess} from './organisationAction';
import {setExploreForApps} from './authAction';
import PushNotificationArray from '../utils/CustomClass/PushNotificationArray';
import {
  resetAutomationState,
  deleteAutomationForDependentCol,
  loadMiniAppAutomationConfig,
} from './automationAction';
import {getListColumnMappedStatesForEntry} from './actionHelpers/listColumnsActionHelper';
import {
  fetchAllDasboardsForScreen,
  setEditDashboardId,
} from './elasticDashboardsAction';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {
  addStatusRequest,
  updateStatusRequestForEditRowOpInKanban,
  updateStatusRequestForFailure,
  updateStatusRequestForSuccess,
} from './entryStatusAction';
import {generateRandomId} from '../utils/RandomGenerator';
import {NotificationService} from '../utils/notifyUsers/notificationUtils';
import {
  NOTIFICATION_TYPE,
  SERVICE_TYPE,
} from '../utils/notifyUsers/notificationConstant';
import {nanoid} from 'nanoid';

const NUMBER_OF_ROWS_TO_FETCH = 20;
const SCREEN_SEARCH_FILTER = 'SCREEN_SEARCH_FILTER';
const delayedExecution = (functionToCall) => setTimeout(functionToCall, 2000); //search-filter delay as data will not be indexed immediately and no results will be shown for 90% of the cases

//get miniApps (listener)
const activateMiniAppsListener =
  (firestoreInstance, onCloseApp, toastFunction) => (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
        home: {listenersObj},
      } = getState();
      firestoreInstance = firestoreInstance ?? firestore;
      const uid = user?.uid;
      if (!uid || listenersObj?.MINI_APPS_LISTENER) {
        return;
      }

      dispatch({
        type: MINI_APPS_ACTION.UPDATE_LOADING_MINI_APPS,
        payload: true,
      });

      const queries = [
        ['appOwner.uid', '==', uid], //apps owned by user
        [`sharedWith.${uid}.timestamp`, '>', 0], //apps shared with user
      ];

      const initialFetchSuccessArr = queries.map(() => false);

      const listenersArr = queries.map((query, queryIndex) => {
        let isFirstFetch = true;
        const isMiniAppOwner = queryIndex === 0;

        const handleListener = (snapshot) => {
          try {
            const changedDocuments = snapshot.docChanges();
            const updatedMiniAppsObj = {};
            const removedMiniAppsIds = [];

            changedDocuments.forEach((change) => {
              try {
                switch (change.type) {
                  case 'added':
                  case 'modified': {
                    const miniAppId = change.doc.id;

                    updatedMiniAppsObj[miniAppId] =
                      miniAppsActionHelper.getFormattedMiniAppObj(
                        change.doc.data(),
                        miniAppId,
                        uid,
                        userPref,
                      );

                    break;
                  }
                  case 'removed': {
                    removedMiniAppsIds.push(change.doc.id);
                    break;
                  }
                }
              } catch (err) {
                captureInfo({
                  err: serializeError(err),
                  changedDocuments: JSONStringifier(changedDocuments),
                });
                captureError(
                  new Error(
                    'Error miniApps listener (change-doc) : isMiniAppOwner : ' +
                      isMiniAppOwner,
                  ),
                  true,
                );
              }
            });

            const {
              miniApps: {
                miniApps,
                activeAppId,
                activeScreenId,
                activeCustomRoleInfo,
              },
            } = getState();
            const updatedMiniApps = omit(
              Object.assign({}, miniApps, updatedMiniAppsObj),
              removedMiniAppsIds,
            );

            dispatch({
              type: MINI_APPS_ACTION.LOAD_MINI_APPS,
              payload: updatedMiniApps,
            });
            if (activeAppId) {
              const appExist = activeAppId in updatedMiniApps;
              const updatedActiveAppData = updatedMiniApps[activeAppId];
              const isPermissionUnchanged =
                updatedActiveAppData?.sharedWith?.[uid]?.permission ==
                  miniApps[activeAppId]?.sharedWith?.[uid]?.permission &&
                updatedActiveAppData?.sharedWith?.[uid]?.customRoleId ==
                  miniApps[activeAppId]?.sharedWith?.[uid]?.customRoleId;
              if (appExist && isPermissionUnchanged) {
                dispatch(updateMiniAppAccessManagerInstance());
                dispatch(loadAppActualData(firestoreInstance, miniApps));
                const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
                  updatedActiveAppData,
                  uid,
                  activeCustomRoleInfo,
                  false,
                  true,
                );
                const screenExist = screens.some(
                  (screen) => screen.screenId === activeScreenId,
                );
                if (!screenExist) {
                  dispatch(changeScreen(null, true));
                }
              } else {
                onCloseApp?.(); //navigate back to apps home
                dispatch(closeApp());
                ENV &&
                  alert(
                    getLocalText(
                      userPref,
                      appExist
                        ? isPermissionUnchanged
                          ? 'This screen has been deleted from the app. Please reopen the app to continue using it.'
                          : 'Your permission for this app has been changed. Please reopen the app to continue using it.'
                        : 'This app has been deleted.',
                    ),
                  );
              }
            }
          } catch (err) {
            captureInfo({
              err: serializeError(err),
              snapshot,
            });
            captureError(
              new Error('Error miniApps listener (processing-snapshot)'),
              true,
            );
          }

          if (isFirstFetch) {
            isFirstFetch = false;
            initialFetchSuccessArr[queryIndex] = true;
            if (initialFetchSuccessArr.every((val) => val)) {
              //all the miniApps are fetched
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_LOADING_MINI_APPS,
                payload: false,
              });
            }
          }
        };

        return FirestoreDB.FirestoreListener(
          FirestoreDB.miniApps.appCollection(firestoreInstance).where(...query),
          handleListener,
        );
      });

      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {
          MINI_APPS_LISTENER: () => {
            listenersArr.forEach((listener) => {
              //deactivate
              if (isFunction(listener)) {
                listener();
              }
            });
          },
        },
      });
      if (typeof onCloseApp === 'function') {
        dispatch(addToFunctionsRefs('ON_CLOSE_APP', onCloseApp));
      }
    } catch (error) {
      captureError(error);
    }
  };

const addToFunctionsRefs = (functionRef, functionCallback) => (dispatch) =>
  typeof functionCallback === 'function' &&
  dispatch({
    type: MINI_APPS_ACTION.UPDATE_FUNCTION_REFS,
    payload: {[functionRef]: functionCallback},
  });

const createEditMiniApp =
  ({
    appName,
    iconName,
    iconColor,
    documentId,
    layoutId,
    mapping,
    mappedValuesConfig,
    isEdit = false, //appName, iconColor, iconName, appId (required only)
    isDelete = false, //appId (required only)
    appId = null, //required for isEdit and isDelete
    isFromMiniAppsPage = false,
    hiddenColIds, //columns to hide from this screen
    appLanguageText,
    //noOfCardsInARow = null, // only required for catalog cards
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: miniAppsInitialState,
      } = getState();

      if (!user?.uid) {
        return null;
      }

      if (!isEdit && !isDelete) {
        //add app
        if (
          Object.values(miniAppsInitialState.miniApps).some(
            (appObj) => appObj.isMiniAppOwner,
          ) //if user have atleast 1 own app
        ) {
          if (!dispatch(checkUserPlanForAccess(true, false, 'CREATE_APP'))) {
            return null;
          }
        }
      }

      const dataObj = Object.assign(
        {},
        {appId},
        {appName, iconColor, iconName, isEdit, appLanguageText},
        isDelete
          ? {isDelete}
          : isEdit
            ? {}
            : {
                hiddenColIds, //columns to hide from this screen
                documentId,
                layoutId,
                mapping,
                mappedValuesConfig,
                //  noOfCardsInARow,
              },
      );
      const {
        activeScreenData: activeScreenMeta,
        activeScreenId,
        activeAppMeta,
      } = isFromMiniAppsPage
        ? miniAppsActionHelper.mapMiniAppStates(
            getState(),
            false,
            false,
            null,
            appId,
          )
        : miniAppsActionHelper.mapMiniAppStates(getState(), false);

      if (isDelete) {
        logAnalyticsEvent('DELETE_APP_INIT', {
          ...getMiniAppsLogObject(getState()),
          iconId: activeAppMeta.appIcon,
          iconName: activeAppMeta.appIcon,
          colorId: activeAppMeta.appIconColor,
          colorName: activeAppMeta.appIconColor,
          iconNameScreen: activeScreenMeta.screenIcon,
          cardLayout: activeScreenMeta.layoutId,
          p_appId: appId,
          p_screenName: activeScreenMeta.screenName,
          screenId: activeScreenId,
          p_recordCount: 0,
          p_appName: activeAppMeta.appName,
        });
      }

      const data =
        await miniAppsActionHelper.createEditMiniAppCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      } else {
        if (!isEdit && !isDelete) {
          await DocumentsMethods.getUserDocumentDataWithoutRows(documentId); //to ensure correct sort order is fetched from cache
        }
        const {miniApps} = getState().miniApps;
        if (isDelete) {
          logAnalyticsEvent('DELETE_APP_SUCCESS', {
            ...getMiniAppsLogObject(getState()),
            iconId: activeAppMeta.appIcon,
            iconName: activeAppMeta.appIcon,
            colorId: activeAppMeta.appIconColor,
            colorName: activeAppMeta.appIconColor,
            iconNameScreen: activeScreenMeta.screenIcon,
            cardLayout: activeScreenMeta.layoutId,
            p_appId: appId,
            p_appName: activeAppMeta.appName,
            p_screenName: activeScreenMeta.screenName,
            screenId: activeScreenId,
            p_recordCount: 0,
          });
        }
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: isDelete
            ? omit(miniApps, [data.miniAppId])
            : Object.assign({}, miniApps, {
                [data.miniAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
                  data.miniAppDocData,
                  data.miniAppId,
                  user.uid,
                  userPref,
                ),
              }),
        });
        const defaultMsg = isDelete
          ? 'App deleted successfully.'
          : isEdit
            ? 'App modified successfully.'
            : 'App created successfully.';
        const message = getLocalText(userPref, data.message ?? defaultMsg);
        ShowToast(message, userPref);
        return data.miniAppId;
      }
    } catch (error) {
      captureError(error);
    }
    return null;
  };

const addEditScreenMiniApp =
  (
    {
      screenName,
      documentId,
      iconName,
      layoutId,
      addItemText, //entry Options
      entryColIds, //entry Options
      entryHideColIds, //entry Options
      disableEdit = false, //entry Options
      disableEntry = false, //entry Options
      mapping,
      mappedValuesConfig,
      view, // config for screen viewtype : Object
      hiddenColIds, //columns to hide from this screen
      type,
      filterOptions, //Should be prechecked for validity
      isEdit = false,
      screenId = null, //required in case of isEdit = true
      isDelete = false, //only appId and screenId required in case of isDelete = true
      isModifyScreenViewLayout = false, //Pass to modify screen View type Layout
      alwaysFullScreen = null,
      customLayoutConfigs,
      customLayoutType,
      //  noOfCardsInARow = null, // only required for catalog cards
      kanbanMeta = null, // only used for screens created/modified/deleted for kanban view configuration
      doNotShowToast = false,
      doNotChangeScreen = false,
      //screen localization
      screenLanguageText = {},
      entryBtnLanguageText = {},
    },
    firestoreInstance,
  ) =>
  async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeScreenId, miniApps, docsData},
        elasticDashboards: {activeDashboardId},
      } = getState();

      if (!user?.uid) {
        return;
      }

      type =
        type in MINI_APPS.SCREEN_TYPE
          ? type
          : MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA;

      const isFilteredDataScreen = type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA;

      if (
        isFilteredDataScreen &&
        (!Array.isArray(filterOptions) || !filterOptions.length)
      ) {
        !doNotShowToast &&
          ShowToast('Please select some filter configuration.', userPref);
        return false;
      }

      const activeScrenMeta = miniApps[activeAppId]?.screens?.[screenId];
      const activeScreenDoc = activeScrenMeta?.docs?.[0];

      const screenCustomLayoutConfigs = activeScrenMeta?.customLayoutConfigs;
      const screenCustomLayoutType = activeScrenMeta?.customLayoutType;
      const screenDisableEntry = activeScrenMeta?.disableEntry;
      const screenDisableEdit = activeScrenMeta?.disableEdit;
      const screenView = Object.assign({}, activeScrenMeta?.view, view);

      //!Note update Layout Params when modifiying screenParams
      const modifyViewLayoutScreenObject = isModifyScreenViewLayout
        ? {
            screenName: activeScrenMeta?.screenName,
            documentId: activeScreenDoc?.docId,
            iconName: activeScrenMeta?.screenIcon,
            layoutId: activeScrenMeta?.layoutId,
            mapping: miniAppsActionHelper.mapColumnHeaderDataWithColumnId(
              activeScreenDoc?.mapping,
              docsData[activeScreenDoc?.docId]?.headerDataAsObj,
              activeScrenMeta?.layoutId,
            ),
            ...(isEmpty(screenView) ? {} : {view: screenView}),
            customLayoutConfigs,
            customLayoutType: customLayoutType ?? screenCustomLayoutType,
            hiddenColIds: activeScrenMeta?.hiddenColIds,
            isEdit,
            screenId,
            type: activeScrenMeta?.type,
            filterOptions:
              activeScrenMeta?.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
                ? mapFilterArrForStoringFirestore(
                    activeScreenDoc?.filterOptions,
                  )
                : activeScreenDoc?.filterOptions,
            mappedValuesConfig: activeScreenDoc?.mappedValuesConfig,
            addItemText: activeScrenMeta?.addItemText,
            entryColIds: activeScrenMeta?.entryColIds,
            entryHideColIds: activeScrenMeta?.entryHideColIds,
            disableEdit: disableEdit || screenDisableEdit,
            disableEntry: disableEntry || screenDisableEntry,
            screenLanguageText: activeScrenMeta?.screenLanguageText,
            entryBtnLanguageText: activeScrenMeta?.entryBtnLanguageText,
          }
        : null;

      const dataObj = Object.assign(
        {},
        {screenId, appId: activeAppId},
        {groupId: activeScrenMeta?.groupId},
        {screenLanguageText},
        {entryBtnLanguageText},
        isDelete
          ? {isDelete}
          : type === MINI_APPS.SCREEN_TYPE.DASHBOARD
            ? {type, screenName, iconName, isEdit, screenId, alwaysFullScreen}
            : isModifyScreenViewLayout //Change view Layout type of Screen
              ? modifyViewLayoutScreenObject
              : {
                  screenName,
                  documentId,
                  iconName,
                  layoutId,
                  mapping,
                  ...(isEmpty(screenView) ? {} : {view: screenView}),
                  ...(screenCustomLayoutConfigs
                    ? {customLayoutConfigs: screenCustomLayoutConfigs}
                    : {}),
                  ...(screenCustomLayoutType
                    ? {customLayoutType: screenCustomLayoutType}
                    : {}),
                  hiddenColIds,
                  isEdit,
                  screenId,
                  type,
                  filterOptions: isFilteredDataScreen
                    ? mapFilterArrForStoringFirestore(filterOptions)
                    : filterOptions,
                  mappedValuesConfig,
                  addItemText,
                  entryColIds,
                  entryHideColIds,
                  disableEdit,
                  disableEntry,
                  ...(!isNil(kanbanMeta) ? {kanbanMeta} : {}),
                  ...(!isNil(activeScrenMeta?.entryConfigId)
                    ? {entryConfigId: activeScrenMeta.entryConfigId}
                    : {}),
                },
      );

      const data =
        await miniAppsActionHelper.addEditScreenMiniAppCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        if (
          !isEdit &&
          !isDelete &&
          MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(type)
        ) {
          await DocumentsMethods.getUserDocumentDataWithoutRows(documentId); //to ensure correct sort order is fetched from cache
        }
        const {miniApps: prevMiniAppsObj} = getState().miniApps;
        const updatedMiniApps = Object.assign({}, prevMiniAppsObj, {
          [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
            data.miniAppDocData,
            activeAppId,
            user.uid,
            userPref,
          ),
        });
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });
        dispatch(closeSearchFilterForScreen(screenId));
        !isDelete &&
          dispatch(loadAppActualData(firestoreInstance, prevMiniAppsObj)); //fallback if the listener is not triggered at the right moment
        if (isDelete) {
          if (!isNil(activeDashboardId)) {
            dispatch(setEditDashboardId(null));
          }

          const kanbanScreenIds =
            activeScrenMeta?.view?.[
              MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
            ]?.kanbanScreenIds;
          if (Array.isArray(kanbanScreenIds) && kanbanScreenIds.length > 0) {
            await dispatch(
              addEditMiniAppScreenForKanban({
                parentScreenId: screenId,
                mainScreenObj: activeScrenMeta,
                isDelete: true,
              }),
            );
          }
          !doNotChangeScreen && dispatch(changeScreen(null, true));
          dispatch({
            type: MINI_APPS_ACTION.REMOVE_MINI_APPS_FILTERED_DOC_DATA,
            payload: {
              screenId,
              docId: activeScreenDoc?.docId,
            },
          });
        } else if (data.modifiedScreenId !== activeScreenId) {
          !doNotChangeScreen && dispatch(changeScreen(data.modifiedScreenId));
        }
        const defaultMsg = isDelete
          ? getLocalText(userPref, 'Screen deleted successfully.')
          : isEdit
            ? getLocalText(
                userPref,
                `Screen SCREEN_NAME modified successfully.`,
              ).replace(
                'SCREEN_NAME',
                screenName || activeScrenMeta?.screenName,
              )
            : getLocalText(userPref, 'Screen added successfully.');
        const message = defaultMsg;
        !doNotShowToast && ShowToast(message, userPref);
        return {success: true, modifiedScreenId: data?.modifiedScreenId};
      }
    } catch (error) {
      captureError(error);
    }
  };

const addEditMultipleScreens =
  (data, firestoreInstance) => async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const reduxState = getState();
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeScreenId, miniApps, docsData},
        elasticDashboards: {activeDashboardId},
      } = reduxState;
      if (!user?.uid) {
        return;
      }

      const screenObjArrToSend = [];
      const postSuccessPromiseArr = [];
      const postSuccessDispatchArr = [];
      data.forEach((dataObj) => {
        const screenObj = miniAppsActionHelper.getDataObjForaddEditScreen(
          dataObj,
          reduxState,
        );
        const {isEdit, isDelete, type} = screenObj;
        screenObjArrToSend.push(screenObj);
        if (
          !isEdit &&
          !isDelete &&
          MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(type)
        ) {
          postSuccessPromiseArr.push(() =>
            DocumentsMethods.getUserDocumentDataWithoutRows(
              screenObj?.documentId,
            ),
          );
        }
        if (!isDelete) {
          postSuccessDispatchArr.push((prevMiniAppsObj) =>
            dispatch(loadAppActualData(firestoreInstance, prevMiniAppsObj)),
          );
        }
      });
      const dataObj = Object.assign(
        {},
        {appId: activeAppId},
        {screenObjArr: [...screenObjArrToSend]},
      );
      const res =
        await miniAppsActionHelper.addEditMultipleScreenMiniAppCloudFunction(
          dataObj,
        );
      if (!res || !res.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        const {success, miniAppId, miniAppDocData, modifiedScreenIds} = res;
        modifiedScreenIds?.forEach((screenId) =>
          dispatch(closeSearchFilterForScreen(screenId)),
        );
        await Promise.all(postSuccessPromiseArr.map((instance) => instance()));
        const {miniApps: prevMiniAppsObj} = getState().miniApps;
        const updatedMiniApps = Object.assign({}, prevMiniAppsObj, {
          [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
            miniAppDocData,
            activeAppId,
            user.uid,
            userPref,
          ),
        });
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });
        await Promise.all(
          postSuccessDispatchArr.map((instance) => instance(prevMiniAppsObj)),
        );
        return modifiedScreenIds;
      }
    } catch (error) {
      captureError(error);
    }
  };

const duplicateScreenMiniApp =
  ({screenId, duplicateAsFiltered = false}, firestoreInstance) =>
  async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeScreenId, miniApps},
      } = getState();

      if (!user?.uid) {
        return;
      }
      screenId = screenId ?? activeScreenId;

      const dataObj = {
        appId: activeAppId,
        screenId,
        duplicateAsFiltered,
      };
      const data =
        await miniAppsActionHelper.duplicateScreenMiniAppCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        const {miniApps: updatedMiniAppsObj} = getState().miniApps;
        const updatedMiniApps = Object.assign({}, updatedMiniAppsObj, {
          [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
            data.miniAppDocData,
            activeAppId,
            user.uid,
            userPref,
          ),
        });
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });
        dispatch(loadAppActualData(firestoreInstance, miniApps)); //fallback if the listener is not triggered at the right moment
        if (data.modifiedScreenId !== activeScreenId) {
          dispatch(changeScreen(data.modifiedScreenId));
        }
        ShowToast(
          duplicateAsFiltered
            ? 'Please add filter config to new screen.'
            : 'Screen added successfully.',
          userPref,
          true,
          duplicateAsFiltered,
        );
        return true;
      }
    } catch (error) {
      captureError(error);
      return false;
    }
  };

const manageScreensMiniApps =
  ({groups, hiddenScreenIdArr, hiddenGroupIdArr}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeCustomRoleInfo, miniApps: allApps},
      } = getState();

      if (!user?.uid) {
        return;
      }

      const activeAppMeta = allApps[activeAppId];
      const activeAppScreens = Object.assign({}, activeAppMeta.screens);

      const analyticsObj = {
        screenOrder: [],
        visibleScreenOrder: [],
        visibleScreenCount: 0,
      };
      groups.forEach((groupObj) => {
        const {screenId} = groupObj;
        const screenName = screenId && activeAppScreens[screenId]?.screenName;
        screenName && analyticsObj.screenOrder.push(screenName);
        if (screenId && !hiddenScreenIdArr.includes(screenId)) {
          analyticsObj.visibleScreenOrder.push(screenName);
          ++analyticsObj.visibleScreenCount;
        }
        //TODO : HANDLE analyticsObj FOR GROUP HIDE FLOW
      });
      logAnalyticsEvent('MANAGE_SCREENS_SAVE_CHANGES_INIT', analyticsObj);

      const dataObj = Object.assign(
        {},
        {
          groups,
          activeAppId,
          hiddenScreenIdArr,
          hiddenGroupIdArr,
        },
      );

      const data =
        await miniAppsActionHelper.manageScreenMiniAppsCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        logAnalyticsEvent('MANAGE_SCREENS_SAVE_CHANGES_SUCCESS', analyticsObj);
        const {miniApps, activeScreenId} = getState().miniApps;
        const updatedActiveAppMeta =
          miniAppsActionHelper.getFormattedMiniAppObj(
            data.miniAppDocData,
            activeAppId,
            user.uid,
            userPref,
          );
        const updatedMiniApps = Object.assign({}, miniApps, {
          [activeAppId]: updatedActiveAppMeta,
        });
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });

        const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
          updatedActiveAppMeta,
          user.uid,
          activeCustomRoleInfo,
          false,
          true,
        );

        if (!screens.some((screen) => screen.screenId === activeScreenId)) {
          dispatch(changeScreen(null, true));
        }

        return true;
      }
    } catch (error) {
      captureError(error);
    }
  };

const updateAppDataSortOrder =
  ({appId, isDescOrderOfIndex}, firestoreInstance, dbInstance) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {activeAppId},
      } = getState();

      if (!user?.uid && appId) {
        return;
      }
      const dataObj = Object.assign(
        {},
        {
          activeAppId: appId,
          property: {
            isDescOrderOfIndex: Boolean(isDescOrderOfIndex),
          },
        },
      );

      const data = await miniAppsActionHelper.changeMiniAppProperty(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        //success
        if (activeAppId === appId) {
          //if app opened
          const {miniApps} = getState().miniApps;
          const updatedMiniApps = Object.assign({}, miniApps, {
            [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
              data.miniAppDocData,
              activeAppId,
              user.uid,
              userPref,
            ),
          });
          dispatch({
            type: MINI_APPS_ACTION.LOAD_MINI_APPS,
            payload: updatedMiniApps,
          });
          return dispatch(
            reloadCurrentAppInternally(
              activeAppId,
              firestoreInstance,
              dbInstance,
            ),
          );
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const reloadCurrentAppInternally =
  (appId, firestoreInstance, dbInstance) => (dispatch) => {
    try {
      dispatch(closeApp(true));
      return dispatch(
        openApp(appId, null, firestoreInstance, dbInstance, false, true),
      );
    } catch (e) {
      captureError(e);
    }
  };

const updateEntryConfig =
  (screensArr = []) =>
  async (dispatch) => {
    const screensEntryConfig = {};
    const fetchEntryConfigPromises = [];

    screensArr?.forEach((screenObj) => {
      const {entryConfigId, screenId} = screenObj ?? {};
      if (entryConfigId) {
        fetchEntryConfigPromises.push(
          getPdfConfig(entryConfigId, 'miniAppScreenEntryConfig').then(
            (entryConfig = {}) => {
              Object.assign(screensEntryConfig, {
                [screenId]: {
                  isActive: true,
                  entryConfigId,
                  entryConfig: entryConfig.pdfConfig ?? {},
                },
              });
            },
          ),
        );
      }
    });

    await Promise.allSettled(fetchEntryConfigPromises);

    dispatch({
      type: MINI_APPS_ACTION.UPDATE_ENTRY_CONFIG,
      payload: screensEntryConfig,
    });
  };

/**
 * Open mini app action
 * @param {string} appId - Mini app Id
 * @param {Function | null} onOpen - Callback function to be called after app is opened.
 * @param {any} firestoreInstance - Firestore instance
 * @param {boolean} bypassLockCheck
 * @param {boolean} isInternalCall - Passed as true when called without user's interaction.
 * @param {object} extra - Extra data to be passed to the app.
 * @param {string} extra.screenId - Screen Id to be opened.
 * @returns
 */
const openApp =
  (
    appId,
    onOpen,
    firestoreInstance,
    dbInstance,
    bypassLockCheck = false,
    isInternalCall = false,
    extra = {},
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps},
        auth: {
          userPref,
          user: {uid},
        },
        organisation: {isUserOrganisationOwner},
      } = getState();
      const appData = miniApps[appId];
      if (!isEmpty(appData)) {
        const {minVersion, minVersionMsg} = appData;
        if (
          minVersion &&
          versionCompare(`${minVersion}`, `${getDeviceVersion()}`, false)
        ) {
          const msg = getLocalText(
            userPref,
            typeof minVersionMsg === 'string'
              ? minVersionMsg
              : 'This app contains data which is not supported by current version of the app. Please update your app to use this app. Contact customer support to learn more.',
          );
          if (ENV) {
            const {documentIncompatibleAlert} = require('../imports');
            documentIncompatibleAlert(userPref, msg);
          } else {
            alert(msg);
          }
          return false;
        }
        const isUserSubscribed = dispatch(checkUserPlanForAccess());
        if (!bypassLockCheck && !isUserSubscribed) {
          //check if free user can only open app
          //for org owner : latest app created by him
          //for org member: no app can be opened
          const isOpeningLatestOwnerApp = () => {
            const appsArr = Object.values(miniApps);
            let bool = false;
            for (let index = appsArr.length - 1; index >= 0; --index) {
              const appObj = appsArr[index];
              if (appObj.isMiniAppOwner) {
                bool = appObj.miniAppId === appId;
                break;
              }
            }
            return bool;
          };
          if (!isUserOrganisationOwner || !isOpeningLatestOwnerApp()) {
            dispatch(checkUserPlanForAccess(true, false, 'OPEN_APP'));
            return false;
          }
        }
        if (!appData.isMiniAppOwner) {
          const {permission, customRoleId} = Object.assign(
            {},
            appData.sharedWith?.[uid],
          );
          if (
            permission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE
          ) {
            if (typeof customRoleId !== 'string') {
              return false;
            }
            const isRoleExist = await dispatch(
              activateCustomRoleListener(
                customRoleId,
                appId,
                firestoreInstance,
              ),
            );
            if (!isRoleExist) {
              return false;
            }
          }
        }
        const {
          miniApps: {activeCustomRoleInfo},
          elasticDashboards: {allDashboards},
        } = getState();
        const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
          appData,
          uid,
          activeCustomRoleInfo,
          false,
          true,
        );

        if (
          extra?.screenId &&
          !screens.some((screen) => screen.screenId === extra.screenId)
        ) {
          return false;
        }

        const screenId = extra?.screenId ?? screens[0]?.screenId;

        dispatch({
          type: MINI_APPS_ACTION.OPEN_APP,
          payload: {
            appId,
            screenId,
          },
        });
        dispatch(setInitialScreenLocalConfigs());
        if (
          screenId &&
          miniApps[appId]?.screens?.[screenId]?.type ===
            MINI_APPS.SCREEN_TYPE.DASHBOARD &&
          !(screenId in allDashboards)
        ) {
          dispatch(fetchAllDasboardsForScreen(screenId));
        }
        dispatch(updateMiniAppAccessManagerInstance());

        if (!isInternalCall) {
          const activeAppMeta = miniApps[appId];
          const activeScreenMeta = Object.assign({}, screens[0]);
          logAnalyticsEvent('p_OPEN_APP', {
            ...getMiniAppsLogObject(getState()),
            iconId: activeAppMeta.appIcon,
            iconName: activeAppMeta.appIcon,
            colorId: activeAppMeta.appIconColor,
            colorName: activeAppMeta.appIconColor,
            iconNameScreen: activeScreenMeta.screenIcon,
            cardLayout: activeScreenMeta.layoutId,
            appId,
            p_screenName: activeScreenMeta.screenName,
            screenId,
            p_recordCount: 0,
          });
          dispatch({
            type: ENTRY_STATUS_ACTION.INITIALIZE_STATE,
            payload: {appId},
          });
          dispatch(loadMiniAppAutomationConfig());
        }

        dispatch(updateEntryConfig(screens));

        if (extra?.screenId) {
          await dispatch(loadAppActualData(firestoreInstance, null, extra));
        } else {
          dispatch(loadAppActualData(firestoreInstance));
        }

        if (!isInternalCall) {
          dispatch(activateAutomationsActivityListener(dbInstance));
          dispatch(activateFormulaUpdatesDBListener(dbInstance));
          dispatch(setProgressNotificationListener(firestoreInstance, appId));
        }

        onOpen?.({appId, screenId});
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const updateRunningProcesses =
  (request, isRemove = false, isMultiRemove = false) =>
  (dispatch) => {
    dispatch({
      type: MINI_APPS_ACTION.UPDATE_RUNNING_PROCESSES,
      payload: {
        request,
        isRemove,
        isMultiRemove,
      },
    });
  };

const automationToastTimeout = {};
const miniAppUpdateAutomationUpdateStatus =
  (
    currAutomationRunningStatus,
    extra = {toastId: '', type: NOTIFICATION_TYPE.REQUESTED},
  ) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        pushNotifications: {customCallbacks},
        automation: {miniAppAutomationConfig},
      } = state;

      const automations = Object.values(
        Object.assign({}, miniAppAutomationConfig),
      ).reduce(
        (acc, automationsMap) => Object.assign({}, acc, automationsMap),
        {},
      ); //since we show automation running tag on both source and destination row,so automationId of a doc can be inside both source and destination doc's row

      const runningAutomationProcess = {};

      const getRunningAutomations = (runningAutomationsObj) => {
        const automationIdDisplayTextMap = {};
        const customNotificationTextArr = new Set();
        if (!isEmpty(miniAppAutomationConfig)) {
          forOwn(runningAutomationsObj, (rowIdAutomationIdMap, docId) => {
            forOwn(rowIdAutomationIdMap, (automationIdMap) => {
              forOwn(automationIdMap, (value, automationId) => {
                const {name, customNotificationText} = Object.assign(
                  {},
                  automations[automationId],
                );

                const notificationText =
                  value === true
                    ? customNotificationText ||
                      `Running ${name || 'Automation'}`
                    : null;

                if (notificationText) {
                  automationIdDisplayTextMap[automationId] = notificationText;
                  customNotificationTextArr.add(notificationText);
                  if (ENV) {
                    if (!runningAutomationProcess[docId]) {
                      runningAutomationProcess[docId] = new Set();
                    }
                    runningAutomationProcess[docId].add(notificationText);
                  }
                }
              });
            });
          });
        }
        return {
          runningAutomations: [...customNotificationTextArr],
          automationIdDisplayTextMap,
        };
      };

      const {runningAutomations, automationIdDisplayTextMap} =
        getRunningAutomations(currAutomationRunningStatus);

      dispatch({
        type: AUTOMATION_ACTION.MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS,
        payload: {
          automationIdDisplayTextMap,
          currAutomationRunningStatus: Object.assign(
            {},
            currAutomationRunningStatus,
          ),
        },
      });
    } catch (err) {
      captureError(err);
    }
  };

const miniAppUpdateAutomationProgressWebAndMobile =
  (currAutomationRunningStatus, extra = {toastId: ''}) =>
  (dispatch, getState) => {
    try {
      const state = getState();
      const {
        automation: {miniAppAutomationConfig},
      } = state;

      const automations = Object.values(
        Object.assign({}, miniAppAutomationConfig),
      ).reduce(
        (acc, automationsMap) => Object.assign({}, acc, automationsMap),
        {},
      ); //since we show automation running tag on both source and destination row,so automationId of a doc can be inside both source and destination doc's row

      const runningAutomationProcess = {};
      const getRunningAutomations = (runningAutomationsObj) => {
        const automationIdDisplayTextMap = {};
        const customNotificationTextArr = new Set();
        if (!isEmpty(miniAppAutomationConfig)) {
          forOwn(runningAutomationsObj, (automationIdMap, docId) => {
            forOwn(automationIdMap, (value, automationId) => {
              const {name, customNotificationText} = Object.assign(
                {},
                automations[automationId],
              );

              const notificationText =
                value === true
                  ? customNotificationText || `Running ${name || 'Automation'}`
                  : null;

              if (notificationText) {
                automationIdDisplayTextMap[automationId] = notificationText;
                customNotificationTextArr.add(notificationText);
                if (ENV) {
                  if (!runningAutomationProcess[docId]) {
                    runningAutomationProcess[docId] = new Set();
                  }
                  runningAutomationProcess[docId].add(notificationText);
                }
              }
            });
            if (isEmpty(runningAutomationProcess[docId])) {
              runningAutomationProcess[docId] = [];
            }
          });
        }
        return {
          runningAutomations: [...customNotificationTextArr],
          automationIdDisplayTextMap,
        };
      };

      const {runningAutomations} = getRunningAutomations(
        currAutomationRunningStatus,
      );
      if (ENV) {
        return runningAutomationProcess;
      }

      const {
        pushNotifications: {customCallbacks},
      } = state;
      const {renderToastComponent} = Object.assign({}, customCallbacks);
      const toastId = extra?.toastId ?? 'AUTOMATION_LOADING';
      return renderToastComponent(
        [
          runningAutomations.map((name) => {
            return renderToastComponent(
              name,
              `aut_` + name,
              'automation-list-item',
            );
          }),
        ],
        toastId,
      );
    } catch (err) {
      captureError(err);
      return '';
    }
  };

async function executeAfterDelay(callback, delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, delay);
  });
}

const progressNotificationTimeouts = {};
const removedProgressNotif = {};
const setProgressNotificationListener =
  (firestoreInstance, initAppId) => (dispatch, getState) => {
    firestoreInstance = firestoreInstance ?? firestore;
    const {
      auth: {user},
      pushNotifications: {customCallbacks},
    } = getState();

    if (!user?.uid || !initAppId) {
      return;
    }

    const {
      getToastInstance,
      renderToastComponent,
      showCustomToastWrapper = () => {},
    } = customCallbacks ?? {};
    const toast = !isEmpty(ToastInstance)
      ? ToastInstance
      : typeof getToastInstance === 'function'
        ? getToastInstance?.()
        : {};

    const handleListener = (snapshot, isRemoveListener) => {
      try {
        const startDate = Date.now();
        const changedDocuments = snapshot.docChanges();
        const {
          miniApps: {activeAppId},
          runningProcesses: {
            processes: runningProcesses,
            progressNotifications,
          },
        } = getReduxState();

        if (initAppId !== activeAppId) {
          console.log('remove listener here');
          return;
        }

        const added = {};
        const removed = [];

        // For Mobile
        const requestedForScreen = {};
        const requestedForDoc = {};

        const removeRequest = {};

        changedDocuments.forEach((change) => {
          if (removedProgressNotif[change.doc.id]) {
            return;
          }
          try {
            const isToastActive = toast?.isActive?.(change.doc.id);
            const dismissToast = () => {
              if (!isToastActive || isEmpty(toast)) {
                return;
              }

              if (progressNotificationTimeouts[change.doc.id]) {
                clearTimeout(progressNotificationTimeouts[change.doc.id]);
              }

              progressNotificationTimeouts[change.doc.id] = setTimeout(
                () => toast?.dismiss?.(change.doc.id),
                2000,
              ); //close toast with a delay of 2 seconds
            };

            switch (change.type) {
              case 'added':
              case 'modified': {
                const data = change.doc.data();

                const commonAddUpdateConditions = () => {
                  const hasJustCompleted =
                    progressNotifications[change.doc.id] &&
                    !progressNotifications[change.doc.id]?.completedAt &&
                    data.completedAt;

                  return hasJustCompleted || data.isRunning;
                };

                if (commonAddUpdateConditions()) {
                  const getBody = () => {
                    if (data.service === SERVICE_TYPE.AUTOMATION) {
                      if (
                        data.type === NOTIFICATION_TYPE.REQUESTED &&
                        isEmpty(data.data.automationsMap)
                      ) {
                        if (ENV) {
                          return {
                            [data?.data?.displayDocId]: [
                              data?.data?.toast?.body,
                            ],
                          };
                        }

                        return renderToastComponent(data?.data?.toast?.body);
                      } else if (data.isRunning) {
                        return dispatch(
                          miniAppUpdateAutomationProgressWebAndMobile(
                            data.data.automationsMap,
                            {toastId: change.doc.id},
                          ),
                        );
                      } else {
                        return ENV ? '' : 'Automations Completed';
                      }
                    }

                    if (ENV) {
                      if (
                        [
                          SERVICE_TYPE.IMPORT,
                          SERVICE_TYPE.EXPORT,
                          SERVICE_TYPE.FORMULA_UPDATE,
                          SERVICE_TYPE.DELETE_ROWS,
                          SERVICE_TYPE.GENERATE_PDF,
                        ].includes(data.service)
                      ) {
                        return data?.data?.toast?.body;
                      }
                    }

                    return renderToastComponent(data?.data?.toast?.body);
                  };
                  const body = getBody();
                  const automationInitialRequest =
                    data.service === SERVICE_TYPE.AUTOMATION &&
                    (data.type === NOTIFICATION_TYPE.REQUESTED ||
                      data.type === NOTIFICATION_TYPE.INFO);
                  const isSuccessOrError =
                    data.type === NOTIFICATION_TYPE.SUCCESS ||
                    data.type === NOTIFICATION_TYPE.ERROR;
                  const shouldRemoveNotification =
                    !data.isRunning || isSuccessOrError;
                  const isInitialSet = isEmpty(
                    progressNotifications[change.doc.id],
                  );

                  const hasDataChanged =
                    progressNotifications[change.doc.id] &&
                    (progressNotifications?.[change.doc.id]?.type !==
                      data.type ||
                      !isEqual(
                        progressNotifications?.[change.doc.id]?.data,
                        data?.data,
                      ));
                  const commonCondition =
                    isInitialSet || hasDataChanged || shouldRemoveNotification;
                  if (
                    ENV &&
                    data.service === SERVICE_TYPE.GENERATE_PDF &&
                    commonCondition
                  ) {
                    const iconInfo = {
                      name: 'progress-download',
                      color: '#449F34',
                      groupName: 'MaterialCommunityIcons',
                    };
                    if (
                      !removedProgressNotif[change.doc.id] &&
                      shouldRemoveNotification
                    ) {
                      // if (data.type === NOTIFICATION_TYPE.ERROR) {
                      requestedForScreen[data.data.displayScreenId] =
                        requestedForScreen[data.data.displayScreenId] ?? {};
                      requestedForScreen[data.data.displayScreenId][
                        change.doc.id
                      ] = {
                        text: body,
                        source: 'action-btn-pdf',
                        ...(data.type === NOTIFICATION_TYPE.ERROR
                          ? {
                              iconInfo: {
                                name: 'warning',
                                color: 'red',
                                groupName: 'MaterialIcons',
                              },
                              contentColor: '#E02A2A',
                              backgroundColor: `${'#E02A2A'}2A`,
                            }
                          : {
                              isSuccess: true,
                              iconInfo,
                              contentColor: iconInfo?.color?.length
                                ? iconInfo?.color
                                : null,
                              backgroundColor: iconInfo?.color?.length
                                ? `${iconInfo?.color}2A`
                                : null,
                            }),
                      };

                      removeRequest[
                        `requestedForScreen.${data.data.displayScreenId}.${change.doc.id}`
                      ] = true;

                      removedProgressNotif[change.doc.id] = true;
                    } else {
                      requestedForScreen[data.data.displayScreenId] =
                        requestedForScreen[data.data.displayScreenId] ?? {};
                      requestedForScreen[data.data.displayScreenId][
                        change.doc.id
                      ] = {
                        text: body,
                        source: 'action-btn-pdf',
                        iconInfo,
                        contentColor: iconInfo?.color?.length
                          ? iconInfo?.color
                          : null,
                        backgroundColor: iconInfo?.color?.length
                          ? `${iconInfo?.color}2A`
                          : null,
                      };
                    }
                    // SCREEN BASED OPERATIONS
                  } else if (
                    ENV &&
                    [SERVICE_TYPE.EXPORT].includes(data.service) &&
                    commonCondition
                  ) {
                    const updateKey =
                      data.service === SERVICE_TYPE.EXPORT
                        ? 'exports_' + change.doc.id
                        : '';
                    const sourceKey =
                      data.service === SERVICE_TYPE.EXPORT
                        ? 'export-excel'
                        : '';
                    const iconInfo =
                      data.service === SERVICE_TYPE.EXPORT
                        ? {
                            name: 'cloud-download-outline',
                            color: '#de8700',
                            groupName: 'MaterialCommunityIcons',
                          }
                        : {};
                    const successInfoIcon =
                      data.service === SERVICE_TYPE.EXPORT
                        ? {
                            name: 'check-circle-outline',
                            color: '#de8700',
                            groupName: 'MaterialCommunityIcons',
                          }
                        : {};

                    if (
                      !removedProgressNotif[change.doc.id] &&
                      shouldRemoveNotification
                    ) {
                      requestedForScreen[data.data.displayScreenId] =
                        requestedForScreen[data.data.displayScreenId] ?? {};
                      requestedForScreen[data.data.displayScreenId][updateKey] =
                        {
                          text: body,
                          isLoading: false,
                          isSuccess: true,
                          source: sourceKey,
                          contentColor: successInfoIcon?.color?.length
                            ? successInfoIcon?.color
                            : null,
                          backgroundColor: successInfoIcon?.color?.length
                            ? `${successInfoIcon?.color}2A`
                            : null,
                          iconInfo: successInfoIcon,
                        };

                      removeRequest[
                        `requestedForScreen.${data.data.displayScreenId}.${updateKey}`
                      ] = true;

                      removedProgressNotif[change.doc.id] = true;
                    } else {
                      requestedForScreen[data.data.displayScreenId] =
                        requestedForScreen[data.data.displayScreenId] ?? {};
                      requestedForScreen[data.data.displayScreenId][updateKey] =
                        {
                          text: body,
                          isLoading: false,
                          source: sourceKey,
                          contentColor: successInfoIcon?.color?.length
                            ? successInfoIcon?.color
                            : null,
                          backgroundColor: successInfoIcon?.color?.length
                            ? `${successInfoIcon?.color}2A`
                            : null,
                          iconInfo,
                        };
                    }
                    // DOC BASED OPERATIONS
                  } else if (
                    ENV &&
                    [
                      SERVICE_TYPE.IMPORT,
                      SERVICE_TYPE.DELETE_ROWS,
                      SERVICE_TYPE.FORMULA_UPDATE,
                    ].includes(data.service) &&
                    commonCondition
                  ) {
                    const updateKey =
                      data.service === SERVICE_TYPE.FORMULA_UPDATE
                        ? 'formulaUpdate'
                        : data.service === SERVICE_TYPE.IMPORT
                          ? 'imports_' + change.doc.id
                          : data.service === SERVICE_TYPE.DELETE_ROWS
                            ? 'deleteRows'
                            : '';
                    const sourceKey =
                      data.service === SERVICE_TYPE.FORMULA_UPDATE
                        ? 'formula-update'
                        : data.service === SERVICE_TYPE.IMPORT
                          ? 'import-excel'
                          : data.service === SERVICE_TYPE.DELETE_ROWS
                            ? 'delete-rows'
                            : '';
                    const iconInfo =
                      data.service === SERVICE_TYPE.FORMULA_UPDATE
                        ? {
                            name: 'information-outline',
                            color: '#0d70ab',
                            groupName: 'MaterialCommunityIcons',
                          }
                        : data.service === SERVICE_TYPE.IMPORT
                          ? {
                              name: 'file-upload-outline',
                              color: '#996d66',
                              groupName: 'MaterialCommunityIcons',
                            }
                          : data.service === SERVICE_TYPE.DELETE_ROWS
                            ? {
                                name: 'information-outline',
                                color: '#0d70ab',
                                groupName: 'MaterialCommunityIcons',
                              }
                            : {};
                    const successInfoIcon =
                      data.service === SERVICE_TYPE.FORMULA_UPDATE
                        ? {
                            name: 'check-circle-outline',
                            color: '#0d70ab',
                            groupName: 'MaterialCommunityIcons',
                          }
                        : data.service === SERVICE_TYPE.IMPORT
                          ? {
                              name: 'check-circle-outline',
                              color: '#996d66',
                              groupName: 'MaterialCommunityIcons',
                            }
                          : data.service === SERVICE_TYPE.DELETE_ROWS
                            ? {
                                name: 'check-circle-outline',
                                color: '#0d70ab',
                                groupName: 'MaterialCommunityIcons',
                              }
                            : {};
                    if (
                      !removedProgressNotif[change.doc.id] &&
                      shouldRemoveNotification
                    ) {
                      requestedForDoc[data.data.displayDocId] =
                        requestedForDoc[data.data.displayDocId] ?? {};
                      requestedForDoc[data.data.displayDocId][updateKey] = {
                        text: body,
                        isLoading: false,
                        source: sourceKey,
                        isSuccess: true,
                        contentColor: successInfoIcon?.color?.length
                          ? successInfoIcon?.color
                          : null,
                        backgroundColor: successInfoIcon?.color?.length
                          ? `${successInfoIcon?.color}2A`
                          : null,
                        iconInfo: successInfoIcon,
                      };
                      removeRequest[
                        `requestedForDoc.${data.data.displayDocId}.${updateKey}`
                      ] = true;
                      removedProgressNotif[change.doc.id] = true;
                    } else {
                      requestedForDoc[data.data.displayDocId] =
                        requestedForDoc[data.data.displayDocId] ?? {};
                      requestedForDoc[data.data.displayDocId][updateKey] = {
                        text: body,
                        isLoading: false,
                        source: sourceKey,
                        contentColor: successInfoIcon?.color?.length
                          ? successInfoIcon?.color
                          : null,
                        backgroundColor: successInfoIcon?.color?.length
                          ? `${successInfoIcon?.color}2A`
                          : null,
                        iconInfo,
                      };
                    }
                  } else if (
                    ENV &&
                    data.service === SERVICE_TYPE.AUTOMATION &&
                    (automationInitialRequest ||
                      hasDataChanged ||
                      shouldRemoveNotification)
                  ) {
                    const startDate1 = Date.now();
                    // convert set to array
                    const runningAutomationProcess = body;
                    Object.keys(runningAutomationProcess).forEach((key) => {
                      runningAutomationProcess[key] =
                        [...runningAutomationProcess[key]] ??
                        Array.from(runningAutomationProcess[key]);
                    });

                    if (!removedProgressNotif[change.doc.id]) {
                      const currentDocIds = shouldRemoveNotification
                        ? []
                        : Object.keys(data?.data?.automationsMap ?? {}).filter(
                            (docId) => {
                              // If every empty then don't add it to current doc ids.
                              return !Object.values(
                                data.data.automationsMap[docId],
                              ).every((val) => val === null);
                            },
                          );
                      const prevDocIds = Object.keys(
                        runningProcesses?.requestedForDoc ?? {},
                      ).reduce((acc, docId) => {
                        if (
                          !isEmpty(
                            runningProcesses?.requestedForDoc?.[docId]?.[
                              change.doc.id
                            ],
                          )
                        ) {
                          acc.push(docId);
                        }
                        return acc;
                      }, []);
                      const iconInfo = {
                        name: 'information-outline',
                        color: '#6F23E5',
                        groupName: 'MaterialCommunityIcons',
                      };
                      const successInfoIcon = {
                        name: 'check-circle-outline',
                        color: '#6F23E5',
                        groupName: 'MaterialCommunityIcons',
                      };

                      // const currentMap = {};
                      if (
                        automationInitialRequest &&
                        data.data.displayDocId &&
                        isEmpty(data.data.automationsMap)
                      ) {
                        const textToDisplay = runningAutomationProcess?.[
                          data.data.displayDocId
                        ]?.length
                          ? runningAutomationProcess?.[
                              data.data.displayDocId
                            ]?.join?.(' ⦿ ')
                          : '';
                        if (textToDisplay?.length) {
                          requestedForDoc[data.data.displayDocId] =
                            requestedForDoc[data.data.displayDocId] ?? {};
                          requestedForDoc[data.data.displayDocId][
                            change.doc.id
                          ] = {
                            text: textToDisplay,
                            isLoading: false,
                            source: 'automation-map-source',
                            contentColor: iconInfo?.color?.length
                              ? iconInfo?.color
                              : null,
                            backgroundColor: iconInfo?.color?.length
                              ? `${iconInfo?.color}2A`
                              : null,
                            iconInfo,
                          };
                        }
                      } else {
                        currentDocIds.forEach((docId) => {
                          const textToDisplay = runningAutomationProcess?.[
                            docId
                          ]?.length
                            ? runningAutomationProcess?.[docId]?.join?.(' ⦿ ')
                            : '';
                          if (textToDisplay?.length) {
                            requestedForDoc[docId] =
                              requestedForDoc[docId] ?? {};
                            requestedForDoc[docId][change.doc.id] = {
                              text: textToDisplay,
                              isLoading: false,
                              source: 'automation-map-source',
                              contentColor: iconInfo?.color?.length
                                ? iconInfo?.color
                                : null,
                              backgroundColor: iconInfo?.color?.length
                                ? `${iconInfo?.color}2A`
                                : null,
                              iconInfo,
                            };
                          }
                        });
                      }

                      const docIdsToRemove = prevDocIds.filter(
                        (docId) => !currentDocIds.includes(docId),
                      );

                      if (
                        docIdsToRemove.length &&
                        !automationInitialRequest &&
                        (!isEqual(currentDocIds, prevDocIds) ||
                          shouldRemoveNotification)
                      ) {
                        docIdsToRemove.forEach((docId) => {
                          requestedForDoc[docId] = requestedForDoc[docId] ?? {};
                          requestedForDoc[docId][change.doc.id] = {
                            text: 'Automations Completed',
                            isLoading: false,
                            isSuccess: true,
                            source: 'automation-map-source',
                            contentColor: successInfoIcon?.color?.length
                              ? successInfoIcon?.color
                              : null,
                            backgroundColor: successInfoIcon?.color?.length
                              ? `${successInfoIcon?.color}2A`
                              : null,
                            iconInfo: successInfoIcon,
                          };
                          removeRequest[
                            `requestedForDoc.${docId}.${change.doc.id}`
                          ] = true;
                        });
                      }

                      removedProgressNotif[change.doc.id] =
                        shouldRemoveNotification;
                    }
                  } else if (!ENV && hasDataChanged && isToastActive) {
                    showCustomToastWrapper({
                      toastType: getUpdateToastType(
                        data.type,
                        true,
                        data.service,
                      ),
                      message: body,
                      position: 'bottom-right',
                      autoClose: false,
                      toastId: change.doc.id,
                      service: data.service,
                      closeOnClick: false,
                      draggable: false,
                      isLoading:
                        data.isRunning && data.type !== NOTIFICATION_TYPE.INFO,
                    });
                  } else if (!ENV) {
                    showCustomToastWrapper({
                      toastType: getUpdateToastType(
                        data.type,
                        false,
                        data.service,
                      ),
                      message: body,
                      position: 'bottom-right',
                      autoClose: false,
                      toastId: change.doc.id,
                      service: data.service,
                      isLoading: ![SERVICE_TYPE.GENERATE_PDF].includes(
                        data.service,
                      ),
                      isToastActive,
                    });
                  }

                  if (data?.isRunning) {
                    added[change.doc.id] = data;
                  } else {
                    added[change.doc.id] = data;
                    removed.push(change.doc.id);
                    removedProgressNotif[change.doc.id] = true;
                    dismissToast();
                  }
                }
                break;
              }
            }
          } catch (err) {
            captureInfo({err: serializeError(err)});
            captureError(
              new Error(
                'Error progress notification miniApps listener (fetched-data)',
              ),
              true,
            );
          }
        });

        if (
          ENV &&
          (!isEmpty(requestedForDoc) || !isEmpty(requestedForScreen))
        ) {
          dispatch(
            updateRunningProcesses(
              {
                requestedForDoc,
                requestedForScreen,
                dateNow: Date.now(),
              },
              false,
            ),
          );
        }

        if (!isEmpty(added)) {
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_PROGRESS_NOTIFICATIONS,
            payload: {added},
          });
        }

        if (ENV && !isEmpty(removeRequest)) {
          executeAfterDelay(() => {
            dispatch(
              updateRunningProcesses(Object.keys(removeRequest), false, true),
            );
          }, 1000);
        }
        if (!isEmpty(removed)) {
          executeAfterDelay(
            () =>
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_PROGRESS_NOTIFICATIONS,
                payload: {removed},
              }),
            2000,
          );
        }
      } catch (err) {
        captureInfo({
          err: serializeError(err),
          snapshot,
        });
        if (!ENV) {
          // handles closing of all toasts in web
          // when user closes an APP
          const {
            pushNotifications: {customCallbacks},
          } = getState();
          const {dismissAllToasts = () => {}} = customCallbacks ?? {};
          typeof dismissAllToasts === 'function' && dismissAllToasts();
        }
        captureError(
          new Error(
            'Error progress notification miniApps listener (processing-snapshot)',
          ),
          true,
        );
      }
    };

    const pastTwoHours = moment().utc().subtract(2, 'hours').toDate();
    const pastTwoDays = moment().utc().subtract(2, 'days').toDate();

    const listener = FirestoreDB.FirestoreListener(
      FirestoreDB.miniApps
        .notifyUserCollection(firestoreInstance)
        .where('appId', '==', initAppId)
        .where('forUID', 'array-contains', user.uid)
        .where('isRunning', '==', true)
        .where('startedAt', '>=', pastTwoDays),
      (snap) => handleListener(snap),
    );

    const removeListener = FirestoreDB.FirestoreListener(
      FirestoreDB.miniApps
        .notifyUserCollection(firestoreInstance)
        .where('appId', '==', initAppId)
        .where('forUID', 'array-contains', user.uid)
        .where('isRunning', '==', false)
        .where('startedAt', '>=', pastTwoHours),
      (snap) => handleListener(snap, true),
    );

    dispatch({
      type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
      payload: {
        progressNotificationListenersForUser: listener,
        progressNotificationRemoveListenersForUser: removeListener,
      },
    });
  };

const activateAutomationsActivityListener =
  (dbInstance = null) =>
  async (dispatch, getState) => {
    try {
      dbInstance = dbInstance ?? database;
      const {
        miniApps: {activeAppId},
        auth: {
          user: {uid: userUID},
        },
      } = getState();

      if (!activeAppId) {
        return;
      }

      const masterHandler = (Snapshot, type) => {
        try {
          const latestStoreState = getState();
          if (
            Snapshot.exists() &&
            activeAppId === latestStoreState.miniApps.activeAppId
          ) {
            const key = Snapshot.key;
            const value = Snapshot.val();
            const currAutomationStatusState =
              latestStoreState.automation.miniAppAutomationStatus;
            switch (type) {
              case 'child_added':
              case 'child_changed': {
                const automationStatusState = Object.assign(
                  {},
                  currAutomationStatusState,
                  {
                    [key]: value,
                  },
                );
                dispatch(
                  miniAppUpdateAutomationUpdateStatus(automationStatusState),
                );
                // dispatch({
                //   type: AUTOMATION_ACTION.MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS,
                //   payload: Object.assign({}, automationStatusState),
                // });
                break;
              }
              case 'child_removed': {
                const automationStatusState = omit(
                  Object.assign({}, currAutomationStatusState),
                  key,
                );
                dispatch(
                  miniAppUpdateAutomationUpdateStatus(automationStatusState),
                );
                // dispatch({
                //   type: AUTOMATION_ACTION.MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS,
                //   payload: Object.assign({}, automationStatusState),
                // });
                break;
              }
            }
          }
        } catch (error) {
          captureInfo({Snapshot, type, error});
          captureError(
            new Error('Error in activateAutomationsActivityListener', error),
          );
        }
      };

      const listeners = [
        {type: 'child_changed'},
        {type: 'child_added'},
        {type: 'child_removed'},
      ];
      const dbRef = dbInstance().ref(
        `/runningAutomations/${activeAppId}/${userUID}`,
      );
      listeners.forEach((obj, index) => {
        listeners[index].instance = dbRef.on(obj.type, (snap) =>
          masterHandler(snap, obj.type),
        );
      });
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
        payload: () =>
          listeners.forEach((obj) => dbRef.off(obj.type, obj.instance)),
      });
    } catch (e) {
      captureError(e);
    }
  };

const realtimeDBListenerForMiniApp =
  (
    dbInstance = null,
    dbPath,
    triggerConditions = (latestReduxState) => {},
    listenerName,
    onAdd = () => {},
    onChange = () => {},
    onRemove = () => {},
    onListenerRemove = () => {},
  ) =>
  async (dispatch, getState) => {
    try {
      dbInstance = dbInstance ?? database;

      // Extra conditions to check

      const activeAppId = getState().miniApps.activeAppId;

      if (!activeAppId) {
        return;
      }

      const masterHandler = (Snapshot, type) => {
        try {
          const latestStoreState = getState();
          let condition = true;
          if (typeof triggerConditions === 'function') {
            condition = triggerConditions(latestStoreState);
          }
          if (
            Snapshot.exists() &&
            activeAppId === latestStoreState.miniApps.activeAppId &&
            condition
          ) {
            switch (type) {
              case 'child_added': {
                onAdd?.();
                break;
              }
              case 'child_changed': {
                onChange?.();
                break;
              }
              case 'child_removed': {
                onRemove?.();
                break;
              }
            }
          }
        } catch (error) {
          captureInfo({Snapshot, type, error});
          captureError(
            new Error(
              'Error in realtimeDBListenerForMiniApp : ' + dbPath + ' : ',
              error,
            ),
          );
        }
      };

      const listeners = [
        {type: 'child_changed'},
        {type: 'child_added'},
        {type: 'child_removed'},
      ];
      const dbRef = dbInstance().ref(dbPath);
      listeners.forEach((obj, index) => {
        listeners[index].instance = dbRef.on(obj.type, (snap) =>
          masterHandler(snap, obj.type),
        );
      });
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
        payload: {
          [listenerName]: () => {
            listeners.forEach((obj) => dbRef.off(obj.type, obj.instance));
            onListenerRemove?.();
          },
        },
      });
    } catch (e) {
      captureError(e);
    }
  };

let formulaUpdateToastTimeout = null;
const activateFormulaUpdatesDBListener =
  (dbInstance = null) =>
  async (dispatch, getState) => {
    // ! Disabled for Mobile devices
    if (ENV) {
      return;
    }

    dbInstance = dbInstance ?? database;
    try {
      const {
        miniApps: {activeAppId},
        auth: {
          user: {uid: userUID},
        },
        pushNotifications: {customCallbacks},
      } = getState();

      const dbPath = `/miniAppRealtimeUpdates/${activeAppId}/${userUID}/formulaUpdates`;

      const {getToastInstance, showFormulaRowsUpdating} = customCallbacks ?? {};
      const toast = !isEmpty(ToastInstance)
        ? ToastInstance
        : typeof getToastInstance === 'function'
          ? getToastInstance()
          : {};
      const toastId = 'FORMULA_ROWS_UPDATING';

      const onUpdateHandler = (show) => {
        if (
          isEmpty(customCallbacks) ||
          typeof showFormulaRowsUpdating !== 'function'
        ) {
          return;
        }

        if (show) {
          // showFormulaRowsUpdating(toastId);
        } else {
          const isToastActive = toast?.isActive?.(toastId);
          if (isToastActive) {
            clearTimeout(formulaUpdateToastTimeout);
            formulaUpdateToastTimeout = setTimeout(
              () => toast.dismiss?.(toastId),
              2000,
            ); //close toast with a delay of 2 seconds
          }
        }
      };

      const onAddOrChange = () => onUpdateHandler(true);
      const onRemove = () => onUpdateHandler(null);
      const onListenerRemove = () => {
        const isToastActive = toast?.isActive?.(toastId);
        if (isToastActive) {
          clearTimeout(formulaUpdateToastTimeout);
          formulaUpdateToastTimeout = setTimeout(
            () => toast.dismiss?.(toastId),
            2000,
          ); //close toast with a delay of 2 seconds
        }
      };

      return dispatch(
        realtimeDBListenerForMiniApp(
          dbInstance,
          dbPath,
          null,
          'miniAppFormulaUpdatesListener',
          onAddOrChange,
          onAddOrChange,
          onRemove,
          onListenerRemove,
        ),
      );
    } catch (e) {
      captureError(e);
    }
  };

const setInitialScreenLocalConfigs = () => async (dispatch, getState) => {
  try {
    const {
      miniApps: {
        miniApps,
        activeAppId,
        activeCustomRoleInfo,
        screenLocalConfig,
      },
      auth: {user},
    } = getState();
    const activeAppMeta = miniApps[activeAppId];
    const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
      activeAppMeta,
      user.uid,
      activeCustomRoleInfo,
    );
    const updatedScreenLocalConfig = {};
    screens.forEach((screenObj) => {
      const {screenId} = screenObj;
      if (!(screenId in screenLocalConfig)) {
        const screenLocalObj = {};
        if (
          miniAppsActionHelper.isCustomViewLayoutFilteredScreen(
            activeAppMeta,
            screenId,
          )
        ) {
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          screenLocalObj.calendarSelectedDate = date;
        }
        updatedScreenLocalConfig[screenId] = screenLocalObj;
      }
    });
    dispatch({
      type: MINI_APPS_ACTION.INITIAL_SCREEN_LOCAL_CONFIG,
      payload: updatedScreenLocalConfig,
    });
  } catch (e) {
    captureError(e);
  }
};

const activateCustomRoleListener =
  (customRoleId, appId, firestoreInstance) => async (dispatch, getState) => {
    let isSuceess = false;
    try {
      const customRoleDocRef = FirestoreDB.miniApps.customRoleDoc(
        appId,
        customRoleId,
        firestoreInstance,
      );

      const processSnapshot = (QuerySnapshot) => {
        if (!QuerySnapshot.exists) {
          return false;
        }
        isSuceess = true;

        const data = QuerySnapshot.data();

        dispatch({
          type: MINI_APPS_ACTION.UPDATE_ACTIVE_CUSTOM_ROLE_INFO,
          payload: data,
        });
      };

      const isFetched = await customRoleDocRef
        .get({source: 'server'})
        .then((...args) => {
          processSnapshot(...args);
          return true;
        })
        .catch((err) => {
          captureError(err);
          return false;
        });

      const {
        auth: {userPref},
      } = getState();

      if (!isFetched) {
        alert(
          getLocalText(
            userPref,
            'Please check your internet connection and try again',
          ),
        );
        return isSuceess;
      }

      let isInitialCall = true;
      const onDocumentDataChange = async (QuerySnapshot) => {
        try {
          const {
            miniApps: {FUNCTION_REFS, activeCustomRoleInfo},
          } = getState();

          processSnapshot(QuerySnapshot);

          if (isInitialCall) {
            isInitialCall = false;
          } else {
            const omitKeys = ['roleName', 'createdBy'];
            if (
              !isEqual(
                omit(QuerySnapshot.data(), omitKeys),
                omit(activeCustomRoleInfo, omitKeys),
              )
            ) {
              FUNCTION_REFS?.ON_CLOSE_APP?.(); //navigate back to apps home
              dispatch(closeApp());
              return (
                ENV &&
                alert(
                  getLocalText(
                    userPref,
                    'Your permission for this app has been changed. Please reopen the app to continue using it.',
                  ),
                )
              );
            }
          }
        } catch (err) {
          captureInfo({customRoleId, appId, QuerySnapshot});
          captureError(err);
        }
      };
      const listenerInstance = FirestoreDB.FirestoreListener(
        customRoleDocRef,
        onDocumentDataChange,
      );
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
        payload: {
          [`customRole_${customRoleId}`]: listenerInstance,
        },
      });
    } catch (error) {
      captureError(error);
    }
    return isSuceess;
  };

const updateMiniAppAccessManagerInstance = () => (dispatch, getState) => {
  try {
    const {
      miniApps: {miniApps, activeAppId, activeCustomRoleInfo},
      auth: {
        user: {uid},
      },
    } = getState();
    const appAccessManagerInstance = new MiniAppsAccessManager(
      uid,
      miniApps[activeAppId],
      activeCustomRoleInfo,
    );
    dispatch({
      type: MINI_APPS_ACTION.SET_MINI_APP_ACCESS_INSTANCE,
      payload: appAccessManagerInstance,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadAppActualData =
  (firestoreInstance, prevMiniAppsObj = null, extra = {}) =>
  (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps, activeAppId, docsData, activeCustomRoleInfo},
        auth: {
          user: {uid},
        },
      } = getState();

      if (activeAppId) {
        const docIdsObj = {}; // docIds to load
        const prevMiniAppMeta = prevMiniAppsObj?.[activeAppId];
        const miniAppMeta = miniApps[activeAppId];

        if (
          !isEmpty(prevMiniAppMeta) &&
          isEqual(prevMiniAppMeta, miniAppMeta)
        ) {
          return;
        }

        const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
          miniAppMeta,
          uid,
          activeCustomRoleInfo,
        );

        screens.forEach((screenData) => {
          const {screenId} = screenData;
          const getScreenType = (appMeta) =>
            miniAppsActionHelper.checkIfFilteredDataScreen(
              appMeta,
              uid,
              screenId,
              activeCustomRoleInfo,
            ) ||
            appMeta?.screens?.[screenId]?.customLayoutType ===
              MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
              ? MINI_APPS.SCREEN_TYPE.FILTERED_DATA
              : screenData.type;

          const screenType = getScreenType(miniAppMeta);
          const prevScreenType = prevMiniAppMeta
            ? getScreenType(prevMiniAppMeta)
            : null;

          switch (screenType) {
            case MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA: {
              const {docId} = screenData.docs[0];
              if (isEmpty(docsData[docId])) {
                docIdsObj[docId] = Object.assign({}, docIdsObj[docId], {
                  isDoc: true,
                });
              }
              break;
            }
            case MINI_APPS.SCREEN_TYPE.FILTERED_DATA: {
              const {docId} = screenData.docs[0];
              const prevStateScreens = prevMiniAppMeta?.screens;
              const prevDocData = prevStateScreens?.[screenId]?.docs ?? [];
              const prevViewLayoutType =
                prevStateScreens?.[screenId]?.customLayoutType ?? null;
              const updatedViewLayoutType =
                screenData?.customLayoutType ?? null;
              const isDocChangedToFilter =
                prevScreenType === MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA;

              if (
                isEmpty(docsData[docId]) ||
                (!isEmpty(prevStateScreens) &&
                  !(screenId in prevStateScreens)) ||
                isDocChangedToFilter
              ) {
                docIdsObj[docId] = Object.assign({}, docIdsObj[docId], {
                  filterScreenIds: [
                    ...(docIdsObj[docId]?.filterScreenIds ?? []),
                    screenId,
                  ],
                  isFilter: true,
                });
              } else if (
                (!isEmpty(prevDocData) &&
                  !isEqual(prevDocData, screenData.docs)) ||
                !isEqual(prevViewLayoutType, updatedViewLayoutType)
              ) {
                docIdsObj[docId] = Object.assign({}, docIdsObj[docId], {
                  alteredFilterScreenIds: [
                    ...(docIdsObj[docId]?.alteredFilterScreenIds ?? []),
                    screenId,
                  ],
                  isFilter: true,
                });
              }
              break;
            }
            case MINI_APPS.SCREEN_TYPE.REPORT:
            case MINI_APPS.SCREEN_TYPE.DASHBOARD: {
              break;
            }
          }
        });
        const isWeb = !ENV;
        if (isWeb) {
          return dispatch(
            activateMiniAppsDocsDataListener(
              docIdsObj,
              firestoreInstance,
              extra,
            ),
          );
        }
        //isApp => loading via chunks for optimization
        if (!isEmpty(docIdsObj)) {
          const chunkObject = (obj, chunkSize) => {
            const entries = Object.entries(obj);
            const chunks = [];
            for (let i = 0; i < entries.length; i += chunkSize) {
              const chunk = entries.slice(i, i + chunkSize);
              chunks.push(Object.fromEntries(chunk));
            }
            return chunks;
          };

          const chunks = chunkObject(docIdsObj, 10);
          const delay = 500; // Delay in milliseconds

          const processChunk = (chunk) => {
            dispatch(
              activateMiniAppsDocsDataListener(chunk, firestoreInstance, extra),
            );
          };

          const screenLen = screens.length;
          const processChunks = (chunks, delay) => {
            dispatch(
              updateGlobalLoader(true, {text: 'Fetching Dependencies...'}),
            );
            chunks.forEach((chunk, index) => {
              setTimeout(() => {
                // Process each chunk after delay without blocking
                processChunk(chunk);
              }, index * delay); // Delay increases for each chunk
            });
            setTimeout(
              () => {
                dispatch(updateGlobalLoader(false));
              },
              chunks.length * (screenLen * (40 / 100)) * delay,
            ); // Delay for the last chunk

            console.log('Chunk processing started');
          };

          // Start processing chunks asynchronously without waiting
          processChunks(chunks, delay);
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const activateMiniAppsDocsDataListener =
  (docIdObj, firestoreInstance, extra = {}) =>
  (dispatch, getState) => {
    try {
      if (isEmpty(docIdObj)) {
        return;
      }
      firestoreInstance = firestoreInstance ?? firestore;

      const {
        miniApps: {docsData},
      } = getState();

      //initialize docs state
      const initialDocsData = {};
      const docIdObjArr = Object.keys(docIdObj).map((docId) => {
        const processObj = docIdObj[docId];
        const isListenerAlreadyActive = !isEmpty(docsData[docId]);
        if (!isListenerAlreadyActive) {
          initialDocsData[docId] = miniAppsActionHelper.DEFAULT_DOC_DATA;
        }
        return Object.assign({}, processObj, {
          isListenerAlreadyActive,
          documentId: docId,
        });
      });
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
        payload: {
          data: initialDocsData,
          isOverwrite: true,
        },
      });

      //on initial fetch or realtime change to any of the doc
      const onChange = (data, documentId, isInitialCall = false) => {
        let onCompletion;
        const {miniApps: miniAppsState} = getState();
        const currentDocData = Object.assign(
          {},
          miniAppsState.docsData[documentId],
        );

        const updatedData = {
          ...currentDocData,
          noOfRows: data.noOfRows,
          lastFetchedTimestamp: data.lastFetchedTimestamp,
          docMetaLastModifiedTimestamp: data.docMetaLastModifiedTimestamp,
          isLoading: false,
        };

        const isDocMetaUpdateAvailable = (() => {
          if (isInitialCall) return true;

          if (!data.docMetaLastModifiedTimestamp) return false;

          if (
            !isEqual(
              currentDocData.docMetaLastModifiedTimestamp,
              data.docMetaLastModifiedTimestamp,
            )
          ) {
            return true;
          }

          return false;
        })();

        if (isDocMetaUpdateAvailable) {
          const headerData = Object.values(Object.assign({}, data.headerData));

          const headerDataAsObj = {};
          const headerProperties = {
            isBarCodeColIncluded: false,
            assignCols: [],
          };

          headerData.forEach((headerObj) => {
            Object.assign(headerDataAsObj, {[headerObj.id]: headerObj});
            const fieldType = getColumnFieldType(headerObj);
            if (headerObj?.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
              headerProperties.assignCols.push(headerObj);
            } else if (fieldType === FIELD_TYPE_ID.BARCODE) {
              headerProperties.isBarCodeColIncluded = true;
            }
          });

          Object.assign(updatedData, {
            headerData,
            headerDataAsObj,
            headerProperties,
            fileObj: Object.assign({}, data.fileObj),
            sections: miniAppsActionHelper.getSortedSectionedHeaderDataAsArray(
              data?.fileObj?.sections ?? {},
              headerDataAsObj,
              headerData,
            ),
          });
        }

        if (isInitialCall) {
          // To Open Mini App Row
          if (extra.docId === documentId) {
            extra?.callback?.({headerData: updatedData.headerData});
          }

          if ('tableData' in data) {
            [updatedData.tableData, updatedData.rowIdDataMap] =
              getRowIdRowDataMapping(data.tableData);
          }
          if ('areAllRowsFetched' in data) {
            updatedData.areAllRowsFetched = data.areAllRowsFetched;
          }
        } else {
          if ('rowsData' in data) {
            const prevTableData = currentDocData.tableData ?? [];
            const prevRowIdDataMap = Object.assign(
              {},
              currentDocData.rowIdDataMap,
            );
            const lastRowRowId =
              !currentDocData.areAllRowsFetched && prevTableData.length > 0
                ? prevTableData[prevTableData.length - 1]
                : null;

            const {sortingKey} = getTableSortingOrder(updatedData.fileObj);

            const indexUpperLimit =
              prevRowIdDataMap[lastRowRowId]?.[sortingKey] ?? null;

            const processedData = processRowsDataForMappedRowId(
              data.rowsData,
              indexUpperLimit,
              prevTableData,
              updatedData.noOfRows,
              prevRowIdDataMap,
              updatedData.fileObj,
              {},
            );
            [updatedData.tableData, updatedData.rowIdDataMap] = [
              processedData.tableData,
              processedData.rowIdDataMap,
            ];

            if (
              currentDocData.lastFetchedTimestamp != null &&
              !isEqual(
                currentDocData.lastFetchedTimestamp,
                data.lastFetchedTimestamp,
              )
            ) {
              onCompletion = () => {
                let rowDocsArr;
                const getRowDocsArr = () => {
                  if (rowDocsArr) {
                    return rowDocsArr;
                  }
                  rowDocsArr = [];
                  data.rowsData.docs.forEach((doc) => {
                    const docData = doc.data();
                    const rowId = doc.id;
                    const prevData = prevRowIdDataMap?.[rowId];
                    rowDocsArr.push({
                      exists: doc.exists,
                      id: doc.id,
                      data: () => docData,
                      prevData: prevData ? () => prevData : undefined,
                    });
                  });
                  return rowDocsArr;
                };

                dispatch(
                  researchFilterOnDataChange({
                    getRowsArr: getRowDocsArr,
                    docId: documentId,
                  }),
                );
              };
            }

            if (processedData.sortingKeyChangedRows?.length) {
              dispatch(
                reorderDocDataForSortKeyChange({
                  docId: documentId,
                  sortingKeyChangedRows: processedData.sortingKeyChangedRows,
                  omitFor: {screenType: [MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA]},
                }),
              );
            }
          }
        }
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
          payload: {
            docId: documentId,
            data: updatedData,
          },
        });

        typeof onCompletion === 'function' && onCompletion();
      };

      const iterateOverFilters = (docObj, processFunc) => {
        if (docObj.isFilter) {
          //load filter data
          const checkAndLoadFilterData = (arr, isAlterFilter) => {
            if (Array.isArray(arr)) {
              arr.forEach((screenId) => processFunc(screenId, isAlterFilter));
            }
          };
          checkAndLoadFilterData(docObj.filterScreenIds, false);
          checkAndLoadFilterData(docObj.alteredFilterScreenIds, true);
        }
      };

      //start listening to these docs
      const listenersMapping = {};
      docIdObjArr.forEach((docObj) => {
        const {documentId} = docObj;
        iterateOverFilters(docObj, (screenId, isAlterFilter) => {
          isAlterFilter = isAlterFilter === true;
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
            payload: {
              docId: documentId,
              screenId,
              data: {
                isFilteredDataLoading: true,
              },
              isOverwrite:
                isAlterFilter ||
                !isEmpty(docsData[documentId]?.filterData?.[screenId]),
              //!IMP: added for a case when screen actual type is changed : filtered -> non-filtered -> filtered in such
              //! way so that residue filterData of the screen causes the isFilteredDataLoading to be true along with
              //! residue filtered data causing infinite loading [sneh-lio]
            },
          });
          if (isAlterFilter) {
            dispatch(closeSearchFilterForScreen(screenId));
          }
        }); //start loader on filter screen

        const fetchActualFilterData = () =>
          iterateOverFilters(docObj, (screenId) =>
            dispatch(getMiniAppPaginatedDocData(screenId)),
          ); //fetch actual filter data after initial fetch of doc data/or when screen filter is added/edited

        if (!docObj.isListenerAlreadyActive) {
          const limit = docObj.isDoc ? NUMBER_OF_ROWS_TO_FETCH : 1;
          listenersMapping[documentId] = dispatch(
            documentDataListenerWrapper(
              documentId,
              (data) => {
                onChange(data, documentId, true);
                fetchActualFilterData();
              },
              (data, isDescOrderOfIndexLocal, isSelfMadeEntryViaCloud) =>
                onChange(
                  data,
                  documentId,
                  false,
                  isDescOrderOfIndexLocal,
                  isSelfMadeEntryViaCloud,
                ),
              firestoreInstance, //only for mobile
              null, //only for mobile
              limit,
              () =>
                dispatch({
                  type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
                  payload: {
                    docId: documentId,
                    data: {docExists: false},
                  },
                }),
              true,
              true,
            ),
          );
        } else {
          fetchActualFilterData();
        }
      });
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
        payload: listenersMapping,
      });
    } catch (error) {
      captureError(error);
    }
  };

const startSearchFilterOnMiniAppScreen =
  ({filterArr, searchedText}, isSearch = false, screenId) =>
  (dispatch, getState) => {
    try {
      if (
        isSearch
          ? typeof searchedText !== 'string' || searchedText.trim().length < 2
          : !filterArr?.length ||
            filterArr.some(
              (filterObj) =>
                !filterObj?.colId ||
                !filterObj.selectedOptions.length ||
                !filterObj.fieldType,
            )
      ) {
        ShowToast(
          isSearch
            ? 'Minimum 2 characters are required to search'
            : 'Please select a valid filter configuration',
          getState().auth.userPref,
        );
        return null;
      }
      const {
        miniApps: {activeAppId, miniApps, docsData},
      } = getState();
      const activeAppMeta = miniApps[activeAppId];
      const screenMeta = activeAppMeta.screens?.[screenId] ?? {};
      const screenDocData = docsData?.[screenMeta?.docs?.[0]?.docId] ?? {};

      dispatch(getMiniAppPaginatedDocData(screenId, {filterArr, searchedText}));
      if (
        isKanbanConfigValid(
          screenId,
          screenMeta,
          screenDocData,
          activeAppMeta.screens,
        ) &&
        screenMeta.customLayoutType ===
          MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
      ) {
        const {kanbanScreenIds = []} =
          screenMeta?.view?.[
            MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
          ] ?? {};
        if (kanbanScreenIds.length > 0) {
          kanbanScreenIds.forEach((kanbanScreenId) =>
            dispatch(
              getMiniAppPaginatedDocData(kanbanScreenId, {
                filterArr,
                searchedText,
              }),
            ),
          );
        }
      }
    } catch (err) {
      captureError(err);
    }
  };

const getMiniAppPaginatedDocData =
  (screenId = null, options = {}) =>
  (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
        miniApps: {activeScreenId},
      } = getState();

      screenId = screenId ?? activeScreenId;

      const {
        activeDocId: docId,
        activeScreenData: activeScreenMeta,
        documentData: currentDocData,
        isFilteredDataScreen,
        screenSearchFilterState: screenSearchFilterData,
      } = miniAppsActionHelper.mapMiniAppStates(
        getState(),
        false,
        false,
        screenId,
      );
      const isFullCalendarView = !isEmpty(
        activeScreenMeta?.view?.fullScreenCalendarMapping,
      );

      const currentFilterData = Object.assign(
        {},
        currentDocData.filterData?.[screenId],
      );

      let {filterArr, searchedText} = options ?? {};
      const {isForceFetch, checkForNextScrollValidation, isCalendarView} =
        options ?? {};

      if (checkForNextScrollValidation) {
        const isInSearchFilterMode =
          screenSearchFilterData.isActive || screenSearchFilterData.isLoading;
        if (
          isInSearchFilterMode
            ? screenSearchFilterData.isLoading
            : isFilteredDataScreen
              ? currentFilterData.isFilteredDataLoading
              : currentDocData.isLoading
        ) {
          //if initial fetch is in progress
          return false;
        }
        if (
          isInSearchFilterMode
            ? screenSearchFilterData.isLoadingMoreRows
            : isFilteredDataScreen
              ? currentFilterData.isLoadingMoreRows
              : currentDocData.isLoadingMoreRows
        ) {
          //if next rows are already being fetched
          return false;
        }
        if (
          isInSearchFilterMode
            ? screenSearchFilterData.areAllRowsFetched
            : isFilteredDataScreen
              ? currentFilterData.areAllRowsFetched
              : currentDocData.areAllRowsFetched
        ) {
          //if all rows are already fetched
          return false;
        }
      }

      const isStartSearchFilter =
        filterArr?.length > 0 || searchedText?.length > 1;

      const screenType =
        isStartSearchFilter || screenSearchFilterData.isActive
          ? SCREEN_SEARCH_FILTER
          : isFilteredDataScreen
            ? MINI_APPS.SCREEN_TYPE.FILTERED_DATA
            : activeScreenMeta.type;

      switch (screenType) {
        case MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA: {
          if (
            currentDocData.areAllRowsFetched ||
            currentDocData.isLoadingMoreRows
          ) {
            return;
          }

          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
            payload: {
              docId: docId,
              data: {isLoadingMoreRows: true},
            },
          });

          return getPaginatedTableDataWrapper(
            docId,
            currentDocData,
            NUMBER_OF_ROWS_TO_FETCH,
            userPref,
            true,
            true,
          ).then((res) => {
            const data = {
              isLoadingMoreRows: false,
            };
            if (res?.success) {
              const {areAllRowsFetched, updatedTableData, updatedRowIdDataMap} =
                res;
              data.areAllRowsFetched = areAllRowsFetched;
              [data.tableData, data.rowIdDataMap] = [
                updatedTableData,
                updatedRowIdDataMap,
              ];
            }
            dispatch({
              type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
              payload: {
                docId,
                data,
              },
            });
          });
        }
        case MINI_APPS.SCREEN_TYPE.FILTERED_DATA: {
          const isInitialFetch =
            isEmpty(currentFilterData) ||
            Object.keys(currentFilterData).every(
              (key) => key === 'isFilteredDataLoading',
            ) ||
            currentFilterData.isFailed ||
            isCalendarView ||
            isFullCalendarView;
          if (
            !(isInitialFetch || isForceFetch) &&
            (currentFilterData.areAllRowsFetched ||
              currentFilterData.isLoadingMoreRows)
          ) {
            return;
          }

          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
            payload: {
              docId,
              screenId,
              data:
                isInitialFetch || isForceFetch
                  ? {isFilteredDataLoading: true}
                  : {isLoadingMoreRows: true},
            },
          });
          return Promise.resolve(
            dispatch(
              searchFilterForMiniAppScreen(
                null,
                null,
                Object.assign(
                  {},
                  {
                    screenToSearchOn: screenId,
                    mode: MINI_APPS.SCREEN_TYPE.FILTERED_DATA,
                    isInitialFetch,
                  },
                  isInitialFetch
                    ? {}
                    : isForceFetch
                      ? {finalResultSize: currentFilterData.tableData?.length}
                      : {searchAfter: currentFilterData.searchAfter},
                ),
              ),
            ),
          ).then((res) => {
            const data =
              isInitialFetch || isForceFetch
                ? {isFilteredDataLoading: false}
                : {isLoadingMoreRows: false};
            if (res?.success) {
              const {
                areAllRowsFetched,
                searchAfter,
                tableDataArr: tableData,
              } = res;
              Object.assign(data, {
                areAllRowsFetched,
                searchAfter,
                tableData,
                isFailed: false,
              });
            } else if (isInitialFetch) {
              data.isFailed = true; //failed on initial fetch
            }
            dispatch({
              type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
              payload: {docId, screenId, data},
            });
            if (res?.sortingKeyChangedRows?.length) {
              dispatch(
                reorderDocDataForSortKeyChange({
                  docId,
                  sortingKeyChangedRows: res.sortingKeyChangedRows,
                  omitFor: {
                    screenType: [MINI_APPS.SCREEN_TYPE.FILTERED_DATA],
                    screenId: [screenId],
                  },
                }),
              );
            }
            if (!isEmpty(res?.updatedRowIdDataMap)) {
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
                payload: {
                  docId,
                  data: {rowIdDataMap: res.updatedRowIdDataMap},
                },
              });
            }
          });
        }
        case SCREEN_SEARCH_FILTER: {
          const payloadData = {};
          if (isStartSearchFilter) {
            Object.assign(
              payloadData,
              miniAppsActionHelper.DEFAULT_SEARCH_FILTER_DATA,
              {isInitialLoadForSearch: !filterArr?.length},
            );
          } else {
            filterArr = screenSearchFilterData.filterArr ?? [];
            searchedText = screenSearchFilterData.searchedText;
            Object.assign(payloadData, {isLoadingMoreRows: true});
          }
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
            payload: {
              screenId,
              data: payloadData,
            },
          });
          return Promise.resolve(
            dispatch(
              searchFilterForMiniAppScreen(
                filterArr,
                searchedText,
                Object.assign(
                  {},
                  {
                    screenToSearchOn: screenId,
                    mode: SCREEN_SEARCH_FILTER,
                  },
                  isStartSearchFilter
                    ? {}
                    : isForceFetch
                      ? {
                          finalResultSize:
                            screenSearchFilterData.tableData?.length,
                        }
                      : {searchAfter: screenSearchFilterData.searchAfter},
                ),
              ),
            ),
          ).then((res) => {
            const data =
              isStartSearchFilter || isForceFetch
                ? {isLoading: false}
                : {isLoadingMoreRows: true};
            if (res?.success) {
              const {
                areAllRowsFetched,
                searchAfter,
                tableDataArr: tableData,
                hitsSize,
              } = res;
              Object.assign(data, {
                areAllRowsFetched,
                searchAfter,
                tableData,
                isFailed: false,
                ...(hitsSize != null ? {hitsSize} : {}),
                ...(isStartSearchFilter || isForceFetch
                  ? {
                      isActive: true,
                      filterOptions: filterArr?.length
                        ? filterArr.reduce(
                            (acc, curr) =>
                              Object.assign({}, acc, {
                                [curr.colId]: curr.selectedOptions,
                              }),
                            {},
                          )
                        : {},
                      filterArr,
                      searchedText: searchedText?.length ? searchedText : '',
                      isLoading: false,
                    }
                  : {isLoadingMoreRows: false}),
              });
            } else if (isStartSearchFilter) {
              data.isFailed = true; //failed on initial fetch
            }
            dispatch({
              type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
              payload: {
                screenId,
                data,
              },
            });
            if (res?.sortingKeyChangedRows?.length) {
              dispatch(
                reorderDocDataForSortKeyChange({
                  docId,
                  sortingKeyChangedRows: res.sortingKeyChangedRows,
                  omitFor: {
                    screenType: ['screen-search-filter'],
                    screenId: [screenId],
                  },
                }),
              );
            }
            if (!isEmpty(res?.updatedRowIdDataMap)) {
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
                payload: {
                  docId,
                  data: {rowIdDataMap: res.updatedRowIdDataMap},
                },
              });
            }
          });
        }
      }
    } catch (error) {
      captureInfo({options});
      captureError(error);
    }
  };

const changeScreen =
  (screenId, toAnyScreen = false) =>
  (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps, activeAppId, activeScreenId, activeCustomRoleInfo},
        auth: {
          user: {uid},
        },
        elasticDashboards: {allDashboards},
      } = getState();
      if (toAnyScreen) {
        const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
          miniApps[activeAppId],
          uid,
          activeCustomRoleInfo,
          false,
          true,
        );
        screenId = screens[0]?.screenId;
      }
      //in case of toAnyScreen : screenId might be null/undefined
      if (
        toAnyScreen
          ? true
          : screenId &&
            screenId !== activeScreenId &&
            !isEmpty(miniApps[activeAppId]?.screens?.[screenId])
      ) {
        dispatch({
          type: MINI_APPS_ACTION.OPEN_APP,
          payload: {
            screenId,
          },
        });
        if (
          screenId &&
          miniApps[activeAppId]?.screens?.[screenId]?.type ===
            MINI_APPS.SCREEN_TYPE.DASHBOARD &&
          !(screenId in allDashboards)
        ) {
          dispatch(fetchAllDasboardsForScreen(screenId));
        }
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const getMiniAppsCategoryData =
  (isBusinessCategory = true) =>
  async (dispatch, getState) => {
    try {
      const {auth} = getState();
      const lang = auth.userPref.lang;
      const categoryData = await backOff(
        () => miniAppsActionHelper.fetchMiniAppsCategory(isBusinessCategory),
        {numOfAttempts: 3},
      );
      const categoryArr = [];
      if (!isEmpty(categoryData) && categoryData?.length) {
        for (let i = 0; i < categoryData.length; i++) {
          if (categoryData[i]) {
            const catObj = miniAppsActionHelper.makeMiniAppsCategoryObjFromSnap(
              categoryData[i],
              lang,
            );
            categoryArr.push(catObj);
          }
        }
      }
      const sortedList = sortBy(categoryArr, ['engName']);
      isBusinessCategory
        ? dispatch({
            type: MINI_APPS_ACTION.LOAD_MINI_APPS_BUSINESS_CATEGORY,
            payload: {
              miniAppsBusinessCategories: sortedList,
            },
          })
        : dispatch({
            type: MINI_APPS_ACTION.LOAD_MINI_APPS_USECASE_CATEGORY,
            payload: {
              miniAppsUsecaseCategories: sortedList,
            },
          });
    } catch (error) {
      captureError(error);
    }
  };

const closeApp =
  (isReopenApp = false) =>
  (dispatch, getState) => {
    if (!ENV) {
      // handles closing of all toasts in web
      // when user closes an APP
      const {
        pushNotifications: {customCallbacks},
      } = getState();
      const {dismissAllToasts = () => {}} = customCallbacks ?? {};
      typeof dismissAllToasts === 'function' && dismissAllToasts();
    }
    dispatch({type: MINI_APPS_ACTION.CLOSE_APP, payload: {isReopenApp}});
    dispatch({type: ELASTIC_DASHBOARDS_ACTION.RESET_ELASTIC_DASHBOARDS_STATE});
    dispatch({type: AGGREGATION_ACTION.CLEAR_AGGREGATION_STATE});
  };

const searchFilterForMiniAppScreen =
  (filterArr, searchedText, extraParams) => async (dispatch, getState) => {
    let screenId;
    try {
      const {
        screenToSearchOn = undefined,
        rowDocs = undefined,
        searchAfter = undefined,
        finalResultSize = undefined,
        mode = undefined,
        isInitialFetch = undefined,
      } = Object.assign({}, extraParams);
      const {
        activeDocId: docId,
        activeScreenData: currScreenMeta,
        documentData,
        activeScreenId,
        activeAppMeta,
      } = miniAppsActionHelper.mapMiniAppStates(
        getState(),
        false,
        false,
        typeof screenToSearchOn === 'string' ? screenToSearchOn : null,
      );
      const {
        miniApps: {activeCustomRoleInfo, screenLocalConfig},
        auth,
      } = getState();
      screenId = activeScreenId;

      const filters =
        currScreenMeta.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
          ? currScreenMeta.docs[0].filterOptions.slice()
          : [];

      const isFullCalendarView =
        !isEmpty(currScreenMeta.view?.fullScreenCalendarMapping) &&
        currScreenMeta.view.customLayoutType ===
          MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.CALENDAR;
      const customViewLayoutFilterArr =
        miniAppsActionHelper.getCustomViewLayoutFilterArray(
          activeAppMeta,
          screenId,
          screenLocalConfig,
          documentData,
        ); //filters for different layouts/views [like: calendar]
      filters.push(...customViewLayoutFilterArr);

      const [isCustomViewAccess, viewAccess] =
        miniAppsActionHelper.isCustomRoleFilteredScreen(
          activeAppMeta,
          auth.user.uid,
          screenId,
          activeCustomRoleInfo,
        ); //get view permissiong params for custom roles

      if (
        !(
          (filterArr?.length &&
            filterArr.every(
              (filterObj) =>
                filterObj?.colId &&
                filterObj.selectedOptions?.length &&
                filterObj.fieldType,
            )) ||
          (typeof searchedText === 'string' &&
            searchedText.trim().length > 1) ||
          filters.length ||
          (isCustomViewAccess && viewAccess)
        )
      ) {
        //if no screen-filter/search or filtered-screen
        return Promise.reject(
          new Error('Invalid search-filter options.', {
            cause: {isCustom: true},
          }),
        );
      }

      filters.push(...(filterArr ?? []));

      const isOnlyRowIdsExpectedInRes =
        Array.isArray(rowDocs) && rowDocs.length > 0;

      const dataObj = {
        isPaginated: !isFullCalendarView, // fetch all valid rows if full calender view is enabled
        searchAfter,
        finalResultSize,
        textToSearch:
          typeof searchedText === 'string' ? searchedText.trim() : '',
        docId,
        originalDocumentId: docId,
        customSorting: documentData.fileObj?.customSorting,
        filters,
        headerIdFieldMapping:
          miniAppsActionHelper.getHeaderFieldMappingForElasticSearch(
            documentData.headerData,
          ),
        ...commonBodyParamsForElastic(auth),
        ...(isCustomViewAccess ? {viewAccess} : {}),
        ...(isOnlyRowIdsExpectedInRes
          ? {
              isRowIdArrOnly: true,
              rowIdsToSearchOn: rowDocs.map(({id}) => id),
            }
          : {getAsFirestoreDoc: true}),
      };
      const data =
        await miniAppsActionHelper.searchFilterOnMiniAppScreenCloudFunction(
          dataObj,
        );
      if (!data || !data.success || isEmpty(data.response)) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        //success
        const latestMiniAppState = getState().miniApps;
        let tableDataArr = [],
          updatedRowIdDataMap = {},
          sortingKeyChangedRows = [];
        const prevDocData = Object.assign(
          {},
          latestMiniAppState.docsData[docId],
        );
        const {
          tableData: prevTableData,
          areAllRowsFetched: prevAreAllRowsFetched,
          searchAfter: prevSearchAfter,
        } = Object.assign(
          {},
          mode === SCREEN_SEARCH_FILTER
            ? latestMiniAppState.searchFilterData?.[screenId]
            : mode === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
              ? prevDocData.filterData?.[screenId]
              : prevDocData,
          isInitialFetch ? {tableData: []} : null,
        ); //get the data based on mode of search/filter
        if (isOnlyRowIdsExpectedInRes) {
          const includedRowIds = data.response.rowIdsArr;
          const {
            tableData: updatedTableData,
            rowIdDataMap: rowIdDataMapUpdated,
            sortingKeyChangedRows: sortingKeyChangedRowsData,
          } = processRowsDataForMappedRowId(
            {
              docs: rowDocs.map((obj) =>
                Object.assign({}, obj, {
                  data: () =>
                    Object.assign(
                      {},
                      prevDocData.rowIdDataMap?.[obj.id] ?? obj.data(),
                      {isDeleted: !includedRowIds.includes(obj.id)},
                    ),
                }),
              ), //if some rows are not included in search result, then mark them as deleted
            },
            prevAreAllRowsFetched ? null : prevSearchAfter?.[0],
            prevTableData,
            null,
            prevDocData.rowIdDataMap,
            prevDocData.fileObj,
            {},
          );
          const newRowIdsDataMap = omit(
            updatedRowIdDataMap,
            Object.keys(Object.assign({}, prevDocData.rowIdDataMap)),
          );
          if (!isEmpty(newRowIdsDataMap)) {
            dispatch({
              type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
              payload: {docId, data: {rowIdDataMap: newRowIdsDataMap}},
            });
          }
          tableDataArr = updatedTableData;
          updatedRowIdDataMap = rowIdDataMapUpdated;
          sortingKeyChangedRows = sortingKeyChangedRowsData;
        } else {
          const {
            tableData: updatedTableData,
            rowIdDataMap: rowIdDataMapUpdated,
            sortingKeyChangedRows: sortingKeyChangedRowsData,
          } = processRowsDataForMappedRowId(
            {docs: data.response.tableData ?? []},
            null,
            prevTableData,
            null,
            prevDocData.rowIdDataMap,
            prevDocData.fileObj,
            {useDocDataAsObject: true},
          );
          tableDataArr = updatedTableData;
          updatedRowIdDataMap = rowIdDataMapUpdated;
          sortingKeyChangedRows = sortingKeyChangedRowsData;
        }
        return {
          success: true,
          tableDataArr,
          searchAfter: data.response.searchAfter,
          areAllRowsFetched: data.response.areAllRowsFetched,
          hitsSize: data.response.hitsSize,
          updatedRowIdDataMap,
          sortingKeyChangedRows,
        };
      }
    } catch (error) {
      captureError(error);
    }
    return {
      success: false,
    };
  };

const updateHeaderDataForDoc =
  (
    docId,
    originalHeaderData,
    originalHeaderDataAsObj,
    updatedColObj,
    fieldOperationType,
  ) =>
  (dispatch) => {
    if (ENV) {
      return;
    }
    const {headerData, headerDataAsObj} =
      miniAppsActionHelper.getUpdatedHeaderDataAndHeaderDataAsObj(
        originalHeaderData,
        originalHeaderDataAsObj,
        fieldOperationType,
        updatedColObj,
      ) ?? {};
    if (!Array.isArray(headerData) || headerData?.length === 0) {
      return;
    }
    if (isEmpty(headerDataAsObj)) {
      return;
    }
    return dispatch({
      type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
      payload: {
        docId,
        data: {
          headerData: headerData,
          headerDataAsObj: headerDataAsObj,
        },
      },
    });
  };

const closeSearchFilterForScreen = (screenId) => (dispatch, getState) => {
  screenId = screenId ?? getState().miniApps.activeScreenId;
  if (screenId) {
    dispatch({
      type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
      payload: {
        screenId,
        isRemove: true,
      },
    });
    const {
      miniApps: {activeAppId, miniApps, docsData},
    } = getState();
    const activeAppMeta = miniApps[activeAppId];
    const screenMeta = activeAppMeta?.screens?.[screenId] ?? {};
    const screenDocData = docsData?.[screenMeta?.docs?.[0]?.docId] ?? {};
    if (
      screenMeta?.customLayoutType ===
        MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN &&
      isKanbanConfigValid(
        screenId,
        screenMeta,
        screenDocData,
        activeAppMeta?.screens,
      )
    ) {
      const {kanbanScreenIds = []} =
        screenMeta?.view?.[MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN] ??
        {};
      if (kanbanScreenIds.length > 0) {
        kanbanScreenIds.forEach((kanbanScreenId) =>
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
            payload: {
              screenId: kanbanScreenId,
              isRemove: true,
            },
          }),
        );
      }
    }
  }

  return {};
};

/**
 * @description : This function is used to create a new role and edit an existing role
 * @returns Boolean : true if role is created/edited successfully
 */
const createEditCustomRole =
  ({
    roleName,
    screenConfiguration,
    customRoleId = null, //in case of edit/delete
    isEdit = false,
    isDelete = false, //for delete just pass isDelete = true and customRoleId
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId, miniApps},
        auth,
      } = getState();

      if (!dispatch(checkUserPlanForAccess(true, false, 'CUSTOM_ROLE'))) {
        return false;
      }

      let updatedScreenConfiguartionObj;

      if (!isDelete) {
        updatedScreenConfiguartionObj =
          miniAppsActionHelper.getUpdatedScreenConfigurationForKanban(
            screenConfiguration,
            miniApps?.[activeAppId],
          );
      }

      const dataObj = {
        miniAppId: activeAppId,
        addByContact: auth.user.phoneNumber
          ? auth.user.phoneNumber
          : auth.user.email,
        roleName,
        screenConfiguration: updatedScreenConfiguartionObj,
        customRoleId,
        isEdit,
        isDelete,
      };

      const data =
        await miniAppsActionHelper.createEditMiniAppCustomRolesCloud(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        //success
        const defaultMsg = isDelete
          ? 'Role deleted successfully.'
          : isEdit
            ? 'Role modified successfully.'
            : 'Role created successfully.';
        const currentCustomRoles = getState().miniApps.customRoles;
        const customRoles = [
          ...(customRoleId
            ? currentCustomRoles.filter(
                (roleObj) => roleObj.customRoleId !== customRoleId,
              )
            : currentCustomRoles),
          ...(!isDelete
            ? [
                Object.assign({}, data.customRoleData, {
                  customRoleId: data.customRoleId,
                }),
              ]
            : []),
        ];
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_CUSTOM_ROLES_STATE,
          payload: {
            customRoles: orderBy(customRoles, 'roleName'),
          },
        });
        const message = getLocalText(auth.userPref, data.message ?? defaultMsg);
        ShowToast(message, auth.userPref);
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

/**
 * @description : This function is used to share a mini app or change permission of already sharedWith user
 * @param : contactArr : Array of contactObj {name, contact, permission, customRoleId - if Custom Permission}
 * @returns Boolean : true if success
 */
const shareMiniApp =
  ({contactArr, isChangePermission = false}) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId},
        auth,
      } = getState();

      if (
        !dispatch(
          checkUserPlanForAccess(
            true,
            true,
            isChangePermission ? 'CHANGE_PERMISSION' : 'SHARE_APP',
          ),
        )
      ) {
        return false;
      }

      const countryDiallingCode = getUserCallingCode(auth.userCountry) ?? '+91';

      const dataObj = {
        miniAppId: activeAppId,
        addByContact: auth.user.phoneNumber
          ? auth.user.phoneNumber
          : auth.user.email,
        countryCode: countryDiallingCode,
        contactArr,
      };

      const data =
        await miniAppsActionHelper.shareChangePermissionMiniAppCloud(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        dispatch(
          setExploreForApps(EXPLORE_LIO_STEPS.SHARE_APP_TO_TEAM_MEMBER.id),
        );
        dispatch(
          setExploreForApps(
            EXPLORE_LIO_MINI_APP_STEPS.INVITE_TEAM_MEMBER.id,
            true,
          ),
        );
        //success
        const {miniApps} = getState().miniApps;
        const updatedMiniApps = Object.assign({}, miniApps, {
          [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
            data.updatedMiniAppData,
            activeAppId,
            auth.user.uid,
            auth.userPref,
          ),
        });
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });
        const defaultMsg = isChangePermission
          ? 'Permission changed successfully.'
          : 'App shared successfully.';
        const message = getLocalText(auth.userPref, data.message ?? defaultMsg);
        ShowToast(message, auth.userPref);
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

/**
 * @description : This function is used to share a mini app or change permission already sharedWith user
 * @param : uidToRemove : UID to remove from app
 * @returns Boolean : true if success
 */
const removeSharedUserOrLeaveApp =
  ({uidToRemove, miniAppId, isLeaveSharedApp = false}) =>
  async (dispatch, getState) => {
    try {
      const {auth} = getState();

      if (isLeaveSharedApp && auth.user.uid !== uidToRemove) {
        ShowToast('Invalid user', auth.userPref);
      }

      const isFreeUserLeavingApp =
        isLeaveSharedApp && !dispatch(checkUserPlanForAccess(false, false));

      if (
        !isFreeUserLeavingApp &&
        !dispatch(
          checkUserPlanForAccess(
            true,
            true,
            isLeaveSharedApp ? 'LEAVE_APP' : 'REMOVE_USER_APP',
          ),
        )
      ) {
        return false;
      }

      const dataObj = {
        miniAppId,
        uidsToRemove: [uidToRemove],
        isLeave: isLeaveSharedApp,
      };

      const data =
        await miniAppsActionHelper.removeSharedUserOrLeaveAppCloud(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        //success
        const {miniApps} = getState().miniApps;
        const updatedMiniApps = {};
        if (isLeaveSharedApp) {
          Object.assign(updatedMiniApps, omit(miniApps, [miniAppId]));
        } else {
          Object.assign(updatedMiniApps, miniApps, {
            [miniAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
              data.updatedMiniAppData,
              miniAppId,
              auth.user.uid,
              auth.userPref,
            ),
          });
        }
        dispatch({
          type: MINI_APPS_ACTION.LOAD_MINI_APPS,
          payload: updatedMiniApps,
        });
        const defaultMsg = isLeaveSharedApp
          ? 'App left successfully.'
          : 'User removed successfully.';
        const message = getLocalText(auth.userPref, data.message ?? defaultMsg);
        ShowToast(message, auth.userPref);
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const fetchCustomRoles = () => async (dispatch, getState) => {
  try {
    const {
      miniApps: {activeAppId},
    } = getState();

    if (activeAppId) {
      const customRolesSnap = await FirestoreDB.miniApps
        .customRoles(activeAppId)
        .orderBy('roleName')
        .get();

      if (
        customRolesSnap?.docs?.length &&
        activeAppId === getState().miniApps.activeAppId //app not changed
      ) {
        const customRoles = [];
        customRolesSnap.docs.forEach((doc) => {
          customRoles.push(
            Object.assign({}, doc.data(), {customRoleId: doc.id}),
          );
        });

        dispatch({
          type: MINI_APPS_ACTION.UPDATE_CUSTOM_ROLES_STATE,
          payload: {customRoles},
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
  return false;
};

const sortDocByCustomColumnId =
  (columnId, isDesc = false, firestoreInstance = null, screenId) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user, userCountry},
        miniApps: {activeAppId, miniApps},
      } = getState();

      if (!user?.uid || !activeAppId || !screenId || !columnId) {
        return null;
      }

      const dataObj = Object.assign(
        {},
        {
          miniAppId: activeAppId,
          screenId,
          docId: miniApps[activeAppId]?.screens?.[screenId]?.docs?.[0]?.docId,
          timezone: getTimezone(),
          columnId,
          userCountry,
          isDesc: Boolean(isDesc),
        },
      );

      const data =
        await miniAppsActionHelper.sortDocByCustomColumnIdCloudFunction(
          dataObj,
        );

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        //success
        if (activeAppId === getState().miniApps.activeAppId) {
          //if app opened
          return dispatch(
            reloadCurrentAppInternally(activeAppId, firestoreInstance),
          );
        }
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const reorderDocDataForSortKeyChange =
  ({docId, sortingKeyChangedRows, omitFor}) =>
  (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps, activeAppId, docsData, searchFilterData},
      } = getState();

      if (!activeAppId || !sortingKeyChangedRows?.length) {
        return;
      }
      const activeAppMeta = miniApps[activeAppId];

      const docData = Object.assign({}, docsData[docId]);

      const docFilteredData = Object.assign({}, docData?.filterData);

      const checkOmitFor = (key, isScreenType = false) =>
        omitFor?.[isScreenType ? 'screenType' : 'screenId']?.includes?.(key);

      //check if this row is part of result set after applying filters : Data Filter Screen
      forOwn(docFilteredData, (filterData, screenId) => {
        if (
          !filterData.isFilteredDataLoading &&
          filterData.tableData?.length &&
          !(
            checkOmitFor(MINI_APPS.SCREEN_TYPE.FILTERED_DATA, true) &&
            checkOmitFor(screenId, false)
          )
        ) {
          const processedData = processRowsDataForMappedRowId(
            {docs: sortingKeyChangedRows},
            null,
            filterData.tableData,
            null,
            docData.rowIdDataMap,
            docData.fileObj,
            {insertOnlyIfExists: true},
          );
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
            payload: {
              docId,
              screenId,
              data: {tableData: processedData.tableData},
            },
          });
        }
      });

      // check if this row is part of result set after applying filters : Screen - Search/Filter
      forOwn(searchFilterData, (searchFilterScreenValue, screenId) => {
        if (
          activeAppMeta?.screens?.[screenId]?.docs?.[0]?.docId === docId &&
          searchFilterScreenValue.isActive &&
          searchFilterScreenValue.tableData?.length &&
          !(
            checkOmitFor('screen-search-filter', true) &&
            checkOmitFor(screenId, false)
          )
        ) {
          const processedData = processRowsDataForMappedRowId(
            {docs: sortingKeyChangedRows},
            null,
            searchFilterScreenValue.tableData,
            null,
            docData.rowIdDataMap,
            docData.fileObj,
            {insertOnlyIfExists: true},
          );
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
            payload: {
              screenId,
              data: {
                tableData: processedData.tableData,
              },
            },
          });
        }
      });

      if (!checkOmitFor(MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA, true)) {
        if (docData.tableData?.length) {
          const processedData = processRowsDataForMappedRowId(
            {docs: sortingKeyChangedRows},
            null,
            docData.tableData,
            null,
            docData.rowIdDataMap,
            docData.fileObj,
            {insertOnlyIfExists: true},
          );
          dispatch({
            type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
            payload: {docId, data: {tableData: processedData.tableData}},
          });
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const researchFilterOnDataChange =
  ({getRowsArr, docId}) =>
  (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps, activeAppId, docsData, searchFilterData},
      } = getState();

      if (!activeAppId) {
        return;
      }
      const activeAppMeta = miniApps[activeAppId];

      const docFilteredData = Object.assign({}, docsData[docId]?.filterData);

      //check if this row is part of result set after applying filters : Data Filter Screen
      forOwn(docFilteredData, (filterData, screenId) => {
        if (!filterData.isFilteredDataLoading) {
          delayedExecution(() =>
            dispatch(
              searchFilterForMiniAppScreen(null, null, {
                screenToSearchOn: screenId,
                rowDocs: getRowsArr(),
                mode: MINI_APPS.SCREEN_TYPE.FILTERED_DATA,
              }),
            ).then((res) => {
              //res.updatedRowIdDataMap && res.sortingKeyChangedRows will be empty as we are not updating the row data
              if (res?.success) {
                dispatch({
                  type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
                  payload: {
                    docId,
                    screenId,
                    data: {tableData: res.tableDataArr},
                  },
                });
              }
            }),
          );
        }
      });

      // check if this row is part of result set after applying filters : Screen - Search/Filter
      forOwn(searchFilterData, (searchFilterScreenValue, screenId) => {
        if (
          activeAppMeta?.screens?.[screenId]?.docs?.[0]?.docId === docId &&
          searchFilterScreenValue.isActive &&
          !searchFilterScreenValue.isLoading
        ) {
          delayedExecution(() =>
            dispatch(
              searchFilterForMiniAppScreen(
                searchFilterScreenValue.filterArr,
                searchFilterScreenValue.searchedText,
                {
                  screenToSearchOn: screenId,
                  rowDocs: getRowsArr(),
                  mode: SCREEN_SEARCH_FILTER,
                },
              ),
            ).then((res) => {
              if (res?.success) {
                //res.updatedRowIdDataMap && res.sortingKeyChangedRows will be empty as we are not updating the row data
                dispatch({
                  type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
                  payload: {
                    screenId,
                    data: {
                      tableData: res.tableDataArr,
                      hitsSize:
                        (searchFilterScreenValue.hitsSize ?? 0) +
                        (res.tableDataArr.length -
                          searchFilterScreenValue.tableData.length),
                    },
                  },
                });
              }
            }),
          );
        }
      });
    } catch (error) {
      captureError(error);
    }
  };

const miniAppRowAddEditSuccessHandler =
  ({docId, rowObj}) =>
  (dispatch, getState) => {
    try {
      const rowId = rowObj?.rowId;

      if (rowId) {
        const prevRowData =
          getState().miniApps.docsData?.[docId]?.rowIdDataMap?.[rowId];
        const getRowDocObj = (isWithOldSortIndex = false) => ({
          id: rowId,
          data: () =>
            isWithOldSortIndex
              ? Object.assign(
                  {},
                  rowObj,
                  prevRowData?.sortKey != null
                    ? {sortKey: prevRowData?.sortKey}
                    : null,
                )
              : rowObj,
          exists: true,
          ...(prevRowData ? {prevData: () => prevRowData} : {}),
        });

        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
          payload: {
            docId,
            data: {
              rowIdDataMap: {
                [rowId]: makeRowObjFromRowDoc(getRowDocObj(true))[0],
              },
            },
          },
        });

        dispatch(
          researchFilterOnDataChange({
            getRowsArr: () => [getRowDocObj(false)],
            docId,
          }),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const miniAppRowDeleteSuccessHandler =
  ({docId, rowIdsArr}) =>
  (dispatch, getState) => {
    try {
      if (Array.isArray(rowIdsArr) && rowIdsArr.length && docId) {
        const {
          miniApps: {miniApps, activeAppId, docsData, searchFilterData},
        } = getState();

        if (!activeAppId) {
          return;
        }

        const activeAppMeta = miniApps[activeAppId];

        const docFilteredData = Object.assign({}, docsData[docId]?.filterData);

        //Remove this row(if exists) in the screen data : Data Filter Screen
        forOwn(docFilteredData, (filterData, screenId) => {
          if (!filterData.isFilteredDataLoading) {
            const updatedTableData = filterData.tableData.filter(
              (rowId) => !rowIdsArr.includes(rowId),
            );
            if (filterData.tableData.length !== updatedTableData) {
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
                payload: {
                  docId,
                  screenId,
                  data: {tableData: updatedTableData},
                },
              });
            }
          }
        });

        //Remove this row(if exists) in the screen data : Screen - Search/Filter
        forOwn(searchFilterData, (searchFilterScreenValue, screenId) => {
          if (
            activeAppMeta.screens?.[screenId]?.docs?.[0]?.docId === docId &&
            searchFilterScreenValue.isActive &&
            !searchFilterScreenValue.isLoading
          ) {
            const updatedTableData = searchFilterScreenValue.tableData.filter(
              (rowId) => !rowIdsArr.includes(rowId),
            );
            if (searchFilterScreenValue.tableData.length !== updatedTableData) {
              dispatch({
                type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
                payload: {
                  screenId,
                  data: {
                    tableData: updatedTableData,
                    hitsSize:
                      (searchFilterScreenValue.hitsSize ?? 0) -
                      (searchFilterScreenValue.tableData.length -
                        updatedTableData.length),
                  },
                },
              });
            }
          }
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

//Add edit fileObj properties using cloud function
const addEditFileObjProperties =
  ({
    docId,
    updateObj,
    propertySource = FIELD_OBJ_MANIPULATION_PROPERTY.UNIQUE_COLUMN_SET,
    isEdit = false,
    isDelete = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId},
        auth,
      } = getState();

      const dataObj = {
        miniAppId: activeAppId,
        propertySource,
        docId,
        updateObj,
        isEdit,
        isDelete,
        timezone: getTimezone(),
      };

      const data =
        await miniAppsActionHelper.addEditFileObjPropertiesCF(dataObj);
      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
        return false;
      }
      //success
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
        payload: {
          docId,
          data: {fileObj: data.updatedFileObj},
        },
      });
      return true;
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const addEditMiniAppsDataFromEntryOnlyV2 =
  (
    {
      rowData,
      prevRowData = {},
      isRowEdit = false,
      docId,
      screenId,
      visibleColIds,
      extra = {},
    },
    {
      isBackgroundOperation = false,
      paramsToPreserve = null,
      requestId,
      onBGOperationStatusChange,
      entryText,
      isKanbanEditRowOp = false,
    },
  ) =>
  async (dispatch, getState) => {
    try {
      const initReduxState = getState();
      const {
        auth: {
          user: {uid},
          userPref,
        },
      } = initReduxState;
      if (!uid) {
        return;
      }

      const {
        activeScreenData: activeScreenMeta,
        activeScreenId: screenIdToUse,
        activeAppMeta,
        activeAppId,
      } = miniAppsActionHelper.mapMiniAppStates(
        initReduxState,
        false,
        false,
        screenId,
      );

      const logEvent = (eventName, params) => {
        const commonLogObject = () => {
          const {appName, appIcon, appIconColor, miniAppId} = activeAppMeta;
          const {layoutId} = activeScreenMeta;

          return {
            appName,
            iconId: appIcon,
            iconName: appIcon,
            colorId: appIconColor,
            colorName: appIconColor,
            cardLayout: layoutId,
            p_appId: miniAppId,
          };
        };

        const commonScreenLogObject = () => {
          const {screenName, docs} = activeScreenMeta;
          return {
            p_screenName: screenName,
            screenId: screenIdToUse,
            p_recordCount: 0,
            p_docId: docs?.[0]?.docId,
          };
        };

        return logAnalyticsEvent(eventName, {
          ...getMiniAppsLogObject(initReduxState),
          ...commonLogObject(),
          ...commonScreenLogObject(),
          ...(params ?? {}),
        });
      };

      logEvent(isRowEdit ? 'APP_UPDATE_ENTRY_INIT' : 'APP_ADD_ENTRY_INIT');

      const {updatedRowIndex} = extra;
      // ^used for setting index of row
      // to a particular value.
      // currently only used for kanban view

      const dataObjToSend = {
        docId,
        rowData,
        prevRowData,
        timezone: getTimezone(),
        isRowEdit: Boolean(isRowEdit),
        userSelectedOptions: {
          isUpdateChildRows: extra?.isUpdateChildRows ?? false,
          recurringInfo: {recurringEvent: extra?.recurringEvent},
          ...(!isNil(updatedRowIndex) ? {updatedRowIndex} : {}),
        },
        optionalMeta: {
          miniAppId: activeAppId,
          screenId: screenIdToUse,
          visibleColIds,
          commentsToAdd: extra?.commentsToAdd,
          listCol: extra.listCol ?? getListColumnMappedStatesForEntry(),
          isBackgroundOperation,
        },
      };

      requestId = requestId ?? generateRandomId(6, 'req_');
      const commanParamsForBGOperation = {
        requestId,
        screenId: screenIdToUse,
        rowMeta: dataObjToSend,
        paramsToPreserve,
        onBGOperationStatusChange,
        entryText,
      };

      if (isBackgroundOperation) {
        dispatch(addStatusRequest(commanParamsForBGOperation));
      }
      if (isRowEdit && isKanbanEditRowOp) {
        dispatch(
          updateStatusRequestForEditRowOpInKanban({
            docId,
            rowMeta: Object.assign(
              {},
              rowData,
              !isNil(updatedRowIndex) ? {index: updatedRowIndex} : {},
            ),
            isUnderProcess: true,
          }),
        );
      }
      const data =
        await miniAppsActionHelper.addEditMiniAppEntry(dataObjToSend);

      const reduxState = getState();

      if (
        reduxState.miniApps.activeAppId &&
        activeAppId !== reduxState.miniApps.activeAppId
      ) {
        //app changed
        return;
      }

      if (!data || !data.success) {
        const message = handleCloudErrorMsgAndLogging(
          data,
          dataObjToSend,
          userPref,
          false,
        );

        if (isBackgroundOperation) {
          dispatch(
            updateStatusRequestForFailure({
              ...commanParamsForBGOperation,
              messageObj: {
                heading: 'Failed',
                subtext: message,
              },
              errorObj: {},
            }),
          );
        }

        if (isRowEdit && isKanbanEditRowOp) {
          //! TODO : THIS CAUSES A UI FLICKER WHEN
          //! SELECTBOX COLUMN ON WHICH KANBAN IS CONFIGURED
          //! IS PART OF SOME UNIQUE KEY SET

          // TODO : MAKE SURE EXECUTION OF miniAppRowAddEditSuccessHandler
          // TODO : IS COMPLETED BEFORE updateStatusRequestForEditRowOpInKanban STARTS
          dispatch(
            miniAppRowAddEditSuccessHandler({
              docId,
              rowObj: Object.assign({}, prevRowData, {
                rowObj: omit(prevRowData, TABLE_LIMITS.REQUIRED_ROW_KEYS),
              }),
            }),
          );
          dispatch(
            updateStatusRequestForEditRowOpInKanban({
              docId,
              rowMeta: rowData,
              isUnderProcess: false,
            }),
          );
        }

        return {
          success: false,
          message,
        };
      }

      //success
      dispatch(
        setExploreForApps(
          isRowEdit
            ? EXPLORE_LIO_MINI_APP_STEPS.EDIT_ENTRY.id
            : EXPLORE_LIO_MINI_APP_STEPS.ADD_ENTRY.id,
          true,
        ),
      );
      logEvent(
        isRowEdit ? 'APP_UPDATE_ENTRY_SUCCESS' : 'p_APP_ADD_ENTRY_SUCCESS',
      );

      const firestoreRowData = Object.assign({}, data.firestoreRowData);
      const updatedRowObj = makeRowObjFromRowDoc({
        id: firestoreRowData.rowId,
        data: () => firestoreRowData,
        exists: true,
      })[0];

      if (!isKanbanEditRowOp) {
        dispatch(
          miniAppRowAddEditSuccessHandler({
            docId,
            rowObj: firestoreRowData,
          }),
        );
      }

      if (isBackgroundOperation) {
        const isRowIdUpdateReq =
          firestoreRowData.rowId && rowData?.rowId !== firestoreRowData.rowId;
        const statusUpdateObj = {
          ...commanParamsForBGOperation,
          messageObj: {
            heading: 'Success',
            subtext: `${entryText ?? 'Entry'} added successfully!`,
          },
        };
        dispatch(
          updateStatusRequestForSuccess(
            isRowIdUpdateReq
              ? merge({}, statusUpdateObj, {
                  rowMeta: {rowData: {rowId: firestoreRowData.rowId}},
                })
              : statusUpdateObj,
          ),
        );
      }
      if (isRowEdit && isKanbanEditRowOp) {
        // Waiting before updating isUnderProcess status
        // for row to make sure row data is updated to
        // latest select box value, once listener acts up
        setTimeout(() => {
          dispatch(
            updateStatusRequestForEditRowOpInKanban({
              docId,
              rowMeta: rowData,
              isUnderProcess: false,
            }),
          );
        }, 1800);
      }

      return {
        success: true,
        updatedRowObject: updatedRowObj,
      };
    } catch (error) {
      captureError(error);
      return {
        success: false,
      };
    }
  };

const downloadPDFUsingActionButton =
  ({
    rowId,
    docId,
    pdfConfigId,
    pdfNamePrefix,
    pdfExportType,
    customNotificationTextObj,
    screenId,
    actionBtnId,
    onSuccess,
  }) =>
  async (dispatch, getState) => {
    const {
      miniApps: {activeAppId, miniApps},
      organisation: {activeOrganisationId},
      auth: {
        user: {uid},
      },
    } = getState();

    const isExcel = pdfExportType === PDF_FIELD_EXPORTS.OPTIONS.EXCEL;

    const updateActionBtnStatus = (updatedObj) => {
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_ACTION_BUTTONS_STATUS,
        payload: {
          appId: activeAppId,
          screenId,
          rowId,
          actionBtnId,
          updatedObj,
        },
      });
    };
    const notificationServiceInstance = new NotificationService();
    const notificationId = notificationServiceInstance.generateNotificationId(
      activeAppId,
      uid,
    );

    const {triggerMsg, errorMsg} = Object.assign({}, customNotificationTextObj);

    try {
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.GENERATE_PDF,
        type: NOTIFICATION_TYPE.REQUESTED,
        data: {
          toast: {
            body: triggerMsg
              ? triggerMsg
              : `Generating ${isExcel ? 'Excel' : 'PDF'}`,
            title: 'Generate PDF',
          },
          displayScreenId: screenId,
        },
        forAppId: activeAppId,
        forUID: [uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: uid,
        notificationId,
      });

      updateActionBtnStatus({
        status: MINI_APPS.ACTION_BUTTONS.BTN_STATUS.RUNNING,
      });

      const response = await miniAppsActionHelper.generatePDF({
        orgId: activeOrganisationId,
        miniAppId: activeAppId,
        screenId,
        rowId,
        docId,
        timezone: getTimezone(),
        pdfConfigId,
        pdfExportType,
        notificationIdToUse: notificationId,
        customNotificationTextObj,
      });

      const isSuccess = Boolean(response?.success);

      if (isSuccess) {
        const {downloadURL} = (isExcel ? response.excel : response.pdf) ?? {};
        onSuccess?.({
          url: downloadURL,
          name:
            pdfNamePrefix ??
            miniApps[activeAppId]?.screens?.[screenId]?.screenName,
          isExcel,
        });
      }

      updateActionBtnStatus({
        status: isSuccess
          ? MINI_APPS.ACTION_BUTTONS.BTN_STATUS.SUCCESS
          : MINI_APPS.ACTION_BUTTONS.BTN_STATUS.FAILED,
      });

      return isSuccess;
    } catch (error) {
      updateActionBtnStatus({
        status: MINI_APPS.ACTION_BUTTONS.BTN_STATUS.FAILED,
        error: error?.message,
      });

      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.GENERATE_PDF,
        type: NOTIFICATION_TYPE.ERROR,
        data: {
          toast: {
            body: errorMsg
              ? errorMsg
              : `${isExcel ? 'Excel' : 'PDF'} cannot be downloaded`,
            title: 'Generate PDF',
          },
        },
        forAppId: activeAppId,
        forUID: [uid],
        isRunning: false,
        orgId: activeOrganisationId,
        initiatedBy: uid,
        notificationId,
      });

      captureError(error);
      return false;
    }
  };

const deleteRowUsingCF =
  (rowId, rowData, originalDocumentIdReq, documentIdReq, ownerUID) =>
  async (dispatch, getState) => {
    try {
      const initState = getState();
      const {
        persistedData,
        auth: {userPref},
      } = initState;

      const {
        activeScreenData: activeScreenMeta,
        activeScreenId,
        activeAppMeta,
        documentData,
        activeAppId,
      } = miniAppsActionHelper.mapMiniAppStates(initState, false);
      const sendObj = {
        rowId,
        rowData,
        originalDocumentIdReq,
        documentIdReq,
        screenId: activeScreenId,
        miniAppId: activeAppId,
        callerDeviceId: persistedData?.uniqueDeviceId ?? moment().unix(),
        ownerUID: ownerUID,
        timezone: userPref?.timezone ?? getTimezone(),
        documentData: {
          headerData: Object.assign({}, documentData.headerData),
          footerData: Object.assign({}, documentData?.footerData),
          fileObj: Object.assign({}, documentData?.fileObj),
          noOfRows:
            typeof documentData?.noOfRows === 'number'
              ? documentData?.noOfRows
              : 0,
        },
      };
      const commonLogObject = () => {
        const {appName, appIcon, appIconColor, miniAppId} = activeAppMeta;
        const {layoutId} = activeScreenMeta;

        return {
          appName,
          iconId: appIcon,
          iconName: appIcon,
          colorId: appIconColor,
          colorName: appIconColor,
          cardLayout: layoutId,
          p_appId: miniAppId,
        };
      };

      const commonScreenLogObject = () => {
        const {screenName, docs} = activeScreenMeta;
        const docId = docs?.[0]?.docId;
        return {
          p_screenName: screenName,
          screenId: activeScreenId,
          p_recordCount: 0,
          p_docId: docId,
          originalDocId: docId,
          docId: docId,
          rowId: rowId,
        };
      };

      logAnalyticsEvent('APP_DELETE_ENTRY_INIT', {
        ...getMiniAppsLogObject(getState()),
        ...commonLogObject(),
        ...commonScreenLogObject(),
      });

      const data = await miniAppsActionHelper.deleteRowCF(sendObj);
      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }

      // success
      dispatch(
        miniAppRowDeleteSuccessHandler({
          docId: originalDocumentIdReq,
          rowIdsArr: [rowId],
        }),
      );

      logAnalyticsEvent('APP_DELETE_ENTRY_SUCCESS', {
        ...getMiniAppsLogObject(getState()),
        ...commonLogObject(),
        ...commonScreenLogObject(),
      });

      return true;
    } catch (error) {
      captureError(error);
      return;
    }
  };

const deleteMultipleRowUsingCF =
  (checkRecords, docId) => async (dispatch, getState) => {
    try {
      const initState = getState();
      const {
        persistedData,
        auth: {userPref},
        miniApps: {activeAppId},
      } = initState;

      const {documentData, activeDocId, activeDocOwnerUID} =
        miniAppsActionHelper.mapMiniAppStates(initState, false);
      const allRows = documentData?.rowIdDataMap;

      const rows = Object.fromEntries(
        Object.entries(allRows).filter(([key]) => checkRecords?.includes(key)),
      );
      const finalRows = [];
      const keys = Object.keys(rows);
      keys.forEach((key) => finalRows.push(rows[key]));

      const sendObj = {
        rows: finalRows,
        originalDocumentIdReq: activeDocId,
        documentIdReq: activeDocId,
        callerDeviceId: persistedData?.uniqueDeviceId ?? moment().unix(),
        ownerUID: activeDocOwnerUID,
        miniAppId: activeAppId,
        timezone: userPref?.timezone ?? getTimezone(),
        documentData: {
          headerData: Object.assign({}, documentData.headerData),
          footerData: Object.assign({}, documentData?.footerData),
          fileObj: Object.assign({}, documentData?.fileObj),
          noOfRows:
            typeof documentData?.noOfRows === 'number'
              ? documentData?.noOfRows
              : 0,
        },
      };

      const data = await miniAppsActionHelper.deleteRowCF(sendObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }

      // success
      dispatch(
        miniAppRowDeleteSuccessHandler({
          docId,
          rowIdsArr: checkRecords,
        }),
      );
      return true;
    } catch (error) {
      captureError(error);
      return;
    }
  };

const addColumnUsingCF =
  (originalDocumentId, type, colName, unitDetails, property, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
      } = getState();
      const {
        activeDocOwnerUID,
        documentData: {headerData, headerDataAsObj},
      } = miniAppsActionHelper.mapMiniAppStates(
        getState(),
        false,
        false,
        extra?.screenId,
      );
      const sendObj = {
        originalDocumentId,
        type,
        property,
        unitDetails,
        colName,
        visibilityCondition: extra?.visibilityCondition,
        selectElements: extra?.selectElements ?? [],
        optionalEqn: Object.assign({}, extra?.optionalEqn),
        eqn: extra?.eqn,
        eqnStr: extra?.eqnStr,
        eqnColName: extra?.eqnColName,
        ownerUID: activeDocOwnerUID,
        subType: extra?.subType,
        parentSubType: extra?.parentSubType,
        selectedColObj: extra?.selectedColObj,
        selectedDocID: extra?.selectedDocID,
        tableUnitMeta: extra?.tableUnitMeta,
        listConfigDetails: extra?.listConfigDetails,
        summaryConfig: extra?.summaryConfig,
        configuration: extra?.configuration,

        dateMathConfig: extra?.dateMathConfig,

        daysConfig: extra?.daysConfig,

        timezone: getTimezone(),
        pdfConfig: extra?.pdfConfig,
        pdfNamePrefix: extra?.pdfNamePrefix,
        pdfExportType: extra?.pdfExportType,

        filterConfig: extra?.filterConfig,
        dateFormat: extra?.dateFormat,
        // nameAsSubtext // TODO : Check how to handle this
        validationCondition: null,
        ...(type === FIELD_TYPE_ID.TABLE &&
        [FIELD_TYPE_ID.RUPEE, FIELD_TYPE_ID.UNIT].includes(extra?.subType)
          ? {uptoDecimalPlaces: extra?.uptoDecimalPlaces ?? undefined}
          : {}),
        otpConfig: extra?.otpConfig,
        languageText: extra?.languageText,
      };
      dispatch(updateGlobalLoader(true, {text: 'Adding Column...'}));
      const data = await miniAppsActionHelper.addColumnCF(sendObj);
      dispatch(updateGlobalLoader(false));

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }

      dispatch(
        updateHeaderDataForDoc(
          originalDocumentId,
          headerData,
          headerDataAsObj,
          data.colData,
          FIELD_OPERATION_TYPE.ADD,
        ),
      );
      return extra?.returnColId ? data.colId : true;
    } catch (error) {
      captureError(error);

      dispatch(updateGlobalLoader(false));

      return false;
    }
  };

const deleteColumnUsingCF =
  (originalDocumentId, colId, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        automation: {dependentCols},
        miniApps: {docsData},
      } = getState();

      let removeAutomationIDs = [];

      if (dependentCols?.includes?.(colId)) {
        // Refetch automation config
        removeAutomationIDs = dispatch(
          deleteAutomationForDependentCol(colId, {fromMiniApps: true}),
        );
      }

      const {activeDocOwnerUID} = miniAppsActionHelper.mapMiniAppStates(
        getState(),
        false,
        false,
        extra?.screenId,
      );

      const sendObj = {
        originalDocumentId,
        columnId: colId,
        uid: user?.uid,
        ownerUID: activeDocOwnerUID,
        removeAutomationIDs,
      };

      dispatch(updateGlobalLoader(true, {text: 'Deleting Column...'}));

      const data = await miniAppsActionHelper.deleteColumnCF(sendObj);

      dispatch(updateGlobalLoader(false));

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }

      dispatch(
        updateHeaderDataForDoc(
          originalDocumentId,
          docsData?.[originalDocumentId]?.headerData,
          docsData?.[originalDocumentId]?.headerDataAsObj,
          {id: colId},
          FIELD_OPERATION_TYPE.DELETE,
        ),
      );

      return true;
    } catch (error) {
      captureError(error);
      dispatch(updateGlobalLoader(false));
      return;
    }
  };

const changeAssigneeForMultipleRows =
  (checkRecords, selectedAppMember, selectedColId, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const initState = getState();
      const {
        auth: {userPref},
      } = initState;
      let finalRows = [];
      let oldRows = [];

      const {activeScreenId, documentData, activeDocId, activeAppId} =
        miniAppsActionHelper.mapMiniAppStates(initState, false);

      if (isEmpty(extra.finalRows)) {
        const allRows = documentData?.rowIdDataMap;
        const rows = {};

        if (allRows) {
          checkRecords.forEach((rowId) => {
            rows[rowId] = allRows[rowId];
          });
          // Object.entries(allRows).forEach(([key, value]) => {
          //   if (checkRecords?.includes(key)) {
          //     rows[key] = value;
          //   }
          // });
        }

        const keys = Object.keys(rows);
        keys.forEach((key) => finalRows.push(rows[key]));

        oldRows = [...finalRows];

        finalRows = finalRows.map((row) => {
          const selectedCol = row[selectedColId];
          const val = selectedCol?.val || {
            note: '',
            dueDate: null,
            priority: null,
            taskId: '-',
            isCompleted: false,
          };
          const assignee = val?.assignee;

          return {
            ...row,
            [selectedColId]: {
              ...selectedCol,
              val: {
                ...val,
                assignee: {
                  ...assignee,
                  name: selectedAppMember?.displayName,
                  uid: selectedAppMember?.m_uid,
                  contact: selectedAppMember.contact,
                },
              },
            },
          };
        });
      }

      // const sendObj = {
      //   docId: activeDocId,
      //   callerUID: uid,
      //   isRowEdit: true,
      //   rows: extra?.finalRows ? extra.finalRows : finalRows,
      //   oldRows: extra?.oldRows ? extra.oldRows : oldRows,
      //   originalDocumentIdReq: activeDocId,
      //   callerDeviceId: persistedData?.uniqueDeviceId ?? moment().unix(),
      //   ownerUID: activeDocOwnerUID,
      //   timezone: userPref?.timezone ?? getTimezone(),
      //   documentData: {
      //     headerData: Object.assign({}, documentData.headerData),
      //     footerData: Object.assign({}, documentData?.footerData),
      //     fileObj: Object.assign({}, documentData?.fileObj),
      //     noOfRows:
      //       typeof documentData?.noOfRows === 'number'
      //         ? documentData?.noOfRows
      //         : 0,
      //   },
      // };

      finalRows = extra?.finalRows ? extra.finalRows : finalRows;
      oldRows = extra?.oldRows ? extra.oldRows : oldRows;

      const hasOldRows = !isEmpty(oldRows);

      const multipleEntryObj = {
        //   docId: subListDocId,
        docId: activeDocId,
        rowsArr: finalRows.map((item, index) => ({
          rowObj: item,
          ...(hasOldRows ? {prevRowObj: oldRows[index]} : {}),
        })),
        timezone: userPref?.timezone ?? getTimezone(),
        source: 'MULTIPLE_ROWS',
        isEdit: true,
        appId: activeAppId,
        screenId: activeScreenId,
        options: Object.assign({}, extra.options, {skipMandatoryCheck: true}),
      };

      // const commonLogObject = () => {
      //   const {appName, appIcon, appIconColor, miniAppId} = activeAppMeta;
      //   const {layoutId} = activeScreenMeta;

      //   return {
      //     appName,
      //     iconId: appIcon,
      //     iconName: appIcon,
      //     colorId: appIconColor,
      //     colorName: appIconColor,
      //     cardLayout: layoutId,
      //     p_appId: miniAppId,
      //   };
      // };

      // const commonScreenLogObject = () => {
      //   const {screenName, docs} = activeScreenMeta;
      //   const docId = docs?.[0]?.docId;
      //   return {
      //     p_screenName: screenName,
      //     screenId: activeScreenId,
      //     p_recordCount: 0,
      //     p_docId: docId,
      //     originalDocId: docId,
      //     docId: docId,
      //     rowId: rowId,
      //   };
      // };

      // logAnalyticsEvent('APP_DELETE_ENTRY_INIT', {
      //   ...getMiniAppsLogObject(getState()),
      //   ...commonLogObject(),
      //   ...commonScreenLogObject(),
      // });

      const data =
        await miniAppsActionHelper.addEditMultipleRows(multipleEntryObj);
      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, multipleEntryObj, userPref);
        return false;
      }
      // logAnalyticsEvent('APP_DELETE_ENTRY_SUCCESS', {
      //   ...getMiniAppsLogObject(getState()),
      //   ...commonLogObject(),
      //   ...commonScreenLogObject(),
      // });

      return true;
    } catch (error) {
      captureError(error);
      return;
    }
  };

const moveColumnsUsingCF =
  (headerData, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
      } = getState();

      const {activeDocId: docId, documentData: docsData} =
        miniAppsActionHelper.mapMiniAppStates(
          getState(),
          false,
          false,
          extra?.screenId,
        );

      const {fileObj} = docsData;
      const sendObj = {
        docId,
        headerData: headerData,
        fileObj: Object.assign({}, fileObj, {
          sections: miniAppsActionHelper.getSectionHeaderDataAsObject(
            extra?.sectionsDataAsArray,
          ),
        }),
        oldHeaderData: extra?.oldHeaderData,
      };

      dispatch(
        updateGlobalLoader(true, {text: getLocalText(userPref, 'Updating...')}),
      );

      const data = await miniAppsActionHelper.moveColumnsCF(sendObj);

      dispatch(updateGlobalLoader(false));

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }
      return true;
    } catch (error) {
      captureError(error);
      dispatch(updateGlobalLoader(false));
      return false;
    }
  };

const addEditActionButtons =
  ({
    screenId,
    actionBtnId,
    actionBtnConfig,
    isEdit,
    isDelete,
    isReorder,
    sortedActionBtnsIdArr,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {activeAppId},
      } = getState();

      const dataObj = {
        miniAppId: activeAppId,
        screenId,
        isEdit,
        isDelete,
        actionBtnId,
        actionBtnConfig,
        isReorder,
        sortedActionBtnsIdArr,
      };

      const data =
        await miniAppsActionHelper.addEditActionButtonsCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      }

      //success
      dispatch({
        type: MINI_APPS_ACTION.LOAD_MINI_APPS,
        payload: Object.assign({}, getState().miniApps.miniApps, {
          [activeAppId]: miniAppsActionHelper.getFormattedMiniAppObj(
            data.miniAppDocData,
            activeAppId,
            user.uid,
            userPref,
          ),
        }),
      });

      return true;
    } catch (error) {
      captureError(error);
      dispatch(updateGlobalLoader(false));
      return false;
    }
  };

const editColumnUsingCF =
  (
    originalDocumentId,
    colId,
    index,
    type,
    colName,
    unitDetails,
    property,
    extra = {},
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
      } = getState();
      const {uptoDecimalPlaces} = extra;
      const {
        activeDocOwnerUID,
        documentData: {headerData, headerDataAsObj},
      } = miniAppsActionHelper.mapMiniAppStates(
        getState(),
        false,
        false,
        extra?.screenId,
      );

      const getFormulaPrecision = (value = null, fieldType) => {
        if (value === '' || value == null || isNaN(value)) {
          return fieldType === FIELD_TYPE_ID.FORMULA ? 2 : 0;
        }
        return isInteger(Number(value)) ? Number(value) : 2;
      };

      const sendObj = {
        uid: user?.uid,
        ownerUID: activeDocOwnerUID,
        colId: colId,
        index: null, // incorrect index was passed causing wrong column update
        originalDocumentId,
        type,
        colName,
        visibilityCondition: extra?.visibilityCondition,
        activeDocumentId: originalDocumentId,
        selectElements: !isEmpty(extra?.selectElements)
          ? extra?.selectElements
          : [],
        unitDetails: unitDetails,
        parentSubType: extra?.parentSubType,
        changeLinkedFile: extra?.changeLinkedFile,
        selectedColObj: extra?.selectedColObj,
        optionalEqn: Object.assign({}, extra?.optionalEqn),
        eqn: extra?.eqn,
        eqnStr: extra?.eqnStr,
        eqnColName: extra?.eqnColName,
        styleObj: {}, // TODO : ADD STYLE OBJ
        nameAsSubtext: '', // TODO : ADD NAME AS SUBTEXT
        property: property,
        selectedDocID: extra?.selectedDocID,
        subType: extra?.subType,
        tableUnitMeta: extra?.tableUnitMeta,

        // List Column Params
        listConfigDetails: extra?.listConfigDetails,
        summaryConfig: extra?.summaryConfig,
        isAlreadyListColumn: extra?.isAlreadyListColumn, // For edit only

        // User Column Params
        configuration: extra?.configuration,
        dateMathConfig: extra?.dateMathConfig,
        daysConfig: extra?.daysConfig,

        timezone: getTimezone(),
        //Pdf config Params
        pdfConfigId: extra?.pdfConfigId,
        pdfConfig: extra?.pdfConfig,
        pdfNamePrefix: extra?.pdfNamePrefix,
        pdfExportType: extra?.pdfExportType,
        isPdfConfigUpdated: extra?.isPdfConfigUpdated,

        filterConfig: extra?.filterConfig,
        dateFormat: extra?.dateFormat,
        validationCondition: extra?.validationCondition,
        ...(!isNil(uptoDecimalPlaces)
          ? {
              uptoDecimalPlaces: getFormulaPrecision(
                extra?.uptoDecimalPlaces,
                type,
              ),
            }
          : {}),
        otpConfig: extra?.otpConfig,
        languageText: extra?.languageText,
      };

      dispatch(updateGlobalLoader(true, {text: 'Updating Column...'}));
      const data = await miniAppsActionHelper.editColumnCF(sendObj);
      dispatch(updateGlobalLoader(false));
      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, sendObj, userPref);
        return false;
      }

      dispatch(
        updateHeaderDataForDoc(
          originalDocumentId,
          headerData,
          headerDataAsObj,
          data.colData,
          FIELD_OPERATION_TYPE.EDIT,
        ),
      );

      return true;
    } catch (error) {
      captureError(error);

      dispatch(updateGlobalLoader(false));

      return false;
    }
  };
/**
 *
 * @param {Object} quickFilterMapping : {screenId: [colA, colB]}
 * @return Boolean : return true if success else false
 */
// add permission check on frontend
// add check to allow only COLUMNS_SUPPORTED_FOR_QUICK_FILTER for quick filter
// add check to allow only columns upto max limit for screen
const updateColumnsForQuickFilter =
  (quickFilterMapping) => async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId, miniApps},
        auth: {userPref},
      } = getState();

      // TODO : check if user has permission to perform this action
      const isAllowed = true;
      if (!isAllowed) {
        ShowToast('You cannot perform this action', userPref);
        return false;
      }

      if (!isObject(quickFilterMapping)) {
        ShowToast('Incorrect Data', userPref);
        return false;
      }

      const requestObj = {activeAppId, quickFilterMapping};
      const response =
        await miniAppsActionHelper.updateQuickFilterColumnsForScreenCloud(
          requestObj,
        );

      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, requestObj, userPref, true);
        return false;
      }

      const updatedMiniAppScreensObj = {};

      for (const screenId in quickFilterMapping) {
        if (Array.isArray(quickFilterMapping[screenId])) {
          Object.assign(updatedMiniAppScreensObj, {
            [screenId]: Object.assign(
              {},
              miniApps[activeAppId]['screens'][screenId],
              {quickFilterColumns: quickFilterMapping[screenId]},
            ),
          });
        }
      }

      const updatedMiniApp = Object.assign({}, miniApps[activeAppId], {
        screens: {
          ...miniApps[activeAppId].screens,
          ...updatedMiniAppScreensObj,
        },
      });

      dispatch({
        type: MINI_APPS_ACTION.UPDATE_QUICK_FILTER_COLUMNS,
        payload: {updatedMiniApp},
      });
      return true;
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const triggerActionBtnByMultiSelect =
  ({actionBtnId, selectedRowsInfo, message}) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        auth: {userPref, user, userCountry},
        miniApps: {
          activeAppId,
          activeScreenId,
          searchFilterData,
          miniApps,
          screenLocalConfig,
          activeCustomRoleInfo,
        },
        remoteConfig: {isOrganisationMode},
        organisation: {activeOrganisationId},
      } = state;
      if (!user?.uid || !activeScreenId || !activeAppId) {
        return null;
      }

      const {documentData, activeDocId} =
        miniAppsActionHelper.mapMiniAppStates(state);

      const {filterOptions, searchedText} = Object.assign(
        {},
        searchFilterData?.[activeScreenId],
      ); //filter or search on active screen
      const {filterArr, success} = !isEmpty(filterOptions)
        ? getFiltersArrFromSelectedOptionsObj(
            filterOptions,
            documentData.headerData,
            userPref,
            isOrganisationMode,
          )
        : {}; //extra filters

      const activeAppMeta = miniApps[activeAppId];
      const screenMeta = activeAppMeta.screens[activeScreenId];

      const filters =
        screenMeta.docs.find((doc) => doc.docId === activeDocId)
          ?.filterOptions ?? []; //screen filters
      if (success) {
        filters.push(...filterArr);
      }
      const customViewLayoutFilterArr =
        miniAppsActionHelper.getCustomViewLayoutFilterArray(
          miniApps[activeAppId],
          activeScreenId,
          screenLocalConfig,
          documentData,
        );
      filters.push(...customViewLayoutFilterArr);

      const [isCustomViewAccess, viewAccess] =
        miniAppsActionHelper.isCustomRoleFilteredScreen(
          activeAppMeta,
          user.uid,
          activeScreenId,
          activeCustomRoleInfo,
        );

      // Initialize Notification for automation
      const notificationServiceInstance = new NotificationService();
      const notificationId = `AUTORUNID_${nanoid()}_ACTION_BUTTON`;
      const setObj = {
        appId: activeAppId,
        service: SERVICE_TYPE.AUTOMATION,
        forAppId: activeAppId,
        forUID: [user.uid],
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId: notificationId,
        type: NOTIFICATION_TYPE.REQUESTED,
        isRunning: true,
        data: {
          toast: {
            body: message,
          },
          displayDocId: activeDocId,
        },
      };
      notificationServiceInstance.addOrUpdateNotification(setObj);

      const dataObj = {
        miniAppId: activeAppId,
        screenId: activeScreenId,
        searchedText,
        userLang: userPref?.lang ?? 'EN',
        timezone: getTimezone(),
        actionBtnId,
        selectedRowsInfo,
        filterConfigArr: filters,
        viewAccess: isCustomViewAccess ? viewAccess : null,
        miniAppData: activeAppMeta,
        userMetaObj: {userCountry},
        customRoleInfo: activeCustomRoleInfo,
        initAutoRunId: notificationId,
      };

      const data =
        await miniAppsActionHelper.triggerActionBtnByMultiSelectCloud(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      }
      const failedMsg = data.failedAutomations
        ? `, ${data.failedAutomations} Failed`
        : '';
      const conditionCheckFailMsg = data.conditionCheckFailCount
        ? `, ${data.conditionCheckFailCount} Disabled`
        : '';
      return `Rows : ${data.rowsProcessedCount} Processed, ${data.successAutomations} Success${failedMsg}${conditionCheckFailMsg}`;
    } catch (error) {
      captureError(error);
    }
    return null;
  };

const deleteRowv2 =
  ({selectedRowsInfo}) =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      auth: {userPref, user, userCountry},
      miniApps: {
        activeAppId,
        activeScreenId,
        searchFilterData,
        miniApps,
        screenLocalConfig,
        activeCustomRoleInfo,
      },
      organisation: {activeOrganisationId},
      remoteConfig: {isOrganisationMode},
    } = state;
    const notificationServiceInstance = new NotificationService();
    const notificationId = notificationServiceInstance.generateNotificationId(
      activeAppId,
      user.uid,
    );
    try {
      if (!user?.uid || !activeScreenId || !activeAppId) {
        return null;
      }
      const {documentData, activeDocId} =
        miniAppsActionHelper.mapMiniAppStates(state);
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.DELETE_ROWS,
        type: NOTIFICATION_TYPE.REQUESTED,
        data: {
          toast: {
            body: 'Delete Initiated',
            title: 'Delete Rows',
          },
          displayDocId: activeDocId,
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });

      const {filterOptions, searchedText} = Object.assign(
        {},
        searchFilterData?.[activeScreenId],
      ); //filter or search on active screen
      const {filterArr, success} = !isEmpty(filterOptions)
        ? getFiltersArrFromSelectedOptionsObj(
            filterOptions,
            documentData.headerData,
            userPref,
            isOrganisationMode,
          )
        : {}; //extra filters

      const activeAppMeta = miniApps[activeAppId];
      const screenMeta = activeAppMeta.screens[activeScreenId];

      const filters =
        screenMeta.docs.find((doc) => doc.docId === activeDocId)
          ?.filterOptions ?? []; //screen filters
      if (success) {
        filters.push(...filterArr);
      }
      const customViewLayoutFilterArr =
        miniAppsActionHelper.getCustomViewLayoutFilterArray(
          miniApps[activeAppId],
          activeScreenId,
          screenLocalConfig,
          documentData,
        );
      filters.push(...customViewLayoutFilterArr);

      const [isCustomViewAccess, viewAccess] =
        miniAppsActionHelper.isCustomRoleFilteredScreen(
          activeAppMeta,
          user.uid,
          activeScreenId,
          activeCustomRoleInfo,
        );

      const dataObj = {
        miniAppId: activeAppId,
        screenId: activeScreenId,
        searchedText,
        userLang: userPref?.lang ?? 'EN',
        timezone: getTimezone(),
        selectedRowsInfo,
        filterConfigArr: filters,
        viewAccess: isCustomViewAccess ? viewAccess : null,
        miniAppData: activeAppMeta,
        notificationIdToUse: notificationId,
      };

      const data = await miniAppsActionHelper.deleteRowV2CF(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      }
      return data;
    } catch (error) {
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.DELETE_ROWS,
        type: NOTIFICATION_TYPE.ERROR,
        data: {
          toast: {
            body: 'Rows cannot be deleted',
            title: 'Delete Rows',
          },
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });
      captureError(error);
    }
    return null;
  };

const exportMiniAppScreenDataAsExcel =
  ({colIdsToInclude, headerDataAsObj}) =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      auth: {userPref, user},
      miniApps: {
        activeAppId,
        activeScreenId,
        searchFilterData,
        miniApps,
        screenLocalConfig,
      },
      remoteConfig: {isOrganisationMode},
      organisation: {activeOrganisationId},
    } = state;
    const notificationServiceInstance = new NotificationService();
    const notificationId = notificationServiceInstance.generateNotificationId(
      activeAppId,
      user.uid,
    );
    const activeScreenName =
      miniApps?.[activeAppId]?.screens?.[activeScreenId]?.screenName ?? '';
    const activeDocId =
      miniApps?.[activeAppId]?.screens?.[activeScreenId]?.docs?.[0]?.docId;
    try {
      if (!user?.uid || !activeScreenId || !activeAppId) {
        return null;
      }
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.EXPORT,
        type: NOTIFICATION_TYPE.REQUESTED,
        data: {
          toast: {
            body: `Export initiated for ${activeScreenName}`,
            title: 'Export',
          },
          displayScreenId: activeScreenId,
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });

      const {filterOptions, searchedText} = Object.assign(
        {},
        searchFilterData?.[activeScreenId],
      ); //filter or search on active screen
      const {documentData} = miniAppsActionHelper.mapMiniAppStates(state);
      const {filterArr, success} = !isEmpty(filterOptions)
        ? getFiltersArrFromSelectedOptionsObj(
            filterOptions,
            documentData.headerData,
            userPref,
            isOrganisationMode,
          )
        : {};

      const filters = [];
      if (success) {
        filters.push(...filterArr);
      }
      const customViewLayoutFilterArr =
        miniAppsActionHelper.getCustomViewLayoutFilterArray(
          miniApps[activeAppId],
          activeScreenId,
          screenLocalConfig,
          documentData,
        );
      filters.push(...customViewLayoutFilterArr);

      const headerIdFieldMapping =
        miniAppsActionHelper.getHeaderFieldMappingForElasticSearch(
          documentData.headerData,
        );
      const dataObj = Object.assign(
        {},
        {
          appId: activeAppId,
          userLang: userPref?.lang ?? 'EN',
          screensArr: [
            {
              screenId: activeScreenId,
              filters: filters.length ? filters : null,
              searchedText,
              colIdsToInclude,
              headerIdFieldMapping,
            },
          ],
          timezone: getTimezone(),
          notificationIdToUse: notificationId,
        },
      );

      const data =
        await miniAppsActionHelper.exportMiniAppScreenDataAsExcelCloud(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      }
      logAnalyticsEvent('APP_DATA_EXPORT_QUEUED', {
        colCount: colIdsToInclude.length,
        exportId: data.taskId,
        selectedColumnTypes: !isEmpty(headerDataAsObj)
          ? getFrequency(
              colIdsToInclude.map((colId) =>
                Object.assign({}, headerDataAsObj[colId]),
              ),
              'fieldType',
            )
          : {},
      });
      return data.taskId;
    } catch (error) {
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.EXPORT,
        type: NOTIFICATION_TYPE.ERROR,
        data: {
          toast: {
            body: `Export failed for ${activeScreenName}`,
            title: 'Export',
          },
          displayScreenId: activeScreenId,
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });
      captureError(error);
    }
    return false;
  };

const fetchMiniAppScreenDataAsExcelExports =
  (sendBackResponse = false) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {user},
        miniApps: {activeAppId},
      } = getState();
      if (!user?.uid || !activeAppId) {
        return null;
      }
      const updatedList = [];

      const startTime = new Date();
      startTime.setDate(startTime.getDate() - 7);

      const list = await FirestoreDB.miniApps
        .userExportExcelColRef(activeAppId, user.uid)
        .where('timestamp', '>', startTime)
        .orderBy('timestamp', 'desc')
        .get();

      list.docs.forEach((doc) =>
        updatedList.push(Object.assign({}, doc.data(), {docId: doc.id})),
      );

      dispatch({
        type: MINI_APPS_ACTION.UPDATE_EXPORTS_DATA_LIST,
        payload: updatedList,
      });

      return sendBackResponse ? updatedList : true;
    } catch (error) {
      captureError(error);
    }
    return null;
  };

const importExcelForMiniApp =
  ({
    fileName,
    columnMapping,
    headerDataAsObj,
    filterConditions,
    shouldMergeUniqueRows,
    skipMandatoryCheck,
    customColumnOptions,
    originalFileName,
    source,
    jobIdToUse = '',
  }) =>
  async (dispatch, getState) => {
    const {
      auth: {userPref, user},
      miniApps: {activeAppId, activeScreenId, miniApps},
      organisation: {activeOrganisationId},
    } = getState();
    if (!user?.uid || !activeAppId || !activeScreenId) {
      return null;
    }
    const notificationServiceInstance = new NotificationService();
    const notificationId = notificationServiceInstance.generateNotificationId(
      activeAppId,
      user.uid,
    );
    const activeDocId =
      miniApps?.[activeAppId]?.screens?.[activeScreenId]?.docs?.[0]?.docId;
    try {
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.IMPORT,
        type: NOTIFICATION_TYPE.REQUESTED,
        data: {
          toast: {
            body: `Import Requested: ${originalFileName}`,
            title: 'Import',
          },
          displayDocId: activeDocId,
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });
      const dataObj = Object.assign(
        {},
        {
          fileName,
          originalFileName,
          columnMapping,
          appId: activeAppId,
          screenId: activeScreenId,
          filterConditions,
          docId:
            miniApps[activeAppId]?.screens?.[activeScreenId]?.docs?.[0]?.docId,
          timezone: getTimezone(),
          userLang: userPref?.lang ?? 'EN',
          shouldMergeUniqueRows:
            typeof shouldMergeUniqueRows === 'boolean'
              ? shouldMergeUniqueRows === true
              : shouldMergeUniqueRows,
          skipMandatoryCheck: skipMandatoryCheck === true,
          customColumnOptions,
          jobIdToUse,
          source,
          notificationIdToUse: notificationId,
        },
      );
      const data =
        await miniAppsActionHelper.importExcelForMiniAppCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      }
      const colIds = Object.values(columnMapping);
      logAnalyticsEvent('APP_DATA_IMPORT_QUEUED', {
        colCount: colIds.length,
        importId: data.taskId,
        selectedColumnTypes: !isEmpty(headerDataAsObj)
          ? getFrequency(
              colIds.map((colId) => Object.assign({}, headerDataAsObj[colId])),
              'fieldType',
            )
          : {},
      });

      return true;
    } catch (error) {
      await notificationServiceInstance.addOrUpdateNotification({
        appId: activeAppId,
        service: SERVICE_TYPE.IMPORT,
        type: NOTIFICATION_TYPE.ERROR,
        data: {
          toast: {
            body: `Import failed for ${originalFileName}`,
            title: 'Import',
          },
          displayDocId: activeDocId,
        },
        forAppId: activeAppId,
        forUID: [user.uid],
        isRunning: true,
        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId,
      });
      captureError(error);
    }
    return false;
  };

const createTemplatesForMiniAppImports =
  ({
    templateName,
    templateMapping,
    filterConditions,
    isDelete = false,
    isEdit = false,
    templateId,
    shouldMergeUniqueRows = false,
    skipMandatoryCheck = false,
    templateArr = [],
    uniqueFileOnlyBool = false,
    fileNameToSend = '',
    source,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeScreenId},
      } = getState();
      if (!user?.uid || !activeAppId || !activeScreenId) {
        return null;
      }

      const dataObj = Object.assign(
        {},
        isEdit
          ? {
              isEdit: true,
              templateName,
              shouldMergeUniqueRows,
              skipMandatoryCheck,
              uniqueFileOnlyBool,
              filterConditions,
              appId: activeAppId,
              templateId,
            }
          : {
              userLang: userPref?.lang ?? 'EN',
              timezone: getTimezone(),
              appId: activeAppId,
              screenId: activeScreenId,
              templateName,
              templateMapping,
              filterConditions,
              templateId,
              isDelete,
              shouldMergeUniqueRows,
              skipMandatoryCheck,
              uniqueFileOnlyBool,
              fileNameToSend,
              source,
            },
      );

      const data =
        await miniAppsActionHelper.createImportTemplateCloudFunction(dataObj);
      if (data?.success) {
        ShowToast(data?.message, userPref);
        if (isDelete) {
          const updatedTemplates = templateArr.slice();
          const templateIndex = updatedTemplates.findIndex(
            (templateObj) => templateObj?.templateId === templateId,
          );
          if (templateIndex > -1) {
            updatedTemplates.splice(templateIndex, 1);
            return {updatedTemplates, success: true};
          }
        }
        return {success: true};
      }

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return {success: false};
      }
    } catch (error) {
      captureError(error);
    }
  };

const createTemplatesForMiniAppExports =
  ({templateName, templateMapping}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {activeAppId, activeScreenId},
      } = getState();
      if (!user?.uid || !activeAppId || !activeScreenId) {
        return null;
      }

      const dataObj = Object.assign(
        {},
        {
          userLang: userPref?.lang ?? 'EN',
          timezone: getTimezone(),
          appId: activeAppId,
          screenId: activeScreenId,
          templateName,
          templateMapping,
        },
      );

      const data =
        await miniAppsActionHelper.createExportTemplateCloudFunction(dataObj);
      if (data?.success) {
        ShowToast(data?.message, userPref);
        return {success: true};
      }

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return {success: false};
      }
    } catch (error) {
      captureError(error);
      return {success: false};
    }
  };

const fetchImportTemplatesForMiniAppImports =
  () => async (dispatch, getState) => {
    try {
      const {
        auth: {user},
        miniApps: {activeAppId, activeScreenId},
      } = getState();

      if (!user?.uid || !activeAppId || !activeScreenId) {
        return null;
      }

      const importTemplates = await FirestoreDB.miniApps
        .importTemplatesDocRef(activeAppId, activeScreenId)
        .orderBy('createdTimeStamp', 'desc')
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data()))
        .catch(console.error);
      dispatch({
        type: MINI_APPS_ACTION.SET_ALL_TEMPLATE_DATA,
        payload: importTemplates,
      });
      return importTemplates || [];
    } catch (error) {
      captureError(error);
    }
  };

const fetchExportTemplatesForMiniApp = () => async (dispatch, getState) => {
  try {
    const {
      auth: {user},
      miniApps: {activeAppId, activeScreenId},
    } = getState();

    if (!user?.uid || !activeAppId || !activeScreenId) {
      return null;
    }

    const exportTemplates = await FirestoreDB.miniApps
      .exportTemplatesDocRef(activeAppId, activeScreenId)
      .orderBy('createdTimeStamp', 'desc')
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => doc.data()))
      .catch(console.error);
    dispatch({
      type: MINI_APPS_ACTION.SET_EXPORT_TEMPLATE_DATA,
      payload: exportTemplates,
    });
    return exportTemplates || [];
  } catch (error) {
    captureError(error);
  }
};

const generateDashboardReport = (screenId) => async (_, getState) => {
  try {
    const {
      auth: {userPref, user},
      miniApps: {activeAppId, miniApps},
      elasticDashboards: {allDashboards},
    } = getState();

    if (!user?.uid) {
      return null;
    }

    const screenMeta = miniApps[activeAppId].screens[screenId];
    const reportName = `${screenMeta.screenName} Report`;

    const screenDashboards = allDashboards?.[screenId];
    const dashboards = Object.assign({}, screenDashboards?.dashboards);

    const reportConfigs = {};

    forOwn(dashboards, (dashboardData, dashboardId) => {
      const {type, subType} = dashboardData;
      const isAllowedType =
        subType ===
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE]
            .N_VALUES_WITHOUT_SPLIT ||
        (type === DASHBOARD_TYPES_V3.SPLIT_BY_VALUE &&
          subType ===
            DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].N_VALUES) ||
        (type === DASHBOARD_TYPES_V3.MULTI_SPLIT &&
          subType ===
            DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].N_VALUES) ||
        (type === DASHBOARD_TYPES_V3.CASH_IN_AND_OUT &&
          subType ===
            DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.CASH_IN_AND_OUT].WITH_PARTY);
      if (isAllowedType) {
        reportConfigs[dashboardId] = dashboardData;
      }
    });

    if (isEmpty(reportConfigs)) {
      alert(
        "You don't have any dashboards to export. Only N-column dashboards can be exported.",
        userPref,
      );
      return null;
    }

    const reportMeta = {
      configs: reportConfigs,
      miniAppId: activeAppId,
      title: reportName,
      timeFilter: screenDashboards.timeFilter,
    };

    const dataObj = Object.assign(
      {},
      {
        reportId: 'dashboard-export',
        miniAppId: activeAppId,
        miniAppData: miniApps[activeAppId],
        reportMeta,
      },
    );

    const data = await miniAppsActionHelper.miniAppsGenerateReport(dataObj);

    if (!data || !data.success) {
      handleCloudErrorMsgAndLogging(data, dataObj, userPref);
      return null;
    }

    //success
    return {
      downloadURL: data.downloadURL,
      reportName: `${reportName}.xlsx`,
    };
  } catch (error) {
    ShowToast('Something went wrong, please try again');
    captureError(error);
  }
  return null;
};

const deleteImportedRows =
  ({importId}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
        miniApps: {
          activeAppId,
          activeScreenId,
          excelImportsList: currentExcelImportsList,
        },
        organisation: {membersList, profileData},
      } = getState();
      if (!user?.uid || !activeAppId || !importId) {
        return null;
      }

      const importDocMeta = currentExcelImportsList.find(
        (item) => item.docId === importId,
      );

      const minSecondsForDelete = 1691605800; //10th August 2023
      if (
        !isEmpty(importDocMeta) &&
        importDocMeta.startTS?.seconds &&
        importDocMeta.startTS?.seconds < minSecondsForDelete
      ) {
        ShowToast('This import cannot be deleted.', userPref);
        return false;
      }

      const dataObj = Object.assign(
        {},
        {
          importId,
          miniAppId: activeAppId,
          screenId: activeScreenId,
          timezone: getTimezone(),
          userLang: userPref?.lang ?? 'EN',
        },
      );

      const data =
        await miniAppsActionHelper.deleteImportedRowsForMiniAppCloudFunction(
          dataObj,
        );

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      }
      if (data.message) {
        ShowToast(data.message, userPref);
      }
      const {
        miniApps: {excelImportsList},
      } = getState();
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_IMPORTS_DATA_LIST,
        payload: excelImportsList.map((item) => {
          if (item.docId === importId) {
            return miniAppsActionHelper.processImportExcelDocFirestore(
              {id: importId, data: () => Object.assign({}, data.updatedData)},
              user,
              profileData,
              membersList,
            );
          }
          return item;
        }),
      });
      return true;
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const fetchNotifications = (params) => async (dispatch, getState) => {
  const {prev, next} = params ?? {};
  try {
    const {
      auth: {user},
      pushNotifications: {
        lastVisibleTimestamp,
        allFetched: rAllFetched,
        notifications,
        localFetched,
      },
    } = getState();
    const allOldNotificationsFetched = rAllFetched && prev;

    if (!user?.uid?.length || allOldNotificationsFetched) {
      return;
    }

    const {fetchFromLastFetchedNotifications, fetchPushNotifications} =
      FirestoreDB.automations;

    let dataSnap =
      next && localFetched
        ? await fetchFromLastFetchedNotifications(user.uid, localFetched, 1)
        : await fetchPushNotifications(user.uid, lastVisibleTimestamp);

    if (!dataSnap.docs?.length) {
      return;
    }

    const firstDoc = dataSnap.docs[0];
    const firstDocData = firstDoc.data();
    const {createdAt} = firstDocData;

    let isNewNotEqual = false;
    if (next && localFetched && isEqual(localFetched, createdAt)) {
      return;
    }

    if (next && localFetched) {
      isNewNotEqual = true;
      dataSnap = await fetchFromLastFetchedNotifications(
        user.uid,
        localFetched,
      );
    }

    const lastDoc = dataSnap.docs[dataSnap.docs.length - 1];
    const lastDocData = lastDoc.data();
    const {createdAt: lastDocCreatedAt} = lastDocData;
    const allFetched = prev && dataSnap.docs.length < 10;
    const fetchedNotifications = dataSnap.docs.map((doc) => ({
      ...doc.data(),
      notificationId: doc.id,
    }));

    const arr =
      next || !notifications?.sections?.length
        ? new PushNotificationArray([
            ...fetchedNotifications,
            ...(flatMap(map(notifications?.sections, 'data')) ?? []),
          ]) // TODO : Optimise this
        : notifications?.addForward?.(fetchedNotifications);

    const payload = {
      notifications: arr,
      lastVisibleTimestamp: prev ? lastDocCreatedAt : null,
      allFetched: allFetched,
      localFetched:
        localFetched && next && isNewNotEqual
          ? createdAt
          : !localFetched
            ? createdAt
            : null,
    };

    dispatch({
      type: PUSH_NOTIFICATIONS_ACTION.UPDATE_NOTIFICATIONS_LIST,
      payload: payload,
    });
  } catch (error) {
    captureError(error);
  }
};

/**
 *
 * @param {string} miniAppId
 * @param {string} screenId
 * @param {string} docId
 * @param {string} rowId
 * @param {string} automationType
 * @param {object} navigation
 * @param {import('@react-native-firebase/firestore')} firestoreL
 * @returns
 */
const openMiniAppRow =
  (
    miniAppId,
    screenId,
    docId,
    rowId,
    automationType,
    navigation,
    firestoreL,
    dbInstance,
    extra = {
      fromLinkTap: false,
      navigationCallback: () => {},
      filterRouteObj: null,
      searchText: '',
    },
  ) =>
  async (dispatch, getState) => {
    const isDeleteEntry =
      automationType === MESSAGE_AUTOMATION.ROW_DELETE_ENTRY;
    if (isDeleteEntry) {
      return;
    }
    const {
      auth: {userPref, user},
      miniApps,
    } = getState();

    const {
      activeAppId,
      activeScreenId,
      documentData: miniAppDocumentData,
    } = miniAppsActionHelper.mapMiniAppStates(getState());

    if (!miniApps?.miniApps?.[miniAppId] && extra?.fromLinkTap) {
      ShowToast('Permission Denied');
      return;
    }

    const miniAppStatesForGivenScreenId = miniAppsActionHelper.mapMiniAppStates(
      getState(),
      false,
      false,
      screenId,
      miniAppId,
    );

    const activeCustomRoleInfoForGivenAppId =
      miniAppStatesForGivenScreenId.activeAppMeta?.sharedWith?.[user?.uid]
        ?.permission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE
        ? await FirestoreDB.miniApps
            .customRoleDoc(
              miniAppId,
              miniAppStatesForGivenScreenId.activeAppMeta?.sharedWith?.[
                user?.uid
              ]?.customRoleId,
            )
            .get()
            .then((res) => {
              return res.exists ? res.data() : {};
            })
            .catch(() => ({}))
        : {};

    const screens = miniAppsActionHelper.getSortedMiniAppsScreens(
      miniAppStatesForGivenScreenId.activeAppMeta,
      user?.uid,
      activeCustomRoleInfoForGivenAppId,
    );

    const isScreenAvailable = screens.find(
      (screenData) => screenData.screenId === screenId,
    );

    if (!isScreenAvailable) {
      ShowToast('Permission Denied');
      return;
    }

    if (
      miniApps?.miniApps?.[miniAppId] &&
      miniApps?.miniApps?.[miniAppId]?.screens?.[screenId]
    ) {
      docId =
        miniApps?.miniApps?.[miniAppId]?.screens?.[screenId]?.docs?.[0]?.docId;
    }
    const isAppAlreadyOpened = activeAppId === miniAppId;
    const isSameScreen = activeScreenId === screenId;
    const isDifferentAppOpened =
      activeAppId?.length && activeAppId !== miniAppId;
    dispatch(
      updateGlobalLoader(true, {
        text: 'Fetching row details...',
        backgroundWhite: true,
      }),
    );
    const openScreenOnly = extra?.fromLinkTap && !rowId;

    let rowData = {};

    const isRowAvailable = openScreenOnly
      ? false
      : (await miniAppsActionHelper
          .fetchRowsFromElastic([rowId], miniAppId, screenId)
          .then((response) => {
            const hasRow = response?.tableData?.length > 0;
            const rowObj = hasRow
              ? Object.values(response.rowIdDataMap)[0]
              : {};
            if (!isEmpty(rowObj)) {
              rowData = rowObj;
            }
            return response.success && response.tableData.length > 0;
          })
          .catch(() => false)) && !isEmpty(rowData);

    // if (!openScreenOnly && !rowData.exists) {
    //   return dispatch(updateGlobalLoader(false));
    // }

    const {rowProperties, isDeleted, index} = openScreenOnly ? {} : rowData;

    if (isDeleted && !isDeleteEntry) {
      setTimeout(() => {
        ShowToast('Row has been deleted.', userPref);
        dispatch(updateGlobalLoader(false));
      }, 750);

      return;
    }

    dispatch(
      updateGlobalLoader(true, {
        text: 'Loading data...',
        backgroundWhite: true,
      }),
    );

    // 1. Open the app
    const extras = {
      screenId: screenId,
      docId,
      callback: async (documentData) => {
        // 3. Open the row
        const {notificationTap} = extra ?? {};

        if (!ENV) {
          dispatch(updateGlobalLoader(false));
          return;
        }

        // For Mobile
        // eslint-disable-next-line local-rules/rb-redux-requires
        const appCallbackData = {
          documentData,
          rowObj: rowData,
          rowProperties,
          rowId,
          screenId,
          index,
          notificationTap,
          navigation,
          isAppAlreadyOpened,
          isDifferentAppOpened,
          dispatch,
          openScreenOnly,
          isRowAvailable,
          filterRouteObj: extra.filterRouteObj,
          searchText: extra.searchText,
        };

        return extra?.navigationCallback?.(appCallbackData);
      },
    };

    if (isDifferentAppOpened) {
      dispatch(resetAutomationState());
      dispatch(closeApp()); // TODO : Don't close app if already open
    }

    return new Promise((resolve) => {
      setTimeout(async () => {
        if (isAppAlreadyOpened) {
          !isSameScreen && dispatch(changeScreen(screenId));
          setTimeout(() => {
            extras.callback(miniAppStatesForGivenScreenId.documentData);
          }, 300);
        } else {
          await dispatch(
            openApp(
              miniAppId,
              undefined,
              firestoreL,
              dbInstance,
              true,
              true,
              extras,
            ),
          );
        }
        resolve();
      }, 300);
    });
  };

const fetchMiniAppScreenImportsProgress =
  (sendBackResponse = false) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {user},
        miniApps: {activeAppId},
        organisation: {membersList, profileData},
      } = getState();
      if (!user?.uid || !activeAppId) {
        return null;
      }
      const updatedList = [];
      const startTime = new Date();
      startTime.setDate(startTime.getDate() - 15); //15 days
      const list = await FirestoreDB.miniApps
        .userImportExcelColRef(activeAppId)
        .where('startTS', '>', startTime)
        .orderBy('startTS', 'desc')
        .get();
      list.docs.forEach((doc) =>
        updatedList.push(
          miniAppsActionHelper.processImportExcelDocFirestore(
            doc,
            user,
            profileData,
            membersList,
          ),
        ),
      );
      dispatch({
        type: MINI_APPS_ACTION.UPDATE_IMPORTS_DATA_LIST,
        payload: updatedList,
      });
      return sendBackResponse ? updatedList : true;
    } catch (error) {
      captureError(error);
    }
    return null;
  };

const updateCheckedValues = (checkedValues) => {
  return {
    type: 'UPDATE_CHECKED_VALUES',
    payload: checkedValues,
  };
};

const updateIsSelectRecords = (val) => {
  return {
    type: 'UPDATE_IS_SELECT_RECORDS',
    payload: val,
  };
};

const updateMiniAppScreenTimeFilter =
  (timeFilter = {}) =>
  async (_, getState) => {
    try {
      if (!isEmpty(timeFilter)) {
        const {
          miniApps: {activeAppId, activeScreenId},
        } = getState();
        const updateobj = {
          [`screens.${activeScreenId}.timeFilter`]: timeFilter,
        };
        FirestoreDB.miniApps.app(activeAppId).update(updateobj);
      }
    } catch (err) {
      captureError(err);
    }
  };

const updateScreenLocalConfig = (screenId, updatedData) => (dispatch) =>
  screenId &&
  dispatch({
    type: MINI_APPS_ACTION.UPDATE_SCREEN_LOCAL_CONFIG,
    payload: {screenId, updatedData},
  });

const generateGmailAuthURLWithLoader = () => async (dispatch, getState) => {
  try {
    dispatch(updateGlobalLoader(true, {text: 'Loading...'}));
    const state = getState();

    const response = await miniAppsActionHelper.generateGmailAuthURL({
      uid: state.auth.user?.uid,
      miniAppId: miniAppsActionHelper.mapMiniAppStates(state).activeAppId,
      organisationId: state.organisation.activeOrganisationId,
      isMobile: ENV,
    });

    if (!response?.success) {
      dispatch(updateGlobalLoader(false));
      return null;
    }

    const url = response.data;

    dispatch(updateGlobalLoader(false));
    return url;
  } catch (error) {
    captureError(error);
    dispatch(updateGlobalLoader(false));
    return null;
  }
};

const deleteIntegrationForMiniAppAction =
  (id, hideLoader = false) =>
  async (dispatch, getState) => {
    try {
      if (!hideLoader) {
        dispatch(updateGlobalLoader(true, {text: 'Loading...'}));
      }

      const state = getState();

      await miniAppsActionHelper.deleteIntegrationForMiniApp({
        uid: state.auth.user?.uid,
        miniAppId: miniAppsActionHelper.mapMiniAppStates(state).activeAppId,
        organisationId: state.organisation.activeOrganisationId,
        isMobile: ENV,
        integrationId: id,
      });
      if (!hideLoader) {
        dispatch(updateGlobalLoader(false));
      }
    } catch (error) {
      captureError(error);
      dispatch(updateGlobalLoader(false));
    }
  };

const loadMiniAppEmailIntegrations = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      miniApps: {activeAppId, miniApps},
      automation: {miniAppIntegrationEmails},
    } = state;
    const appData = miniApps?.[activeAppId] || {};
    const emails = await miniAppsActionHelper
      .getMiniAppEmailsObjects(appData, miniAppIntegrationEmails)
      .catch(() => []);

    if (isEqual(emails, miniAppIntegrationEmails)) {
      return;
    }

    dispatch({
      type: AUTOMATION_ACTION.MINI_APP_SET_EMAILS_FOR_INTEGRATION,
      payload: emails,
    });
  } catch (error) {
    captureError(error);
  }
};

const addEditMiniAppScreenForKanban =
  ({
    parentScreenId,
    mainScreenObj,
    headerDataAsObj = {},
    selectBoxColId = '',
    isDelete = false,
  }) =>
  async (dispatch) => {
    try {
      const selectBoxValues = headerDataAsObj?.[selectBoxColId]?.[
        `selectElements`
      ]?.map((selectElementsObj) => selectElementsObj?.val);
      const selectBoxValuesPlusNoValueFilter = [
        ...(selectBoxValues ?? []),
        DATA_FILTERS.EMPTY_CELLS_ROWS,
      ];

      const {
        docId,
        mapping,
        mappedValuesConfig,
        filterOptions: mainScreenFilterOptions,
      } = mainScreenObj?.[`docs`]?.[0] ?? {};

      const mappingObjToSend = {};
      for (const key in mapping) {
        mappingObjToSend[key] = headerDataAsObj?.[mapping[key]];
      }

      if (isDelete) {
        // Handle delete operation
        try {
          // const deleteScreenPromiseArr = [];
          const deleteScreenObjArr = [];
          mainScreenObj?.view?.[
            MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
          ]?.kanbanScreenIds?.forEach((screenId) =>
            deleteScreenObjArr.push({
              isDelete: true,
              screenId: screenId,
              doNotShowToast: true,
            }),
          );
          const res = await dispatch(
            addEditMultipleScreens(deleteScreenObjArr),
          );
          if (res.success) {
            return {success: true}; // Return success for delete operation
          }
          return {success: false}; // Return failure for delete operation
        } catch (error) {
          console.error('Error in deleting kanban screens:', error);
          return {success: false}; // Return failure for delete operation
        }
      } else {
        // Check if Kanban view is already configured
        const isKanbanViewAlreadyConfigured =
          Array.isArray(
            mainScreenObj?.view?.[
              MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
            ]?.kanbanScreenIds,
          ) &&
          mainScreenObj?.view?.[
            MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
          ]?.kanbanScreenIds.length > 0;

        if (isKanbanViewAlreadyConfigured) {
          // Delete existing Kanban screens
          const deleteScreenObjArr = [];
          const addScreenObjArr = [];
          try {
            mainScreenObj?.view?.[
              MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
            ]?.kanbanScreenIds?.forEach((screenId) =>
              deleteScreenObjArr.push({
                isDelete: true,
                screenId: screenId,
              }),
            );

            selectBoxValuesPlusNoValueFilter.forEach((item) => {
              const payload = {
                screenName: `${selectBoxColId}_${item}`,
                documentId: docId,
                iconName: mainScreenObj.screenIcon,
                layoutId: mainScreenObj.layoutId,
                mapping: mappingObjToSend,
                mappedValuesConfig,
                type: MINI_APPS.SCREEN_TYPE.FILTERED_DATA,
                filterOptions: [
                  ...(mainScreenFilterOptions ?? []), // to include main screen's filter options
                  {
                    [`colId`]: selectBoxColId,
                    [`selectedOptions`]: [item],
                    [`fieldType`]: FIELD_TYPE_ID.SELECT_POP_UP,
                  },
                ],
                kanbanMeta: {
                  parentScreenId,
                  colId: selectBoxColId,
                  selectBoxValue: item,
                },
                doNotShowToast: true,
                doNotChangeScreen: true,
              };
              addScreenObjArr.push(payload);
            });

            const res = await dispatch(
              addEditMultipleScreens([
                ...deleteScreenObjArr,
                ...addScreenObjArr,
              ]),
            );

            if (
              res?.length !==
              [...deleteScreenObjArr, ...addScreenObjArr]?.length
            ) {
              const parentScreenUpdatePayload = {
                screenName: mainScreenObj.screenName,
                documentId: docId,
                iconName: mainScreenObj.screenIcon,
                layoutId: mainScreenObj.layoutId,
                mapping: mappingObjToSend,
                mappedValuesConfig,
                type: mainScreenObj.type,
                isEdit: true,
                filterOptions: mainScreenFilterOptions,
                screenId: parentScreenId,
                hiddenColIds: mainScreenObj.hiddenColIds,
                addItemText: mainScreenObj.addItemText,
                entryColIds: mainScreenObj.entryColIds,
                entryHideColIds: mainScreenObj.entryHideColIds,
                disableEdit: mainScreenObj.disableEdit,
                disableEntry: mainScreenObj.disableEntry,
                isModifyScreenViewLayout: true,
                view: Object.assign(
                  {},
                  omit(mainScreenObj?.view, [
                    MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN,
                  ]),
                ),
                customLayoutType:
                  MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN,
                doNotShowToast: true,
              };

              await dispatch(addEditScreenMiniApp(parentScreenUpdatePayload));
              console.log('Unable to reconfigure Kanban view');
              return {success: false}; // Return failure for reconfiguring kanban view
            }

            const deleteScreensLength = deleteScreenObjArr.length;
            const addScreensLength = addScreenObjArr.length;

            const modifiedScreenIdsForAddOp = res.slice(
              deleteScreensLength,
              deleteScreensLength + addScreensLength,
            );

            const parentScreenPayload = {
              screenName: mainScreenObj.screenName,
              documentId: docId,
              iconName: mainScreenObj.screenIcon,
              layoutId: mainScreenObj.layoutId,
              mapping: mappingObjToSend,
              mappedValuesConfig,
              type: mainScreenObj.type,
              isEdit: true,
              filterOptions: mainScreenFilterOptions,
              screenId: parentScreenId,
              hiddenColIds: mainScreenObj.hiddenColIds,
              addItemText: mainScreenObj.addItemText,
              entryColIds: mainScreenObj.entryColIds,
              entryHideColIds: mainScreenObj.entryHideColIds,
              disableEdit: mainScreenObj.disableEdit,
              disableEntry: mainScreenObj.disableEntry,
              isModifyScreenViewLayout: true,
              view: Object.assign({}, mainScreenObj?.view, {
                [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN]: {
                  kanbanScreenIds: modifiedScreenIdsForAddOp,
                  kanbanSelectBoxColId: selectBoxColId,
                },
              }),
              customLayoutType:
                MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN,
            };

            await dispatch(addEditScreenMiniApp(parentScreenPayload));
            return {success: true}; // Return success if everything is done
          } catch (error) {
            console.error('Error in reconfiguring kanban view:', error);
            return {success: false}; // Return failure for reconfiguring kanban view
          }
        } else {
          try {
            // Create new Kanban screens
            const screenObjArr = [];
            selectBoxValuesPlusNoValueFilter.forEach((item) => {
              const payload = {
                screenName: `${selectBoxColId}_${item}`,
                documentId: docId,
                iconName: mainScreenObj.screenIcon,
                layoutId: mainScreenObj.layoutId,
                mapping: mappingObjToSend,
                mappedValuesConfig,
                type: MINI_APPS.SCREEN_TYPE.FILTERED_DATA,
                filterOptions: [
                  ...(mainScreenFilterOptions ?? []), // to include main screen's filter options
                  {
                    [`colId`]: selectBoxColId,
                    [`selectedOptions`]: [item],
                    [`fieldType`]: FIELD_TYPE_ID.SELECT_POP_UP,
                  },
                ],
                kanbanMeta: {
                  parentScreenId,
                  colId: selectBoxColId,
                  selectBoxValue: item,
                },
                doNotShowToast: true,
                doNotChangeScreen: true,
              };
              screenObjArr.push(payload);
            });

            const res = await dispatch(addEditMultipleScreens(screenObjArr));
            const successScreenIds = res;

            if (
              selectBoxValuesPlusNoValueFilter.length !==
              successScreenIds.length
            ) {
              // Handle case where not all screens were created successfully
              const deleteScreenObjArr = [];
              successScreenIds.forEach((item) => {
                deleteScreenObjArr.push({
                  isDelete: true,
                  screenId: item,
                  doNotShowToast: true,
                });
              });
              await dispatch(addEditMultipleScreens(deleteScreenObjArr));
              ShowToast('Unable to load Kanban view, Please try again later.');
              return {success: false}; // Return failure if not all screens were created
            }

            // Update parent screen with new Kanban view
            const parentScreenPayload = {
              screenName: mainScreenObj.screenName,
              documentId: docId,
              iconName: mainScreenObj.screenIcon,
              layoutId: mainScreenObj.layoutId,
              mapping: mappingObjToSend,
              mappedValuesConfig,
              type: mainScreenObj.type,
              isEdit: true,
              filterOptions: mainScreenFilterOptions,
              screenId: parentScreenId,
              hiddenColIds: mainScreenObj.hiddenColIds,
              addItemText: mainScreenObj.addItemText,
              entryColIds: mainScreenObj.entryColIds,
              entryHideColIds: mainScreenObj.entryHideColIds,
              disableEdit: mainScreenObj.disableEdit,
              disableEntry: mainScreenObj.disableEntry,
              isModifyScreenViewLayout: true,
              view: Object.assign({}, mainScreenObj?.view, {
                [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN]: {
                  kanbanScreenIds: successScreenIds,
                  kanbanSelectBoxColId: selectBoxColId,
                },
              }),
              customLayoutType:
                MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN,
            };

            await dispatch(addEditScreenMiniApp(parentScreenPayload));
            return {success: true}; // Return success if everything is done
          } catch (error) {
            console.error('Error in creating kanban screens:', error);
            return {success: false}; // Return failure in case of an error
          }
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      return {success: false}; // Return failure in case of an unexpected error
    }
  };

const handleRowReorderInKanbanView =
  ({
    sourceScreenId,
    destinationScreenId,
    indexUpperBound,
    indexLowerBound,
    rowObjArr,
  }) =>
  async (dispatch, getState) => {
    const rowObjArrToUse = rowObjArr.filter((rowObj) => !isNil(rowObj?.rowId));
    // ^removed any rows with missing rowId
    // to ensure only row edit opeations
    if (!Array.isArray(rowObjArrToUse)) {
      return;
    }
    if (rowObjArrToUse?.length === 0) {
      return;
    }
    let noIndexUpdateRequired = false;
    //TODO : INCLUDE ALL ERROR HANDLING HERE
    if (isNil(indexLowerBound) && isNil(indexUpperBound)) {
      if (sourceScreenId === destinationScreenId) {
        ShowToast('Invalid reordering attempt');
        return;
      }
      noIndexUpdateRequired = true;
    }
    if (isNil(indexLowerBound) && !noIndexUpdateRequired) {
      indexLowerBound = indexUpperBound - rowObjArrToUse?.length - 1;
    }
    if (isNil(indexUpperBound) && !noIndexUpdateRequired) {
      indexUpperBound = indexLowerBound + rowObjArrToUse?.length + 1;
    }
    // reverse rowObjArrToUse so that rows can be
    // assigned new index value in same order
    rowObjArrToUse.reverse();

    // all required information from state
    const {
      miniApps: {
        activeAppId,
        activeScreenId,
        miniApps,
        docsData,
        activeCustomRoleInfo,
        searchFilterData,
      },
      auth: {
        user: {uid},
      },
    } = getState();

    const activeAppMeta = miniApps[activeAppId];
    const screenMeta = activeAppMeta.screens[activeScreenId];

    const docId = screenMeta?.[`docs`]?.[0]?.docId;
    const docData = docsData?.[docId];
    const {headerDataAsObj} = docData;
    const isSearchFilterActive = !isNil(searchFilterData?.[activeScreenId]);

    // index helper class to assign index based on position of drop
    const IndexHelper =
      !noIndexUpdateRequired &&
      new GetIncrementalIndexes(
        indexLowerBound,
        indexUpperBound,
        rowObjArrToUse?.length,
      );

    // handles case when not enough index values can be generated for
    // the given new position of dropped row(s);
    if (!noIndexUpdateRequired && !IndexHelper?.areIndexesPossible()) {
      return ShowToast('Row reorder failed at given position');
    }

    if (sourceScreenId === destinationScreenId) {
      // handles case for reorder in the same kanban value
      const updatedTableData = [];
      const updatedIndexArray = [];
      const updatedRowIdsArray = [];

      rowObjArrToUse.forEach((rowObj) => {
        const updatedIndex = IndexHelper?.getNextIndex();
        updatedTableData.push(
          Object.assign(
            {},
            {
              exists: true,
              id: rowObj.rowId,
              data: Object.assign(
                {},
                pick(rowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS),
                {rowObj: omit(rowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS)},
                {index: updatedIndex},
              ),
            },
          ),
        );
        updatedIndexArray.push(updatedIndex);
        updatedRowIdsArray.push(rowObj.rowId);
      });

      const sourceProcessedData = processRowsDataForMappedRowId(
        {docs: updatedTableData},
        null,
        isSearchFilterActive
          ? searchFilterData?.[sourceScreenId]?.tableData
          : docData?.filterData?.[sourceScreenId]?.tableData,
        null,
        docData.rowIdDataMap,
        docData.fileObj,
        {useDocDataAsObject: true},
      );

      if (isSearchFilterActive) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
          payload: {
            screenId: sourceScreenId,
            data: {
              tableData: sourceProcessedData.tableData,
            },
          },
        });
      } else {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
          payload: {
            docId: docId,
            screenId: sourceScreenId,
            data: {tableData: sourceProcessedData.tableData},
          },
        });
      }

      if (sourceProcessedData?.sortingKeyChangedRows?.length) {
        dispatch(
          reorderDocDataForSortKeyChange({
            docId,
            sortingKeyChangedRows: sourceProcessedData.sortingKeyChangedRows,
            omitFor: {
              screenType: isSearchFilterActive
                ? ['screen-search-filter']
                : [MINI_APPS.SCREEN_TYPE.FILTERED_DATA],
              screenId: [sourceScreenId],
            },
          }),
        );
      }
      if (!isEmpty(sourceProcessedData?.rowIdDataMap)) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
          payload: {
            docId,
            data: {rowIdDataMap: sourceProcessedData.rowIdDataMap},
          },
        });
      }
      // ****** EDIT ENTRY RELATED STARTS ******
      const editRowsPromiseArr = [];
      rowObjArrToUse.forEach((rowObj, index) => {
        const [headerDataWithRestrictions] =
          miniAppsActionHelper.getHeaderDataForMiniAppRowEdit(
            docData.headerData,
            activeAppMeta,
            uid,
            activeCustomRoleInfo,
            activeScreenId,
            rowObj,
            true,
            false,
          );
        const visibleColIds = headerDataWithRestrictions.map(
          (colObj) => colObj.id,
        );
        const isRecurringRow = () => {
          return (
            rowObj?.rowProperties &&
            !isEmpty(rowObj?.rowProperties?.recurringConfig)
          );
        };
        editRowsPromiseArr.push(
          dispatch(
            addEditMiniAppsDataFromEntryOnlyV2(
              {
                rowData: rowObj,
                prevRowData: rowObj,
                isRowEdit: true,
                docId: docId,
                screenId: activeScreenId,
                visibleColIds: visibleColIds,
                extra: {
                  commentsToAdd: [],
                  recurringEvent: isRecurringRow()
                    ? MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_ONLY
                    : null,
                  isUpdateChildRows: true,
                  updatedRowIndex: updatedIndexArray[index],
                },
              },
              {isBackgroundOperation: false, isKanbanEditRowOp: true},
            ),
          ),
        );
      });
      Promise.all(editRowsPromiseArr);
      // ****** EDIT ENTRY RELATED ENDS ******
    } else {
      const updatedSourceTableData = [];
      const updatedDestinationTableData = [];
      const updatedIndexArray = [];

      const destinationScreenMeta = activeAppMeta.screens[destinationScreenId];
      const selectBoxColId = destinationScreenMeta?.[`kanbanMeta`]?.[`colId`];
      const destinationSelectBoxValueObj = headerDataAsObj?.[selectBoxColId]?.[
        `selectElements`
      ]?.find(
        (selectBoxValObj) =>
          selectBoxValObj.val ===
          destinationScreenMeta?.[`kanbanMeta`]?.[`selectBoxValue`],
      );

      rowObjArrToUse.forEach((rowObj) => {
        const updatedIndex = noIndexUpdateRequired
          ? ''
          : IndexHelper?.getNextIndex();
        updatedSourceTableData.push(
          Object.assign(
            {},
            {
              exists: true,
              id: rowObj.rowId,
              data: Object.assign(
                {},
                {
                  index: rowObj.index,
                  isDeleted: true,
                  rowProperties: rowObj?.rowProperties,
                  rowObj: omit(rowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS),
                },
              ),
            },
          ),
        );
        updatedDestinationTableData.push(
          Object.assign(
            {},
            {
              exists: true,
              id: rowObj.rowId,
              data: Object.assign(
                {},
                {
                  index: noIndexUpdateRequired ? rowObj.index : updatedIndex,
                  isDeleted: false,
                  rowProperties: rowObj?.rowProperties,
                  rowObj: omit(rowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS),
                },
              ),
            },
          ),
        );
        updatedIndexArray.push(updatedIndex);
      });

      const sourceProcessedData = processRowsDataForMappedRowId(
        {docs: updatedSourceTableData},
        null,
        isSearchFilterActive
          ? searchFilterData?.[sourceScreenId]?.tableData
          : docData?.filterData?.[sourceScreenId]?.tableData,
        null,
        docData.rowIdDataMap,
        docData.fileObj,
        {useDocDataAsObject: true},
      );

      const destinationProcessedData = processRowsDataForMappedRowId(
        {docs: updatedDestinationTableData},
        null,
        isSearchFilterActive
          ? searchFilterData?.[destinationScreenId]?.tableData
          : docData?.filterData?.[destinationScreenId]?.tableData,
        null,
        docData.rowIdDataMap,
        docData.fileObj,
        {useDocDataAsObject: true},
      );

      if (isSearchFilterActive) {
        // SOURCE SCREEN HANDLING
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
          payload: {
            screenId: sourceScreenId,
            data: {
              tableData: sourceProcessedData.tableData,
            },
          },
        });
      } else {
        await dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
          payload: {
            docId: docId,
            screenId: sourceScreenId,
            data: {tableData: sourceProcessedData.tableData},
          },
        });
      }
      if (sourceProcessedData?.sortingKeyChangedRows?.length) {
        dispatch(
          reorderDocDataForSortKeyChange({
            docId,
            sortingKeyChangedRows: sourceProcessedData.sortingKeyChangedRows,
            omitFor: {
              screenType: isSearchFilterActive
                ? ['screen-search-filter']
                : [MINI_APPS.SCREEN_TYPE.FILTERED_DATA],
              screenId: [sourceScreenId],
            },
          }),
        );
      }
      if (!isEmpty(sourceProcessedData?.rowIdDataMap)) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
          payload: {
            docId,
            data: {rowIdDataMap: sourceProcessedData.rowIdDataMap},
          },
        });
      }
      // ******* SOURCE SCREEN HANDLING ENDS *******
      // ******* DESTINATION SCREEN HANDLING STARTS *******
      if (isSearchFilterActive) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA,
          payload: {
            screenId: destinationScreenId,
            data: {
              tableData: destinationProcessedData.tableData,
            },
          },
        });
      } else {
        await dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA,
          payload: {
            docId: docId,
            screenId: destinationScreenId,
            data: {tableData: destinationProcessedData.tableData},
          },
        });
      }
      if (destinationProcessedData?.sortingKeyChangedRows?.length) {
        dispatch(
          reorderDocDataForSortKeyChange({
            docId,
            sortingKeyChangedRows:
              destinationProcessedData.sortingKeyChangedRows,
            omitFor: {
              screenType: isSearchFilterActive
                ? ['screen-search-filter']
                : [MINI_APPS.SCREEN_TYPE.FILTERED_DATA],
              screenId: [destinationScreenId],
            },
          }),
        );
      }
      if (!isEmpty(destinationProcessedData?.rowIdDataMap)) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA,
          payload: {
            docId,
            data: {rowIdDataMap: destinationProcessedData.rowIdDataMap},
          },
        });
      }
      // ******* DESTINATION SCREEN HANDLING ENDS *******
      const editRowsPromiseArr = [];
      rowObjArrToUse.forEach((rowObj, index) => {
        const [headerDataWithRestrictions] =
          miniAppsActionHelper.getHeaderDataForMiniAppRowEdit(
            docData.headerData,
            activeAppMeta,
            uid,
            activeCustomRoleInfo,
            activeScreenId,
            rowObj,
            true,
            false,
          );
        const visibleColIds = headerDataWithRestrictions.map(
          (colObj) => colObj.id,
        );
        const updatedRowData = Object.assign({}, rowObj, {
          [selectBoxColId]: Object.assign({}, destinationSelectBoxValueObj),
        });
        const isRecurringRow = () => {
          return (
            rowObj?.rowProperties &&
            !isEmpty(rowObj?.rowProperties?.recurringConfig)
          );
        };
        editRowsPromiseArr.push(
          dispatch(
            addEditMiniAppsDataFromEntryOnlyV2(
              {
                rowData: updatedRowData,
                prevRowData: rowObj,
                isRowEdit: true,
                docId: docId,
                screenId: activeScreenId,
                visibleColIds: visibleColIds,
                extra: {
                  commentsToAdd: [],
                  recurringEvent: isRecurringRow()
                    ? MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_ONLY
                    : null,
                  isUpdateChildRows: true,
                  ...(noIndexUpdateRequired
                    ? {}
                    : {updatedRowIndex: updatedIndexArray[index]}),
                },
              },
              {isBackgroundOperation: false, isKanbanEditRowOp: true},
            ),
          ),
        );
      });
      Promise.all(editRowsPromiseArr);
    }
  };
const uniqueValuesCheck =
  (
    docId,
    uniqueValColumns,
    uniqueKeySetColumns,
    rowObj,
    prevRowObj,
    isRowEdit,
  ) =>
  (dispatch, getState) => {
    const {
      auth: {userPref},
      miniApps: {activeAppId},
    } = getState();

    const data = {
      docId,
      uniqueValColumns,
      uniqueKeySetColumns,
      rowObj,
      prevRowObj,
      timezone: userPref?.timezone,
      isRowEdit,
      miniAppId: activeAppId,
    };

    return callCloudFunction(
      CLOUD_FUNCTION_PATHS.MINI_APP_UNIQUE_VALUES_CHECK,
      data,
    )
      .then((response) => {
        const {error, success, uniqueFailed, uniqueKeySetFailed} = response;
        const uniqueFailColIds =
          uniqueFailed?.map((item) => item.colObj.id) ?? [];
        const uniqueKeySetFailColIdMap = uniqueKeySetFailed?.reduce(
          (acc, item) => {
            const {uniqueCheckColArr, name} = item;
            uniqueCheckColArr.forEach((uniqueObj) => {
              acc[uniqueObj?.colObj?.id] = name;
            });
            return acc;
          },
          {},
        );

        return {
          success: !error && success,
          uniqueFailColIds: error ? [] : uniqueFailColIds,
          uniqueKeySetFailColIdMap: error ? {} : uniqueKeySetFailColIdMap,
        };
      })
      .catch(() => ({
        success: false,
        uniqueFailColIds: [],
        uniqueKeySetFailColIdMap: {},
      }));
  };

const setMiniAppNavigationState =
  (
    state = {
      path: '',
    },
  ) =>
  (dispatch) => {
    dispatch({
      type: MINI_APPS_ACTION.UPDATE_NAVIGATION_STATE,
      payload: state,
    });
  };

const miniAppUpdateRealTimeDeleteRowStatus =
  (key, value, isRemoveToast) => async (dispatch, getState) => {
    // key => refers to the deleted row triggered docId in realtime db
    // value => delete row progress
    try {
      const state = getState();
      const {
        pushNotifications: {customCallbacks},
      } = state;
      const {getToastInstance, showDeleteRowsUpdating} = Object.assign(
        {},
        customCallbacks,
      );
      if (ENV) {
        if (!isRemoveToast) {
          if (!isEmpty(value)) {
            const {isLastChunk, isProcessingRows} = value;
            const isSingleRowDelete = value.totalRows === 1;
            const deletingMsg = isProcessingRows
              ? 'Processing Records for Delete'
              : isLastChunk
                ? `Deleted ${value.deletedRowsLenSoFar} ${
                    isSingleRowDelete ? 'Row' : 'Rows'
                  } Successfully`
                : `Deleting ${value.totalRows} ${
                    isSingleRowDelete ? 'Record' : 'Records'
                  } (${(
                    (value.deletedRowsLenSoFar / value.totalRows) *
                    100
                  ).toFixed(2)}%)`;
          }
        }
      } else {
        const toast = !isEmpty(ToastInstance)
          ? ToastInstance
          : typeof getToastInstance === 'function'
            ? getToastInstance()
            : {};

        const isToastActive = (toastId) => toast?.isActive?.(toastId);

        if (isRemoveToast && key && isToastActive(key)) {
          toast.dismiss?.(key);
        }

        if (
          typeof getToastInstance === 'function' &&
          typeof showDeleteRowsUpdating === 'function'
        ) {
          if (!isEmpty(value)) {
            showDeleteRowsUpdating(key, value);
          }
        }
      }
    } catch (err) {
      captureError(err);
    }
  };

const activateMiniAppUIDRealtimeDeleteRowListener =
  (dbInstance = null) =>
  async (dispatch, getState) => {
    try {
      dbInstance = dbInstance ?? database;
      const isWeb = !ENV;
      const {
        miniApps: {activeAppId, docsData},
        auth: {
          user: {uid: userUID},
        },
        pushNotifications: {customCallbacks},
      } = getState();

      if (!activeAppId) {
        return;
      }

      const {getToastInstance} = Object.assign({}, customCallbacks);
      const toast = !isEmpty(ToastInstance)
        ? ToastInstance
        : typeof getToastInstance === 'function'
          ? getToastInstance()
          : {};

      const isToastActive = (toastId) => toast?.isActive?.(toastId);

      const removeToast = (toastId) => {
        if (isToastActive(toastId)) {
          toast?.dismiss?.(toastId);
        }
      };

      const masterHandler = (Snapshot, type) => {
        const key = Snapshot.key;
        const value = Snapshot.val();

        try {
          const latestStoreState = getState();
          if (
            Snapshot.exists() &&
            activeAppId === latestStoreState.miniApps.activeAppId
          ) {
            switch (type) {
              case 'child_added':
              case 'child_changed': {
                dispatch(miniAppUpdateRealTimeDeleteRowStatus(key, value));
                break;
              }
              case 'child_removed': {
                dispatch(
                  miniAppUpdateRealTimeDeleteRowStatus(key, value, true),
                );
                break;
              }
            }
          }
        } catch (error) {
          captureInfo({Snapshot, type, error});
          captureError(
            new Error(
              'Error in activateMiniAppUIDRealtimeDeleteRowListener',
              error,
            ),
          );
        }
      };

      const listeners = [
        {type: 'child_changed'},
        {type: 'child_added'},
        {type: 'child_removed'},
      ];
      const dbRef = dbInstance().ref(
        `/miniAppRealtimeUpdates/${activeAppId}/${userUID}/deleteRow`,
      );
      listeners.forEach((obj, index) => {
        listeners[index].instance = dbRef.on(obj.type, (snap) =>
          masterHandler(snap, obj.type),
        );
      });

      if (isWeb) {
        const {getToastInstance} = Object.assign({}, customCallbacks);
        const toast =
          typeof getToastInstance === 'function'
            ? getToastInstance()
            : () => {};

        const isToastActive = (toastId) => toast?.isActive?.(toastId);

        const removeToast = (toastId) => {
          if (isToastActive(toastId)) {
            toast?.dismiss?.(toastId);
          }
        };
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
          payload: {
            miniappDeleteRowRealtimeDbListener: () => {
              listeners.forEach((obj) => dbRef.off(obj.type, obj.instance));
              Object.keys(docsData ?? {}).forEach((docId) =>
                removeToast(docId),
              );
            },
          },
        });
      } else {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS,
          payload: {
            miniappDeleteRowRealtimeDbListener: () =>
              listeners.forEach((obj) => dbRef.off(obj.type, obj.instance)),
          },
        });
      }
    } catch (e) {
      captureError(e);
    }
  };

const generateOtp =
  ({email, phoneNumber, colObj, otpColId}) =>
  async (dispatch, getState) => {
    const {
      auth: {user, userPref},
      miniApps: {activeAppId, miniApps},
    } = getState();
    try {
      const {mode, customText} = Object.assign(
        {},
        colObj?.otpConfig?.[otpColId],
      );
      if (!mode) {
        return ShowToast('Mode of Verification is required.', userPref);
      }
      const dataObj = {
        callerUID: user.uid,
        email,
        phoneNumber,
        mode,
        customText,
      };
      const response = await miniAppsActionHelper.generateOtpCF(dataObj);
      if (!response) {
        handleCloudErrorMsgAndLogging(response, dataObj, userPref);
        return null;
      }

      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
const verifyOtp =
  ({requestId, userOtp}) =>
  async (dispatch, getState) => {
    const {
      auth: {user, userPref},
      miniApps: {activeAppId, miniApps},
    } = getState();
    try {
      const dataObj = {
        callerUID: user.uid,
        requestId,
        userOtp,
      };
      const response = await miniAppsActionHelper.verifyOtpCF(dataObj);
      if (!response) {
        handleCloudErrorMsgAndLogging(response, dataObj, userPref);
        return null;
      }

      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

const getDataFromApiEndpoint = (props) => (dispatch, getState) => {
  try {
    return miniAppsActionHelper.getDataFromEndpoint(props);
  } catch (e) {
    captureError(e);
  }
};

const exportOrgRolesInfoDataHandler = () => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref, user},
      organisation: {
        membersList,
        activeOrganisationId,
        profileData,
        allOrganisationApps,
      },
    } = getState();
    if (!user?.uid || !membersList) {
      return null;
    }

    const dataObj = Object.assign(
      {},
      {
        userLang: userPref?.lang ?? 'EN',
        timezone: getTimezone(),
        membersList,
        ownerInfo: Object.assign({}, profileData?.owner, {
          uid: profileData?.ownerUID,
        }),
        orgId: activeOrganisationId,
        orgAppsMeta: allOrganisationApps,
      },
    );

    const data = await miniAppsActionHelper.exportOrgInfoDataCloudFunction(
      dataObj,
    );

    if (data?.success) {
      const url = data?.downloadUrl;
      const link = document.createElement('a');
      link.href = url;
      setTimeout(() => {
        link.click();
      }, 100);
      return {success: true};
    }

    if (!data || !data.success) {
      handleCloudErrorMsgAndLogging(data, dataObj, userPref);
      return {success: false};
    }
  } catch (error) {
    captureError(error);
    return {success: false};
  }
};

export {
  activateMiniAppsListener,
  addColumnUsingCF,
  addEditActionButtons,
  addEditFileObjProperties,
  addEditMiniAppScreenForKanban,
  addEditMiniAppsDataFromEntryOnlyV2,
  addEditScreenMiniApp,
  addEditMultipleScreens,
  addToFunctionsRefs,
  changeAssigneeForMultipleRows,
  changeScreen,
  checkUserPlanForAccess,
  closeApp,
  closeSearchFilterForScreen,
  createEditCustomRole,
  createEditMiniApp,
  createTemplatesForMiniAppImports,
  deleteColumnUsingCF,
  deleteImportedRows,
  deleteMultipleRowUsingCF,
  deleteRowUsingCF,
  downloadPDFUsingActionButton,
  duplicateScreenMiniApp,
  editColumnUsingCF,
  exportMiniAppScreenDataAsExcel,
  fetchCustomRoles,
  fetchMiniAppScreenDataAsExcelExports,
  fetchMiniAppScreenImportsProgress,
  fetchNotifications,
  generateDashboardReport,
  generateGmailAuthURLWithLoader,
  getMiniAppPaginatedDocData,
  getMiniAppsCategoryData,
  handleRowReorderInKanbanView,
  importExcelForMiniApp,
  manageScreensMiniApps,
  miniAppRowDeleteSuccessHandler,
  moveColumnsUsingCF,
  openApp,
  openMiniAppRow,
  reloadCurrentAppInternally,
  removeSharedUserOrLeaveApp,
  shareMiniApp,
  sortDocByCustomColumnId,
  startSearchFilterOnMiniAppScreen,
  triggerActionBtnByMultiSelect,
  updateAppDataSortOrder,
  updateCheckedValues,
  updateColumnsForQuickFilter,
  updateHeaderDataForDoc,
  updateIsSelectRecords,
  updateMiniAppScreenTimeFilter,
  updateRunningProcesses,
  updateScreenLocalConfig,
  deleteIntegrationForMiniAppAction,
  loadMiniAppEmailIntegrations,
  fetchImportTemplatesForMiniAppImports,
  uniqueValuesCheck,
  setMiniAppNavigationState,
  deleteRowv2,
  miniAppUpdateRealTimeDeleteRowStatus,
  setProgressNotificationListener,
  generateOtp,
  verifyOtp,
  getDataFromApiEndpoint,
  createTemplatesForMiniAppExports,
  fetchExportTemplatesForMiniApp,
  exportOrgRolesInfoDataHandler,
};
