/* eslint require-atomic-updates: 0 */
import {
  cloneDeep,
  forOwn,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  keys,
  merge,
  omit,
  orderBy,
  pick,
  pickBy,
  pullAt,
  debounce,
} from 'lodash';
import moment from 'moment';
import {setUserPref} from '../actions/authAction';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import UsersMethods from '../FirestoreHandlers/Users/UsersMethods';
import LioArray from '../utils/CustomClass/LioArray';
import {
  captureError,
  captureInfo,
  database,
  ENV,
  firestore,
  functions,
  logAnalyticsEvent,
  openLink,
  ShowToast,
} from '../imports';
import {setVisibleTasks} from './tasksAction';
import {first10RowAddEvent} from '../utils/analyticsHelper';
import {isColumnTypeAvailable} from '../utils/columnHelper';
import {
  AUTO_TEMPLATE_TYPE,
  CLOUD_FUNCTION_PATHS,
  COLUMN_PROPERTY_KEYS,
  DASHBOARD_ERRORS,
  DEFAULT_ROWS,
  FIELD_TYPE_ID,
  FOOTER_MANDATORY_FIELDS,
  FOOTER_OPERATION_TYPES,
  INITIAL_FETCH_COMPLETED,
  INVALID_INPUT,
  NUMBER_SORT_FIELD,
  PAGE_TYPE,
  SHARED_DOC_META_FIELDS,
  SHARE_PERMISSION_TYPE,
  SMART_TEMPLATE_TYPES,
  TABLE_LIMITS,
  TABLE_VIEW_MODES,
  MINI_APPS,
  SUMMARY_OPERATION_TYPE,
} from '../utils/constant';
import {
  calculateSplitByTotal,
  calculateTotal,
  getEqnCalculatedData,
  getOperationValue,
  getTotalObject,
  mapHeaderData,
  reCalculateFooter,
  solvePrevRowRefEqnForRow,
  solveRowAllEqns,
} from '../utils/equationHelper';
import PrevRowRefEqnHelper from '../utils/PrevRowRefEqnHelper';
import {restrictHeaderData} from '../utils/restrictionUtils';
import * as tableLinkUtils from '../utils/tableLinkUtils';
import {TABLE_PRESS_RESTRICTIONS} from '../utils/tableViewUtils';
import {
  checkIfPlainText,
  formatDateAndTimeAsText,
  formatMsgForWhatsapp,
  formatMsgForWhatsappWeb,
  getAddRowLimit,
  getLocalText,
  serializeError,
  getNewColumnName,
  getNewColumnId,
  getNewRowPropertiesObject,
  getDateTimeCellObj,
  currentDocNoOfRows,
  getRowObjAtIndex,
  getPrintTime,
  getOriginalFieldType,
} from '../utils/utils';
import {
  disablePagesCollabDoc,
  manageEntryOnlyHeader,
} from './actionHelpers/collabActionHelper';
import {
  checkAndUpdateDashboard,
  checkAndUpdateDashboardForColumn,
  checkIfColumnHasWithoutSplitByDashboard,
  updateAllDashboardsProperty,
  updateDashboardFirestore,
} from './actionHelpers/dashboardActionHelper';
import {
  getUpdatedListSummaryConfig,
  reCalculateSummaryCF,
  setChildColumnForConfig,
} from './actionHelpers/listColumnsActionHelper';
import {addEntryForFlipkartCron} from './actionHelpers/flipkartActions/flipkartActionHelper';
import {
  checkParticipantVersion,
  fetchSharedDocsMeta,
  generateNewFileMeta,
  getPageNameStr,
  setFileDetails,
  setSharedDocMeta,
} from './actionHelpers/homeActionHelper';
import * as tableActionHelper from './actionHelpers/tableActionHelper';
import {
  setAutoFilledLinkedData,
  setAutoFillLinkedDataFirestore,
} from './actionHelpers/tableLinksActionHelper';
import {modifyTaskActiveState} from './actionHelpers/tasksActionHelper';
import {extraActionsHandler} from './actionHelpers/undoRedoActions/undoActions';
import {
  getTableUndoRedoActions,
  TABLE_UNDO_REDO_TYPES,
} from './actionHelpers/undoRedoActions/undoRedoHelper';
import {
  COLLABORATION_ACTION,
  HOME_ACTION,
  ROW_HEIGHT_ACTION,
  TABLE_ACTION,
  VERSION_ACTION,
} from './actionType';
import {CommentsUtils} from '../utils/CommentsHelper';
import {
  deletePendingAutomations,
  loadPendingAutomations,
  loadAutomationConfig,
  setPendingAutomation,
} from './automationAction';
import {addEntryOnlyData, modifyListenersOnPageChange} from './collabAction';
import {reloadVisibleFiles, shareWithWhatsapp} from './homeAction';
import {tableDataHandler} from './tableActionsUtil';
import {
  deleteChildLinks,
  deleteColumnTableLinkHandler,
  deleteLinkedFiles,
  fetchParentFileData,
  getLinkedDocIds,
  manageLinkedIdsList,
} from './tableLinksActions';
import {
  manageTableViewModesFromMeta,
  tableHeaderDataFilterRestricted,
  updateTableViewModes,
} from './tableViewActions';
import {updateSearchFilterTableData} from './searchFilterAction';
import ColumnUtility from '../utils/ColumnUtility';

const loadInitialDocumentData = (documentId) => async (dispatch, getState) => {
  //load initial data without activating listener
  try {
    const {
      home,
      auth: {userPref},
    } = getState();
    const activeDocId = documentId ? documentId : home.activeDocumentId;
    const limit = TABLE_LIMITS.INITIAL_FETCH_LIMIT;

    if (activeDocId) {
      const [docData, rowsData] = await Promise.all([
        DocumentsMethods.getUserDocumentDataWithoutRows(activeDocId),
        DocumentsMethods.getRowsData({
          docId: activeDocId,
          isLimited: true,
          limit,
        }),
      ]);

      if (rowsData === null) {
        return ShowToast(
          'Fetching rows failed due to network issue.',
          userPref,
        );
      } else if (rowsData.docs.length < limit) {
        dispatch({
          type: TABLE_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE,
          payload: true,
        });
      }

      if (!isEmpty(docData)) {
        docData.tableData = tableActionHelper.processRowsData(
          rowsData,
          null,
          [],
        );
        docData.emptyRowIndexes = tableActionHelper.getEmptyRowIndex(
          docData.tableData,
          docData.headerData,
        );
        dispatch({
          type: TABLE_ACTION.LOAD_TABLE_DATA,
          payload: docData,
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const loadTableData = () => async (dispatch, getState) => {
  //load data into redux without activating listener
  try {
    const {home} = getState();
    const activeDocId = home.activeDocumentId;

    const tableDataObj = await DocumentsMethods.getUserDocumentData(
      activeDocId,
    );
    if (!isEmpty(tableDataObj)) {
      dispatch({
        type: TABLE_ACTION.LOAD_TABLE_DATA,
        payload: {
          headerData: tableDataObj.headerData,
          tableData: tableDataObj.tableData,
          footerData: Object.assign({}, tableDataObj.footerData),
          fileObj: Object.assign({}, tableDataObj.fileObj),
          emptyRowIndexes: tableActionHelper.getEmptyRowIndex(
            tableDataObj.tableData,
            tableDataObj.headerData,
          ),
          noOfRows: tableDataObj.noOfRows,
        },
      });
      return true;
    }
  } catch (error) {
    dispatch({type: TABLE_ACTION.STOP_TABLE_LOADING});
    captureError(error);
  }
  return false;
};

const loadEntryOnlyDocData = (docIdtoFetch) => async (dispatch, getState) => {
  const source = 'server';
  try {
    const {
      home: {activeDocumentMeta, originalDocumentId},
      auth: {user, userPref},
      table: {helperFunctionRefs},
    } = getState();

    let pagesMeta = null;
    let pagesEnabled = false;
    let latestDatePageExist = false;
    let dataFetched = false;
    let updatedPageId = originalDocumentId;
    let headerData = [],
      fileObj = {};
    if (!user?.uid) {
      return {
        success: false,
      };
    }
    if (!docIdtoFetch) {
      docIdtoFetch = originalDocumentId;
      const pagesSnapShot = await fetchSharedDocsMeta(originalDocumentId, {
        source,
      });
      if (pagesSnapShot.exists) {
        pagesMeta = pagesSnapShot.data();
        pagesEnabled = pagesMeta.pageObj?.enabled ? true : false;
      }
      if (pagesEnabled) {
        const {pages, pageObj} = pagesMeta;
        switch (pageObj.type) {
          case PAGE_TYPE.DAILY: {
            const currDay = moment().format('D MMM YY');
            const obj = pages.find((p) => p.pageName === currDay);
            if (obj) {
              latestDatePageExist = true;
              docIdtoFetch = obj.id;
            }
            break;
          }
          case PAGE_TYPE.MONTHLY: {
            const currMonth = moment().format('MMM YY');
            const obj = pages.find((p) => p.pageName === currMonth);
            if (obj) {
              latestDatePageExist = true;
              docIdtoFetch = obj.id;
            }
            break;
          }
          case PAGE_TYPE.CLIENT: {
            latestDatePageExist = true;
            docIdtoFetch =
              activeDocumentMeta.activePageId ?? pages[pages.length - 1].id; //first page
            break;
          }
        }
        if (!latestDatePageExist) {
          //call cloud function and get data from there
          updatedPageId = `${user.uid}_${moment().valueOf()}`;
          const dataObj = {
            updatedPages: [
              {
                id: updatedPageId,
                createdTimestamp: moment().valueOf(),
                displayColObj: {},
                pageName: getPageNameStr(pageObj.type),
              },
              ...pages,
            ],
            callerUID: user.uid,
            docId: originalDocumentId,
            lastPageId: pages[0].id,
            isPageAdd: true,
          };
          const data = await manageEntryOnlyHeader(dataObj);
          if (!data || !data.success) {
            let message = '';
            if (data?.message) {
              message = data.message;
            } else {
              message = 'Something went wrong, please try again';
            }
            if (data?.error) {
              captureInfo({log: data.log});
              captureError(new Error(data.error), true);
            }
            ShowToast(message, userPref, true, true);
            return false;
          } else {
            dataFetched = true;
            headerData = data.headerData;
            fileObj = data.fileObj;
          }
        } else {
          updatedPageId = docIdtoFetch;
        }
      }
    }
    if (!dataFetched) {
      updatedPageId = docIdtoFetch;
      const headerDataSnap = await tableActionHelper.fetchEntryOnlyData(
        docIdtoFetch,
        {source},
      );
      if (headerDataSnap.exists) {
        const firestoreData = headerDataSnap.data();
        headerData = Object.values(firestoreData.headerData ?? {});
        fileObj = firestoreData.fileObj;
      } else {
        //if by any case `docIdtoFetch` doesnt exist in `entryOnlyData`
        //call cloud function to copy headerData from documents->entryOnlyData
        const dataObj = {
          callerUID: user.uid,
          docId: docIdtoFetch,
          isPageAdd: false,
        };
        const data = await manageEntryOnlyHeader(dataObj);
        if (!data || !data.success) {
          let message = '';
          if (data?.message) {
            message = data.message;
          } else {
            message = 'Something went wrong, please try again';
          }
          if (data?.error) {
            captureInfo({log: data.log});
            captureError(new Error(data.error), true);
          }
          ShowToast(message, userPref, true, true);
          return {
            success: false,
          };
        } else {
          headerData = data.headerData;
          fileObj = data.fileObj;
        }
      }
    }
    let isCompatible = true; //for web
    if (ENV) {
      const {checkFieldTypeCompatibility} = require('../imports');
      isCompatible = checkFieldTypeCompatibility({
        navigation: helperFunctionRefs.NAVIGATION_REF,
        userPref,
        headerData,
      });
    }
    if (!isCompatible) {
      return {
        success: false,
      };
    }
    dispatch({
      type: HOME_ACTION.UPDATE_SELECTED_PAGE,
      payload: {
        activeDocumentId: updatedPageId,
        activeDocumentMeta: Object.assign(
          {},
          activeDocumentMeta,
          pagesMeta,
          pagesMeta?.pages ? {pages: pagesMeta.pages.reverse()} : {},
        ),
      },
    });

    dispatch({
      type: TABLE_ACTION.LOAD_ENTRY_ONLY_DATA,
      payload: {
        headerData: restrictHeaderData(activeDocumentMeta, headerData),
        originalHeaderData: headerData,
        fileObj,
      },
    });

    return {
      success: true,
      headerData: restrictHeaderData(activeDocumentMeta, headerData),
    };
  } catch (error) {
    dispatch({type: TABLE_ACTION.STOP_TABLE_LOADING});
    captureError(error);
    return {
      success: false,
    };
  }
};

/**
 * add new column to right of current table
 */
const addNewColumn =
  (obj = {}, isSharedCalled) =>
  (dispatch, getState) => {
    try {
      //Handle all cases in addColMid as well
      const {
        table,
        auth,
        home,
        remoteConfig: {isOrganisationMode},
      } = getState();
      const activeDocumentMeta = !isEmpty(obj?.activeDocumentMeta)
        ? obj?.activeDocumentMeta
        : home.activeDocumentMeta;
      const originalDocumentId = obj?.originalDocumentId?.length
        ? obj.originalDocumentId
        : home.originalDocumentId;
      const activeDocumentId = obj?.activeDocumentId?.length
        ? obj.activeDocumentId
        : home.activeDocumentId;
      const headerData =
        isArray(obj?.headerData) && !isEmpty(obj.headerData)
          ? obj.headerData.slice()
          : table.headerData.slice();
      const currentLength = headerData.length;
      const initFileObject = Object.assign(
        {},
        !isEmpty(obj?.fileObj) ? obj.fileObj : table.fileObj,
      );
      /**
       * Check if comment column already exits.
       */
      if (
        obj?.type === FIELD_TYPE_ID.COMMENT &&
        isColumnTypeAvailable(headerData, FIELD_TYPE_ID.COMMENT)
      ) {
        ShowToast(
          'Currently only single comment column is allowed.',
          auth.userPref,
        );
        return {
          errorMessage: true,
        };
      }

      let updatedFileObj = {};

      const isCreatedInfoCol =
        isSharedCalled || obj.type === FIELD_TYPE_ID.CREATED_INFO
          ? true
          : false;
      const isUserColCreation =
        obj.type === FIELD_TYPE_ID.USER_COLUMN ? true : false;

      /**
       * Check if CREATED_INFO_COLUMN OR Assign Task Column already exits.
       */
      if (
        (isCreatedInfoCol &&
          isColumnTypeAvailable(headerData, FIELD_TYPE_ID.CREATED_INFO)) ||
        (isUserColCreation &&
          isColumnTypeAvailable(headerData, FIELD_TYPE_ID.USER_COLUMN)) ||
        (!isOrganisationMode &&
          obj.type === FIELD_TYPE_ID.ASSIGN_TASK &&
          isColumnTypeAvailable(headerData, FIELD_TYPE_ID.ASSIGN_TASK))
      ) {
        return null;
      }

      /**
       * If Created Info column use default ID.
       */
      const id = getNewColumnId(obj.type);

      const newColName = obj?.colName?.length
        ? obj.colName
        : getNewColumnName(headerData, auth.userPref);

      const extraColumnsToAdd = [];

      logAnalyticsEvent('COL_ADDED', {
        colName: newColName,
        colNumber: currentLength + 1,
        colType: obj.type || FIELD_TYPE_ID.TEXT,
        withModal: obj.withModal ? true : false,
        docId: originalDocumentId,
        colId: id,
      });

      const newHeaderObj = {
        id,
        val: newColName,
        fieldType: obj && obj.type ? obj.type : FIELD_TYPE_ID.TEXT,
        ...(obj?.subType ? {subType: obj.subType} : {}),
      };
      const updatedMandatoryColumnObject = Object.assign(
        {},
        table.headerMappedValues?.mandatoryColumnObject,
      );
      FIELD_TYPE_ID.TABLE;
      if (obj.property) {
        newHeaderObj['columnProperties'] = obj.property;
        if (obj.property.MANDATORY) {
          const newObjKey = {};
          newObjKey[id] = id;
          Object.assign(updatedMandatoryColumnObject, newObjKey);
        }
      }
      // Set Obj accroding to FieldTyepe
      if (obj.type === FIELD_TYPE_ID.LIST) {
        //For LIST type
        if (obj.listConfigDetails) {
          newHeaderObj.listConfig = obj.listConfigDetails;
          const {updatedSummaryConfig, columnsToAdd, summaryFileObj} =
            getUpdatedListSummaryConfig(
              obj.summaryConfig,
              {},
              id,
              obj.subListHeaderMapping,
              obj.subListFileObj,
              auth.userPref,
            );
          extraColumnsToAdd.push(...columnsToAdd);
          newHeaderObj.summaryConfig = updatedSummaryConfig;
          updatedFileObj = {...summaryFileObj};
        }
      } else if (newHeaderObj.fieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
        newHeaderObj.selectElements = Array.isArray(obj?.selectElements)
          ? obj?.selectElements
          : [];
        newHeaderObj.isSelectPopupUserCreated = true;
      } else if (obj.type === FIELD_TYPE_ID.LABEL) {
        newHeaderObj.selectElements = Array.isArray(obj?.selectElements)
          ? obj?.selectElements
          : [];
        newHeaderObj.isLabelPopupUserCreated = true;
      } else if (
        obj.type === FIELD_TYPE_ID.USER_COLUMN &&
        !isEmpty(obj.configuration)
      ) {
        newHeaderObj.configuration = obj.configuration;
      } else if (obj?.eqn && obj.type === FIELD_TYPE_ID.FORMULA) {
        newHeaderObj.eqn = obj?.eqn;
        newHeaderObj.eqnStr = obj?.eqnStr;
        newHeaderObj.subType = obj?.subType;
        newHeaderObj.eqnColName = obj?.eqnColName;
      } else if (obj.type === FIELD_TYPE_ID.UNIT && obj.unitDetails) {
        const colId = id;
        const unitObj = {UNIT: obj.unitDetails};
        updatedFileObj = {
          [colId]: Object.assign({}, initFileObject[colId], unitObj),
        };
      } else if (obj.type === FIELD_TYPE_ID.CONTACT) {
        newHeaderObj.nameAsSubtext = true;
      }

      headerData.splice(currentLength, 0, newHeaderObj, ...extraColumnsToAdd);
      if (!obj?.noReduxAction) {
        dispatch({
          type: TABLE_ACTION.ADD_COLUMN_AT_POS,
          payload: {
            newHeaderObj,
            insertIndex: currentLength,
            headerData,
            updatedFileObj,
            updatedMandatoryColumnObject,
            extraColumnsToAdd,
          },
        });
      }
      if (obj.property?.UNIQUE_VALUES) {
        DocumentsMethods.setUniqueValuesDataForColumn(activeDocumentId, id, {});
      }
      const fileObj = Object.assign({}, initFileObject, updatedFileObj);

      DocumentsMethods.replaceHeaderData(activeDocumentId, headerData, {
        fileObj,
      });

      tableActionHelper.checkAndUpdateEntryOnlyData({
        getState,
        fileObj,
        activeDocumentMeta,
        ...(obj?.fromMiniApps
          ? {headerData: [...headerData, newHeaderObj], docId: activeDocumentId}
          : {}),
      });
      const newAddedColData = {
        index: currentLength,
        colId: id,
        colData: newHeaderObj,
      };
      if (
        !isNil(newAddedColData.colId) &&
        obj.type === FIELD_TYPE_ID.LIST &&
        obj.listConfigDetails?.primaryColId
      ) {
        const activeDocumentName = home?.activeDocumentName;
        const primaryColumnIndex = headerData.findIndex(
          (item) => item.id === obj.listConfigDetails.primaryColId,
        );
        const primaryColumnType = getOriginalFieldType(
          headerData?.[primaryColumnIndex],
        );
        const subListColObj = {
          originalDocumentId: obj.listConfigDetails.docId,
          activeDocumentId: obj.listConfigDetails.docId,
          subType: primaryColumnType,
          colName: obj.listConfigDetails.primaryColName, // should be same as primary column name
          parentDocId: originalDocumentId,
          primaryColId: obj.listConfigDetails.primaryColId,
          parentDocName: activeDocumentName,
          primaryColName: obj.listConfigDetails.primaryColName,
          listColIndex: newAddedColData.index, // needed to add default mapping
          listColHeaderObj: newHeaderObj, // needed to add default mapping
          addDefaultMapping: isEmpty(
            // check to add default mapping
            obj?.listConfigDetails?.previewConfig?.mappedValues,
          ),
          primaryColumnIndex: primaryColumnIndex,
          primaryColumnHeaderObj: headerData[primaryColumnIndex],
        };
        dispatch(addTableColumnForSublistAndHandleMapping(subListColObj));

        // Summary Config Update
        setChildColumnForConfig(
          newHeaderObj?.summaryConfig,
          originalDocumentId,
          obj.listConfigDetails?.docId,
          id,
        );
      }
      return newAddedColData;
    } catch (error) {
      captureError(error);
      return null;
    }
  };

/**
 * add new column at any position of current headerData
 * */
const addColMid = (obj) => async (dispatch, getState) => {
  try {
    //Handle all cases in addNewColumn as well
    const {table, auth, home} = getState();
    const headerData = table.headerData.slice();
    let updatedFileObj = {};

    const id = getNewColumnId(obj.type);
    /**
     * Check if comment column already exits.
     */
    if (
      obj?.type === FIELD_TYPE_ID.COMMENT &&
      isColumnTypeAvailable(headerData, FIELD_TYPE_ID.COMMENT)
    ) {
      ShowToast(
        'Currently only single comment column is allowed.',
        auth.userPref,
      );
      return {
        errorMessage: true,
      };
    }

    const newColName = obj?.colName?.length
      ? obj.colName
      : getNewColumnName(headerData, auth.userPref);

    const extraColumnsToAdd = [];

    logAnalyticsEvent('COL_ADDED', {
      colName: newColName,
      colNumber: headerData.length + 1,
      position: obj.index,
      colType: obj.type,
      colId: id,
      withModal: obj.withModal ? true : false,
      docId: home.originalDocumentId,
    });

    const newHeaderObj = {
      id,
      val: newColName,
      fieldType: obj && obj.type ? obj.type : FIELD_TYPE_ID.TEXT,
    };
    const updatedMandatoryColumnObject = Object.assign(
      {},
      table.headerMappedValues?.mandatoryColumnObject,
    );
    if (obj.property) {
      newHeaderObj['columnProperties'] = obj.property;
      if (obj.property.MANDATORY) {
        const newObjKey = {};
        newObjKey[id] = id;
        Object.assign(updatedMandatoryColumnObject, newObjKey);
      }
    }

    if (obj.type === FIELD_TYPE_ID.LIST) {
      //For LIST type
      if (obj.listConfigDetails) {
        newHeaderObj.listConfig = obj.listConfigDetails;
        const {updatedSummaryConfig, columnsToAdd, summaryFileObj} =
          getUpdatedListSummaryConfig(
            obj.summaryConfig,
            {},
            id,
            obj.subListHeaderMapping,
            obj.subListFileObj,
            auth.userPref,
          );
        extraColumnsToAdd.push(...columnsToAdd);
        newHeaderObj.summaryConfig = updatedSummaryConfig;
        updatedFileObj = {...summaryFileObj};
      }
    }

    // Set Obj accroding to FieldTyepe
    if (obj.type === FIELD_TYPE_ID.SELECT_POP_UP) {
      newHeaderObj.selectElements = Array.isArray(obj?.selectElements)
        ? obj?.selectElements
        : [];
      newHeaderObj.isSelectPopupUserCreated = true;
    } else if (obj.type === FIELD_TYPE_ID.LABEL) {
      newHeaderObj.selectElements = Array.isArray(obj?.selectElements)
        ? obj?.selectElements
        : [];
      newHeaderObj.isLabelPopupUserCreated = true;
    } else if (obj?.eqn && obj.type === FIELD_TYPE_ID.FORMULA) {
      newHeaderObj.eqn = obj?.eqn;
      newHeaderObj.eqnStr = obj?.eqnStr;
      newHeaderObj.subType = obj?.subType;
      newHeaderObj.eqnColName = obj?.eqnColName;
    } else if (
      obj.type === FIELD_TYPE_ID.USER_COLUMN &&
      !isEmpty(obj.configuration)
    ) {
      newHeaderObj.configuration = obj.configuration;
    } else if (obj.type === FIELD_TYPE_ID.UNIT && obj.unitDetails) {
      const colId = id;
      const unitObj = {UNIT: obj.unitDetails};
      const fileObj = Object.assign({}, table.fileObj);
      updatedFileObj = {
        [colId]: Object.assign({}, fileObj[colId], unitObj),
      };
    }
    headerData.splice(obj.index, 0, newHeaderObj, ...extraColumnsToAdd);
    dispatch({
      type: TABLE_ACTION.ADD_COLUMN_AT_POS,
      payload: {
        newHeaderObj,
        insertIndex: obj.index,
        headerData,
        updatedFileObj,
        updatedMandatoryColumnObject,
        extraColumnsToAdd,
      },
    });
    if (obj.property?.UNIQUE_VALUES) {
      DocumentsMethods.setUniqueValuesDataForColumn(
        home.activeDocumentId,
        id,
        {},
      );
    }
    const fileObj = Object.assign({}, table.fileObj, updatedFileObj);
    DocumentsMethods.replaceHeaderData(home.activeDocumentId, headerData, {
      fileObj,
    });
    tableActionHelper.checkAndUpdateEntryOnlyData({
      getState,
      headerData,
      fileObj,
    });
    const newAddedColData = {index: obj.index, colId: id};
    if (
      !isNil(newAddedColData.colId) &&
      obj.type === FIELD_TYPE_ID.LIST &&
      obj.listConfigDetails?.primaryColId
    ) {
      const activeDocumentName = home?.activeDocumentName;
      const primaryColumnIndex = headerData.findIndex(
        (item) => item.id === obj.listConfigDetails.primaryColId,
      );
      const primaryColumnType = headerData[primaryColumnIndex].fieldType;
      const subListColObj = {
        originalDocumentId: obj.listConfigDetails.docId,
        activeDocumentId: obj.listConfigDetails.docId,
        subType: primaryColumnType,
        colName: obj.listConfigDetails.primaryColName, // should be same as primary column name
        parentDocId: home.originalDocumentId,
        primaryColId: obj.listConfigDetails.primaryColId,
        parentDocName: activeDocumentName,
        primaryColName: obj.listConfigDetails.primaryColName,
        listColIndex: newAddedColData.index, // needed to add default mapping
        listColHeaderObj: newHeaderObj, // needed to add default mapping
        addDefaultMapping: isEmpty(
          // check to add default mapping
          obj?.listConfigDetails?.previewConfig?.mappedValues,
        ),
        primaryColumnIndex: primaryColumnIndex,
        primaryColumnHeaderObj: headerData[primaryColumnIndex],
      };
      dispatch(addTableColumnForSublistAndHandleMapping(subListColObj));
      // Summary Config Update
      setChildColumnForConfig(
        newHeaderObj?.summaryConfig,
        home.originalDocumentId,
        obj.listConfigDetails?.docId,
        id,
      );
    }
    return newAddedColData;
  } catch (error) {
    captureError(error);
    return null;
  }
};

/**
 * edit properties of any column object of headerData
 */
const editColumn =
  (obj, extraOptions = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        table,
        home,
        auth,
        tableLinks: {autoFillLinkedFiles},
      } = getState();
      const uid = auth.user.uid;

      if (!uid) {
        return;
      }

      const extra = {};
      const newColObj = {};
      let updatedFileObj = {};
      const currentHeaderData = table.headerData.slice();

      const initialHeaderData = currentHeaderData.slice();
      const index = !isNil(obj.index)
        ? obj.index
        : currentHeaderData.length - 1;
      const currentHeaderObj = Object.assign({}, currentHeaderData[index]);
      const activeDocumentMeta = Object.assign({}, home.activeDocumentMeta);
      const activeDocumentName = home.activeDocumentName;
      const isShared = !isEmpty(activeDocumentMeta?.collab);
      const dashboardExistOnColumn = !isEmpty(
        activeDocumentMeta.dashboards?.[currentHeaderObj.id],
      );
      const activeDocumentId = home.activeDocumentId;
      const footerAsHeaderFieldUpdateObj = {};
      const dashboardUpdateObj = {};
      const arrUndoActions = [];
      const arrRedoActions = [];
      const promises = [];
      const updatedUniqueColumnData = Object.assign({}, table.uniqueColumnData);
      const extraColumnsToAdd = [];
      const columnIdsToRemove = [];

      if (obj.type) {
        /**
         * Add Column type to new Col Object
         */
        newColObj.fieldType = obj.type;

        if (currentHeaderObj.fieldType !== obj.type) {
          /**
           * Check if comment column already exits.
           */
          if (
            obj.type === FIELD_TYPE_ID.COMMENT &&
            isColumnTypeAvailable(currentHeaderData, FIELD_TYPE_ID.COMMENT)
          ) {
            ShowToast(
              'Currently only single comment column is allowed.',
              auth.userPref,
            );
            return {
              errorMessage: true,
            };
          }

          logAnalyticsEvent('COLUMN_TYPE_CHANGED', {
            newType: obj.type,
            oldType: currentHeaderObj.fieldType,
            source: obj.source ? obj.source : 'edit-screen',
            docId: home.originalDocumentId,
            colId: currentHeaderObj.id,
          });
          footerAsHeaderFieldUpdateObj.fieldType = obj.type;
          if (dashboardExistOnColumn) {
            dashboardUpdateObj.fieldType = obj.type;
            dashboardUpdateObj.subType = null;
          }
          if (currentHeaderObj.fieldType === FIELD_TYPE_ID.TABLE) {
            const linkedMeta = currentHeaderObj.linkedMeta;

            for (const parentDocId in linkedMeta) {
              arrUndoActions.push({
                updateObjType: 'object',
                elementType: 'object',
                element: {[linkedMeta[parentDocId].colId]: currentHeaderObj.id},
                action: 'ADD',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${parentDocId}`,
                addIdPathIfNotExist: true,
                runAfterParentObjAdd: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: parentDocId,
                    action: 'ADD',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              });
              if (autoFillLinkedFiles?.[parentDocId]) {
                arrRedoActions.push({
                  updateObjType: 'object',
                  elementType: 'object',
                  element: {
                    [linkedMeta[parentDocId].colId]: currentHeaderObj.id,
                  },
                  action: 'REMOVE',
                  collection: 'userDocuments',
                  removeParentObjIfEmpty:
                    keys(autoFillLinkedFiles[parentDocId])?.length === 1
                      ? true
                      : false,
                  path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                  updatePath: `${parentDocId}`,
                  runAfterParentObjEmptyRemove: [
                    {
                      updateObjType: 'array',
                      elementType: 'string',
                      element: parentDocId,
                      action: 'REMOVE',
                      collection: isShared ? 'sharedDocsMeta' : 'users',
                      path: isShared
                        ? `${activeDocumentId}`
                        : `${uid}/documents/${activeDocumentId}`,
                      updatePath: 'linkedDocIds',
                    },
                  ],
                });
              }
              promises.push(() =>
                dispatch(
                  deleteLinkedFiles(
                    currentHeaderData,
                    parentDocId,
                    activeDocumentId,
                    index,
                  ),
                ),
              );
            }
          }
        }
      }
      if (obj.type === FIELD_TYPE_ID.LIST) {
        //For LIST type
        if (obj.listConfigDetails) {
          newColObj.listConfig = obj.listConfigDetails;
          const {
            updatedSummaryConfig,
            columnsToAdd,
            colIdsToRemove,
            summaryFileObj,
          } = getUpdatedListSummaryConfig(
            obj.summaryConfig,
            currentHeaderObj.summaryConfig,
            currentHeaderObj.id,
            obj.subListHeaderMapping,
            obj.subListFileObj,
            auth.userPref,
          );
          extraColumnsToAdd.push(...columnsToAdd);
          columnIdsToRemove.push(...colIdsToRemove);
          newColObj.summaryConfig = updatedSummaryConfig;
          updatedFileObj = merge(updatedFileObj, summaryFileObj);
        }
      } else if (
        obj.type === FIELD_TYPE_ID.USER_COLUMN &&
        !isEmpty(obj?.configuration)
      ) {
        newColObj.configuration = obj.configuration;
      } else if (obj.type === FIELD_TYPE_ID.SELECT_POP_UP) {
        newColObj.selectElements = obj?.selectElements?.length
          ? obj.selectElements
          : [];
        newColObj.isSelectPopupUserCreated = obj?.selectElements?.[0]?.textStyle
          ? undefined
          : true;
      } else if (obj.type === FIELD_TYPE_ID.LABEL) {
        newColObj.selectElements = obj?.selectElements?.length
          ? obj.selectElements
          : [];
        newColObj.isLabelPopupUserCreated = true;
      } else if (obj.type === FIELD_TYPE_ID.UNIT && !isEmpty(obj.unitDetails)) {
        const colId = currentHeaderObj.id;
        const unitObj = {UNIT: obj.unitDetails};
        const fileObj = Object.assign({}, table.fileObj);
        updatedFileObj = {[colId]: Object.assign({}, fileObj[colId], unitObj)};
        if (dashboardExistOnColumn) {
          dashboardUpdateObj.unitSymbol = obj.unitDetails.unitSymbol ?? '';
        }
      } else if (obj.type === FIELD_TYPE_ID.TABLE && obj.subType) {
        const colId = currentHeaderObj.id;
        const fileObj = Object.assign({}, table.fileObj);
        if (obj.subType === FIELD_TYPE_ID.UNIT) {
          updatedFileObj = {
            [colId]: Object.assign(
              {},
              fileObj[colId],
              extraOptions?.tableUnitMeta,
            ),
          };
        }
        newColObj.subType = obj.subType;
        newColObj.parentSubType = obj.parentSubType ?? undefined;
        newColObj.linkedMeta = Object.assign(
          {},
          currentHeaderObj?.linkedMeta ? currentHeaderObj.linkedMeta : {},
        );

        if (obj.isRemove) {
          // If Remove Linked File
          if (newColObj.linkedMeta?.[obj.selectedDocID]) {
            newColObj.linkedMeta = omit(newColObj.linkedMeta, [
              obj.selectedDocID,
            ]);
            currentHeaderObj.linkedMeta = omit(currentHeaderObj.linkedMeta, [
              obj.selectedDocID,
            ]);
          }
        } else if (
          checkIfPlainText(obj.changeLinkedFile) &&
          obj.changeLinkedFile?.length // If Change Linked File
        ) {
          newColObj.linkedMeta = omit(newColObj.linkedMeta, [
            obj.changeLinkedFile,
          ]);
          currentHeaderObj.linkedMeta = omit(currentHeaderObj.linkedMeta, [
            obj.changeLinkedFile,
          ]);
          newColObj.linkedMeta[obj.selectedDocID] = obj.selectedColObj;
        } else {
          newColObj.linkedMeta[obj.selectedDocID] = obj.selectedColObj;
        }
      }

      if (obj.colName) {
        if (currentHeaderObj.val !== obj.colName) {
          logAnalyticsEvent('COLUMN_RENAME', {
            docId: home.originalDocumentId,
            colId: currentHeaderObj.id,
            colName: currentHeaderObj.val,
            prevColName: obj.colName,
          });
          footerAsHeaderFieldUpdateObj.headerText = {EN: obj.colName};
        }
        newColObj.val = obj.colName;
      }
      if (obj.eqn) {
        newColObj.eqn = obj.eqn;
        newColObj.eqnStr = obj.eqnStr;
        newColObj.subType = obj.subType;
        newColObj.eqnColName = obj.eqnColName;
        if (dashboardExistOnColumn) {
          dashboardUpdateObj.subType = obj.subType;
        }
      }
      if (obj.styleObj) {
        newColObj.styleObj = obj.styleObj;
      }
      if ('nameAsSubtext' in obj) {
        newColObj.nameAsSubtext = obj.nameAsSubtext;
      }
      const updatedMandatoryColumnObject = Object.assign(
        {},
        table.headerMappedValues?.mandatoryColumnObject,
      );
      if (obj.property) {
        newColObj['columnProperties'] = obj.property;
        if (obj.property.MANDATORY) {
          const newObjKey = {};
          newObjKey[currentHeaderObj.id] = currentHeaderObj.id;
          Object.assign(updatedMandatoryColumnObject, newObjKey);
        } else if (
          !obj.property.MANDATORY &&
          !isEmpty(updatedMandatoryColumnObject) &&
          currentHeaderObj.id in updatedMandatoryColumnObject
        ) {
          delete updatedMandatoryColumnObject[currentHeaderObj.id];
        }
        if (
          !obj.property.UNIQUE_VALUES &&
          currentHeaderObj?.columnProperties?.UNIQUE_VALUES &&
          currentHeaderObj.id in updatedUniqueColumnData
        ) {
          delete updatedUniqueColumnData[currentHeaderObj.id];
          DocumentsMethods.deleteUniqueValuesDataForColumn(
            activeDocumentId,
            currentHeaderObj.id,
          );
        }
      }

      let updatedHeader = Object.assign({}, currentHeaderObj, newColObj);
      if (
        !isEmpty(footerAsHeaderFieldUpdateObj) &&
        activeDocumentMeta.fileData?.footerAsHeader
      ) {
        extra.setPrevFooterAsHeader = () =>
          //undo action
          dispatch(
            checkAndUpdateFooterAsHeaderFields(
              activeDocumentMeta.fileData.footerAsHeader,
            ),
          );
        extra.setNewFooterAsHeader = () =>
          //redo action
          dispatch(
            checkAndUpdateFooterAsHeaderFields(footerAsHeaderFieldUpdateObj),
          );
        dispatch(
          checkAndUpdateFooterAsHeaderFields(footerAsHeaderFieldUpdateObj),
        );
      }
      const undoDashboardUpdateObj = {};
      if (dashboardExistOnColumn) {
        forOwn(dashboardUpdateObj, (_, key) => {
          Object.assign(undoDashboardUpdateObj, {
            [key]:
              currentHeaderObj[key] ||
              table.fileObj?.[currentHeaderObj.id]?.['UNIT']?.[key],
          });
        });
      }

      if (
        currentHeaderObj.fieldType !== obj.type &&
        currentHeaderObj.fieldType === FIELD_TYPE_ID.TABLE
      ) {
        updatedHeader = omit(updatedHeader, ['linkedMeta']);
      }

      const redoDashboardUpdateObj = dashboardExistOnColumn
        ? Object.assign({}, dashboardUpdateObj)
        : {};

      /**
       * For Table Link Delete File - undo/redo actions
       */
      const {tableLinkDeleteData} = extraOptions ?? {};
      const tableLinkURA = !isEmpty(tableLinkDeleteData)
        ? tableLinkUtils.getTableLinkDeleteFileUndoRedoActions({
            parentColId: tableLinkDeleteData.parentColId,
            childColId: tableLinkDeleteData.childColId,
            parentDocId: tableLinkDeleteData.parentDocId,
            activeDocumentId,
            activeDocumentMeta,
            uid,
          })
        : {extraUndoActions: [], extraRedoActions: []}; // Undo / Redo Actions ;

      const extraUndoActions = [
        ...(extraOptions?.extraUndoActions
          ? extraOptions.extraUndoActions
          : []),
        ...arrUndoActions,
        ...tableLinkURA.extraUndoActions,
      ];

      const extraRedoActions = [
        ...(extraOptions?.extraRedoActions
          ? extraOptions.extraRedoActions
          : []),
        ...arrRedoActions,
        ...tableLinkURA.extraRedoActions,
      ];

      let headerDataAfterEdit = currentHeaderData.slice();
      headerDataAfterEdit.splice(index, 1, updatedHeader, ...extraColumnsToAdd);
      if (columnIdsToRemove.length > 0) {
        headerDataAfterEdit = headerDataAfterEdit.filter(
          (colObj) => !columnIdsToRemove.includes(colObj.id),
        );
      }

      dispatch({
        type: TABLE_ACTION.EDIT_COL,
        payload: {
          newColObj: updatedHeader,
          index,
          extra,
          updatedFileObj,
          extraUndoActions,
          extraRedoActions,
          undoDashboardUpdateObj,
          redoDashboardUpdateObj,
          undoPrevColObj: initialHeaderData[index],
          undoNewColObj: updatedHeader,
          dashboardColumnId: currentHeaderObj.id,
          updatedMandatoryColumnObject,
          extraColumnsToAdd,
          columnIdsToRemove,
          headerDataAfterEdit,
        },
      });
      if (obj.type === FIELD_TYPE_ID.TABLE && obj.subType && obj.isRemove) {
        dispatch(
          deleteLinkedFiles(
            initialHeaderData,
            obj.selectedDocID,
            activeDocumentId,
            index,
          ),
        );
      }
      const fileObj = Object.assign({}, table.fileObj, updatedFileObj);
      if (!(obj.type === FIELD_TYPE_ID.TABLE && obj.subType && obj.isRemove)) {
        DocumentsMethods.replaceHeaderData(
          home.activeDocumentId,
          headerDataAfterEdit,
          {fileObj},
        );
      }

      tableActionHelper.checkAndUpdateEntryOnlyData({
        getState,
        fileObj,
      });
      if (dashboardExistOnColumn && !isEmpty(dashboardUpdateObj)) {
        const updateObj = {};
        forOwn(dashboardUpdateObj, (val, key) => {
          Object.assign(updateObj, {
            [`pages.${activeDocumentId}.${key}`]: val == undefined ? null : val,
          });
        });
        const dashboards = {
          [currentHeaderObj.id]:
            activeDocumentMeta.dashboards[currentHeaderObj.id],
        };
        updateAllDashboardsProperty(dashboards, updateObj);
      }
      if (
        obj.type === FIELD_TYPE_ID.LIST &&
        obj.listConfigDetails?.docId &&
        !obj?.isAlreadyListColumn
      ) {
        const primaryColumnIndex = initialHeaderData.findIndex(
          (item) => item.id === obj.listConfigDetails.primaryColId,
        );
        const primaryColumnType =
          initialHeaderData?.[primaryColumnIndex]?.fieldType;
        const subListColObj = {
          originalDocumentId: obj.listConfigDetails.docId,
          activeDocumentId: obj.listConfigDetails.docId,
          subType: primaryColumnType,
          colName: obj.listConfigDetails.primaryColName,
          parentDocId: activeDocumentId,
          primaryColId: obj.listConfigDetails.primaryColId,
          parentDocName: activeDocumentName,
          primaryColName: obj.listConfigDetails.primaryColName,
          listColIndex: index, // needed to add default mapping
          listColHeaderObj: updatedHeader, // needed to add default mapping
          addDefaultMapping: isEmpty(
            // check to add default mapping
            obj?.listConfigDetails?.previewConfig?.mappedValues,
          ),
          primaryColumnIndex: primaryColumnIndex,
          primaryColumnHeaderObj: currentHeaderData[primaryColumnIndex],
        };
        dispatch(addTableColumnForSublistAndHandleMapping(subListColObj));
      }

      const subListPrimaryColId =
        currentHeaderObj?.listConfig?.subListPrimaryColId;
      const shouldReCalculateSummary =
        !isEmpty(newColObj.summaryConfig) &&
        !isEqual(currentHeaderObj.summaryConfig, newColObj?.summaryConfig) &&
        subListPrimaryColId;

      if (shouldReCalculateSummary) {
        reCalculateSummaryCF(
          obj.listConfigDetails.docId,
          home.originalDocumentId,
          obj.listConfigDetails.primaryColId,
          subListPrimaryColId,
          newColObj.summaryConfig,
        );
      }

      // Summary Config Update
      setChildColumnForConfig(
        newColObj?.summaryConfig,
        home.originalDocumentId,
        obj.listConfigDetails?.docId,
        currentHeaderObj.id,
      );

      Promise.all(promises.map((fn) => fn()));
    } catch (error) {
      captureError(error);
    }
  };

const checkAndUpdateFooterAsHeaderFields =
  (updatedFields) => (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentMeta, originalDocumentId, files, activeFileIndex},
        auth: {
          user: {uid},
        },
      } = getState();

      if (activeDocumentMeta.fileData?.footerAsHeader) {
        const updateObj = {
          fileData: Object.assign({}, activeDocumentMeta.fileData, {
            footerAsHeader: Object.assign(
              {},
              activeDocumentMeta.fileData.footerAsHeader,
              updatedFields, //fieldType or name
            ),
          }),
        };
        const updatedMeta = Object.assign({}, activeDocumentMeta, updateObj);
        const filesArr = files.slice();
        filesArr.splice(activeFileIndex, 1, {
          documentId: originalDocumentId,
          documentMeta: updatedMeta,
        });
        dispatch({
          type: HOME_ACTION.UPDATE_DOCUMENT_META,
          payload: updatedMeta,
        });
        dispatch({
          type: HOME_ACTION.LOAD_FILES,
          payload: {files: filesArr},
        });
        reloadVisibleFiles(dispatch, getState);
        setFileDetails(
          uid,
          originalDocumentId,
          Object.assign({}, {collab: updatedMeta.collab}, updateObj),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

/**
 * fetches all the rows data which are
 * still not fetched from the server
 */
const checkAndFetchAllRows = () => async (dispatch, getState) => {
  const {
    table: {tableData, areAllRowsFetched},
    home: {activeDocumentId},
  } = getState();
  if (!areAllRowsFetched && activeDocumentId) {
    const docs = await DocumentsMethods.getAllRowsData(
      activeDocumentId,
      tableData[tableData.length - 1]?.index,
      true,
    );
    dispatch({
      type: TABLE_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE,
      payload: true,
    });
    const rowsData = {docs};
    if (rowsData.docs.length) {
      const payload = {
        tableData: tableActionHelper.processRowsData(rowsData),
      };
      dispatch({
        type: TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA,
        payload,
      });
      return payload.tableData;
    }
  }
  return Promise.resolve(tableData);
};

/**
 * remove formula from a formula column
 */
const deleteFormulaFromColumn =
  (...args) =>
  async (dispatch, getState) => {
    const [obj] = args;
    try {
      const {
        table,
        auth,
        home: {activeDocumentId, activeDocumentMeta},
      } = getState();

      const headerData = table.headerData.slice();
      const tableData = table.tableData.slice();
      const columnObj = headerData[obj.index];
      const columnId = columnObj?.id;
      const footerData = cloneDeep(table.footerData);
      const isDashboardActiveOnColumn = !isEmpty(
        activeDocumentMeta.dashboards?.[columnId],
      );
      const rowsFirestoreUpdateIndexArr = [];
      const newColObj = {
        fieldType:
          columnObj?.subType === FIELD_TYPE_ID.RUPEE
            ? FIELD_TYPE_ID.RUPEE
            : FIELD_TYPE_ID.NUMBER,
        id: columnId,
        val: columnObj?.val,
      };
      let updateFooterData = footerData;
      let updatedTableData = tableData.slice();
      const updatedHeaderData = headerData.slice();
      if (columnId in footerData) {
        //if footer is active for this column
        if (
          isDashboardActiveOnColumn &&
          checkIfColumnHasWithoutSplitByDashboard(
            columnId,
            activeDocumentMeta.dashboards,
          )
        ) {
          //if isWithoutSplitDashboardActiveOnThisColumn
          updateFooterData = footerData;
          updateFooterData[columnId].isVisible = false;
          updateFooterData[columnId].type = null;
        } else {
          updateFooterData = omit(footerData, [columnId]);
        }
      }

      const isDateTimeDiff = [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(
        columnObj?.subType,
      );
      const prevRowRefEqnArr = columnObj?.hasPrevRowRef
        ? table.headerMappedValues.prevRowRefEqnArr.filter(
            (col) => `${col.colId}` === `${columnId}`,
          )
        : [];
      const hasPrevRowRef = prevRowRefEqnArr.length > 0;
      if (isDateTimeDiff || hasPrevRowRef) {
        if (!table.areAllRowsFetched) {
          await dispatch(checkAndFetchAllRows());
          return dispatch(deleteFormulaFromColumn(...args));
        }
        updatedTableData = [];
        tableData.forEach((rowObj, index) => {
          let updatedRowObj = Object.assign({}, rowObj);
          let isChanged = false;
          if (isDateTimeDiff) {
            if (columnId in rowObj) {
              updatedRowObj = Object.assign({}, updatedRowObj, {
                [columnId]: {
                  val: formatDateAndTimeAsText(
                    updatedRowObj[columnId],
                    columnObj?.subType,
                    auth.userPref,
                  ),
                },
              });
              isChanged = true;
            }
          } else if (hasPrevRowRef) {
            [updatedRowObj] = solvePrevRowRefEqnForRow(
              updatedRowObj,
              prevRowRefEqnArr,
              updatedTableData[index - 1],
            );
            const processVal = (val) => (isNil(val) ? '' : val);
            isChanged = !isEqual(
              processVal(updatedRowObj[columnId]?.val),
              processVal(rowObj[columnId]?.val),
            );
          }
          isChanged && rowsFirestoreUpdateIndexArr.push(index);
          updatedTableData.push(updatedRowObj);
        });
        isDateTimeDiff && (newColObj.fieldType = FIELD_TYPE_ID.TEXT);
      }
      updatedHeaderData.splice(obj.index, 1, newColObj);

      dispatch({
        type: TABLE_ACTION.DELETE_COL_OR_FORMULA,
        payload: {
          updatedTableData,
          updatedHeaderData,
          updateFooterData,
          extra: {},
          fileObj: table.fileObj,
          undoDashboardUpdateObj: isDashboardActiveOnColumn
            ? {
                fieldType: columnObj?.fieldType,
                subType: columnObj?.subType,
              }
            : {},
          redoDashboardUpdateObj: isDashboardActiveOnColumn
            ? {
                fieldType: newColObj.fieldType,
                subType: null,
              }
            : {},
          dashboardColumnId: columnId,
          undoColumnIdForUnique: [columnId],
          rowsFirestoreUpdateIndexArr,
        },
      });

      if (rowsFirestoreUpdateIndexArr.length) {
        //update both row and headerdata
        DocumentsMethods.updateMultipleRows(
          activeDocumentId,
          rowsFirestoreUpdateIndexArr.map((index) => updatedTableData[index]),
          {
            headerData: updatedHeaderData,
            footerData: updateFooterData,
          },
        );
      } else {
        //update headerdata only
        DocumentsMethods.updateHeaderDataAtIndex(
          activeDocumentId,
          obj.index,
          newColObj,
          {footerData: updateFooterData},
        );
      }

      tableActionHelper.checkAndUpdateEntryOnlyData({getState});
      if (isDashboardActiveOnColumn) {
        const updateObj = {
          [`pages.${activeDocumentId}.fieldType`]: newColObj.fieldType,
          [`pages.${activeDocumentId}.subType`]: null,
        };
        updateAllDashboardsProperty(
          {[columnId]: activeDocumentMeta.dashboards[columnId]},
          updateObj,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const addPDFData = (obj) => (dispatch) => {
  if (obj.position === 'ABOVE') {
    dispatch({
      type: TABLE_ACTION.ADD_PDF_HEADER,
      payload: {data: obj.data},
    });
  } else {
    dispatch({
      type: TABLE_ACTION.ADD_PDF_FOOTER,
      payload: {data: obj.data},
    });
  }
};

/**
 * edit an existing row or add a new row at the end of the table
 * !IMP : Action used for both data edit and adding row/cell based styling
 * !      and entry from quick entry mode
 */
const editRow =
  (
    obj,
    index,
    extra = {},
    doNotPushToUndoStack = false,
    extraOptions = {},
    isEdit = true,
  ) =>
  async (dispatch, getState) => {
    try {
      const {checkPendingAutomation = true} = extra ?? {};
      //footer needs old object as well, so it should be called before the row is updated
      /* For Undo Functionality Memorize Previous Footer */
      const {table, searchFilter} = getState();
      const isSearchFilterActive = searchFilter.isActive;
      let addNewRow = false;
      if (!isSearchFilterActive) {
        addNewRow = index === table.tableData.length;
      }
      if (!isNil(index) && (addNewRow ? true : !isNil(obj?.rowId))) {
        const {
          home: {activeDocumentMeta, originalDocumentId, activeDocumentId},
          auth,
          tableLinks: {parentRowMeta: parentRowMetaObj},
        } = getState();

        obj = getEqnCalculatedData(obj);
        let stopEditProcess = false;
        let editObj = obj;

        const isFirstDataAdded = !(
          'firstAddedByContact' in Object.assign({}, obj?.rowProperties)
        );

        if (isEdit !== false && !extra.isCollabUndoRedoHandler) {
          editObj['rowProperties'] = getNewRowPropertiesObject(
            obj?.rowProperties,
            isFirstDataAdded,
          );
        }

        const extraAutomationActions = checkPendingAutomation
          ? dispatch(
              setPendingAutomation(obj, index, extra.currentlyModifiedColId),
            )
          : {extraUndoActions: [], extraRedoActions: []};

        const prevSplitByCalculation = cloneDeep(table.splitByCalculation);
        if (!auth.userPref.isFirstDataAddded) {
          //this is called only once for a user
          dispatch(setUserPref({isFirstDataAddded: true}));
          logAnalyticsEvent('FIRST_TIME_DATA_ADDED');
        }
        const currentTableData = table.tableData.slice();
        let prevRowDataObj = getRowObjAtIndex(
          table.tableData,
          searchFilter,
          index,
        );
        const prevFooterData = cloneDeep(table.footerData);
        const updatedFooterData = reCalculateFooter(editObj, prevRowDataObj);
        if (
          !doNotPushToUndoStack &&
          isEmpty(omit(prevRowDataObj, TABLE_LIMITS.REQUIRED_ROW_KEYS))
        ) {
          const templateAnalyticsName =
            activeDocumentMeta.fileData?.templateAnalyticsName;
          const sharingDeatils = {};
          if (!isEmpty(activeDocumentMeta.collab)) {
            sharingDeatils.type = 'shared';
            sharingDeatils.mode = activeDocumentMeta.collab.permission;
          }
          //add page id
          logAnalyticsEvent('DATA_ADDED_IN_ROW', {
            rowNumber: index + 1,
            fileType: templateAnalyticsName ? 'Template' : 'Empty',
            ...sharingDeatils,
            docId: originalDocumentId,
          });
          if (index + 1 === 10) {
            logAnalyticsEvent('DATA_ADDED_10_ROW', {docId: originalDocumentId});
          }
          first10RowAddEvent();
        }

        /**
         * For TableLinks Undo/Redo
         */

        const {updatedParentMeta} = extraOptions ?? {};
        if (!isEmpty(updatedParentMeta)) {
          for (const childColId in updatedParentMeta) {
            editObj[childColId] = {
              ...editObj[childColId],
              parentMeta: updatedParentMeta[childColId],
            };
          }
        }

        // New Child Links code
        const previousObj = omit(
          prevRowDataObj,
          TABLE_LIMITS.REQUIRED_ROW_KEYS,
        );
        const previousParentMetaObj = {};
        if (!isEmpty(previousObj)) {
          for (const childColId in previousObj) {
            if (
              !isEmpty(previousObj[childColId]) &&
              'parentMeta' in previousObj[childColId] &&
              !isEmpty(updatedParentMeta) &&
              Object.keys(updatedParentMeta).includes(childColId)
            ) {
              Object.assign(previousParentMetaObj, {
                [childColId]: previousObj[childColId].parentMeta,
              });
            }
          }
        }

        if (!isEmpty(updatedParentMeta)) {
          tableActionHelper.updateChildLinks(
            updatedParentMeta,
            activeDocumentId,
            previousParentMetaObj,
          );
        }

        // const {addedParentRowMetaData} = extraOptions ?? {};
        // const {
        //   extraRedoActions: tableLinkRedoActions,
        //   extraUndoActions: tableLinkUndoActions,
        // } = tableLinkUtils.getSubmitUndoRedoActions(
        //   activeDocumentId,
        //   addedParentRowMetaData,
        //   parentRowMetaObj,
        // );

        const extraUndoActions = [
          ...(extraOptions?.extraUndoActions
            ? extraOptions.extraUndoActions
            : []),
          ...(extraAutomationActions?.extraUndoActions
            ? extraAutomationActions.extraUndoActions
            : []),

          // ...tableLinkUndoActions,
        ];

        const extraRedoActions = [
          ...(extraOptions?.extraRedoActions
            ? extraOptions.extraRedoActions
            : []),
          ...(extraAutomationActions?.extraRedoActions
            ? extraAutomationActions.extraRedoActions
            : []),
          // ...tableLinkRedoActions,
        ];

        const editParentRowMeta =
          extra?.currentRowValue?.[extra?.currentlyModifiedColId]
            ?.parentRowMeta;
        if (editParentRowMeta) {
          const {
            extraUndoActions: childLinkUndoActions,
            extraRedoActions: childLinkRedoActions,
          } = dispatch(
            deleteChildLinks([
              {
                [extra?.currentlyModifiedColId]: {
                  parentRowMeta: editParentRowMeta,
                },
                rowId: obj.rowId,
              },
            ]),
          );
          // extraUndoActions = [...extraUndoActions, ...childLinkUndoActions];
          // extraRedoActions = [...extraRedoActions, ...childLinkRedoActions];

          // if (isEdit && isEmpty(obj[extra?.currentlyModifiedColId])) {
          //   const removeParentRowMetaObj = {
          //     [editParentRowMeta]: parentRowMetaObj['meta'][editParentRowMeta],
          //   };
          //   removeParentRowMetas(removeParentRowMetaObj, activeDocumentId);
          //   const {
          //     extraRedoActions: tableLinkParentRowMetaRedoActions,
          //     extraUndoActions: tableLinkParentRowMetaUndoActions,
          //   } = getTableUndoRedoActions(
          //     TABLE_UNDO_REDO_TYPES.MULTIPLE_ROW_COL_DELETE,
          //     {
          //       activeDocumentId: activeDocumentId,
          //       addedParentRowMetaData: removeParentRowMetaObj,
          //     },
          //   );
          //   extraUndoActions = [
          //     ...extraUndoActions,
          //     ...tableLinkParentRowMetaUndoActions,
          //   ];
          //   extraRedoActions = [
          //     ...extraRedoActions,
          //     ...tableLinkParentRowMetaRedoActions,
          //   ];
          // }
        }

        const headerData = tableActionHelper.shouldUseOriginalHeaderData()
          ? table.originalHeaderData
          : table.headerData;
        for (let i = 0; i < headerData.length; i++) {
          if (
            headerData[i]?.fieldType === FIELD_TYPE_ID.DATE &&
            headerData[i]?.columnProperties &&
            headerData[i]?.columnProperties[
              COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_ENTRY
            ] &&
            !extra?.eraseCell &&
            extra.colId != headerData[i]?.id
          ) {
            const colId = headerData[i]?.id || null;
            if (
              colId &&
              (isNil(prevRowDataObj?.[headerData[i]?.id]?.val) ||
                prevRowDataObj?.[headerData[i]?.id]?.val === '')
            ) {
              const cellObj = getDateTimeCellObj(
                null,
                false,
                true,
                headerData[i].dateFormat,
              );
              const value = cellObj.val;
              if (headerData[i]?.columnProperties?.['UNIQUE_VALUES'] === true) {
                if (!table.uniqueColumnData?.[colId]?.[`${value}`]) {
                  editObj[colId] = cellObj;
                  dispatch(addUniqueValue(headerData[i], value, index));
                } else {
                  stopEditProcess = true;
                  const message = getLocalText(
                    auth.userPref,
                    `Cannot auto-fill date in COLUMN_NAME column, found duplicate value`,
                  ).replace('COLUMN_NAME', headerData[i]?.val);
                  ShowToast(`${message}`, auth.userPref);
                  break;
                }
              } else {
                editObj[colId] = cellObj;
              }
            } else {
              editObj[colId] = {
                ...prevRowDataObj?.[headerData[i]?.id],
              };
            }
          } else if (
            headerData[i]?.fieldType === FIELD_TYPE_ID.TIME &&
            headerData[i]?.columnProperties &&
            headerData[i]?.columnProperties[
              COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_ENTRY
            ] &&
            !extra?.eraseCell &&
            extra.colId != headerData[i]?.id
          ) {
            const colId = headerData[i]?.id || null;
            if (colId && isNil(prevRowDataObj?.[headerData[i]?.id]?.val)) {
              const cellObj = getDateTimeCellObj(
                null,
                true,
                true,
                headerData[i].dateFormat,
              );
              const value = cellObj.val;
              if (headerData[i]?.columnProperties['UNIQUE_VALUES'] === true) {
                const time = `${getPrintTime(cellObj.val)}`.toLowerCase();
                if (!table.uniqueColumnData?.[colId]?.[time]) {
                  editObj[colId] = cellObj;
                  dispatch(addUniqueValue(headerData[i], value, index));
                } else {
                  stopEditProcess = true;
                  const message = getLocalText(
                    auth.userPref,
                    `Cannot auto-fill time in COLUMN_NAME column, found duplicate value`,
                  ).replace('COLUMN_NAME', headerData[i]?.val);
                  ShowToast(`${message}`, auth.userPref);
                  break;
                }
              } else {
                editObj[colId] = cellObj;
              }
            } else {
              editObj[colId] = {
                ...prevRowDataObj?.[headerData[i]?.id],
              };
            }
          }
        }

        let isQuickEntryIndexGreater = false;

        if (addNewRow) {
          let lastIndexInTable =
            currentTableData[currentTableData.length - 1]?.index;
          if (!table.areAllRowsFetched) {
            const lastDocRefArr = await DocumentsMethods.getRowsData({
              docId: activeDocumentId,
              isDescOrder: true,
              isLimited: true,
              limit: 1,
            });
            lastIndexInTable =
              lastDocRefArr?.docs?.[0]?.data?.()?.index ?? 9999999;
            isQuickEntryIndexGreater = true;
          }
          [editObj] = tableActionHelper.prepareTableDataForFirestore(
            [editObj],
            lastIndexInTable,
          );
          prevRowDataObj = pick(editObj, TABLE_LIMITS.REQUIRED_ROW_KEYS);
        }

        if (stopEditProcess) {
          return false;
        }
        let actualIndex = index;
        if (isSearchFilterActive) {
          dispatch(updateSearchFilterTableData([editObj]));
          actualIndex = table.tableData.findIndex(
            (rowObj) => rowObj.rowId === obj.rowId,
          );
        }

        if (!isNil(actualIndex) && actualIndex >= 0) {
          dispatch({
            type: TABLE_ACTION.EDIT_ROW,
            payload: {
              updatedRowData: editObj,
              index: actualIndex,
              prevFooterData,
              extra,
              prevRowDataObj,
              extraUndoActions,
              extraRedoActions,
              doNotPushToUndoStack:
                doNotPushToUndoStack || isQuickEntryIndexGreater,
              updatedFooterData,
              prevSplitByCalculation,
              isQuickEntryIndexGreater,
            },
          });
        }

        const UpdateMethod = addNewRow
          ? DocumentsMethods.addMultipleRows
          : DocumentsMethods.updateMultipleRows;
        return UpdateMethod(activeDocumentId, [editObj], {
          footerData: updatedFooterData,
        });
      } else {
        const {home, auth} = getState();
        const uid = auth.user.uid;
        const activeDocId = home.activeDocumentId;
        captureInfo({
          uid,
          activeDocId,
          params: JSON.stringify({
            obj,
            index,
            extra,
            doNotPushToUndoStack,
            extraOptions,
          }),
        });
        captureError(new Error('Index is empty'), true);
        return ShowToast(
          'Something went wrong, please try again',
          auth.userPref,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const removeParentRowMetas = (parentRowMetaObj, activeDocumentId) => {
  /**
   * @param : parentRowMetaObj = Object with "key" as "<parentRowMeta>" and
   *          "value" as Object of "parentDocId", "parentRowId", "parentColId".
   */
  if (!isEmpty(parentRowMetaObj)) {
    extraActionsHandler([
      {
        updateObjType: 'object',
        elementType: 'object',
        element: {
          ...parentRowMetaObj,
        },
        action: 'REMOVE',
        collection: 'userDocuments',
        path: `${activeDocumentId}/parentRowMeta/mapping`,
        updatePath: `meta`,
      },
    ]);
  }
};

/**
 * delete multiple rows at once
 */
const deleteRow = (rowIndexes) => (dispatch, getState) => {
  try {
    /* For Undo Functionality Memorize Previous Footer */
    const {
      table,
      home,
      auth,
      tableLinks: {parentRowMeta},
      automation: {pendingAutomations},
    } = getState();

    rowIndexes = (() => {
      const arr = new LioArray();
      rowIndexes.forEach((index) => {
        arr.binaryInsert(Number(index), (b, a) => (a < b ? -1 : a > b ? 1 : 0));
      });
      return [...arr]; //sorted in descending order, so that splice can be used
    })();

    /** Undo/Redo Extra Actions */
    let extraUndoActions = [];
    let extraRedoActions = [];

    const prevSplitByCalculation = cloneDeep(table.splitByCalculation);
    const prevFooterData = Object.assign({}, cloneDeep(table.footerData));
    const prevTableData = table.tableData.slice();
    const tableData = table.tableData.slice();
    const deletedRowIdsArr = [];
    const deletedTableRows = [];

    const headerData = table.headerData.slice();

    const uid = auth.user?.uid;
    if (!uid) {
      return;
    }
    let count = 0;
    const activeDocId = home.activeDocumentId;
    const originalDocumentId = home.originalDocumentId;
    const emptyRowIndexes = table.emptyRowIndexes.slice();
    const deletedRowIds = [];

    const uniqueColumnData = cloneDeep(table.uniqueColumnData);
    // PARENT ROW META FOR UNDO REDO
    let parentRowMetaObj = {};
    const getParentRowMetaArrayFromRowData = (rowData, parentRowMetaData) => {
      const arr = {};
      if (!isEmpty(rowData) && !isEmpty(parentRowMetaData?.meta)) {
        Object.values(rowData).forEach((item) => {
          if (
            typeof item === 'object' &&
            checkIfPlainText(item?.parentRowMeta) &&
            parentRowMetaData?.meta?.[item.parentRowMeta]
          ) {
            arr[item.parentRowMeta] =
              parentRowMetaData.meta[item.parentRowMeta];
          }
        });
      }
      return arr;
    };

    const removeRows = [];
    rowIndexes.forEach((rowIndex) => {
      const [data] = tableData.splice(rowIndex, 1);
      if (data?.rowId) {
        deletedRowIdsArr.push(data.rowId);
        deletedTableRows.push(rowIndex);
        removeRows.push(data);
        ++count;

        /** Table Linking */
        parentRowMetaObj = Object.assign(
          {},
          getParentRowMetaArrayFromRowData(data, parentRowMeta),
          parentRowMetaObj,
        );
      }
    });
    const updatedEmptyRowArr = tableActionHelper.updateEmptyRowIndexes(
      emptyRowIndexes,
      deletedRowIdsArr,
      false,
    );
    /** Child Links */
    const {
      extraRedoActions: childLinkRedoActions,
      extraUndoActions: childLinkUndoActions,
    } = dispatch(deleteChildLinks(removeRows));
    extraUndoActions = [...extraUndoActions, ...childLinkUndoActions];
    extraRedoActions = [...extraRedoActions, ...childLinkRedoActions];

    const deletedTaskIds = [];

    headerData.forEach((obj) => {
      if (obj.fieldType === FIELD_TYPE_ID.REMINDER) {
        deletedTableRows.forEach((index) => {
          const currentRowObj = prevTableData[index];
          if (currentRowObj[obj.id] && currentRowObj[obj.id].reminderId) {
            const reminderId = currentRowObj[obj.id].reminderId;
            if (ENV) {
              const {nativeBridgeFunctions} = require('../imports');
              nativeBridgeFunctions('DELETE_REMINDER_ANDROID', [
                uid,
                reminderId,
              ]);
            } else {
              const {
                deleteReminderFirestore,
              } = require('./actionHelpers/reminderActions/reminderAction');
              deleteReminderFirestore(uid, reminderId);
            }
          }
        });
      } else if (obj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
        deletedTableRows.forEach((index) => {
          const taskId = prevTableData[index]?.[obj?.id]?.val?.taskId;
          if (taskId?.length) {
            deletedTaskIds.push(taskId);
            modifyTaskActiveState(home.activeDocumentId, taskId, false);
          }
        });
      }
      if (!isEmpty(uniqueColumnData) && uniqueColumnData[obj?.id]) {
        rowIndexes.forEach((rowIndex) => {
          dispatch(addUniqueValue(obj, '', rowIndex));
        });
      }
    });

    let updatedFooterData = Object.assign({}, table.footerData);

    if (
      !isEmpty(prevFooterData) ||
      !isEmpty(home.activeDocumentMeta.dashboards) //req for dashboards
    ) {
      //calculate total for each column
      deletedTableRows.forEach((index) => {
        updatedFooterData = reCalculateFooter({}, prevTableData[index], {
          doNotUpdateDashboard: true,
          footerToUse: updatedFooterData,
        });
      });
      checkAndUpdateDashboard(
        home,
        headerData,
        table.splitByCalculation,
        prevFooterData,
        updatedFooterData,
        deletedTableRows.map((rowIndex) => {
          return {
            prev: prevTableData[rowIndex],
          };
        }),
      );
    }

    if (Object.keys(pendingAutomations ?? {}).length > 0) {
      dispatch(deletePendingAutomations(deletedRowIdsArr));
    }

    /** Table Link Calls */
    removeParentRowMetas(parentRowMetaObj, activeDocId);
    const {
      extraRedoActions: tableLinkRedoActions,
      extraUndoActions: tableLinkUndoActions,
    } = getTableUndoRedoActions(TABLE_UNDO_REDO_TYPES.MULTIPLE_ROW_COL_DELETE, {
      activeDocumentId: activeDocId,
      addedParentRowMetaData: parentRowMetaObj,
    });
    extraUndoActions = [...extraUndoActions, ...tableLinkUndoActions];
    extraRedoActions = [...extraRedoActions, ...tableLinkRedoActions];

    dispatch({
      type: TABLE_ACTION.DELETE_ROW,
      payload: {
        tableData,
        prevTableData,
        prevFooterData,
        updatedFooterData,
        extraUndoActions,
        extraRedoActions,
        updatedEmptyRowArr,
        prevSplitByCalculation,
        deletedTaskIds,
        deletedRowIdsArr,
        updatedFileObj: Object.assign({}, table.fileObj, {
          ...(deletedTaskIds.length
            ? {
                tasksCount: table.fileObj.tasksCount
                  ? table.fileObj.tasksCount - deletedTaskIds.length
                  : 0,
              }
            : {}),
        }),
      },
    });

    DocumentsMethods.deleteOrRestoreMultipleRows(
      activeDocId,
      deletedRowIdsArr,
      {footerData: updatedFooterData},
    ); //delete
    logAnalyticsEvent('ROW_DELETE', {
      docId: originalDocumentId,
      count,
    });
  } catch (error) {
    captureError(error);
  }
};

/**
 * sort table based on a column
 */
const sortCol =
  (...args) =>
  async (dispatch, getState) => {
    const [obj] = args;
    try {
      const {table, home, searchFilter} = getState();

      // halting execution of function if
      // search-filter is active
      if (searchFilter.isActive) {
        return ShowToast('Please disable filter and search to sort your data.');
      }

      if (!table.areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(sortCol(...args));
      }

      const tableData = table.tableData.slice();
      const {colId, colType, sortType} = obj;
      const sortedArr = orderBy(
        tableData,
        [
          (item) => {
            if (isEmpty(item)) {
              //empty rows on last
              return 0;
            }
            /*	
           Empty cell of sorting column	
           on last	
          */
            if (isNil(item[colId]?.val) || item[colId].val === '') {
              return 1; //low priority
            } else {
              return 2; //high priority
            }
          },
          (item) => {
            if (NUMBER_SORT_FIELD.includes(colType)) {
              return Number(item?.[colId]?.val);
            }
            return item?.[colId]?.val;
          },
        ],
        ['desc', sortType],
      );

      const updatedTableData =
        tableActionHelper.prepareTableDataForFirestore(sortedArr);
      dispatch({
        type: TABLE_ACTION.SORT_COL,
        payload: {updatedTableData},
      });

      logAnalyticsEvent('COLUMN_SORTED', {
        typeOfSort: obj.sortType,
        columnType: obj.colType,
        docId: home.originalDocumentId,
        colId,
        noOfRows: tableData.length,
        noOfCols: table.headerData.length,
      });

      return DocumentsMethods.updateMultipleRows(
        home.activeDocumentId,
        tableActionHelper.prepareTableDataForFirestore(sortedArr),
        null,
        true,
        false,
        true,
      );
    } catch (error) {
      captureError(error);
    }
  };

const deleteCol =
  (...args) =>
  async (dispatch, getState) => {
    const [objArr] = args;
    try {
      const {
        table,
        home,
        auth,
        tableLinks: {parentRowMeta},
      } = getState();
      const uid = auth.user?.uid;
      if (!uid) {
        return;
      }

      if (!table.areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(deleteCol(...args));
      }

      const prevSplitByCalculation = cloneDeep(table.splitByCalculation);
      const updatedHeaderData = table.headerData.slice();
      const tableData = table.tableData.slice();
      let footerData = cloneDeep(Object.assign({}, table.footerData));
      let fileObj = cloneDeep(Object.assign({}, table.fileObj));
      const extra = {deletedColRef: []};
      const activeDocId = home.activeDocumentId;
      let mandatoryColumnObject = cloneDeep(
        Object.assign({}, table.headerMappedValues?.mandatoryColumnObject),
      );
      let uniqueColumnData = cloneDeep(
        Object.assign({}, table.uniqueColumnData),
      );

      const summaryColsOfDeletingListCols = [];
      const summaryOperations = Object.keys(SUMMARY_OPERATION_TYPE);
      objArr.forEach((item) => {
        const colData = table.headerData[item.index];
        if (colData?.fieldType === FIELD_TYPE_ID.LIST) {
          if (!isEmpty(colData.summaryConfig)) {
            Object.keys(colData.summaryConfig).forEach((colId) =>
              summaryOperations.forEach((operation) => {
                const summaryColId =
                  colData.summaryConfig[colId][operation]?.colId;
                if (summaryColId) {
                  const index = table.headerData.findIndex(
                    (colObj) => colObj.id == summaryColId,
                  );
                  if (index > -1) {
                    summaryColsOfDeletingListCols.push({
                      index,
                      colId: summaryColId,
                      isListDelete: true,
                    });
                  }
                }
              }),
            );
          }
        }
      });
      if (summaryColsOfDeletingListCols.length) {
        objArr.push(...summaryColsOfDeletingListCols);
      }

      let updatedTableData = tableData.slice();
      let parentRowMetaObj = {};
      let promisesToResolve = [];
      let undoActions = [];
      let redoActions = [];
      let isAssignTaskColumn = false;
      for (let j = 0; j < objArr.length; j++) {
        const colIndex = objArr[j].index;
        const colData = table.headerData[colIndex];

        if (
          objArr[j].isListDelete !== true &&
          colData.columnProperties?.[
            COLUMN_PROPERTY_KEYS.NON_EDITABLE_HEADER
          ] === true
        ) {
          continue;
        }

        // prettier-ignore
        if (!isAssignTaskColumn) {
          isAssignTaskColumn =
          colData.fieldType === FIELD_TYPE_ID.ASSIGN_TASK;
          fileObj = isAssignTaskColumn
            ? Object.assign({}, fileObj, {tasksCount: 0})
            : fileObj;
        }
        logAnalyticsEvent('COLUMN_DELETE', {
          columnType: colData?.fieldType,
          docId: home.originalDocumentId,
          colId: colData?.id,
          colName: colData?.val,
        });
        const fieldType = colData?.fieldType;
        const colId = objArr[j].colId;
        if (mandatoryColumnObject[colId]) {
          mandatoryColumnObject = omit(mandatoryColumnObject, [colId]);
        }
        if (uniqueColumnData[colId]) {
          uniqueColumnData = omit(uniqueColumnData, [colId]);
          DocumentsMethods.deleteUniqueValuesDataForColumn(activeDocId, colId);
        }
        if (fieldType === FIELD_TYPE_ID.REMINDER) {
          for (
            let tableIndex = 0;
            tableIndex < tableData.length;
            tableIndex++
          ) {
            if (
              tableData[tableIndex][colId] &&
              tableData[tableIndex][colId].reminderId
            ) {
              const reminderId = tableData[tableIndex][colId].reminderId;
              if (ENV) {
                const {nativeBridgeFunctions} = require('../imports');
                nativeBridgeFunctions('DELETE_REMINDER_ANDROID', [
                  uid,
                  reminderId,
                ]);
              } else {
                const {
                  deleteReminderFirestore,
                } = require('./actionHelpers/reminderActions/reminderAction');
                deleteReminderFirestore(uid, reminderId);
              }
            }
          }
        } else if (fieldType === FIELD_TYPE_ID.TABLE) {
          if (!isEmpty(parentRowMeta?.meta)) {
            for (
              let tableIndex = 0;
              tableIndex < tableData.length;
              tableIndex++
            ) {
              const parentRowMetaId =
                tableData?.[tableIndex]?.[colId]?.parentRowMeta;

              if (parentRowMetaId && parentRowMeta?.meta?.[parentRowMetaId]) {
                parentRowMetaObj = Object.assign(
                  {},
                  {
                    [parentRowMetaId]: parentRowMeta?.meta?.[parentRowMetaId],
                  },
                  parentRowMetaObj,
                );
              }
            }
          }

          /** Table Linking */

          const {
            undoActions: handlerUndoActions,
            redoActions: handlerRedoActions,
            promises: handlerPromises,
          } = dispatch(deleteColumnTableLinkHandler(colData, colIndex));

          promisesToResolve = [...promisesToResolve, ...handlerPromises];
          undoActions = [...undoActions, ...handlerUndoActions];
          redoActions = [...redoActions, ...handlerRedoActions];
        }

        pullAt(updatedHeaderData, [objArr[j].index - j]);

        if (updatedHeaderData.length > 0) {
          const {eqnArrs} = mapHeaderData(updatedHeaderData);
          if (eqnArrs.length) {
            const {updateTableData: updateTableArr} = tableDataHandler(
              tableData,
              {
                evaluateEqnAndDependentColsOptions: {
                  lastId: colId,
                  obj: {},
                  eqnArrs,
                },
                childLinksOptions: {
                  colObj: colData,
                },
              },
            );
            updatedTableData = updateTableArr;
          }
        }

        if (colId in footerData) {
          //if footer is active for this column
          footerData = omit(footerData, [colId]);
        }

        fileObj = omit(fileObj, [colId]);
      }

      if (!updatedHeaderData.length) {
        return ShowToast(
          'Document must have atleast one column.',
          auth.userPref,
        );
      }

      /** TABLE DATA HANDLER */
      const {childLinkRemoveArr} = tableDataHandler(tableData, {
        childLinksOptions: {
          columnRemoveArray: objArr.map(
            (item) => table.headerData[item.index] ?? {},
          ),
        },
      });

      /** Table Linking */
      removeParentRowMetas(parentRowMetaObj, activeDocId);
      Promise.all(promisesToResolve.map((fn) => fn()));

      const {
        extraUndoActions: tableLinkUndoActions,
        extraRedoActions: tableLinkRedoActions,
      } = getTableUndoRedoActions(
        TABLE_UNDO_REDO_TYPES.MULTIPLE_ROW_COL_DELETE,
        {
          activeDocumentId: activeDocId,
          addedParentRowMetaData: parentRowMetaObj,
        },
      );

      /** Undo/Redo */
      let extraUndoActions = [...tableLinkUndoActions, ...undoActions];
      let extraRedoActions = [...tableLinkRedoActions, ...redoActions];

      /** Child Links */
      const {
        extraUndoActions: childLinkUndoActions,
        extraRedoActions: childLinkRedoActions,
      } = dispatch(deleteChildLinks(childLinkRemoveArr));

      extraUndoActions = [...extraUndoActions, ...childLinkUndoActions];
      extraRedoActions = [...extraRedoActions, ...childLinkRedoActions];

      const rowsFirestoreUpdateIndexArr = [];
      const rowUpdateArr = [];
      const dashboardsRowDataChangeArr = [];
      table.tableData.forEach((row, index) => {
        if (!isEqual(row, updatedTableData[index])) {
          rowsFirestoreUpdateIndexArr.push(index);
          rowUpdateArr.push(updatedTableData[index]);
          dashboardsRowDataChangeArr.push({
            prev: row,
            updated: updatedTableData[index],
          });
        }
      });

      dispatch({
        type: TABLE_ACTION.DELETE_COL_OR_FORMULA,
        payload: {
          updatedTableData,
          updatedHeaderData: updatedHeaderData,
          updateFooterData: footerData,
          extra,
          extraUndoActions,
          extraRedoActions,
          fileObj,
          prevSplitByCalculation,
          mandatoryColumnObject,
          rowsFirestoreUpdateIndexArr,
          uniqueColumnData,
          undoColumnIdForUnique: objArr.map((d) => d.colId),
        },
      });

      DocumentsMethods.updateMultipleRows(activeDocId, rowUpdateArr, {
        headerData: updatedHeaderData,
        footerData,
        fileObj,
      });
      tableActionHelper.checkAndUpdateEntryOnlyData({getState, fileObj});

      const frozenColumnIds = table.frozenColumnIds;
      if (frozenColumnIds?.length) {
        const columnIdsToDelete = objArr.map((d) => d.colId);
        const frozenIdsToDelete = intersection(
          frozenColumnIds,
          columnIdsToDelete,
        );
        if (frozenColumnIds.length === frozenIdsToDelete.length) {
          dispatch(unfreezeDocumentColumns());
        }
      }
      checkAndUpdateDashboard(
        home,
        updatedHeaderData,
        table.splitByCalculation,
        Object.assign({}, table.footerData),
        footerData,
        dashboardsRowDataChangeArr,
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 * enable/disable total/average on a column
 */
const updateFooterDataReduxAndFirestore =
  (updatedFooterData) => (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId},
      } = getState();
      dispatch({
        type: TABLE_ACTION.SHOW_TOTAL,
        payload: updatedFooterData,
      });
      return DocumentsMethods.replaceFooterData(
        activeDocumentId,
        updatedFooterData,
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 * enables total/average on a column
 */
const showTotal =
  (...args) =>
  async (dispatch, getState) => {
    const [obj, isVisible = true] = args;
    try {
      const {table, home} = getState();

      if (!table.areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(showTotal(...args));
      }

      const activeDocId = home.activeDocumentId;
      const colId = obj.colId;

      const tableData = table.tableData.slice();
      const footerData = cloneDeep(table.footerData);
      const totalObj = calculateTotal(
        tableData,
        colId,
        table.headerData.slice(),
      );
      footerData[colId] = getTotalObject({isVisible, type: obj.type}, totalObj);

      dispatch(updateFooterDataReduxAndFirestore(footerData));

      if (!isEmpty(home.activeDocumentMeta?.dashboards?.[colId])) {
        //if some dashboard exists on this column,
        //this can be useful if some value is miscalculated
        checkAndUpdateDashboardForColumn(
          table.footerData[colId],
          footerData[colId],
          home.activeDocumentMeta.dashboards[colId],
          activeDocId,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

/**
 * disables total/average on a column
 */
const removeTotal = (obj) => async (dispatch, getState) => {
  try {
    const {table, home} = getState();
    const footerData = Object.assign({}, table.footerData);
    const activeDocumentMeta = Object.assign({}, home.activeDocumentMeta);
    const colId = obj.colId;
    const isWithoutSplitDashboardActiveOnCol =
      checkIfColumnHasWithoutSplitByDashboard(
        colId,
        activeDocumentMeta.dashboards,
      );

    const updatedFooterData = isWithoutSplitDashboardActiveOnCol
      ? Object.assign({}, footerData, {
          [colId]: Object.assign({}, footerData[colId], {
            isVisible: false,
            type: null,
          }),
        })
      : omit(footerData, [colId]);
    dispatch(updateFooterDataReduxAndFirestore(updatedFooterData));
    if (activeDocumentMeta.pageObj && activeDocumentMeta.pageObj.enabled) {
      dispatch(setDisplayColIdValue(colId, true, true));
    }
  } catch (error) {
    captureError(error);
  }
};

/**
 * resets table reducer
 */
const clearLastState = () => (dispatch) =>
  dispatch({type: TABLE_ACTION.CLEAR_LAST});

/**
 * change the current active page
 */
const updateSelectedPage =
  (activePageId, firestoreInstance = null, dbInstance = null) =>
  (dispatch, getState) => {
    try {
      //page changed
      firestoreInstance = firestoreInstance ?? firestore;
      dbInstance = dbInstance ?? database;
      dispatch(updateLastModifiedTimestamp()); //should be called before accessing getState
      const {
        home,
        auth: {user},
      } = getState();
      const prevPageDocId = home.activeDocumentId;
      const updateObj = {
        activePageId,
      };
      const updatedMetaData = Object.assign(
        {},
        home.activeDocumentMeta,
        updateObj,
      );

      const originalDocId = home.originalDocumentId;

      setFileDetails(
        user.uid,
        originalDocId,
        Object.assign({}, {collab: updatedMetaData.collab}, updateObj),
      );

      const filesArr = home.files;

      filesArr.splice(home.activeFileIndex, 1, {
        documentId: originalDocId,
        documentMeta: updatedMetaData,
      });
      dispatch(callPendingAutomations('Auto'));
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      dispatch({
        type: HOME_ACTION.UPDATE_SELECTED_PAGE,
        payload: {
          activeDocumentId: activePageId,
          activeDocumentMeta: updatedMetaData,
        },
      });

      dispatch(
        modifyListenersOnPageChange(
          updatedMetaData,
          prevPageDocId,
          firestoreInstance,
          dbInstance,
        ),
      );
      dispatch(loadPendingAutomations(activePageId));
      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

//checks if the undo stack is not empty, then update *lastModifiedTimestamp*
const updateLastModifiedTimestamp = () => (dispatch, getState) => {
  try {
    const {table} = getState();
    if (table.undo?.length || table.redo?.length) {
      const lastModifiedTimestamp = moment().unix();
      dispatch(updateActiveDocMeta({lastModifiedTimestamp}));
    }
  } catch (error) {
    captureError(error);
  }
};

/**
 * this action can be used to add/update any type of meta of active document
 */
const updateActiveDocMeta =
  (updateObj, extra = {}) =>
  (dispatch, getState) => {
    try {
      const {
        home: homeState,
        auth: {user},
        table,
      } = getState();
      if (isEmpty(updateObj) || !user?.uid) {
        return;
      }
      const activeDocumentMeta = extra?.documentMeta
        ? extra.documentMeta
        : homeState.activeDocumentMeta;
      const originalDocumentId = extra?.documentId
        ? extra.documentId
        : homeState.originalDocumentId;
      if (!originalDocumentId) {
        return;
      }
      const activeFileIndex = extra?.fileIndex ?? homeState.activeFileIndex;

      const filesArr = homeState.files.slice();
      const newObj = Object.assign({}, activeDocumentMeta, updateObj);
      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocumentId,
        documentMeta: newObj,
      });
      if (extra?.fileIndex == null) {
        dispatch({
          type: HOME_ACTION.UPDATE_DOCUMENT_META,
          payload: newObj,
        });
      }
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });
      /**
       * sharedDocMetaObj : this object contains all the fields which
       * should be added to sharedDocMeta of a collab doc (pages, pagesObj etc)
       * ---------------------------------------------------------------------
       * nonSharedDocMetaObj : this object contains all the fields which
       * should be added to 'sharedDocs/${docId}'(of a collab/shared doc)
       * or 'documents/${docId}'((of a not shared doc))
       */

      const sharedDocMetaObj = pickBy(updateObj, (_, key) =>
        SHARED_DOC_META_FIELDS.includes(`${key}`),
      );
      const nonSharedDocMetaObj = !isEmpty(activeDocumentMeta.collab)
        ? omit(updateObj, SHARED_DOC_META_FIELDS)
        : updateObj;
      if (!isEmpty(activeDocumentMeta.collab) && !isEmpty(sharedDocMetaObj)) {
        //for shared doc meta (pages, pageObj ,docLock) : update sharedDocsMeta collection
        setSharedDocMeta(originalDocumentId, sharedDocMetaObj);
      }
      if (!isEmpty(nonSharedDocMetaObj)) {
        setFileDetails(
          user.uid,
          originalDocumentId,
          Object.assign({}, {collab: newObj.collab}, nonSharedDocMetaObj),
        );
      }

      if ('viewType' in updateObj) {
        const noOfColumn = table.headerData.length;
        logAnalyticsEvent('VIEW_CHANGED', {
          viewType: updateObj.viewType,
          noOfColumn,
          docId: originalDocumentId,
        });
      }
      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

/**
 * pages added for first time (or) all prev pages
 * are discarded and new type of pages are added
 */
const updateDocumentPageMeta = (pageType) => (dispatch, getState) => {
  return new Promise((resolve) => {
    try {
      const {
        home: homeState,
        auth: {user},
      } = getState();
      if (!user?.uid) {
        return;
      }

      const activeDocumentId = homeState.originalDocumentId;
      const activeDocumentMeta = Object.assign(
        {},
        homeState.activeDocumentMeta,
      );
      const existingPagesIdArray = isArray(activeDocumentMeta.pages)
        ? activeDocumentMeta.pages
            .map((obj) => obj?.id)
            .filter((id) => !isNil(id) && id !== activeDocumentId)
        : []; //except for originalDocumentId/activeDocumentId
      const activeFileIndex = homeState.activeFileIndex;
      const filesArr = homeState.files.slice();
      const pageObj = {
        enabled: true,
        type: pageType,
      };
      const newPage = {
        id: activeDocumentId,
        createdTimestamp: moment().valueOf(),
        displayColObj: {},
        pageName: getPageNameStr(pageType),
      };
      const pages = [newPage];
      const updateObj = {
        pageObj,
        pages,
        activePageId: activeDocumentId,
        carryForwardColumns: [],
      };
      const updatedMeta = Object.assign({}, activeDocumentMeta, updateObj);

      dispatch({
        type: HOME_ACTION.UPDATE_DOCUMENT_META,
        payload: updatedMeta,
      });
      filesArr.splice(activeFileIndex, 1, {
        documentId: activeDocumentId,
        documentMeta: updatedMeta,
      });
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });
      if (!isEmpty(updatedMeta.collab)) {
        setSharedDocMeta(activeDocumentId, {pages, pageObj});
      }
      setFileDetails(
        user.uid,
        activeDocumentId,
        Object.assign({}, {collab: updatedMeta.collab}, updateObj),
      );

      if (!isEmpty(updatedMeta.dashboards)) {
        const deletingPagesObj = {};
        existingPagesIdArray.forEach((id) => {
          deletingPagesObj[`pages.${id}`] = firestore(true).FieldValue.delete();
        });
        updateAllDashboardsProperty(updatedMeta.dashboards, {
          pagesEnabled: true,
          pagesType: pageType,
          [`pages.${activeDocumentId}.name`]: newPage.pageName,
          [`pages.${activeDocumentId}.createdTimestamp`]:
            newPage.createdTimestamp,
          ...deletingPagesObj,
        });
      }

      reloadVisibleFiles(dispatch, getState);
      resolve(true);
    } catch (error) {
      captureError(error);
      resolve(false);
    }
  });
};

/**
 * to create file from a template
 */
const createNewFileUsingTemplate =
  (fileName, fileData, analyticsObj, filePageType) =>
  async (dispatch, getState) => {
    // return new Promise((resolve) => {
    try {
      const {auth, table, home} = getState();
      const user = auth.user;
      const parent = home.activeFolder?.id;
      if (!user?.uid) {
        return;
      }
      const fileMetaObj = generateNewFileMeta(
        fileName,
        user.uid,
        parent,
        false,
      );
      const docId = fileMetaObj.docId;

      let pageEnabled = true,
        pageType = filePageType,
        pages = [
          {
            id: docId,
            createdTimestamp: moment().valueOf(),
            displayColObj: {},
            pageName: getPageNameStr(filePageType),
          },
        ],
        activePageId = docId,
        originalDocumentId = docId;

      if (!filePageType) {
        pageEnabled = false;
        pageType = '';
        pages = [];
        activePageId = null;
        //originalDocumentId = null;
      }

      const pageMetaData = {
        pageObj: {
          enabled: pageEnabled,
          type: pageType,
        },
        pages,
        activePageId,
        originalDocumentId,
      };

      const meta = {
        ...fileMetaObj.meta,
        ...pageMetaData,
        fileData,
        isRowIdAdded: true,
      };

      dispatch({
        type: HOME_ACTION.CREATE_NEW_FILE,
        payload: {
          activeDocumentId: docId,
          documentMeta: meta,
          isFolder: false,
        },
      });

      const currentHeader = table.headerData.slice();

      const tableDataOld = fileData?.doNotCopyData
        ? fileData.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER
          ? [{}]
          : cloneDeep(DEFAULT_ROWS)
        : table.tableData.slice();

      const tableData =
        tableActionHelper.prepareTableDataForFirestore(tableDataOld);

      const fileObj = Object.assign({}, table.fileObj);

      const footerData = {};
      Object.keys(Object.assign({}, table.footerData)).forEach((colId) => {
        const totalObj = calculateTotal(tableData, colId, currentHeader);
        const prevFooterObjType = table.footerData[colId]?.type;
        footerData[colId] = getTotalObject(
          Object.assign(
            {},
            {isVisible: true, type: FOOTER_OPERATION_TYPES.TOTAL},
            prevFooterObjType ? {type: prevFooterObjType} : {},
          ),
          totalObj,
        );
      });

      const docData = {
        headerData: currentHeader,
        tableData,
        footerData,
        fileObj,
      };

      dispatch({
        type: TABLE_ACTION.LOAD_TABLE_DATA,
        payload: docData,
      });

      await UsersMethods.createNewFileorFolderWithFileData(
        user.uid,
        docId,
        meta,
        docData,
      );

      reloadVisibleFiles(dispatch, getState);

      if (fileData?.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER) {
        addEntryForFlipkartCron(user.uid, docId);
      }

      const fileNo = home.files.length + 1;
      if (analyticsObj.catgName === 'My_templates') {
        const obj = {
          fileNumber: fileNo,
          source: 'templates-cateogry',
          docId: originalDocumentId,
        };
        logAnalyticsEvent('REGISTER_CREATED_USER_TEMPLATE', obj);
      } else if (fileData?.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER) {
        logAnalyticsEvent('REGISTER_CREATED_TEMPLATE_AUTO', {
          docId: originalDocumentId,
          templateName: 'FLIPKART_ORDER_DETAILS',
          catgName: analyticsObj.catgName,
        });
      } else if (
        fileData?.smartTemplate === SMART_TEMPLATE_TYPES.W4B_TEMPLATE
      ) {
        logAnalyticsEvent('REGISTER_CREATED_SMART_TABLE', {
          docId: originalDocumentId,
          tableType: 'WHATSAPP_BIZ',
        });
      } else {
        const obj = Object.assign({}, analyticsObj, {
          fileNumber: fileNo,
          docId: originalDocumentId,
        });
        logAnalyticsEvent('REGISTER_CREATED_TEMPLATE', obj);
      }

      if (isEmpty(meta?.collab)) {
        //since this is not a shared file so set shared meta fetched true!
        dispatch(
          updateInitialFetchObj({
            [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
          }),
        );
      }
      // resolve(true);
    } catch (error) {
      captureError(error);
      // resolve(false);
    }
    // });
  };

const createNewFileUsingTemplateCloudFun =
  ({docId, meta}) =>
  (dispatch) => {
    dispatch({
      type: HOME_ACTION.LOAD_NEW_CREATED_FILE_DATA,
      payload: {
        activeDocumentId: docId,
        documentMeta: meta,
      },
    });
    // dispatch(fetchParentDocData(meta.linkedDocIds));
    dispatch(
      fetchParentFileData({
        isInitialFetch: true,
        extra: {linkedDocIds: meta.linkedDocIds},
      }),
    );
  };

//delete selected document page
const deleteDocumentPage =
  (pageId, firestoreInstanceParam = null, dbInstance = null) =>
  async (dispatch, getState) => {
    try {
      const firestoreInstance = firestoreInstanceParam ?? firestore;
      dbInstance = dbInstance ?? database;
      const {
        auth: {user, userPref},
        home,
      } = getState();
      if (!user?.uid || pageId === home.originalDocumentId) {
        return;
      }
      const {
        activeFileIndex,
        activeDocumentId,
        originalDocumentId: originalDocId,
      } = home;

      dispatch({type: VERSION_ACTION.CLEAR_VERSION_DATA});

      let updatedActivePageId;

      const prevPageDocId = activeDocumentId;

      const updatedMetaData = Object.assign({}, home.activeDocumentMeta);

      if (!isEmpty(home.activeDocumentMeta.collab)) {
        const docRef = firestoreInstance()
          .collection('sharedDocsMeta')
          .doc(originalDocId);
        const res = await firestoreInstance()
          .runTransaction((transaction) =>
            transaction.get(docRef).then((txnData) => {
              const {pages} = txnData.data();
              const updatedPages = pages.filter((p) => p.id !== pageId);
              if (pageId === activeDocumentId) {
                // active page deleted
                updatedActivePageId = updatedPages[0].id;
                updatedMetaData.activePageId = updatedActivePageId;
              }
              updatedMetaData.pages = updatedPages;
              transaction.update(docRef, {pages: updatedPages});
            }),
          )
          .then(() => {
            return true;
          })
          .catch((err) => {
            ShowToast('Something went wrong, please try again', userPref);
            captureError(err);
            return false;
          });
        if (!res) {
          return;
        }
      } else {
        let documentPages = home.activeDocumentMeta.pages ?? [];
        documentPages = documentPages.filter((p) => p.id !== pageId);
        updatedMetaData.pages = documentPages;
        if (activeDocumentId === pageId) {
          // fetch new table data if deleting the active page
          updatedActivePageId = documentPages[0].id;
          updatedMetaData.activePageId = updatedActivePageId;
        }
      }

      dispatch({
        type: HOME_ACTION.DELETE_PAGE,
        payload: {
          activeDocumentId: updatedActivePageId || activeDocumentId,
          activeDocumentMeta: updatedMetaData,
        },
      });

      setFileDetails(user.uid, originalDocId, {
        activePageId: updatedMetaData.activePageId,
        pages: updatedMetaData.pages,
        collab: Object.assign({}, updatedMetaData.collab),
      });

      const filesArr = home.files.slice();
      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocId,
        documentMeta: updatedMetaData,
      });
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });
      reloadVisibleFiles(dispatch, getState);
      if (updatedActivePageId) {
        //if current page deleted then load last page which was added
        dispatch(
          modifyListenersOnPageChange(
            updatedMetaData,
            prevPageDocId,
            firestoreInstance,
            dbInstance,
          ),
        );
        dispatch(loadPendingAutomations(updatedActivePageId));
      }

      if (!isEmpty(updatedMetaData.dashboards)) {
        //delete page details from dashboards (if-any)
        updateAllDashboardsProperty(updatedMetaData.dashboards, {
          [`pages.${pageId}`]: firestore(true).FieldValue.delete(),
          pagesEnabled: true,
          pagesType: updatedMetaData.pagesObj?.type,
        });
      }

      ShowToast('Page Deleted Successfully', userPref);
    } catch (err) {
      ShowToast(
        'Something went wrong, please try again',
        getState().auth.userPref,
      );
      captureError(err);
    }
  };

// clear document page data
const clearSelectedPageData =
  (...args) =>
  async (dispatch, getState) => {
    let [pageId, firestoreInstance] = args;
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const {home, table, auth} = getState();
      if (!auth.user?.uid) {
        return;
      }
      const {activeDocumentMeta, activeDocumentId} = home;
      const isActiveOnSamePage = activeDocumentId === pageId;
      if (isActiveOnSamePage && !table.areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(clearSelectedPageData(...args));
      }
      const updatedTableData = isActiveOnSamePage
        ? {
            headerData: tableActionHelper.shouldUseOriginalHeaderData()
              ? table.originalHeaderData
              : table.headerData,
            tableData: table.tableData,
            fileObj: table.fileObj,
            footerData: table.footerData,
          }
        : await DocumentsMethods.getUserDocumentData(pageId);

      const oldTableDataRowIdArr = updatedTableData.tableData.map(
        (obj) => obj.rowId,
      );

      const newFooterObj = {};
      const prevFooterData = Object.assign({}, updatedTableData.footerData);
      Object.keys(prevFooterData).forEach((colId) => {
        const totalObj = calculateTotal(
          [{}], //dummy empty tableData
          colId,
          updatedTableData.headerData,
        );
        newFooterObj[colId] = getTotalObject(prevFooterData[colId], totalObj);
      });

      updatedTableData.headerData.forEach((colObj) => {
        if (colObj.columnProperties?.UNIQUE_VALUES === true) {
          DocumentsMethods.updateUniqueValuesDataForColumn(pageId, colObj.id, {
            data: {},
          });
        }
      });

      Object.assign(updatedTableData, {
        tableData: tableActionHelper.prepareTableDataForFirestore(DEFAULT_ROWS),
        footerData: newFooterObj,
      });

      const {pages, activePageId, fileData, collab} = activeDocumentMeta;

      const documentPages = pages ?? [];

      const selectedPage = documentPages.find((p) => p.id === pageId);

      if (fileData?.footerAsHeader && !isEmpty(fileData.footerAsHeader)) {
        const columnId =
          !isEmpty(selectedPage.displayColObj) && selectedPage.displayColObj.id;

        let updatedMetaData = Object.assign({}, activeDocumentMeta);

        if (columnId) {
          if (!isEmpty(collab)) {
            const docRef = firestoreInstance()
              .collection('sharedDocsMeta')
              .doc(pageId);

            await firestoreInstance()
              .runTransaction((transaction) =>
                transaction.get(docRef).then((txnData) => {
                  const {pages: newlyReceivedPagesData} = txnData.data();
                  const updatedPages = newlyReceivedPagesData.map((p) => {
                    return p.id === pageId
                      ? {...p, displayColObj: {...p.displayColObj, val: 0}}
                      : p;
                  });
                  updatedMetaData = Object.assign({}, updatedMetaData, {
                    pages: updatedPages,
                  });
                  transaction.update(docRef, {pages: updatedPages});
                }),
              )
              .catch((err) => {
                captureError(err);
                throw err;
              });
          } else {
            const updatedPages = documentPages.map((p) => {
              return p.id === pageId
                ? {...p, displayColObj: {...p.displayColObj, val: 0}}
                : p;
            });
            const updateObj = {
              pages: updatedPages,
            };
            updatedMetaData = Object.assign({}, updatedMetaData, updateObj);
            setFileDetails(auth.user.uid, pageId, {
              ...updateObj,
              collab: Object.assign({}, updatedMetaData.collab),
            });
          }

          const {files, activeFileIndex} = home;

          const filesArr = files.slice();

          filesArr.splice(activeFileIndex, 1, {
            documentId: pageId,
            documentMeta: updatedMetaData,
          });

          dispatch({
            type: HOME_ACTION.UPDATE_DOCUMENT_META,
            payload: updatedMetaData,
          });

          dispatch({
            type: HOME_ACTION.LOAD_FILES,
            payload: {files: filesArr},
          });
          reloadVisibleFiles(dispatch, getState);
        }
      }

      if (pageId === activePageId) {
        dispatch({
          type: TABLE_ACTION.TABLE_DATA_UPDATE_PAGE_CHANGE,
          payload: updatedTableData,
        });
      }
      checkAndUpdateDashboard(
        {...home, activeDocumentId: pageId},
        updatedTableData.headerData.slice(),
        {}, //recalculate splitByCalculation
        {}, //consider as empty for dashboards
        updatedTableData.footerData,
        updatedTableData.tableData.map((rowObj) => {
          return {
            prev: {},
            updated: rowObj,
          };
        }),
      );
      DocumentsMethods.deleteOrRestoreMultipleRows(
        pageId,
        oldTableDataRowIdArr,
        null,
        true,
      ); //delete
      return DocumentsMethods.setNewPageData(pageId, updatedTableData).then(
        () => ShowToast('Page Data Removed Successfully', auth.userPref),
      );
    } catch (err) {
      captureError(err);
      return ShowToast(
        'Something went wrong, please try again',
        getState().auth.userPref,
      );
    }
  };

/**
 * adds new page to doc already having pages
 */
const createNewDocumentPage =
  (...args) =>
  async (dispatch, getState) => {
    let [pageData = {}, firestoreInstance = null, dbInstance = null] = args;
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      dbInstance = dbInstance ?? database;
      dispatch(updateLastModifiedTimestamp()); //should be called before accessing getState
      const {
        auth: {user},
        table,
        home,
        tableLinks,
      } = getState();
      if (!user?.uid) {
        return;
      }
      const {
        activeDocumentId,
        activeFileIndex,
        originalDocumentId: originalDocId,
      } = home;

      let documentPages = home.activeDocumentMeta.pages ?? [];
      const lastUpdatedDocId = documentPages[0].id;

      const carryForwardColumns =
        home.activeDocumentMeta.carryForwardColumns || [];
      const carryForwardColumnsAvailable = carryForwardColumns?.length > 0;
      const latesTableData = {};

      if (activeDocumentId === lastUpdatedDocId) {
        //active page is the latest page
        if (carryForwardColumnsAvailable && !table.areAllRowsFetched) {
          await dispatch(checkAndFetchAllRows());
          return dispatch(createNewDocumentPage(...args));
        }
        Object.assign(latesTableData, {
          tableData: table.tableData.slice(),
          headerData: tableActionHelper.shouldUseOriginalHeaderData()
            ? table.originalHeaderData
            : table.headerData.slice(),
          footerData: table.footerData,
          fileObj: omit(table.fileObj, ['tasksCount']), //"tasksCount" will be different for all new pages
        });
      } else {
        const dataFromFirestore = await (carryForwardColumnsAvailable
          ? DocumentsMethods.getUserDocumentData(lastUpdatedDocId)
          : DocumentsMethods.getUserDocumentDataWithoutRows(lastUpdatedDocId));
        Object.assign(latesTableData, dataFromFirestore);
      }

      const autoFillLinkedFiles = Object.assign(
        {},
        tableLinks.autoFillLinkedFiles,
      );
      const docId = `${user.uid}_${moment().valueOf()}`;

      const dashboardsUpdateArray = [];
      const prevPageDocId = activeDocumentId;
      dispatch(callPendingAutomations('Auto'));
      dispatch({type: VERSION_ACTION.CLEAR_VERSION_DATA});

      const filePageType = home.activeDocumentMeta.pageObj.type;

      const filesArr = home.files;
      const newPage = {
        id: docId,
        createdTimestamp: isNumber(pageData.createdTimestamp)
          ? pageData.createdTimestamp
          : moment().valueOf(),
        displayColObj: {},
        pageName: pageData.pageName || getPageNameStr(filePageType),
      };

      logAnalyticsEvent('NEW_PAGE_ADDED', {
        pageType: filePageType,
        docId: originalDocId,
      });
      const isShared = !isEmpty(home.activeDocumentMeta.collab);

      const checkIfDuplicateDatePage = (pages) => {
        if (
          filePageType !== PAGE_TYPE.CLIENT &&
          pages.some((page) => newPage.pageName === page.pageName)
        ) {
          //if pages are of day/month/week or any date type and, a new page of same date
          //which is already present in the pages array is requested to be created, then
          //do not create this page again : invalid request
          captureInfo({
            pageName: newPage.pageName,
            pageType: filePageType,
            pages: JSON.stringify(pages),
            isShared,
            collabState: JSON.stringify(getState().collab),
          });
          throw new Error('Attempt to create a duplicate date page');
        }
      };

      checkIfDuplicateDatePage(documentPages);

      documentPages = [newPage, ...documentPages];

      const updateObj = {pages: documentPages, activePageId: docId};

      const updatedMetaData = {
        ...home.activeDocumentMeta,
        ...updateObj,
      };

      let {footerData, tableData} = latesTableData;
      const {headerData, fileObj} = latesTableData;
      footerData = Object.assign({}, footerData);
      if (!isArray(headerData) || headerData.length === 0) {
        captureInfo({
          error: 'Document header data is empty.',
          latesTableData: JSON.stringify(latesTableData),
          activeDocumentId,
          lastUpdatedDocId,
          originalDocId,
        });
        throw new Error('Document header data is empty.');
      }
      if (!isArray(tableData)) {
        tableData = [{}];
      }

      if (carryForwardColumnsAvailable) {
        //map carry forward and recalculate equations/formula
        const mappedTableData = tableData
          .map((rowData) => pick(rowData, carryForwardColumns))
          .slice();
        const {eqnArrs} = mapHeaderData(headerData); //get eqnArrs
        for (let i = 0; i < mappedTableData.length; i++) {
          const updatedRowValues = solveRowAllEqns(
            mappedTableData[i],
            eqnArrs,
            null,
            null,
          );
          mappedTableData.splice(i, 1, updatedRowValues);
        }
        tableData = mappedTableData;
      } else {
        tableData = cloneDeep(DEFAULT_ROWS);
      }
      tableData = tableActionHelper.prepareTableDataForFirestore(tableData);
      const newFooterObj = {};
      Object.keys(footerData).forEach((colId) => {
        const totalObj = calculateTotal(tableData, colId, headerData);
        newFooterObj[colId] = getTotalObject(footerData[colId], totalObj);
      });
      footerData = newFooterObj;

      /**
       * For : add pages info and calculations in dashboards
       * ----------------------------------------------------
       * IMP: following dashboards block should be called before adding pages
       * to firestore and redux because it can add some footer for any column
       * available in dashboards which is not in footerData
       * ----------------------------------------------------
       * also all the dashboard updates are not called in the below looping
       * they are added to dashboardsUpdateArray so that they are only called
       * at the end of this action when this action execute/completes successfully (importtant for collab files)
       */
      if (!isEmpty(updatedMetaData.dashboards)) {
        const splitByCalculation = {};
        const getDashboardPageObj = (
          dashboardMeta,
          dashboardColumnId,
          isWithSplit = false,
          splitByColumnObj = {},
        ) => {
          const newPageDashboardData = {
            //common properties
            name: newPage.pageName,
            createdTimestamp: newPage.createdTimestamp,
          };
          const headerObj = headerData.find(
            (obj) => `${obj.id}` === `${dashboardColumnId}`,
          );
          if (!isEmpty(headerObj)) {
            newPageDashboardData.fieldType = headerObj.fieldType;
            newPageDashboardData.subType = headerObj.subType ?? null;
            newPageDashboardData.unitSymbol =
              headerObj.fieldType === FIELD_TYPE_ID.UNIT
                ? (!isEmpty(fileObj) &&
                    fileObj[dashboardColumnId]?.UNIT?.unitSymbol) ||
                  null
                : undefined;
          } else {
            newPageDashboardData.fieldType = FIELD_TYPE_ID.TEXT;
            newPageDashboardData.subType = null;
          }
          if (isWithSplit) {
            splitByColumnObj = Object.assign({}, splitByColumnObj);
            const splitByCurrentHeader = splitByColumnObj.id
              ? headerData.find(
                  (obj) => obj?.id && `${obj.id}` === `${splitByColumnObj.id}`,
                )
              : null;
            const splitByColumnAvailable = !isEmpty(splitByCurrentHeader);
            if (!splitByColumnAvailable) {
              newPageDashboardData.splitByVal = {
                val: DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND,
              };
            } else if (
              splitByCurrentHeader.fieldType === FIELD_TYPE_ID.TABLE
                ? splitByCurrentHeader.subType !== splitByColumnObj.subType
                : splitByCurrentHeader.fieldType !== splitByColumnObj.fieldType
            ) {
              newPageDashboardData.splitByVal = {
                val: DASHBOARD_ERRORS.DASHBOARD_SPLIT_BY_FIELDTYPE_MISMATCH,
              };
            } else {
              const splitByCalculatedDataForDashboard = calculateSplitByTotal(
                tableData,
                dashboardColumnId,
                splitByColumnObj,
              );
              const splitByValObj = {};
              forOwn(splitByCalculatedDataForDashboard, (res, key) => {
                const val = getOperationValue(dashboardMeta?.operation, res);
                if (!isNil(val)) {
                  splitByValObj[key] = val;
                }
              });
              Object.assign(splitByCalculation, {
                [dashboardColumnId]: Object.assign(
                  {},
                  splitByCalculation[dashboardColumnId],
                  {
                    [splitByColumnObj.id]: splitByCalculatedDataForDashboard,
                  },
                ),
              });
              newPageDashboardData.splitByVal = splitByValObj;
            }
          } else {
            //without split
            const currFooterObj = footerData[dashboardColumnId];
            if (
              !isEmpty(currFooterObj) &&
              FOOTER_MANDATORY_FIELDS.every((key) => key in currFooterObj)
            ) {
              //total available in footer
              newPageDashboardData.val =
                getOperationValue(dashboardMeta?.operation, currFooterObj) ??
                INVALID_INPUT; //add to dashboad
            } else {
              //total unavailable in footer : recalculate
              const totalObj = calculateTotal(
                tableData.slice(),
                dashboardColumnId,
                headerData.slice(),
              ); //calculate total
              Object.assign(footerData, {
                [dashboardColumnId]: getTotalObject({}, totalObj),
              }); //add to footer
              newPageDashboardData.val =
                getOperationValue(dashboardMeta?.operation, totalObj) ??
                INVALID_INPUT; //add to dashboad
            }
          }
          return newPageDashboardData;
        };
        forOwn(
          updatedMetaData.dashboards,
          (dashboardColumnData, dashboardColumnId) => {
            forOwn(dashboardColumnData, (dashboardMeta, dashboardId) => {
              if (!isEmpty(dashboardMeta) && dashboardMeta.isV2Dashboard) {
                const isWithSplit =
                  isArray(dashboardMeta.splitBy) &&
                  dashboardMeta.splitBy.length;
                const updateObjData = getDashboardPageObj(
                  dashboardMeta,
                  dashboardColumnId,
                  isWithSplit,
                  isWithSplit ? dashboardMeta.splitBy[0] : {},
                );
                dashboardsUpdateArray.push(() =>
                  updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                    [`pages.${docId}`]: updateObjData,
                    pagesEnabled: true,
                    pagesType: updatedMetaData.pageObj?.type,
                  }),
                );
              }
            });
          },
        );
        if (!isEmpty(splitByCalculation)) {
          dashboardsUpdateArray.push(() =>
            DocumentsMethods.updateFooterPropertiesData(
              docId,
              splitByCalculation,
              false,
            ),
          );
        }
      }

      if (isShared) {
        const docRef = firestoreInstance()
          .collection('sharedDocsMeta')
          .doc(originalDocId);
        await firestoreInstance()
          .runTransaction((transaction) => {
            return transaction.get(docRef).then((txnData) => {
              const {pages} = txnData.data();
              checkIfDuplicateDatePage(pages);
              const updatedPages = [newPage, ...pages];
              updatedMetaData.pages = updatedPages;
              transaction.update(docRef, {pages: updatedPages});
            });
          })
          .catch((err) => {
            captureError(err);
            throw err;
          });
      }

      setFileDetails(user.uid, originalDocId, {
        ...updateObj,
        collab: Object.assign({}, updatedMetaData?.collab),
      });
      await DocumentsMethods.setNewPageData(docId, {
        headerData,
        tableData,
        footerData,
        fileObj,
      });
      // set AutoFillLinked Files in the new page if present in previous page
      if (!isEmpty(autoFillLinkedFiles)) {
        setAutoFilledLinkedData(autoFillLinkedFiles, docId);
      }
      dispatch({
        type: HOME_ACTION.ADD_NEW_PAGE,
        payload: {
          activeDocumentId: docId,
          activeDocumentMeta: updatedMetaData,
        },
      });

      dispatch({
        type: TABLE_ACTION.TABLE_DATA_UPDATE_PAGE_CHANGE,
        payload: {
          headerData,
          tableData,
          footerData,
          fileObj,
        },
      });
      dispatch(filterAndSetUniqueDataForFile({activeDocumentId: docId}));

      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocId,
        documentMeta: updatedMetaData,
      });

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      if (isShared) {
        tableActionHelper.checkAndUpdateEntryOnlyData({
          getState,
          headerData,
          isShared: true,
          docId,
          fileObj,
        });
      }
      dispatch(
        modifyListenersOnPageChange(
          updatedMetaData,
          prevPageDocId,
          firestoreInstance,
          dbInstance,
        ),
      );
      dispatch(loadPendingAutomations(docId));
      reloadVisibleFiles(dispatch, getState);
      if (dashboardsUpdateArray.length) {
        dashboardsUpdateArray.forEach((updateFunction) => {
          if (typeof updateFunction === 'function') {
            updateFunction();
          }
        });
      }
      return true;
    } catch (error) {
      ShowToast(
        'Something went wrong, please try again',
        getState().auth.userPref,
      );
      captureError(error);
      return false;
    }
  };

/**
 * change the name of custom page
 */
const updateDocumentPageName =
  (pageName, pageId, firestoreInstance) => async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const {
        home: homeData,
        auth: {user},
      } = getState();
      if (!user?.uid) {
        return;
      }

      const filesArr = homeData.files.slice();
      const {activeDocumentMeta, activeFileIndex} = homeData;

      let documentPages = activeDocumentMeta.pages;

      documentPages = documentPages.map((page) => {
        return pageId === page.id
          ? Object.assign({}, page, {pageName})
          : Object.assign({}, page);
      });

      const updateObj = {pages: documentPages};

      const updatedMeta = {
        ...activeDocumentMeta,
        ...updateObj,
      };

      const originalDocId = homeData.originalDocumentId;

      if (!isEmpty(activeDocumentMeta.collab)) {
        const docRef = firestoreInstance()
          .collection('sharedDocsMeta')
          .doc(originalDocId);
        await firestoreInstance()
          .runTransaction((transaction) =>
            transaction.get(docRef).then(async (txnData) => {
              const {pages} = txnData.data();
              const updatedPages = pages.map((page) => {
                return pageId === page.id
                  ? Object.assign({}, page, {pageName})
                  : Object.assign({}, page);
              });
              updatedMeta.pages = updatedPages;
              await transaction.update(
                docRef,
                Object.assign({}, {pages: updatedPages}),
              );
            }),
          )
          .catch((err) => {
            captureError(err);
            throw err;
          });
      } else {
        setFileDetails(user.uid, originalDocId, {
          ...updateObj,
          collab: updatedMeta.collab,
        });
      }

      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocId,
        documentMeta: updatedMeta,
      });

      dispatch({
        type: HOME_ACTION.UPDATE_DOCUMENT_META,
        payload: updatedMeta,
      });

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      if (!isEmpty(updatedMeta.dashboards)) {
        updateAllDashboardsProperty(updatedMeta.dashboards, {
          pagesEnabled: true,
          [`pages.${pageId}.name`]: pageName,
        });
      }

      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

const setDisplayColIdValue =
  (...args) =>
  async (dispatch, getState) => {
    const [id, hideColumn, totalRemoved = false] = args;
    try {
      const {
        home: {activeDocumentMeta, files, activeFileIndex, originalDocumentId},
        table: {footerData, headerData},
        auth: {user},
      } = getState();
      if (!user?.uid) {
        return;
      }
      const {activePageId, pages = [], fileData} = activeDocumentMeta;
      let updatedMeta;
      let onlyFileDataChanged = false;
      if (hideColumn) {
        if (totalRemoved) {
          const {footerAsHeader} = fileData ?? {};

          if (footerAsHeader?.colId && footerData[footerAsHeader.colId]) {
            return;
          }
        }

        updatedMeta = {
          ...activeDocumentMeta,
          fileData: {
            ...(fileData ?? {}),
            footerAsHeader: {},
          },
        };
        onlyFileDataChanged = true;
      } else {
        if (isEmpty(footerData?.[id])) {
          await dispatch(
            showTotal({colId: id, type: FOOTER_OPERATION_TYPES.TOTAL}),
          );
          const updatedFooterData = getState().table.footerData;
          return (
            !isEmpty(updatedFooterData?.[id]) &&
            dispatch(setDisplayColIdValue(...args))
          );
        }
        const selectedColumn = headerData.find((d) => d.id == id);
        const columnDisplayInfo = {
          //data which is used to display symbol/unit
          subType: selectedColumn.subType,
          unitDependentColumn: selectedColumn.unitDependentColumn, //in case of formula fieldType
        };
        const displayColObj = {
          id,
          val: footerData[id].val,
          type: selectedColumn.fieldType,
          ...columnDisplayInfo,
        };
        if (!isArray(pages)) {
          return;
        }

        const updatedPages = pages.map((page) => {
          return page.id === activePageId
            ? {
                ...page,
                displayColObj,
              }
            : page;
        });

        updatedMeta = {
          ...activeDocumentMeta,
          pages: updatedPages,
          fileData: {
            ...(fileData ?? {}),
            footerAsHeader: {
              colId: id,
              fieldType: selectedColumn.fieldType,
              headerText: {
                EN: selectedColumn.val,
              },
              ...columnDisplayInfo,
            },
          },
        };
      }

      const filesArr = [...files];

      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocumentId,
        documentMeta: updatedMeta,
      });

      dispatch({
        type: HOME_ACTION.UPDATE_DOCUMENT_META,
        payload: updatedMeta,
      });

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      if (!onlyFileDataChanged && !isEmpty(updatedMeta.collab)) {
        setSharedDocMeta(originalDocumentId, {pages: updatedMeta.pages});
      }

      setFileDetails(user.uid, originalDocumentId, {
        ...(onlyFileDataChanged ? {} : {pages: updatedMeta.pages}),
        fileData: updatedMeta.fileData,
        collab: updatedMeta.collab,
      });

      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

const updateDisplayColumnValue =
  (unmount = false) =>
  (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentMeta, files, activeFileIndex, originalDocumentId},
        table: {footerData, headerData},
        auth: {user},
      } = getState();
      if (!user?.uid) {
        return;
      }
      const {activePageId, pages, fileData} = activeDocumentMeta;
      if (!activePageId) {
        return;
      }

      const activePage = pages.find((p) => p.id === activePageId) ?? {};

      const columnId =
        activePage.displayColObj?.id || fileData?.footerAsHeader?.colId || null;

      if (
        !columnId ||
        !(footerData[columnId] && 'val' in footerData[columnId])
      ) {
        return;
      }

      const columnDetails = headerData.find((d) => d.id === columnId);
      if (!columnDetails) {
        return;
      }

      const displayColObj = {
        id: columnId,
        subType: columnDetails.subType,
        type: columnDetails.fieldType,
        unitDependentColumn: columnDetails.unitDependentColumn, //in case of formula fieldType
        val: footerData[columnId].val,
      };
      if (!isArray(pages)) {
        return;
      }
      const updatedPages = pages.map((page) => {
        return page.id === activePageId
          ? {
              ...page,
              displayColObj,
            }
          : page;
      });

      const updatedMeta = {
        ...activeDocumentMeta,
        pages: updatedPages,
      };

      const filesArr = [...files];

      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocumentId,
        documentMeta: updatedMeta,
      });

      if (!unmount) {
        dispatch({
          type: HOME_ACTION.UPDATE_DOCUMENT_META,
          payload: updatedMeta,
        });
      }

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      if (!isEmpty(updatedMeta.collab)) {
        setSharedDocMeta(originalDocumentId, {pages: updatedMeta.pages});
      } else {
        setFileDetails(user.uid, originalDocumentId, {
          pages: updatedPages,
          collab: updatedMeta.collab,
        });
      }
      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

/**
 * enable or disable pages
 */
const toggleDocumentPages =
  (enablePage, firestoreInstance = null, dbInstance = null) =>
  async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      dbInstance = dbInstance ?? database;
      const {
        home: {
          files,
          activeDocumentId,
          activeFileIndex,
          originalDocumentId,
          activeDocumentMeta,
        },
        auth: {user, userPref},
      } = getState();
      if (!user?.uid) {
        return;
      }
      const prevPageDocId = activeDocumentId;
      const docMeta = Object.assign({}, activeDocumentMeta);

      const {pageObj} = docMeta;
      const updateObj = {
        activePageId: enablePage ? activeDocumentId : null,
        pageObj: {
          ...pageObj,
          enabled: enablePage ? true : false,
        },
      };
      const updatedMeta = Object.assign({}, docMeta, updateObj);
      const filesArr = [...files];

      filesArr.splice(activeFileIndex, 1, {
        documentId: originalDocumentId,
        documentMeta: updatedMeta,
      });

      if (!isEmpty(updatedMeta.collab)) {
        if (enablePage) {
          setSharedDocMeta(originalDocumentId, {pageObj: updatedMeta.pageObj});
        } else {
          //call cloud function
          let message = 'Pages has been disabled';
          const dataObj = {docId: originalDocumentId, callerUID: user.uid};
          const data = await disablePagesCollabDoc(dataObj);
          if (!data || !data.success) {
            if (data?.message) {
              message = data.message;
            } else {
              message = 'Something went wrong, please try again';
            }
            if (data?.error) {
              captureInfo({log: data.log});
              captureError(new Error(data.error), true);
            }
            dispatch({type: TABLE_ACTION.STOP_TABLE_LOADING});
            ShowToast(message, userPref, true, true);
            return;
          } else {
            ShowToast(message, userPref, true, true);
          }
        }
      }

      if (!isEmpty(updatedMeta.dashboards)) {
        //enable/disabled pagesEnabled flag on dashboards (if-any)
        updateAllDashboardsProperty(updatedMeta.dashboards, {
          pagesEnabled: enablePage,
          pagesType: updatedMeta.pageObj?.type,
        });
      }

      dispatch({
        type: HOME_ACTION.TOGGLE_DOCUMENT_PAGES,
        payload: {
          activeDocumentMeta: updatedMeta,
          activeDocumentId: originalDocumentId,
        },
      });

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });

      setFileDetails(
        user.uid,
        originalDocumentId,
        Object.assign({}, {collab: updatedMeta.collab}, updateObj),
      );

      reloadVisibleFiles(dispatch, getState);

      if (!enablePage && originalDocumentId !== prevPageDocId) {
        dispatch(
          modifyListenersOnPageChange(
            updatedMeta,
            prevPageDocId,
            firestoreInstance,
          ),
        );
        dispatch(loadPendingAutomations(originalDocumentId));
      }
    } catch (error) {
      captureError(error);
      dispatch({type: TABLE_ACTION.STOP_TABLE_LOADING});
    }
  };

/**
 * add empty rows at the last of table
 */
const addEmptyRow = (isBlockView) => (dispatch, getState) => {
  try {
    const {
      table,
      home: {activeDocumentId, activeDocumentMeta},
      remoteConfig: {applyUserRestrictions, restrictionConfig},
    } = getState();

    const currentLength = table.tableData.length;
    const emptyRowIndexes = table.emptyRowIndexes.slice();
    const isSharedDoc = !isEmpty(activeDocumentMeta?.collab);
    let limit = getAddRowLimit(
      isSharedDoc,
      currentLength,
      applyUserRestrictions,
      restrictionConfig,
      activeDocumentMeta,
    ); // default 5
    if (isBlockView) {
      //donot change this limit, as it is being used in Collab-QuickEntry
      limit = 1;
    }
    if (limit) {
      const newRows = tableActionHelper.prepareTableDataForFirestore(
        Array(limit).fill({}),
        table.tableData[currentLength - 1]?.index ?? 1000000,
      );

      for (let i = 0; i < newRows.length; i++) {
        emptyRowIndexes.push(newRows[i].rowId);
      }

      if (emptyRowIndexes[0] === -1) {
        emptyRowIndexes.splice(0, 1);
      }
      dispatch({
        type: TABLE_ACTION.ADD_EMPTY_ROW,
        payload: {
          newRows,
          emptyRowIndexes,
        },
      });
      DocumentsMethods.addMultipleRows(activeDocumentId, newRows);
      return currentLength + limit;
    }
    return currentLength;
  } catch (error) {
    captureError(error);
  }
};

/**
 * add empty row in between existing rows
 */
const addNewRowInBetween = (index, isAbove) => (dispatch, getState) => {
  try {
    const {
      table,
      home: {activeDocumentId},
      auth: {userPref},
    } = getState();

    const tableData = table.tableData.slice();
    const insertIndex = isAbove ? index : index + 1;
    const isAddAtLast = insertIndex === tableData.length;
    if (isAddAtLast) {
      return dispatch(addEmptyRow(true));
    }
    const emptyRowIndexes = table.emptyRowIndexes.slice();

    const lastIndex = tableData[insertIndex]?.index ?? 0;
    const lastIndexMinusOne =
      tableData[insertIndex - 1]?.index ?? lastIndex - 100;
    const [newRowObj] = tableActionHelper.prepareTableDataForFirestore([{}]);
    const IndexHelper = new tableActionHelper.GetIncrementalIndexes(
      lastIndexMinusOne,
      lastIndex,
      1,
    );
    const updatedEmptyRowArr = tableActionHelper.updateEmptyRowIndexes(
      emptyRowIndexes,
      [newRowObj.rowId],
      true,
    );
    if (!updatedEmptyRowArr.includes(newRowObj.rowId)) {
      updatedEmptyRowArr.push(newRowObj.rowId);
    }
    if (!IndexHelper.areIndexesPossible()) {
      return ShowToast(
        'Row cannot be added at the provided position.',
        userPref,
      );
    }
    Object.assign(newRowObj, {index: IndexHelper.getNextIndex()});
    tableData.splice(insertIndex, 0, newRowObj);

    dispatch({
      type: TABLE_ACTION.ADD_ROW_IN_BETWEEN,
      payload: {
        tableData: tableData,
        index: insertIndex,
        emptyRowIndexes: updatedEmptyRowArr,
      },
    });

    DocumentsMethods.addMultipleRows(activeDocumentId, [newRowObj]);
  } catch (error) {
    captureError(error);
  }
};

/**
 * add/edit formula on a column
 */
const formulaEditColumn =
  (...args) =>
  async (dispatch, getState) => {
    const [{headerUpdates}] = args;
    try {
      const {table, home} = getState();
      if (!table.areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(formulaEditColumn(...args));
      }
      const prevSplitByCalculation = cloneDeep(table.splitByCalculation);
      const activeDocumentMeta = Object.assign({}, home.activeDocumentMeta);
      const currentHeaderData = table.headerData.slice();
      let dashboardUpdateObj = {}; //fot updateObjIndex=0 only as it contains formula details
      let formulaColumnId = null,
        isPrevRowRef = false;
      headerUpdates.forEach((obj, updateObjIndex) => {
        /* update currentHeaderData(i.e.headerData) for each
      change; then update tableData for equation results
      and push changes to redux-store and firestore */
        const newColObj = {};
        if (obj.type) {
          newColObj.fieldType = obj.type;
          if (updateObjIndex === 0) {
            dashboardUpdateObj.fieldType = obj.type;
          }
        }
        if ('hasPrevRowRef' in obj) {
          newColObj.hasPrevRowRef = obj.hasPrevRowRef;
          if (updateObjIndex === 0) {
            isPrevRowRef = obj.hasPrevRowRef ? true : false;
          }
        }
        if ('nullPrevRowValue' in obj) {
          //condition checking in this way as obj.nullPrevRowValue can
          // have 0 as value which evaluates as false
          newColObj.nullPrevRowValue = obj.nullPrevRowValue;
        }

        const index =
          obj.index != null ? obj.index : currentHeaderData.length - 1;
        const oldColObj = Object.assign({}, currentHeaderData[index]);

        if (obj.eqn) {
          newColObj.eqn = obj.eqn;
          newColObj.eqnStr = obj.eqnStr;
          newColObj.subType = obj.subType;
          newColObj.eqnColName = obj.eqnColName;
          const columnProps =
            oldColObj.columnProperties || obj.columnProperties || {};
          newColObj.columnProperties =
            ColumnUtility.extractRequiredColumnProperties(
              columnProps,
              newColObj.fieldType,
              null,
            );

          if (updateObjIndex === 0) {
            dashboardUpdateObj.subType = obj.subType;
          }
        }
        if (obj.styleObj) {
          newColObj.styleObj = obj.styleObj;
        }

        if (updateObjIndex === 0) {
          formulaColumnId = `${oldColObj.id}`;
        }
        if (obj.subType === FIELD_TYPE_ID.UNIT && obj.unitDependentColumn) {
          newColObj.unitDependentColumn = obj.unitDependentColumn;
          if (updateObjIndex === 0) {
            const unitSymbol =
              !isEmpty(table.fileObj) &&
              table.fileObj[obj.unitDependentColumn]?.UNIT?.unitSymbol;
            if (unitSymbol) {
              dashboardUpdateObj.unitSymbol = unitSymbol;
            }
          }
        }
        const updatedHeader = Object.assign(
          {},
          omit(Object.assign({}, oldColObj), [
            'nullPrevRowValue',
            'hasPrevRowRef',
          ]),
          newColObj,
        );
        currentHeaderData.splice(index, 1, updatedHeader); //replaced header here
      });
      if (isEqual(currentHeaderData, table.headerData)) {
        //no change in headerData
        return;
      }
      const dashboardExistOnColumn =
        formulaColumnId &&
        !isEmpty(activeDocumentMeta.dashboards?.[formulaColumnId]);
      if (!dashboardExistOnColumn) {
        dashboardUpdateObj = {};
      }
      const tableData = table.tableData.slice();

      //if isPrevRowRef -> no calculation req!
      const eqnArrs = isPrevRowRef
        ? []
        : mapHeaderData(currentHeaderData).eqnArrs.filter(
            (eqnObj) =>
              `${eqnObj.colId}` === formulaColumnId ||
              (eqnObj?.eqnStr ?? '').includes(formulaColumnId),
          ); //only equations which are dependent on this formula column and, eqn of this formula column itself

      const rowsFirestoreUpdateIndexArr = [];
      const rowUpdateArr = [];
      const dashboardsRowDataChangeArr = [];
      const updatedTableData = isPrevRowRef
        ? table.tableData.slice()
        : tableData.map((rowObj, index) => {
            const newRowObj = solveRowAllEqns(rowObj, eqnArrs, null, null);
            if (!isEqual(rowObj, newRowObj)) {
              rowsFirestoreUpdateIndexArr.push(index);
              rowUpdateArr.push(newRowObj);
              dashboardsRowDataChangeArr.push({
                prev: rowObj,
                updated: newRowObj,
              });
            }
            return newRowObj;
          });

      const prevFooterData = cloneDeep(table.footerData);
      const newFooterData = {};
      if (!isEmpty(prevFooterData)) {
        forOwn(prevFooterData, (prevFooterObj, key) => {
          const totalObj = calculateTotal(
            updatedTableData,
            key,
            currentHeaderData,
          );
          newFooterData[key] = getTotalObject(
            Object.assign(
              {},
              prevFooterObj,
              key == formulaColumnId && isPrevRowRef
                ? {isVisible: false}
                : null,
            ),
            totalObj,
          );
        });
      }
      const currFormulaHeaderObj = Object.assign(
        {},
        !isNil(headerUpdates[0].index) &&
          table.headerData[headerUpdates[0].index],
      );
      const undoDashboardUpdateObj = {};
      if (dashboardExistOnColumn) {
        forOwn(dashboardUpdateObj, (_, key) => {
          const val = currFormulaHeaderObj[key];
          Object.assign(undoDashboardUpdateObj, {
            [key]: isNil(val) ? null : val,
          });
        });
      }
      const redoDashboardUpdateObj = dashboardExistOnColumn
        ? Object.assign({}, dashboardUpdateObj)
        : {};

      dispatch({
        type: TABLE_ACTION.EDIT_FORMULA_HEADER,
        payload: {
          updatedHeader: currentHeaderData,
          updatedTable: updatedTableData,
          newFooterData,
          prevFooterData,
          prevSplitByCalculation,
          undoDashboardUpdateObj,
          redoDashboardUpdateObj,
          dashboardColumnId: formulaColumnId,
          rowsFirestoreUpdateIndexArr,
        },
      });
      DocumentsMethods.updateMultipleRows(home.activeDocumentId, rowUpdateArr, {
        headerData: currentHeaderData,
        footerData: newFooterData,
      });
      tableActionHelper.checkAndUpdateEntryOnlyData({getState});
      if (!isEmpty(activeDocumentMeta.dashboards) && !isPrevRowRef) {
        //if some dashboard exists
        if (!isEmpty(dashboardUpdateObj) && dashboardExistOnColumn) {
          const updateObj = {};
          forOwn(dashboardUpdateObj, (val, key) => {
            Object.assign(updateObj, {
              [`pages.${home.activeDocumentId}.${key}`]:
                val == undefined ? null : val,
            });
          });
          updateAllDashboardsProperty(
            {[formulaColumnId]: activeDocumentMeta.dashboards[formulaColumnId]},
            updateObj,
          );
        }
        checkAndUpdateDashboard(
          home,
          currentHeaderData.slice(),
          table.splitByCalculation,
          prevFooterData,
          newFooterData,
          dashboardsRowDataChangeArr,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const addClientDataForPDF = (obj) => (dispatch) => {
  dispatch({
    type: TABLE_ACTION.ADD_CLIENT_INFO_FOR_PDF,
    payload: obj,
  });
};

/**
 * listener for footer properties for data for dashboards
 */
const activeDocumentFooterPropertiesListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    //listener on current document's/page's footerProperties like dashboard's splitByData
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const onDocumentDataChange = (QuerySnapshot) => {
        try {
          if (!QuerySnapshot.exists) {
            return;
          }
          const {
            home: {activeDocumentId},
          } = getState();
          if (docId === activeDocumentId) {
            const data = QuerySnapshot.data();
            const splitByCalculation = Object.assign(
              {},
              data.splitByCalculation,
            );
            dispatch({
              type: TABLE_ACTION.SET_SPLIT_BY_CALCULATIONS,
              payload: splitByCalculation,
            });
          }
        } catch (err) {
          captureInfo({
            QuerySnapshot,
            docId,
          });
          captureError(err);
        }
      };
      return FirestoreDB.FirestoreListener(
        FirestoreDB.documents.footerPropertiesRef(docId, firestoreInstance),
        onDocumentDataChange,
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 * action to perform task after initial fetch of table data or shared meta
 * or both
 */
const intitialFetchCallback = () => (dispatch, getState) => {
  try {
    //was used for rowIdIndex mapping
    //can be used for future functionalities
    const {table} = getState();
    if (
      table.initialFetchCompleted?.[INITIAL_FETCH_COMPLETED.TABLE_DATA] &&
      table.initialFetchCompleted?.[INITIAL_FETCH_COMPLETED.SHARED_META]
    ) {
      //both table data and shared meta has been set in reducer till now
      //(all meta has being available now)
    }
  } catch (error) {
    captureError(error);
  }
};

const updateInitialFetchObj = (payload) => (dispatch) => {
  try {
    dispatch({
      type: TABLE_ACTION.UPDATE_INITIAL_DATA_FETCH_OBJ,
      payload,
    });
    dispatch(intitialFetchCallback());
  } catch (error) {
    captureError(error);
  }
};

/**
 * to fetch rows data on scroll
 */
const getPaginatedTableData =
  (
    documentId,
    isDescOrderOfIndex = false, //fetch for reversed index
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId},
        table: {areAllRowsFetched, isLoadingMoreRows},
        version: {shouldCurrentTableReload},
        auth: {userPref},
      } = getState();

      const docIdToFetch = documentId ?? activeDocumentId;

      if (
        docIdToFetch &&
        !shouldCurrentTableReload &&
        !areAllRowsFetched &&
        !isLoadingMoreRows
      ) {
        dispatch({
          type: TABLE_ACTION.UPDATE_IS_LOADING_MORE_ROWS,
          payload: true,
        });
        return tableActionHelper
          .getPaginatedTableDataWrapper(
            docIdToFetch,
            getState().table,
            TABLE_LIMITS.INITIAL_FETCH_LIMIT,
            userPref,
            false,
            false,
          )
          .then((res) => {
            if (res?.success) {
              if (res.areAllRowsFetched) {
                dispatch({
                  type: TABLE_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE,
                  payload: true,
                });
              }
              dispatch({
                type: TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA,
                payload: {
                  tableData: res.updatedTableData,
                },
              });
            }
            dispatch({
              type: TABLE_ACTION.UPDATE_IS_LOADING_MORE_ROWS,
              payload: false,
            });
          });
      }
    } catch (error) {
      captureError(error);
    }
  };

/**
 *
 * @param {*} documentId : docId to activate listener for
 * @param {*} onInitalFetch : callback to process initial fetched data (first time ever)
 *                            All of userDocument/{docId} data along with "tableData" and "areAllRowsFetched" will be passed
 * @param {*} onRealtimeChange : on every change in data (header/footer/fileObj/any of the row)
 *                                All of userDocument/{docId} data along with "rowsData" ref will be passed which is optional (will not be passed always)
 * @param {*} firestoreInstance : only for mobile (refers to firestore instance)
 * @param {*} navigationRef : (refers to navigation ref: for existing the screen when doc is realtime deleted)
 * @param {*} initialFetchLimit : number of rows to fetch initially : if 0 or less than 0, then no rows will be fetched
 * @param {*} onDocumentDelete : callback if document is deleted/doc does not exist
 * @returns Listener deactivator function
 */
const documentDataListenerWrapper =
  (
    documentId,
    onInitalFetch, //callback to process initial fetched data
    onRealtimeChange, //callback to process post-initial fetched data
    firestoreInstance, //only for mobile
    navigationRef, //only for mobile
    initialFetchLimit = TABLE_LIMITS.INITIAL_FETCH_LIMIT,
    onDocumentDelete = null, //callback if document is deleted/doc does not exist
    checkForCustomSorting = false, //check for custom column level sorted rows
    debounceRealtimeChanges = false, //debounce on realtime changes
  ) =>
  (_, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      let isInitialFetch = true; // data fetched for first time ever since the listener got activated
      let isDescOrderOfIndex = false; //fetch for reversed index
      let isCompatible = true; //for web
      let lastFetchedTimestampMergedObj = {}; // to keep track of last fetched timestamp for each table
      const onFirstCall = (...args) => {
        try {
          return onInitalFetch(...args);
        } catch (err) {
          captureError(err);
        }
      };
      const onRealtimeChangeWrapper = (...args) => {
        try {
          return onRealtimeChange(...args);
        } catch (err) {
          captureError(err);
        }
      };
      const {
        auth: {
          userPref,
          user: {uid},
        },
        persistedData: {uniqueDeviceId},
      } = getState();
      const cloudUniqueDeviceId = `cf_${uniqueDeviceId}`;

      const removeOwnDeviceId = (source, deviceId) =>
        Object.assign({}, source, {
          [uid]: omit(Object.assign({}, source?.[uid]), [deviceId]),
        });

      const isWithoutRows =
        !isNumber(initialFetchLimit) || initialFetchLimit < 1;

      const getInitialRowsData = (customSortingKey = null) =>
        DocumentsMethods.getRowsData({
          docId: documentId,
          isLimited: true,
          limit: initialFetchLimit,
          isDescOrder: isDescOrderOfIndex,
          customSortingKey,
        });

      initialFetchLimit = initialFetchLimit ?? TABLE_LIMITS.INITIAL_FETCH_LIMIT;
      const initialRowsData =
        checkForCustomSorting || isWithoutRows
          ? Promise.resolve()
          : getInitialRowsData(); //assigning promise

      const onDocumentDataChange = async (QuerySnapshot) => {
        try {
          const isLocalChange = Boolean(
            QuerySnapshot.metadata.hasPendingWrites,
          );
          const isFromCache = Boolean(QuerySnapshot.metadata.fromCache);

          if (!isCompatible) {
            return false;
          }

          if (!QuerySnapshot.exists) {
            /**
             * document doesn't exist :
             * 1. User is offline
             * 2. Document doesn't actually exist in DB
             */
            typeof onDocumentDelete === 'function' && onDocumentDelete();
            return isInitialFetch
              ? ShowToast(
                  isFromCache
                    ? 'Fetching data failed. Make sure you have an active internet connection.'
                    : 'This document does not exist.',
                  userPref,
                )
              : null;
          }

          const data = QuerySnapshot.data();

          if (isLocalChange && data.lastFetchedTimestamp === null) {
            //local row edit (fieldvalue call)
            return;
          }

          const latestLastFetchedTimestampMergedObj = {
            time: data.lastFetchedTimestamp ?? firestore(true).Timestamp.now(),
            userObj: removeOwnDeviceId(data.lastFetchedUserObj, uniqueDeviceId),
            //remove current device id from lastFetchedUserObj
          };

          const fileObjForCustomSort = checkForCustomSorting
            ? data.fileObj
            : {};
          const {isCustomSorted, sortingKey, isDesc} =
            tableActionHelper.getTableSortingOrder(fileObjForCustomSort);

          isDescOrderOfIndex = isDesc;

          if (isInitialFetch) {
            //first time data fetched
            isInitialFetch = false;
            if (ENV) {
              const {checkFieldTypeCompatibility} = require('../imports');
              isCompatible = checkFieldTypeCompatibility({
                navigation: navigationRef,
                userPref,
                headerData: Object.values(Object.assign({}, data.headerData)),
              });
            }
            if (!isCompatible) {
              return false;
            }

            lastFetchedTimestampMergedObj = latestLastFetchedTimestampMergedObj;

            const rowsData = !checkForCustomSorting
              ? await initialRowsData //already awaiting promise
              : await getInitialRowsData(isCustomSorted ? sortingKey : null);

            if (!isWithoutRows && rowsData === null) {
              return ShowToast(
                'Fetching rows failed due to network issue.',
                userPref,
              );
            }

            return onFirstCall(
              Object.assign(
                {},
                data,
                isWithoutRows
                  ? null
                  : {
                      tableData: tableActionHelper.processRowsData(
                        rowsData,
                        null,
                        [],
                        null,
                        fileObjForCustomSort,
                      ),
                      areAllRowsFetched:
                        rowsData.docs.length < initialFetchLimit,
                    },
              ),
            );
          } else {
            //data fetched after first time
            let isSelfMadeEntryViaCloud = false;
            if (
              !isWithoutRows &&
              !isEqual(
                lastFetchedTimestampMergedObj?.time,
                latestLastFetchedTimestampMergedObj.time,
              )
            ) {
              //some change in some row
              const hasSomeRowChanged =
                !isNil(lastFetchedTimestampMergedObj?.time) &&
                !isEqual(
                  lastFetchedTimestampMergedObj.userObj,
                  latestLastFetchedTimestampMergedObj.userObj,
                );
              if (!hasSomeRowChanged) {
                //self edited row
                lastFetchedTimestampMergedObj =
                  latestLastFetchedTimestampMergedObj;
                return null;
              }
              isSelfMadeEntryViaCloud = isEqual(
                removeOwnDeviceId(
                  lastFetchedTimestampMergedObj.userObj,
                  cloudUniqueDeviceId,
                ),
                removeOwnDeviceId(
                  latestLastFetchedTimestampMergedObj.userObj,
                  cloudUniqueDeviceId,
                ),
              );
              const queryTime = isNumber(lastFetchedTimestampMergedObj.time)
                ? new Date(lastFetchedTimestampMergedObj.time)
                : lastFetchedTimestampMergedObj.time.toDate();
              const rowsData = await DocumentsMethods.getRowsData({
                docId: documentId,
                lastFetchedTimestamp: queryTime,
                isLimited: false,
                isDescOrder: isDescOrderOfIndex,
                customSortingKey: isCustomSorted ? sortingKey : null,
              });
              if (rowsData === null) {
                lastFetchedTimestampMergedObj =
                  latestLastFetchedTimestampMergedObj;
                return ShowToast(
                  'Fetching rows failed due to network issue.',
                  userPref,
                );
              } else if (rowsData.docs.length) {
                onRealtimeChangeWrapper(
                  Object.assign({}, data, {rowsData}),
                  isDescOrderOfIndex,
                  isSelfMadeEntryViaCloud,
                );
              }
            } else {
              //headerData or footerData or fileObj or other data changed
              onRealtimeChangeWrapper(
                Object.assign({}, data),
                isDescOrderOfIndex,
                isSelfMadeEntryViaCloud,
              );
            }
            lastFetchedTimestampMergedObj = latestLastFetchedTimestampMergedObj;
          }
        } catch (err) {
          captureInfo({err: serializeError(err), QuerySnapshot});
          captureError(
            new Error('Error in activateDocumentListener onDocumentDataChange'),
          );
        }
      };

      let listenerCallback = onDocumentDataChange;

      if (debounceRealtimeChanges) {
        listenerCallback = debounce(onDocumentDataChange, 2000, {
          maxWait: 8000,
          trailing: true,
        });
      }

      return FirestoreDB.FirestoreListener(
        FirestoreDB.documents.documentRef(documentId, firestoreInstance),
        (...args) =>
          (isInitialFetch ? onDocumentDataChange : listenerCallback)(...args),
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 *listener on actual table headerData,footerData,fileObj etc
 */
const activateDocumentListener =
  (docId, firestoreInstance, navigationRef) => (dispatch, getState) => {
    //listener on current document's/page's data
    try {
      const {
        table: {helperFunctionRefs},
        home: {originalDocumentId},
      } = getState();

      const processData = (data) => {
        const {
          home: {activeDocumentMeta, activeDocumentId},
        } = getState();

        if (activeDocumentId !== docId) {
          //if somehow listerner changed
          return null;
        }

        const collab = !isEmpty(activeDocumentMeta?.collab)
          ? activeDocumentMeta.collab
          : {};

        const originalHeaderData = Object.values(
          Object.assign({}, data.headerData),
        );

        /**
         * Filter Restricted Columns when CUSTOM shared Document
         */
        const isCustomSharedUser =
          collab.permission === SHARE_PERMISSION_TYPE.CUSTOM;
        const finalHeaderData = isCustomSharedUser
          ? restrictHeaderData(activeDocumentMeta, originalHeaderData)
          : originalHeaderData;
        if (
          finalHeaderData.length === 0 &&
          isCustomSharedUser &&
          isFunction(helperFunctionRefs.SHOW_EMPTY_HEADER_TOAST)
        ) {
          helperFunctionRefs.SHOW_EMPTY_HEADER_TOAST();
          return null;
        }

        const payload = {};
        payload.headerData = finalHeaderData.slice();
        payload.footerData = Object.assign({}, data.footerData);
        payload.fileObj = Object.assign({}, data.fileObj);
        payload.noOfRows = data.noOfRows;
        payload.originalHeaderData = originalHeaderData;
        if (Array.isArray(payload.tableData)) {
          payload.emptyRowIndexes = tableActionHelper.getEmptyRowIndex(
            payload.tableData,
            payload.headerData,
          );
        }

        return payload;
      };

      const dispatchPayload = (payload, isInitialFetch = false) => {
        const {
          table: {frozenColumnIds},
          version: {shouldCurrentTableReload: isViewingVersion},
        } = getState();
        dispatch({
          type: isInitialFetch
            ? TABLE_ACTION.LOAD_TABLE_DATA
            : isViewingVersion
            ? VERSION_ACTION.BACKUP_TABLE_DATA
            : TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA,
          payload,
        });

        if (
          frozenColumnIds?.length &&
          frozenColumnIds.some(
            (columnId) =>
              !payload.headerData.some((colId) => `${columnId}` === `${colId}`),
          )
        ) {
          //column frozen but that column is not available in headerData
          dispatch(unfreezeDocumentColumns());
        }

        if (isInitialFetch) {
          //keep it  @ last
          dispatch(loadAutomationConfig(originalDocumentId, null, true));
          dispatch(setVisibleTasks());
          dispatch(
            updateInitialFetchObj({
              [INITIAL_FETCH_COMPLETED.TABLE_DATA]: true,
            }),
          );
        }
      };

      const onInitialFetchCallback = (data) => {
        const payload = processData(data);

        if (!isEmpty(payload)) {
          PrevRowRefEqnHelper.reset();
          payload.areAllRowsFetched = data.areAllRowsFetched;
          payload.tableData = data.tableData;
          if (Array.isArray(payload.tableData)) {
            payload.emptyRowIndexes = tableActionHelper.getEmptyRowIndex(
              payload.tableData,
            );
          }
          return dispatchPayload(payload, true);
        }
      };

      const onRealtimeChangeCallback = (data) => {
        const payload = processData(data);
        if (!isEmpty(payload)) {
          if (data.rowsData?.docs?.length) {
            const {
              table: {tableData, areAllRowsFetched},
              searchFilter: {isActive: isSearchFilterActive},
            } = getState();
            const indexUpperLimit =
              !areAllRowsFetched && tableData.length > 0
                ? tableData[tableData.length - 1].index
                : null;
            payload.tableData = tableActionHelper.processRowsData(
              data.rowsData,
              indexUpperLimit,
              null,
              payload.noOfRows,
            );
            if (isSearchFilterActive) {
              dispatch(updateSearchFilterTableData(data.rowsData.docs, true));
            }
          }
          return dispatchPayload(payload);
        }
      };

      return dispatch(
        documentDataListenerWrapper(
          docId,
          onInitialFetchCallback,
          onRealtimeChangeCallback,
          firestoreInstance,
          navigationRef,
          TABLE_LIMITS.INITIAL_FETCH_LIMIT,
          null,
          false,
          false,
        ),
      );
    } catch (error) {
      captureError(error);
    }
  };

const activateUniqueColumnValuesListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    firestoreInstance = firestoreInstance ?? firestore;
    const handleListener = (snapshot) => {
      try {
        const uniqueColumnData = {};
        const removedColIdForUnique = [];
        const changedDocuments = snapshot.docChanges();
        changedDocuments.forEach((change) => {
          try {
            const colId = change.doc.id;
            const docData = change.doc.data();
            switch (change.type) {
              case 'added':
              case 'modified': {
                uniqueColumnData[colId] = docData?.data ?? {};
                break;
              }
              case 'removed': {
                removedColIdForUnique.push(colId);
                break;
              }
            }
          } catch (error) {
            captureError(error);
          }
        });
        let newData = Object.assign(
          {},
          getState().table.uniqueColumnData,
          uniqueColumnData,
        );
        newData = omit(newData, removedColIdForUnique);

        dispatch({
          type: TABLE_ACTION.ADD_UNIQUE_VALUE,
          payload: newData,
        });
      } catch (error) {
        captureError(error);
      }
    };
    return FirestoreDB.FirestoreListener(
      FirestoreDB.documents.activeUniqueColDocRef(docId, firestoreInstance),
      handleListener,
    );
  };

/**
 * listen(callback) to document meta changes (which is active)
 */
const handleDocumentMetaListenerChanges =
  (Snapshot) => (dispatch, getState) => {
    //this event is always triggered from homeActions's documents listener
    try {
      const {
        home,
        table: {helperFunctionRefs},
        auth: {user, userPref},
      } = getState();
      if (!user?.uid) {
        return;
      }
      const currFileMeta = Object.assign({}, home.activeDocumentMeta);
      const isCollabDoc = !isEmpty(currFileMeta.collab) ? true : false;
      const isOwner = isCollabDoc && currFileMeta.collab.isOwner ? true : false;
      const isEntryOnlyUser =
        isCollabDoc &&
        currFileMeta.collab.permission === SHARE_PERMISSION_TYPE.ENTRY_ONLY;
      if (Snapshot.type === 'removed') {
        //file unshared or removed on some other device
        if (isEntryOnlyUser) {
          ShowToast(
            'Your access from this document has been removed.',
            userPref,
          );
          return helperFunctionRefs.NAVIGATION_REF.pop?.();
        }
        return helperFunctionRefs.PERMISSION_CHANGE_ACTION(
          null,
          null,
          true,
          true,
          isCollabDoc,
        );
      } else {
        const receivedMeta = Object.assign({}, Snapshot.doc.data());
        let updatedMeta = Object.assign({}, currFileMeta, receivedMeta);
        if (isEmpty(receivedMeta.collab)) {
          updatedMeta = omit(updatedMeta, ['collab']);
        }
        if (currFileMeta.activePageId !== receivedMeta.activePageId) {
          //if some changes related to page
          if (!receivedMeta.activePageId && currFileMeta.activePageId) {
            //if pages disabled by someone else or on some other device
            if (home.activeDocumentId !== home.originalDocumentId) {
              //current page is not original single page doc
              return helperFunctionRefs.PAGES_ACTION('disabled');
            }
          } else if (currFileMeta.activePageId && receivedMeta.activePageId) {
            //page change on some other device by same user
            return helperFunctionRefs.PAGES_ACTION('change');
          }
        } else {
          if (isCollabDoc) {
            //shared doc
            if (!isOwner) {
              /**
               * Update Table View Modes
               */
              dispatch(manageTableViewModesFromMeta(updatedMeta));
              dispatch(tableHeaderDataFilterRestricted(updatedMeta));

              if (
                currFileMeta.collab.permission !== updatedMeta.collab.permission
              ) {
                //permission changed
                if (
                  updatedMeta.collab.permission ===
                  SHARE_PERMISSION_TYPE.ENTRY_ONLY
                ) {
                  return helperFunctionRefs.PERMISSION_CHANGE_ACTION(
                    null,
                    null,
                    true,
                    false,
                  );
                } else if (isEntryOnlyUser) {
                  //permission of entry only user changed
                  ShowToast(
                    'Your permission for this document has been changed.',
                    userPref,
                  );
                  return helperFunctionRefs.NAVIGATION_REF.pop?.();
                } else {
                  helperFunctionRefs.PERMISSION_CHANGE_ACTION(
                    currFileMeta.collab.permission,
                    updatedMeta.collab.permission,
                  );
                }
              }
            } else {
              //owner
              if (isEmpty(receivedMeta.collab)) {
                //all users left sharing
                dispatch({
                  type: COLLABORATION_ACTION.LOAD_COLLAB_META,
                  payload: {sharedWith: [], ownerDetails: {}},
                });
              }
            }
          } else {
            //not a shared doc
            if (receivedMeta.pageObj?.enabled) {
              const activePageId = home.originalDocumentId; //fallback
              const updateFallbackDocId = () => {
                const filesArr = home.files.slice();
                const index = filesArr.findIndex(
                  (obj) => obj.documentId === activePageId,
                );
                filesArr.splice(index, 1, {
                  documentId: activePageId,
                  documentMeta: Object.assign(
                    {},
                    filesArr[index].documentMeta,
                    receivedMeta,
                    {activePageId},
                  ),
                });
                dispatch({
                  type: HOME_ACTION.LOAD_FILES,
                  payload: {files: filesArr},
                });
                reloadVisibleFiles(dispatch, getState);
              };
              if (!receivedMeta.activePageId) {
                updateFallbackDocId();
                setFileDetails(user.uid, home.originalDocumentId, {
                  activePageId,
                  collab: null,
                });
              }
            }
            if (receivedMeta.isDocumentLock !== currFileMeta.isDocumentLock) {
              //document locked on some other device
              dispatch(
                updateTableViewModes({
                  [TABLE_VIEW_MODES.DOCUMENT_LOCK]: receivedMeta.isDocumentLock
                    ? true
                    : false,
                }),
              );
            }
          }
        }
        dispatch({
          type: HOME_ACTION.UPDATE_DOCUMENT_META,
          payload: updatedMeta,
        });
      }
    } catch (error) {
      captureInfo({Snapshot});
      captureError(error);
    }
  };

/**
 * listener on sharedDocsMeta
 */
const activateSharedDocMetaListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    //listener on shared meta of a shared/collab file
    try {
      dispatch({
        type: HOME_ACTION.START_SHARED_PAGES_META_LOADING,
      });
      firestoreInstance = firestoreInstance ?? firestore;
      let isInitialFetch = true;
      let isCalledAtLeastOnce = false;
      const {
        auth: {userPref, user},
        table: {helperFunctionRefs},
        home: {originalDocumentId},
      } = getState();

      if (!user?.uid) {
        return;
      }

      const onSharedDocMetaChange = async (QuerySnapshot) => {
        try {
          const isLocalChange = QuerySnapshot.metadata.hasPendingWrites && true;
          if (!isCalledAtLeastOnce) {
            dispatch({
              type: HOME_ACTION.STOP_SHARED_PAGES_META_LOADING,
            });
            isCalledAtLeastOnce = true;
          }
          if (isInitialFetch) {
            dispatch(getLinkedDocIds(QuerySnapshot)).then((linkedDocIds) => {
              if (linkedDocIds && linkedDocIds?.length) {
                dispatch(
                  fetchParentFileData({
                    isInitialFetch: true,
                    docSnap: QuerySnapshot,
                  }),
                );
              }
            });
          }
          if (
            (!isLocalChange || isInitialFetch) &&
            QuerySnapshot.exists &&
            docId === originalDocumentId
          ) {
            const {
              home: {activeDocumentMeta, files, activeFileIndex},
            } = getState();
            let {activePageId} = activeDocumentMeta;
            const filesArr = files.slice();
            const sharedDocMeta = QuerySnapshot.data();
            const pagesEnabled = sharedDocMeta.pageObj?.enabled ? true : false;
            if (pagesEnabled) {
              if (!activePageId) {
                //enabled now
                activePageId = originalDocumentId;
                Object.assign(sharedDocMeta, {activePageId});
                setFileDetails(user.uid, originalDocumentId, {
                  activePageId,
                  collab: activeDocumentMeta.collab,
                });
              } else {
                const pages = sharedDocMeta.pages || [];
                const exist =
                  pages.find((obj) => obj?.id === activePageId) && true;
                if (!exist) {
                  //page removed
                  activePageId = originalDocumentId;
                  setFileDetails(user.uid, originalDocumentId, {
                    activePageId,
                    collab: activeDocumentMeta.collab,
                  });
                  const updateFallbackDocId = () => {
                    try {
                      const {home} = getState();
                      const filesArrCopy = home.files.slice();
                      const index = filesArrCopy.findIndex(
                        (obj) => obj.documentId === activePageId,
                      );
                      filesArrCopy.splice(index, 1, {
                        documentId: activePageId,
                        documentMeta: Object.assign(
                          {},
                          filesArrCopy[index].documentMeta,
                          activeDocumentMeta,
                          sharedDocMeta,
                          {activePageId},
                        ),
                      });
                      dispatch({
                        type: HOME_ACTION.LOAD_FILES,
                        payload: {files: filesArrCopy},
                      });
                      reloadVisibleFiles(dispatch, getState);
                    } catch (err) {
                      captureError(err);
                    }
                  };
                  dispatch({
                    type: HOME_ACTION.UPDATE_DOCUMENT_META,
                    payload: Object.assign(
                      {},
                      activeDocumentMeta,
                      sharedDocMeta,
                      {activePageId: null},
                    ),
                  });
                  return helperFunctionRefs.PAGES_ACTION(
                    'removed',
                    updateFallbackDocId,
                  );
                }
              }
            } else {
              if (activePageId) {
                // If pages have been disabled by a shared user

                setFileDetails(user.uid, originalDocumentId, {
                  activePageId: null,
                  collab: activeDocumentMeta.collab,
                });

                const {home} = getState();
                const filesArrCopy = home.files.slice();
                const index = filesArrCopy.findIndex(
                  (obj) => obj.documentId === originalDocumentId,
                );
                filesArrCopy.splice(index, 1, {
                  documentId: originalDocumentId,
                  documentMeta: Object.assign(
                    {},
                    filesArrCopy[index].documentMeta,
                    activeDocumentMeta,
                    sharedDocMeta,
                    {activePageId: null},
                  ),
                });
                dispatch({
                  type: HOME_ACTION.LOAD_FILES,
                  payload: {files: filesArrCopy},
                });
                reloadVisibleFiles(dispatch, getState);
              }
            }
            let msg;

            if (
              activeDocumentMeta.isGlobalDocumentLock !=
              sharedDocMeta.isGlobalDocumentLock
            ) {
              const {collab} = activeDocumentMeta;
              const isDocumentLock = sharedDocMeta.isGlobalDocumentLock
                ? true
                : !isEmpty(collab) &&
                  (collab.isOwner ||
                    [
                      SHARE_PERMISSION_TYPE.ADMIN,
                      SHARE_PERMISSION_TYPE.OWNER,
                    ].includes(collab.permission))
                ? false
                : activeDocumentMeta.isDocumentLock
                ? true
                : false;

              //  Update Table View Modes
              dispatch(
                updateTableViewModes({
                  [TABLE_VIEW_MODES.DOCUMENT_LOCK]: isDocumentLock,
                }),
              );

              if (
                !isEmpty(collab) &&
                collab.permission !== SHARE_PERMISSION_TYPE.CAN_VIEW
              ) {
                if (sharedDocMeta.isGlobalDocumentLock) {
                  msg = 'Document has been locked by some admin or owner.';
                } else if (!isInitialFetch) {
                  msg = 'Document has been unlocked by some admin or owner.';
                }
              }
            }
            if ('isRowIdAdded' in sharedDocMeta) {
              activeDocumentMeta.isRowIdAdded = sharedDocMeta.isRowIdAdded;
            }
            const updatedMeta = Object.assign(
              {},
              activeDocumentMeta,
              sharedDocMeta,
              {activePageId: pagesEnabled ? activePageId : null},
            );

            dispatch({
              type: HOME_ACTION.UPDATE_DOCUMENT_META,
              payload: updatedMeta,
            });
            filesArr.splice(activeFileIndex, 1, {
              documentId: docId,
              documentMeta: updatedMeta,
            });
            dispatch({
              type: HOME_ACTION.LOAD_FILES,
              payload: {files: filesArr},
            });
            reloadVisibleFiles(dispatch, getState);
            if (msg?.length) {
              ShowToast(msg, userPref, true, true);
            }
            if (isInitialFetch) {
              //keep it  @ last
              dispatch(
                updateInitialFetchObj({
                  [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
                }),
              );
              if (sharedDocMeta.isAutomationEnabled) {
                dispatch(loadAutomationConfig(originalDocumentId, null, true));
                dispatch(
                  loadPendingAutomations(
                    pagesEnabled ? activePageId : originalDocumentId,
                  ),
                );
              }
              isInitialFetch = false;
            }
          }
        } catch (err) {
          captureInfo({QuerySnapshot, docId});
          captureError(err);
        }
      };
      return FirestoreDB.FirestoreListener(
        firestoreInstance().collection('sharedDocsMeta').doc(docId),
        onSharedDocMetaChange,
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 * action to use functions defined on app/web-app side to call
 * directly from rb-redux
 */
const addToHelperFunctionRefs = (obj) => (dispatch) => {
  dispatch({
    type: TABLE_ACTION.UPDATE_HELPER_FUNCTION_REFS,
    payload: obj,
  });
};

/**
 * adds dummy meta data to the file for enable pages modal!
 */
const addDummyMetaData = (filePageType, backup) => (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentMeta, activeDocumentId},
      auth: {user},
    } = getState();
    if (!user?.uid) {
      return;
    }
    const docId = activeDocumentId || `${user.uid}_${moment().valueOf()}`;

    let pageEnabled = true,
      pageType = filePageType,
      pages = [
        {
          id: docId,
          createdTimestamp: moment().valueOf(),
          displayColObj: {},
          pageName: getPageNameStr(filePageType),
        },
      ],
      activePageId = docId;

    if (!filePageType) {
      pageEnabled = false;
      pageType = '';
      pages = [];
      activePageId = null;
    }

    let pageMetaData = {
      ...activeDocumentMeta,
      pageObj: {
        enabled: pageEnabled,
        type: pageType,
      },
      pages,
      activePageId,
    };

    if (!isEmpty(backup)) {
      pageMetaData = {...activeDocumentMeta, ...backup};
    }

    dispatch({
      type: HOME_ACTION.UPDATE_DOCUMENT_META,
      payload: pageMetaData,
    });
  } catch (error) {
    captureError(error);
  }
};

/**
 * undo action handler
 */
const undo = () => (dispatch, getState) => {
  try {
    const {table, home} = getState();
    const undoArr = table.undo.slice();
    if (undoArr.length === 0) {
      return;
    }
    const lastUndoObj = undoArr[undoArr.length - 1];
    if (typeof lastUndoObj.extra?.setNewFooterAsHeader === 'function') {
      lastUndoObj.extra.setPrevFooterAsHeader();
    }
    const isShared = !isEmpty(home.activeDocumentMeta.collab);
    dispatch({
      type: TABLE_ACTION.UNDO,
      payload: {
        docId: home.activeDocumentId,
        isShared,
        activeDocumentMeta: home.activeDocumentMeta,
        latestReduxState: getState(),
      },
    });
    logAnalyticsEvent('UNDO', {
      docId: home.originalDocumentId,
      ACTION_TYPE: lastUndoObj.ACTION_TYPE,
    });
  } catch (error) {
    captureError(error);
  }
};

/**
 * redo action handler
 */
const redo = () => (dispatch, getState) => {
  try {
    const {table, home} = getState();
    const redoArr = table.redo.slice();
    if (redoArr.length === 0) {
      return;
    }
    const lastRedoObj = redoArr[redoArr.length - 1];
    if (typeof lastRedoObj.extra?.setNewFooterAsHeader === 'function') {
      lastRedoObj.extra.setNewFooterAsHeader();
    }
    const isShared = !isEmpty(home.activeDocumentMeta.collab);
    dispatch({
      type: TABLE_ACTION.REDO,
      payload: {
        docId: home.activeDocumentId,
        isShared,
        activeDocumentMeta: home.activeDocumentMeta,
        latestReduxState: getState(),
      },
    });
    logAnalyticsEvent('REDO', {
      docId: home.originalDocumentId,
      ACTION_TYPE: lastRedoObj.ACTION_TYPE,
    });
  } catch (error) {
    captureError(error);
  }
};

/**
 * delete/clear data from multiple cell at once
 */
const deleteSelectedRowAndCols =
  (row, col, selectedElement) => (dispatch, getState) => {
    try {
      const {
        home,
        table,
        tableLinks: {parentRowMeta},
      } = getState();

      switch (selectedElement) {
        case 'ROW': {
          return dispatch(deleteRow(row));
        }
        case 'COL': {
          const headerData = table.headerData.slice();
          const colArr = [];
          for (let i = 0; i < headerData.length; i++) {
            if (headerData[i].id in col) {
              const obj = {
                colId: headerData[i].id,
                index: i,
                fieldType: headerData[i].fieldType,
              };
              colArr.push(Object.assign({}, obj));
            }
          }
          return dispatch(deleteCol(colArr));
        }
        case 'BOTH': {
          // only modifies table data
          const modifiedRowIdArr = [],
            modifiedColIdArr = [];
          const uniqueColumnData = cloneDeep(table.uniqueColumnData);
          const prevSplitByCalculation = cloneDeep(table.splitByCalculation);
          const headerData = table.headerData.slice();
          const tableData = table.tableData.slice();
          const footerData = Object.assign({}, cloneDeep(table.footerData));
          let updatedFooterData = Object.assign({}, table.footerData);
          const activeDocId = home.activeDocumentId;
          const {eqnArrs} = table.headerMappedValues;
          const rowsFirestoreUpdateObjArr = [],
            rowsFirestoreUpdateUndoObjArr = [];

          /** Child Links Variables */
          const childLinkRemoveArr = [];

          const deletedTaskIds = [];
          const formulaHeaderIds = {}; //id of all columns with formula
          const assignTaskHeaderIds = {};
          let commentColumnId = null;

          const nonEditableFields = [];

          headerData.forEach(({id, fieldType, columnProperties}) => {
            if (fieldType === FIELD_TYPE_ID.COMMENT) {
              commentColumnId = id;
            } else if (fieldType === FIELD_TYPE_ID.FORMULA) {
              formulaHeaderIds[id] = true;
            } else if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
              assignTaskHeaderIds[id] = true;
            }
            if (
              columnProperties?.[COLUMN_PROPERTY_KEYS.NON_EDITABLE_DATA] ===
              true
            ) {
              nonEditableFields.push(id);
            }
          });

          let parentRowMetaObj = {};

          const updatedTableData = tableData.slice();

          const colKeys = Object.keys(col).filter(
            (item) =>
              `${item}` !== `${commentColumnId}` &&
              !nonEditableFields.includes(item),
          );

          const footerAvailable =
            !isEmpty(footerData) ||
            !isEmpty(home.activeDocumentMeta.dashboards);

          row.forEach((index) => {
            let rowObject = tableData[index];
            const removeObject = {rowId: rowObject?.rowId};

            colKeys.forEach((columnId) => {
              const colIndex =
                table.headerMappedValues?.headerIdIndexMap?.[columnId];
              const colObj = table.headerData[colIndex];
              Object.assign(removeObject, {[columnId]: rowObject[columnId]});
              if (assignTaskHeaderIds[columnId]) {
                //if assign column cell
                const taskId = rowObject?.[columnId]?.val?.taskId;
                if (taskId?.length) {
                  deletedTaskIds.push(taskId);
                  modifyTaskActiveState(home.activeDocumentId, taskId, false);
                }
              }
              if (columnId in uniqueColumnData) {
                const checkVal =
                  colObj.fieldType === FIELD_TYPE_ID.TIME
                    ? `${getPrintTime(rowObject[columnId]?.val)}`
                        .toLowerCase()
                        .trim()
                    : rowObject[columnId]?.val
                    ? `${rowObject[columnId]?.val}`.toLowerCase().trim()
                    : '';
                if (checkVal in uniqueColumnData[columnId]) {
                  if (!modifiedRowIdArr.includes(rowObject?.rowId)) {
                    modifiedRowIdArr.push(rowObject?.rowId);
                  }
                  if (!modifiedColIdArr.includes(columnId)) {
                    modifiedColIdArr.push(columnId);
                  }
                  uniqueColumnData[columnId][checkVal] =
                    !uniqueColumnData[columnId][checkVal];
                }
              }
              if (!formulaHeaderIds[columnId]) {
                //not a formula column
                const parentRowMetaId = rowObject?.[columnId]?.parentRowMeta;
                if (
                  checkIfPlainText(parentRowMetaId) &&
                  !isEmpty(parentRowMeta?.meta?.[parentRowMetaId])
                ) {
                  parentRowMetaObj = Object.assign(
                    {},
                    {[parentRowMetaId]: parentRowMeta.meta[parentRowMetaId]},
                    parentRowMetaObj,
                  );
                }
                rowObject = omit(rowObject, [columnId]);
              }
            });

            childLinkRemoveArr.push(removeObject);

            const updatedRow = solveRowAllEqns(rowObject, eqnArrs, null, null);
            updatedTableData.splice(index, 1, updatedRow);
            if (!isEqual(updatedRow, tableData[index])) {
              rowsFirestoreUpdateObjArr.push(updatedRow);
              rowsFirestoreUpdateUndoObjArr.push(tableData[index]);

              if (footerAvailable) {
                updatedFooterData = reCalculateFooter(
                  updatedRow,
                  tableData[index],
                  {doNotUpdateDashboard: true, footerToUse: updatedFooterData},
                );
              }
            }
          });

          if (footerAvailable) {
            checkAndUpdateDashboard(
              home,
              table.headerData.slice(),
              table.splitByCalculation,
              footerData,
              updatedFooterData,
              rowsFirestoreUpdateObjArr.map((updatedRowObj, index) => {
                return {
                  prev: rowsFirestoreUpdateUndoObjArr[index],
                  updated: updatedRowObj,
                };
              }),
            );
          }

          if (!isEmpty(uniqueColumnData)) {
            for (const colId in uniqueColumnData) {
              DocumentsMethods.updateUniqueValuesDataForColumn(
                activeDocId,
                colId,
                {data: uniqueColumnData[colId]},
              );
            }
          }

          /** Table Linking */
          removeParentRowMetas(parentRowMetaObj, activeDocId);
          const {
            extraRedoActions: tableLinkRedoActions,
            extraUndoActions: tableLinkUndoActions,
          } = getTableUndoRedoActions(
            TABLE_UNDO_REDO_TYPES.MULTIPLE_ROW_COL_DELETE,
            {
              addedParentRowMetaData: parentRowMetaObj,
              activeDocumentId: activeDocId,
            },
          );

          let extraRedoActions = [...tableLinkRedoActions];
          let extraUndoActions = [...tableLinkUndoActions];

          /** Child Links Calls */
          const {
            extraUndoActions: childLinkUndoActions,
            extraRedoActions: childLinkRedoActions,
          } = dispatch(deleteChildLinks(childLinkRemoveArr));
          extraUndoActions = [...extraUndoActions, ...childLinkUndoActions];
          extraRedoActions = [...extraRedoActions, ...childLinkRedoActions];

          const deletedRowIdsArr = [];

          dispatch({
            type: TABLE_ACTION.DELETE_ROW_COL_DATA,
            payload: {
              modifiedColIdArr,
              modifiedRowIdArr,
              tableData: updatedTableData,
              prevTableData: tableData,
              extraRedoActions,
              extraUndoActions,
              prevFooterData: footerData,
              updatedFooterData,
              prevSplitByCalculation,
              deletedTaskIds,
              rowsFirestoreUpdateObjArr,
              rowsFirestoreUpdateUndoObjArr,
              updatedFileObj: Object.assign({}, table.fileObj, {
                ...(deletedTaskIds.length
                  ? {
                      tasksCount: table.fileObj.tasksCount
                        ? table.fileObj.tasksCount - deletedTaskIds.length
                        : 0,
                    }
                  : {}),
              }),
            },
          });
          if (rowsFirestoreUpdateObjArr.length) {
            DocumentsMethods.updateMultipleRows(
              activeDocId,
              rowsFirestoreUpdateObjArr,
              null,
              false,
              true,
            );
          }
          DocumentsMethods.deleteOrRestoreMultipleRows(
            activeDocId,
            deletedRowIdsArr,
            {footerData: updatedFooterData},
          ); //delete
          break;
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const setCarryForwardColumn = (colId, addValue) => (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentMeta, activeFileIndex, files, originalDocumentId},
      auth: {user},
    } = getState();

    if (!user?.uid) {
      return;
    }

    const filesArr = files.slice();
    let carryForwardColumns = activeDocumentMeta.carryForwardColumns || [];

    if (addValue) {
      carryForwardColumns = [colId, ...carryForwardColumns];
    } else {
      carryForwardColumns = carryForwardColumns.filter((col) => col !== colId);
    }

    const updatedMeta = {
      ...activeDocumentMeta,
      carryForwardColumns,
    };

    const originalDocId = originalDocumentId;

    filesArr.splice(activeFileIndex, 1, {
      documentId: originalDocId,
      documentMeta: updatedMeta,
    });

    dispatch({
      type: HOME_ACTION.UPDATE_DOCUMENT_META,
      payload: updatedMeta,
    });

    dispatch({
      type: HOME_ACTION.LOAD_FILES,
      payload: {files: filesArr},
    });

    setFileDetails(user.uid, originalDocId, {
      carryForwardColumns,
      collab: updatedMeta.collab,
    });

    reloadVisibleFiles(dispatch, getState);
  } catch (error) {
    captureError(error);
  }
};

const updateHeaderSmsObj = (index, obj) => (dispatch, getState) => {
  try {
    const {
      table: {headerData},
      home: {activeDocumentId},
    } = getState();
    if (
      index < headerData.length &&
      headerData[index].fieldType === FIELD_TYPE_ID.CONTACT
    ) {
      const updatedObj = Object.assign({}, headerData[index], obj);
      headerData.splice(index, 1, updatedObj);
      dispatch({
        type: TABLE_ACTION.UPDATE_SMS_OBJ,
        payload: headerData,
      });
      DocumentsMethods.updateHeaderDataAtIndex(
        activeDocumentId,
        index,
        updatedObj,
      );
    }
  } catch (error) {
    captureError(error);
  }
};

const getHelpViaWhatsapp = (helpText) => async (dispatch, getState) => {
  const userPref = getState()?.auth?.userPref;
  try {
    const message = getLocalText(
      userPref,
      helpText ? helpText : 'Hi Team, Need help with Lio.',
    );

    const url =
      'https://wa.me/+919619601744?text=' + message.replace(/\n/g, '%0a');

    let isResolved = await openLink(url);
    if (ENV && !isResolved) {
      const {openShareTab} = require('../imports');
      isResolved = await openShareTab(message.replaceAll('%0a', ' \n'));
    }
    if (!isResolved) {
      ShowToast('Something went wrong, please try again', userPref);
    }
  } catch (error) {
    ShowToast('Something went wrong, please try again', userPref);
    captureError(error);
  }
};

const shareRowOnWeb =
  ({userPref, userCountry, processedtableData, extraParams}) =>
  async (_, getState) => {
    try {
      const {table} = getState();
      const {headerData, fileObj} = table;
      let message = formatMsgForWhatsappWeb({
        headerData: extraParams?.isMiniApps
          ? extraParams?.headerData
          : headerData,
        tableData: processedtableData,
        userCountry,
        userPref,
        fileObj: extraParams?.isMiniApps ? extraParams?.fileObj : fileObj,
      });

      if (message === '') {
        ShowToast('No data present to share.', userPref);
        return;
      } else {
        message += `%0a${getLocalText(
          userPref,
          'Download Free Lio App',
        )} : https://lio.app.link/B3c1dlEKflb`;
      }

      const url = 'https://wa.me/?text=' + message.replace(/\n/g, '%0a');

      let isResolved = await openLink(url);
      if (ENV && !isResolved) {
        const {openShareTab} = require('../imports');
        isResolved = await openShareTab(message.replaceAll('%0a', ' \n'));
      }
      if (!isResolved) {
        ShowToast('Something went wrong, please try again', userPref);
      }
    } catch (error) {
      ShowToast('Something went wrong, please try again', userPref);
      captureError(error);
    }
  };

const shareRow =
  ({
    rowIndex,
    rowDataToShare,
    selectableShare,
    selectedRow,
    selectedCol,
    docId,
    ErrorAlert,
    headerDataArray,
  }) =>
  async (dispatch, getState) => {
    try {
      const {shareOnWhatsapp} = require('../imports');
      const {
        table,
        searchFilter,
        premium: {isUserSubscribed},
        auth: {userPref, userCountry},
      } = getState();
      let msgTableData = [],
        msgHeaderData = headerDataArray?.length ? headerDataArray : [];
      if (rowDataToShare) {
        //quick entry
        msgTableData = [Object.assign({}, rowDataToShare)];
      } else if (rowIndex >= 0) {
        //row index passed
        const rowObj = searchFilter.isActive
          ? searchFilter.searchFilterRowIdDataMap[
              searchFilter.searchFilterTableData[rowIndex]
            ]
          : table.tableData[rowIndex];
        msgTableData = [Object.assign({}, rowObj)];
      } else {
        //other cases
        const {getDocumentDataForExport} = require('../utils/exportFile');
        const {tableData, headerData} = await getDocumentDataForExport({
          selectableShare,
          selectedRow,
          selectedCol,
          docId,
          ErrorAlert,
        });
        msgTableData = tableData;
        msgHeaderData = headerData;
      }
      const headerData =
        msgHeaderData.length > 0 ? msgHeaderData : table.headerData.slice();
      let message = formatMsgForWhatsapp({
        headerData,
        tableData: msgTableData,
        userCountry,
        userPref,
        fileObj: table.fileObj,
      });
      if (message === '') {
        ShowToast('No data present to share.', userPref);
        return;
      } else if (!isUserSubscribed) {
        message += `\n${getLocalText(
          userPref,
          'Download Free Lio App',
        )} : https://lio.app.link/B3c1dlEKflb`;
      }
      let isResolved = await shareOnWhatsapp({message});
      if (isResolved?.isBoth) {
        dispatch(
          shareWithWhatsapp({
            isShare: true,
            isBusiness: null,
            options: {message},
          }),
        );
      }
      if (ENV && !isResolved) {
        const {openShareTab} = require('../imports');
        isResolved = await openShareTab(message);
      }
      if (!isResolved) {
        ShowToast('Something went wrong, please try again', userPref);
      }
    } catch (error) {
      const {
        auth: {userPref},
      } = getState();
      if (error.code === 'package-not-installed') {
        ShowToast('Whatsapp not installed on your device', userPref);
      } else {
        ShowToast('Something went wrong, please try again', userPref);
        captureError(error);
      }
    }
  };

/**
 * move row from one position to other
 */
const moveRowAtPos =
  (...args) =>
  async (dispatch, getState) => {
    const [removeFrom, insertAt] = args;
    try {
      // remove the element and then insert
      const {
        table,
        home: {activeDocumentId},
        auth: {userPref},
      } = getState();

      const maxIndex = currentDocNoOfRows(table);
      const tableLength = table.tableData.length;

      const indexArr = [removeFrom, insertAt];
      if (
        indexArr.some((index) => !(index >= 0 && index < maxIndex)) ||
        removeFrom === insertAt
      ) {
        //invalid movement of row
        return;
      }

      if (
        !table.areAllRowsFetched &&
        indexArr.some((index) => index >= tableLength)
      ) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(moveRowAtPos(...args));
      }
      const prevTableData = table.tableData.slice();
      const tableData = table.tableData.slice();
      const isMoveAbove = insertAt < removeFrom;
      const refVal = isMoveAbove ? 1 : -1;
      const indexA = prevTableData[insertAt]?.index;
      const indexB = prevTableData[insertAt - refVal]?.index ?? indexA - refVal;
      const IndexHelper = new tableActionHelper.GetIncrementalIndexes(
        indexA,
        indexB,
        1,
      );
      if (!IndexHelper.areIndexesPossible()) {
        return ShowToast('Row cannot be move to provided position.', userPref);
      }
      const newSortedIndex = IndexHelper.getNextIndex();
      const prevRowObj = cloneDeep(prevTableData[removeFrom]);
      const [rowObject] = tableData.splice(removeFrom, 1);
      Object.assign(rowObject, {index: newSortedIndex});
      tableData.splice(insertAt, 0, rowObject);

      dispatch({
        type: TABLE_ACTION.MOVE_ROW,
        payload: {
          removeFrom,
          insertAt,
          tableData,
          prevRowObj,
          newRowObj: cloneDeep(Object.assign({}, rowObject)),
        },
      });
      DocumentsMethods.addMultipleRows(
        activeDocumentId,
        [rowObject],
        null,
        false,
        false,
        true,
      );
    } catch (error) {
      captureError(error);
    }
  };

/**
 * move column from one position to other
 */
const moveColumn =
  (currentHeader, updatedHeaderData) => (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId},
      } = getState();
      dispatch({
        type: TABLE_ACTION.MOVE_COLUMN,
        payload: {
          updatedHeaderData,
          currentHeader,
        },
      });
      DocumentsMethods.replaceHeaderData(activeDocumentId, updatedHeaderData);
    } catch (error) {
      captureError(error);
    }
  };

/**
 * freeze a column for scroll
 */
const freezeDocumentColumns = (colId) => (dispatch) => {
  try {
    dispatch({
      type: TABLE_ACTION.SET_FROZEN_COLUMN_IDS,
      payload: [colId],
    });
  } catch (error) {
    captureError(error);
  }
};

const addOrUpdateFrequentColors = (color) => (dispatch, getState) => {
  try {
    const {auth} = getState();
    if (isArray(auth.userPref?.frequentlyUsedColors)) {
      let colors = [...auth.userPref.frequentlyUsedColors];
      if (!colors.includes(color)) {
        if (colors.length === 5) {
          colors.pop();
        }
        colors = [color, ...colors];
        dispatch(setUserPref({frequentlyUsedColors: colors}));
      } else {
        // TO CHECK IF color already at first place
        // If at first no need to update
        if (colors.indexOf(color) != 0) {
          colors = [color, ...colors.filter((item) => item != color)];
          if (colors.length <= 5) {
            dispatch(setUserPref({frequentlyUsedColors: colors}));
          }
        }
      }
    } else {
      let colors = [];
      colors = [color];
      dispatch(setUserPref({frequentlyUsedColors: colors}));
    }
  } catch (error) {
    captureError(error);
  }
};

const unfreezeDocumentColumns = () => (dispatch) => {
  dispatch({type: TABLE_ACTION.UNFREEZE_COLUMNS});
};

const saveTableRowsHeight =
  (height, rowType, rowIndex = null) =>
  (dispatch, getState) => {
    try {
      let {tableRowHeights} = getState().rowHeight;
      tableRowHeights = {
        ...tableRowHeights,
        [['header', 'footer'].includes(rowType)
          ? rowType
          : rowIndex != null
          ? rowIndex
          : 'firstRowHeight']: height,
      };

      dispatch({
        type: ROW_HEIGHT_ACTION.SAVE_TABLE_ROW__HEIGHT,
        payload: tableRowHeights,
      });
    } catch (error) {
      captureError(error);
    }
  };

const resetRowHeightValues = () => (dispatch) => {
  dispatch({type: ROW_HEIGHT_ACTION.RESET_HEIGHT_VALUES});
};

const callPendingAutomations = (triggerType) => async (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentId, originalDocumentId, activeDocumentMeta},
      automation: {pendingAutomations},
      auth: {userPref},
    } = getState();

    const isShared = !isEmpty(activeDocumentMeta?.collab);
    const collab = activeDocumentMeta?.collab;
    const permission = collab?.permission;
    const shouldRun =
      [
        SHARE_PERMISSION_TYPE.CUSTOM,
        SHARE_PERMISSION_TYPE.ENTRY_ONLY,
        SHARE_PERMISSION_TYPE.ADMIN,
        SHARE_PERMISSION_TYPE.CAN_EDIT,
      ].includes(permission) ||
      collab?.isOwner ||
      !isShared;
    if (!activeDocumentMeta?.isAutomationEnabled) {
      return;
    }
    let response;
    if (shouldRun && !isEmpty(pendingAutomations)) {
      const analyticsObj = {
        docId: originalDocumentId,
        trigger: triggerType,
        numRows: Object.keys(pendingAutomations).length,
        actionType: 'Whatsapp Message',
      };
      logAnalyticsEvent('SEND_PENDING_AUTOMATION_INIT', analyticsObj);
      if (triggerType === 'Manual') {
        response = await functions()
          .httpsCallable(CLOUD_FUNCTION_PATHS.AUTO_MESSAGE_WHATSAPP_CALL)({
            docId: activeDocumentId,
            originalDocumentId,
          })
          .catch((err) => {
            if (err?.code === functions(true).HttpsErrorCode.UNAVAILABLE) {
              ShowToast(
                'Please check your internet connection and try again.',
                userPref,
              );
            }
            captureError(err);
          });
      } else {
        functions()
          .httpsCallable(CLOUD_FUNCTION_PATHS.AUTO_MESSAGE_WHATSAPP_CALL)({
            docId: activeDocumentId,
            originalDocumentId,
          })
          .catch((err) => {
            captureError(err);
          });
      }
      logAnalyticsEvent('SEND_PENDING_AUTOMATION_COMPLETE', analyticsObj);
    }
    return response;
  } catch (error) {
    captureError(error);
  }
};

const checkParticipantAppVersion = (docId) => async (dispatch) => {
  const failedDueToVersionArr = await checkParticipantVersion(docId);
  if (failedDueToVersionArr) {
    dispatch({
      type: TABLE_ACTION.SET_PARTICIPANT_LIST,
      payload: failedDueToVersionArr,
    });
  }
};

const filterAndSetTableDataViewOnlyEntry =
  (pageDocId = null) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId, activeDocumentMeta, originalDocumentId},
        auth: {user, userPref},
      } = getState();
      const docId = pageDocId
        ? pageDocId
        : activeDocumentMeta?.activePageId
        ? activeDocumentMeta.activePageId
        : activeDocumentId;
      if (pageDocId) {
        const updateObj = {
          activePageId: pageDocId,
        };
        const updatedMetaData = Object.assign(
          {},
          activeDocumentMeta,
          updateObj,
        );
        const originalDocId = originalDocumentId;
        setFileDetails(
          user.uid,
          originalDocId,
          Object.assign({}, {collab: updatedMetaData.collab}, updateObj),
        );
        dispatch({
          type: HOME_ACTION.UPDATE_SELECTED_PAGE,
          payload: {
            activeDocumentId: pageDocId,
            activeDocumentMeta: updatedMetaData,
          },
        });
      } else {
        dispatch({
          type: HOME_ACTION.UPDATE_SELECTED_PAGE,
          payload: {
            activeDocumentId: docId,
            activeDocumentMeta,
          },
        });
      }
      const docDataObj = await DocumentsMethods.getUserDocumentData(docId);
      const tableDataCopy = docDataObj.tableData;

      const newTableData = [];

      for (let i = 0; i < tableDataCopy.length; i++) {
        const createdByUID =
          tableDataCopy[i]?.['rowProperties']?.createdByUID ?? null;
        if (createdByUID && createdByUID === user?.uid) {
          newTableData.push(tableDataCopy[i]);
        }
      }

      const payload = Object.assign(docDataObj, {
        tableData: newTableData,
        originalHeaderData: docDataObj.headerData,
        headerData: restrictHeaderData(
          activeDocumentMeta,
          docDataObj.headerData,
        ),
      });

      if (isEmpty(newTableData)) {
        ShowToast('There is no data added by you in this page.', userPref);
      }
      dispatch({type: TABLE_ACTION.LOAD_TABLE_DATA, payload});
    } catch (error) {
      captureError(error);
    }
  };

const fetchViewEntryData =
  ({isInitialFetch = false, documentId}) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId, activeDocumentMeta},
        auth: {user, userPref},
        table: {tableData},
      } = getState();
      const docId = documentId
        ? documentId
        : activeDocumentMeta?.activePageId
        ? activeDocumentMeta.activePageId
        : activeDocumentId;
      let payload = {};
      if (isInitialFetch) {
        const promiseArray = [];
        const tableMetaDataPromise =
          DocumentsMethods.getUserDocumentDataWithoutRows(docId);
        promiseArray.push(tableMetaDataPromise);
        const tableRowsDataPromise = DocumentsMethods.getRowsData({
          docId,
          isLimited: true,
          limit: TABLE_LIMITS.INITIAL_FETCH_LIMIT,
          isForViewEntry: true,
          userIdForQuery: user.uid,
        });
        promiseArray.push(tableRowsDataPromise);
        const [docData, docTableData] = await Promise.all(promiseArray);
        const updatedTableData = tableActionHelper.processRowsData(
          docTableData,
          null,
          [],
          null,
        );
        if (updatedTableData.length < TABLE_LIMITS.INITIAL_FETCH_LIMIT) {
          dispatch({
            type: TABLE_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE,
            payload: true,
          });
        }
        const {
          home: {activeDocumentMeta: latestActiveDocumentMeta},
        } = getState();
        payload = Object.assign(docTableData, {
          tableData: updatedTableData,
          originalHeaderData: docData.headerData,
          headerData: restrictHeaderData(
            latestActiveDocumentMeta,
            docData.headerData,
          ),
        });
        if (isEmpty(updatedTableData)) {
          ShowToast('There is no data added by you in this page.', userPref);
        } else {
          dispatch({
            type: TABLE_ACTION.UPDATE_INITIAL_DATA_FETCH_OBJ,
            payload: {
              [INITIAL_FETCH_COMPLETED.VIEW_ENTRY_DATA]: true,
            },
          });
        }
      } else {
        const lastSortIndex = tableData[tableData.length - 1]?.index ?? 0;
        if (!isNil(lastSortIndex)) {
          const docTableData = await DocumentsMethods.getRowsData({
            docId: documentId,
            lastFetchedIndex: lastSortIndex,
            isLimited: true,
            limit: TABLE_LIMITS.INITIAL_FETCH_LIMIT,
            isForViewEntry: true,
            userIdForQuery: user.uid,
          });
          const updatedTableData = tableActionHelper.processRowsData(
            docTableData,
            null,
            tableData,
            null,
          );
          const {
            table: {headerData: latestHeaderData},
          } = getState();
          payload = Object.assign(docTableData, {
            tableData: updatedTableData,
            // originalHeaderData: docData.headerData,
            headerData: latestHeaderData,
          });
        }
      }
      const {
        home: {activeDocumentMeta: latestActiveDocumentMeta},
      } = getState();
      const updateObj = {
        activePageId: docId,
      };
      const updatedMetaData = Object.assign(
        {},
        latestActiveDocumentMeta,
        updateObj,
      );
      dispatch({
        type: HOME_ACTION.UPDATE_SELECTED_PAGE,
        payload: {
          activeDocumentId: docId,
          activeDocumentMeta: updatedMetaData,
        },
      });
      if (!isEmpty(payload)) {
        dispatch({type: TABLE_ACTION.LOAD_TABLE_DATA, payload});
        return payload.tableData;
      }
    } catch (error) {
      captureError(error);
    }
  };

const handlePagesForViewEntry = () => (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentMeta, activePageIdCacheForViewEntry},
    } = getState();
    if (activeDocumentMeta?.pageObj?.enabled) {
      const docId = activePageIdCacheForViewEntry
        ? activePageIdCacheForViewEntry
        : activeDocumentMeta.activePageId;
      const updateObj = {
        activePageId: docId,
      };
      const updatedMetaData = Object.assign({}, activeDocumentMeta, updateObj);
      dispatch({
        type: HOME_ACTION.UPDATE_SELECTED_PAGE,
        payload: {
          activeDocumentId: docId,
          activeDocumentMeta: updatedMetaData,
        },
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const addUniqueValue =
  (colObj, val, rowIndex, extra = {}) =>
  (_, getState) => {
    try {
      const {rowObj} = extra || {};
      const colId = colObj.id;
      const {
        home: {activeDocumentId},
        table: {tableData},
        searchFilter,
      } = getState();
      val = (checkIfPlainText(val) ? `${val}` : '').toLowerCase().trim();

      if (colObj.fieldType === FIELD_TYPE_ID.TIME) {
        val = val ? `${getPrintTime(val)}`.toLowerCase() : '';
      }
      const currentRowObj =
        rowObj ?? getRowObjAtIndex(tableData, searchFilter, rowIndex);
      const cellVal = currentRowObj?.[colId]?.val;
      const prevValue =
        colObj.fieldType === FIELD_TYPE_ID.TIME
          ? `${getPrintTime(cellVal)}`.toLowerCase().trim()
          : (checkIfPlainText(cellVal) ? `${cellVal}` : '').toLowerCase();

      const updateObj = {};
      if (!isNil(val) && val !== '') {
        updateObj[val] = true;
      }
      if (!isNil(prevValue) && prevValue !== '') {
        updateObj[prevValue] = false; // used false instead of delete
      }
      if (!isEmpty(updateObj)) {
        DocumentsMethods.setUniqueValuesDataForColumn(
          activeDocumentId,
          colId,
          updateObj,
          true,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const filterAndSetUniqueValues =
  (colId = '', fieldType = null) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId},
        table: {tableData, areAllRowsFetched},
      } = getState();
      if (!areAllRowsFetched) {
        await dispatch(checkAndFetchAllRows());
        return dispatch(filterAndSetUniqueValues(colId));
      }
      const updatedUniqueValues = {};
      for (let i = 0; i < tableData.length; i++) {
        const tableValue = tableData[i][colId]?.val
          ? fieldType === FIELD_TYPE_ID.TIME
            ? `${getPrintTime(tableData[i][colId]?.val)}`
            : `${tableData[i][colId]?.val}`
          : '';
        if (tableValue) {
          const newValue = tableValue?.toLowerCase().trim();
          updatedUniqueValues[newValue] = true;
        }
      }
      DocumentsMethods.setUniqueValuesDataForColumn(
        activeDocumentId,
        colId,
        updatedUniqueValues,
      );
    } catch (error) {
      captureError(error);
    }
  };

const filterAndSetUniqueDataForFile = (args) => async (dispatch, getState) => {
  try {
    const {
      table: {tableData, headerData, areAllRowsFetched},
    } = getState();
    for await (const colData of headerData) {
      const updatedUniqueValues = {};
      if (colData?.columnProperties?.UNIQUE_VALUES) {
        if (!areAllRowsFetched) {
          await dispatch(checkAndFetchAllRows());
          return dispatch(filterAndSetUniqueDataForFile(args));
        }
        const colId = colData.id;
        for (let i = 0; i < tableData.length; i++) {
          const tableValue = tableData[i][colId]?.val
            ? `${tableData[i][colId]?.val}`
            : '';
          if (tableValue) {
            const newValue = tableValue?.toLowerCase();
            updatedUniqueValues[newValue] = true;
          }
        }
        DocumentsMethods.setUniqueValuesDataForColumn(
          args.activeDocumentId,
          colId,
          updatedUniqueValues,
        );
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const clearParticipantList = () => (dispatch) => {
  dispatch({type: TABLE_ACTION.CLEAR_PARTICIPANT_LIST});
};

const setPremiumAutomationBannerShown = () => (dispatch) => {
  dispatch({type: TABLE_ACTION.SET_SHOW_AUTOMATION_BANNER});
};

const addOrEditComment =
  (columnId, rowId, rowIndex, commentText, options = {}) =>
  (dispatch, getState) => {
    const {
      commentId = null,
      commentsList = [],
      isFromRowEdit = false,
      rowObj = null,
      docIdToUse,
      checkLatestComment = false,
    } = options ?? {};
    try {
      const {
        table: {tableData, originalHeaderData},
        auth: {user, userPref},
        home: {activeDocumentId, activeDocumentMeta, originalDocumentId},
        searchFilter,
      } = getState();
      const docId = docIdToUse ?? activeDocumentId;

      const timestamp = moment().utc().unix();
      const {ENTRY_ONLY} = TABLE_PRESS_RESTRICTIONS.SHARED_PERMISSIONS({
        collab: activeDocumentMeta?.collab,
      });
      const isEdit = !isNil(commentId);
      const contact = user.phoneNumber ?? user.email;
      const uid = user.uid;
      const name = userPref.name?.length ? userPref.name : contact;
      const isLatestCommentBeingEdited =
        isEdit && commentsList?.length && commentsList[0]?.id === commentId
          ? true
          : false;

      if (ENTRY_ONLY && !isFromRowEdit) {
        const commentVal = {
          addedByName: name,
          timestamp,
          uid: user.uid,
          val: commentText,
        };

        return dispatch(
          addEntryOnlyData(Object.assign({}, {[columnId]: commentVal}), {
            addEntryToParentFile: false,
            docId: docId,
            fileName: activeDocumentMeta?.name,
            headerData: originalHeaderData,
            originalDocumentId: originalDocumentId,
            isRowEdit: true,
            rowId: rowId,
            addCommentFirst: true,
          }),
        );
      }

      if ((!isEdit || isLatestCommentBeingEdited) && !isFromRowEdit) {
        const isSearchFilterActive = searchFilter.isActive;
        let currentRowObj =
          rowObj ??
          Object.assign(
            {},
            isSearchFilterActive
              ? searchFilter.searchFilterRowIdDataMap[
                  searchFilter.searchFilterTableData[rowIndex]
                ]
              : tableData[rowIndex],
          );

        if (currentRowObj?.rowId !== rowId && !rowObj) {
          rowIndex = tableData.findIndex((rowData) => rowData.rowId === rowId);
          if (rowIndex === -1) {
            return;
          }
          currentRowObj = Object.assign({}, tableData[rowIndex]);
        }

        const updatedCellObj = Object.assign({}, currentRowObj[columnId], {
          val: commentText,
          addedByName: name,
          uid,
          timestamp: isLatestCommentBeingEdited
            ? commentsList[0].createdTimestamp
            : timestamp,
        });

        if (!isFromRowEdit) {
          dispatch(
            editRow(
              Object.assign({}, currentRowObj, {[columnId]: updatedCellObj}),
              rowIndex,
              {checkPendingAutomation: false},
              true,
            ),
          );
        }
      }

      if (isEdit) {
        const commentObj = CommentsUtils.generateEditedCommentObj(
          commentText,
          {}, //empty as final new obj not required here
        )[1];
        DocumentsMethods.editComment(
          docId,
          rowId,
          commentId,
          Object.assign(
            {},
            commentObj,
            checkLatestComment === true ? {checkLatestComment} : null,
          ),
        );
      } else {
        const commentObj = CommentsUtils.generateCommentObj(
          columnId,
          rowId,
          commentText,
        );
        DocumentsMethods.addComment(
          docId,
          rowId,
          Object.assign(
            {},
            commentObj,
            checkLatestComment === true ? {checkLatestComment} : null,
          ),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const deleteComment =
  (columnId, rowId, rowIndex, commentId, commentsList, options = {}) =>
  (dispatch, getState) => {
    try {
      const {isFromRowEdit, docIdToUse} = options ?? {};

      const {
        table: {tableData},
        home: {activeDocumentId},
        searchFilter,
      } = getState();

      const docId = docIdToUse ?? activeDocumentId;

      const isLatestCommentBeingDeleted =
        commentsList[0]?.id === commentId && !isFromRowEdit ? true : false;

      if (isLatestCommentBeingDeleted) {
        const secondLastCommentObj = Object.assign({}, commentsList[1]);
        const isSecondLastCommentAvailable = !isEmpty(secondLastCommentObj);

        const isSearchFilterActive = searchFilter.isActive;
        let currentRowObj = Object.assign(
          {},
          isSearchFilterActive
            ? searchFilter.searchFilterRowIdDataMap[
                searchFilter.searchFilterTableData[rowIndex]
              ]
            : tableData[rowIndex],
        );

        if (currentRowObj.rowId !== rowId) {
          rowIndex = tableData.findIndex((rowObj) => rowObj.rowId === rowId);
          if (rowIndex === -1) {
            return;
          }
          currentRowObj = Object.assign({}, tableData[rowIndex]);
        }

        const updatedCellObj = Object.assign({}, currentRowObj[columnId], {
          val: isSecondLastCommentAvailable ? secondLastCommentObj.text : '',
          addedByName: isSecondLastCommentAvailable
            ? secondLastCommentObj.addedBy.name
            : null,
          timestamp: isSecondLastCommentAvailable
            ? secondLastCommentObj.createdTimestamp
            : null,
        });

        dispatch(
          editRow(
            Object.assign({}, currentRowObj, {[columnId]: updatedCellObj}),
            rowIndex,
            {checkPendingAutomation: false},
            true,
          ),
        );
      }
      DocumentsMethods.deleteComment(docId, rowId, commentId);
    } catch (error) {
      captureError(error);
    }
  };

const appendDataToCurrentTable =
  ({newData, onSuccess, onfailure}) =>
  async (dispatch, getState) => {
    // type of newData = {tableData,headerData}
    try {
      const {
        table,
        home: {activeDocumentId},
      } = getState();
      const {newTableData, newHeaderData, newFooterData} =
        await tableActionHelper.mergeDataFromTwoTables(table, newData);

      const payload = {
        tableData: tableActionHelper.prepareTableDataForFirestore(newTableData),
        headerData: newHeaderData,
        footerData: newFooterData,
      };

      dispatch({
        type: TABLE_ACTION.APPEND_DATA_IN_BULK,
        payload,
      });

      await DocumentsMethods.updateMultipleRows(
        activeDocumentId,
        payload.tableData,
        {headerData: payload.headerData, footerData: payload.footerData},
        true,
      );
      onSuccess?.();
    } catch (err) {
      captureError(err);
      onfailure?.();
    }
  };

const checkAndAddUniqueValues =
  ({
    columnObj = {},
    value,
    rowIndex,
    rowObj = null,
    isDeleted = false,
    deletedVal = null,
  }) =>
  (dispatch, getState) => {
    try {
      const {
        auth,
        table: {uniqueColumnData, tableData},
        searchFilter,
        home: {activeDocumentId},
      } = getState();

      const currentRowObj =
        rowObj ?? getRowObjAtIndex(tableData, searchFilter, rowIndex);

      const prevValue = currentRowObj?.[columnObj?.id]?.val;
      if (isNil(value.val) && isNil(prevValue)) {
        return false;
      }
      if (columnObj?.columnProperties?.UNIQUE_VALUES === true) {
        const uniqueColumnDataObj = Object.assign({}, uniqueColumnData);
        const uniqueValueObject = uniqueColumnDataObj[columnObj.id];
        let checkVal = value?.val;

        checkVal = String(checkVal).toLowerCase().trim();

        if (columnObj.fieldType === FIELD_TYPE_ID.TIME) {
          checkVal = `${getPrintTime(value?.val)}`.toLowerCase().trim();
        }
        if (uniqueValueObject && checkVal && uniqueValueObject[checkVal]) {
          ShowToast('Not a Unique value', auth.userPref);
          return true;
        } else if (!isNil(value?.val)) {
          dispatch(addUniqueValue(columnObj, value?.val, rowIndex, {rowObj}));
        }
        if (isDeleted && deletedVal) {
          if (columnObj.fieldType === FIELD_TYPE_ID.TIME) {
            deletedVal = getPrintTime(deletedVal);
          }
          deletedVal = `${deletedVal}`.toLowerCase().trim();
          DocumentsMethods.updateUniqueValuesDataForColumn(
            activeDocumentId,
            columnObj.id,
            {
              [`data.${deletedVal}`]: false,
            },
          );
        }
      }
      return false;
    } catch (error) {
      captureError(error);
    }
  };

const showInformationModal =
  (messageObj = {}) =>
  (dispatch) => {
    dispatch({type: TABLE_ACTION.SHOW_INFORMAITON_MODAL, payload: messageObj});
  };

const addTableColumnForSublistAndHandleMapping =
  (subListColObj = {}) =>
  async (dispatch, getState) => {
    try {
      const {home} = getState();
      const documentDataIndex = home.files.findIndex(
        (item) => item.documentId === subListColObj.originalDocumentId,
      );

      if (documentDataIndex === -1) return;

      const activeDocumentMeta = home?.files[documentDataIndex].documentMeta;
      let headerData;
      let fileObj;

      const res = await DocumentsMethods.getUserDocumentDataWithoutRows(
        subListColObj.originalDocumentId,
      );

      headerData = res?.headerData;
      fileObj = res?.fileObj;

      const type = FIELD_TYPE_ID.TABLE;
      const updatedSublistColObj = {
        ...subListColObj,
        activeDocumentMeta: activeDocumentMeta,
        headerData: headerData,
        fileObj: fileObj,
        type: type,
        noReduxAction: true,
      };

      const addedSublistColData = dispatch(addNewColumn(updatedSublistColObj));

      if (isNil(addedSublistColData.colId)) {
        return;
      }

      const updatedHeaderObject = {
        id: addedSublistColData?.colId,
        fieldType: FIELD_TYPE_ID.TABLE,
        linkedMeta: {
          [subListColObj.parentDocId]: {
            colId: subListColObj.primaryColId,
            colName: subListColObj.primaryColName,
            fileName: subListColObj.parentDocName,
          },
        },
        linkedToList: {colId: subListColObj?.listColHeaderObj?.id},
        subType: subListColObj.subType,
        val: `${subListColObj.primaryColName} (P)`,
        // to make sure Name of TABLE type column is same as Primary Column Name
      };
      DocumentsMethods.updateHeaderDataAtIndex(
        subListColObj?.activeDocumentId,
        addedSublistColData?.index,
        updatedHeaderObject,
      ).then(() => {
        const autoFillPayload = {
          activeDocId: updatedSublistColObj.originalDocumentId,
          parentDocID: updatedSublistColObj.parentDocId,
          parentColId: updatedSublistColObj.primaryColId,
          childColId: addedSublistColData?.colId,
        };
        setAutoFillLinkedDataFirestore(autoFillPayload);
        dispatch(
          manageLinkedIdsList(updatedSublistColObj.parentDocId, {
            activeDocumentMeta,
            docId: updatedSublistColObj.originalDocumentId,
          }),
        );
      });
      // adds colId of TABLE col added in subList file to listConfig key in headerDataObj
      let updatedHeaderObjForListCol = Object.assign(
        {},
        subListColObj?.listColHeaderObj,
        {
          ...subListColObj?.listColHeaderObj,
          listConfig: {
            ...subListColObj?.listColHeaderObj?.listConfig,
            subListPrimaryColId: addedSublistColData.colId,
          },
        },
      );
      //add default mapping flow
      if (subListColObj?.addDefaultMapping) {
        // this flow will run only if
        // user has not given any mapping configuration
        let defaultMappedValues = {};
        headerData
          .filter((item) =>
            MINI_APPS.ALLOWED_FIELD_TYPES.includes(item.fieldType),
          )
          .filter(
            (item) =>
              !Object.values(MINI_APPS.SPECIAL_FIELD_MAPPING).includes(
                item.fieldType,
              ),
          )
          .map((item, index) => {
            if (index <= 5)
              defaultMappedValues = Object.assign({}, defaultMappedValues, {
                [MINI_APPS?.LAYOUT_FIELDS?.['DEFAULT'][index]]: item.id,
              });
            return 0;
          });
        updatedHeaderObjForListCol = Object.assign(
          {},
          updatedHeaderObjForListCol,
          {
            ...updatedHeaderObjForListCol,
            listConfig: {
              ...updatedHeaderObjForListCol.listConfig,
              previewConfig: {mappedValues: defaultMappedValues},
            },
          },
        );
      }
      // updated header data for newly added LIST type Column
      DocumentsMethods.updateHeaderDataAtIndex(
        subListColObj?.parentDocId,
        subListColObj?.listColIndex,
        updatedHeaderObjForListCol,
      );
      // update header data for selected primary column to make it MANDATORY
      const newPrimaryColumnHeaderObj = Object.assign(
        {},
        subListColObj?.primaryColumnHeaderObj,
        {
          columnProperties: {
            ...subListColObj?.primaryColumnHeaderObj?.columnProperties,
            [COLUMN_PROPERTY_KEYS.MANDATORY]: true,
          },
        },
      );

      const {headerData: updatedHeaderData} = getState().table;
      const updatedPrimaryColIndex = updatedHeaderData.findIndex(
        (headerObj) => headerObj.id === subListColObj.primaryColId,
      );
      if (updatedPrimaryColIndex >= 0) {
        DocumentsMethods.updateHeaderDataAtIndex(
          subListColObj?.parentDocId,
          updatedPrimaryColIndex,
          newPrimaryColumnHeaderObj,
        );
      } else {
        captureInfo({
          primaryColId: subListColObj.primaryColId,
          docId: subListColObj?.parentDocId,
        });
        captureError(new Error('Primary Column Index not found - LIST COLUMN'));
      }
      return;
    } catch (error) {
      captureError(error);
    }
  };

export {
  activateDocumentListener,
  activateSharedDocMetaListener,
  activeDocumentFooterPropertiesListener,
  activateUniqueColumnValuesListener,
  addClientDataForPDF,
  addColMid,
  addDummyMetaData,
  addEmptyRow,
  addNewColumn,
  addNewRowInBetween,
  addOrEditComment,
  addOrUpdateFrequentColors,
  addPDFData,
  addToHelperFunctionRefs,
  addUniqueValue,
  appendDataToCurrentTable,
  callPendingAutomations,
  checkAndAddUniqueValues,
  checkAndFetchAllRows,
  checkParticipantAppVersion,
  clearLastState,
  clearParticipantList,
  clearSelectedPageData,
  createNewDocumentPage,
  createNewFileUsingTemplate,
  createNewFileUsingTemplateCloudFun,
  deleteCol,
  deleteComment,
  deleteDocumentPage,
  deleteFormulaFromColumn,
  deleteRow,
  deleteSelectedRowAndCols,
  documentDataListenerWrapper,
  editColumn,
  editRow,
  filterAndSetTableDataViewOnlyEntry,
  fetchViewEntryData,
  handlePagesForViewEntry,
  filterAndSetUniqueValues,
  filterAndSetUniqueDataForFile,
  formulaEditColumn,
  freezeDocumentColumns,
  getHelpViaWhatsapp,
  getPaginatedTableData,
  handleDocumentMetaListenerChanges,
  loadEntryOnlyDocData,
  loadTableData,
  moveColumn,
  moveRowAtPos,
  redo,
  removeTotal,
  resetRowHeightValues,
  saveTableRowsHeight,
  setCarryForwardColumn,
  setDisplayColIdValue,
  setPremiumAutomationBannerShown,
  shareRow,
  shareRowOnWeb,
  showInformationModal,
  showTotal,
  sortCol,
  toggleDocumentPages,
  undo,
  unfreezeDocumentColumns,
  updateActiveDocMeta,
  updateDisplayColumnValue,
  updateDocumentPageMeta,
  updateDocumentPageName,
  updateHeaderSmsObj,
  updateInitialFetchObj,
  updateLastModifiedTimestamp,
  updateSelectedPage,
  loadInitialDocumentData,
};
